import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import BatchSelectionTable from "./BatchSelectionTable";

import { fetchBatchNumber } from "../../../../inventory/services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  minHeight: "50%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

export default function BatchSelectionModal(props) {
  var token = JSON.parse(localStorage.getItem("userInfo"));
  var storeId = token.storeId;

  const {
    open,
    selctedRowData,
    setSelctedRowData,
    handleClose,
    FocusBatchModal,
    // batchSelctedRowData,
    setBatchSelctedRowData,
    batchData,
    setBatchData,
    //
    selectedDrug,
    setSelectedDrug,
    batchInsideTableInput,
    previousBatchRowData,
    //
    // inputRefThree,
    //
    setSelectedDrugData,
    selectedDrugData,
    selectedItemDtlsID,
    setIndex,
    //
  } = props;

  if (selectedDrug !== null && selectedDrug !== undefined) {
    var BatchSelObj = {
      batchExpDate: new Date(),
      isConsignment: selectedDrug.IsConsignment ? 1 : 0,
      itemId: selectedDrug.Id,
      storeId: storeId,
    };
  }
  ///object created inside table input
  if (selctedRowData !== null && selctedRowData !== undefined) {
    var BatchSelObj = {
      batchExpDate: new Date(), //"2023-03-04T10:08:03.766Z",
      isConsignment: selctedRowData.IsConsignment ? 1 : 0,
      itemId: selctedRowData.Id,
      storeId: storeId,
    };
  }

  useEffect(() => {
    console.log(selctedRowData, selectedDrug);
    getBatchNo();
  }, [selctedRowData, selectedDrug]);

  const getBatchNo = () => {
    fetchBatchNumber(BatchSelObj)
      .then((response) => response.data)
      .then((res) => {

        console.log("The found batch data is :",res.result);
        
        // if (
        //   previousBatchRowData.current &&
        //   previousBatchRowData.current.length > 0 &&
        //   res?.result?.length > 0
        // ) {
        //   const previousBatchCodes = previousBatchRowData.current.filter(
        //     (item) => item.BatchNumber !== ""
        //   );
        //   const previousBatchNumbers = previousBatchCodes.map(
        //     (item) => item.BatchNumber
        //   );
        //   const filteredBatchData = res.result.filter((item) => {
        //     return (
        //       item.BatchCode !== "" &&
        //       !previousBatchNumbers.includes(item.BatchCode)
        //     );
        //   });
        //   setBatchData(filteredBatchData);
        // } else {
        //   const filteredBatchData = res.result.filter(
        //     (item) => item.BatchCode !== ""
        //   );
        //   setBatchData(filteredBatchData);
        // }
        setBatchData(res?.result);
        res.result.length < 1 && warningAlert("Stock Unavailable...");
      });
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={(e) => {
              handleClose();
              e.preventDefault();
              setBatchSelctedRowData(null);
              setIndex(null);
              setBatchData([]);
              // setSelectedDrug(null)
            }}
          />
          <div>
            <div className="my-2">
              <span className="my-2 text-gray-700 font-semibold whitespace-nowrap">
                Item List
              </span>
            </div>
            <div className="">
              <fieldset
                className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
              >
                <div className=" grid  grid-cols-3 2xl:grid-cols-3  ">
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Description</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {(selctedRowData && selctedRowData.Drug) ||
                          (selectedDrug && selectedDrug.Drug)}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Item Code</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {(selctedRowData && selctedRowData.Code) ||
                          (selectedDrug && selectedDrug.Code)}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div ref={FocusBatchModal}>
              {(batchData && batchData.length > 0) ||
              (selctedRowData && selctedRowData.length > 0) ? (
                <BatchSelectionTable
                  data={batchData}
                  batchData={batchData}
                  setBatchData={setBatchData}
                  handleClose={handleClose}
                  setBatchSelctedRowData={setBatchSelctedRowData}
                  selctedRowData={selctedRowData}
                  setSelctedRowData={setSelctedRowData}
                  batchInsideTableInput={batchInsideTableInput}
                  previousBatchRowData={previousBatchRowData}
                  setIndex={setIndex}
                  // onEnterFocusNext={onEnterFocusNext}
                  // inputRefThree={inputRefThree}

                  //
                  setSelectedDrugData={setSelectedDrugData}
                  selectedDrugData={selectedDrugData}
                  selectedItemDtlsID={selectedItemDtlsID}
                />
              ) : (
                <div className=" font-semibold  text-center mt-4">
                  No Data Found
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import { TextField } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import InputArea from "../../../../common/components/FormFields/InputArea";
import { OtDetailsContext } from "./OtDetails";
import { useContext } from "react";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";

const AnesthetiaNotes = () => {
  const { patchData } = useContext(OtDetailsContext);
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    //

    let inputString = patchData?.AnesthesiaRecord;
    let keyValuePairs = inputString?.split(",");

    let result = {};

    keyValuePairs?.forEach((pair) => {
      let parts = pair.split("-");

      if (parts.length === 2) {
        let key = parts[0].trim();
        let value = parts[1].trim();
        result[key] = value;
      }
    });

    console.log(result, patchData?.AnesthesiaRecord);
    if (!!result) {
      setValue("anesthetiaType", result?.anesthetiaType || "");
      setValue("startingTime", result?.startingTime || new Date());
      setValue("endingTime", result?.endingTime || new Date());
      setValue("bp", result?.bp || "");
      setValue("pulseType-A", result?.pulseType || "");
    }
    //
  }, [patchData]);

  return (
    <>
      <div className=" text-gray-700 font-semibold whitespace-nowrap">
        Anesthesia Notes
      </div>
      <div className="mt-3 grid grid-cols-6 gap-3">
        <div className="col-span-2">
          <InputArea
            name="anesthetiaType"
            label="Anesthesia Type "
            placeholder="Anesthesia Type"
          />
        </div>

        <div className=" ">
          <Controller
            control={control}
            name="startingTime"
            render={({ field: { value, onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label="Starting Time"
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={errors.startTime}
                      helperText={errors.startTime?.message}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                      }}
                      sx={{
                        svg: {
                          color: "#1e3a8a",
                          height: 22,
                          width: "100%",
                          marginRight: "16px",
                        },
                        backgroundColor: "white",
                        overflow: "visible",

                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-input": {
                            // border: 0,
                            fontSize: 14,
                            height: "18px",
                            width: "100%",

                            borderColor: "  ",
                            overflow: "hidden",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </div>

        <div className=" ">
          <Controller
            control={control}
            name="endingTime"
            render={({ field: { value, onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label="Ending Time"
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={errors.endTime}
                      helperText={errors.endTime?.message}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                          position: "absolute",
                          top: "-2px",
                        },
                      }}
                      sx={{
                        svg: {
                          color: "#1e3a8a",
                          height: 22,
                          width: "100%",
                          marginRight: "16px",
                        },
                        backgroundColor: "white",
                        overflow: "visible",

                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-input": {
                            // border: 0,
                            fontSize: 14,
                            height: "18px",
                            width: "100%",

                            borderColor: "  ",
                            overflow: "hidden",
                          },
                          "& .MuiFormLabel-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </div>

        <div>
          <InputField
            name="bp"
            variant="outlined"
            label="BP"
            // error={errors.email}
            control={control}
            inputProps={{ maxLength: 100 }}
          />
        </div>

        <div>
          <InputField
            name="pulseType-A"
            variant="outlined"
            label="Pulse Type-A"
            // error={errors.email}
            control={control}
            inputProps={{ maxLength: 100 }}
          />
        </div>
      </div>

      <hr className="col-span-6 border my-2 divide-x-8 bg-slate-300 border-slate-300" />

      <div className="grid grid-cols-4 gap-3">
        <div>
          <CheckBoxField
            name="anaesthesiaRelatedMortility"
            label="Anaesthesia Related Mortility"
          />
        </div>
        <div className="col-span-3">
          <InputArea
            name="reasonOfAnaesthesiaRelatedMortility"
            label="Reason Of Anaesthesia Related Mortility "
            placeholder="Reason Of Anaesthesia Related Mortility"
          />
        </div>
        <div>
          <CheckBoxField
            name="planOfAnaesthesiaModified"
            label="Plan Of Anaesthesia Modified"
          />
        </div>
        <div className="col-span-3">
          <InputArea
            name="reasonOfModificationOfAnaesthesia"
            label="Reason Of Modification Of Anaesthesia"
            placeholder="Reason Of Modification Of Anaesthesia"
          />
        </div>
        <div>
          <CheckBoxField
            name="unplannedVentilationAnaesthesia"
            label="Unplanned Ventilation Anaesthesia"
          />
        </div>
        <div className="col-span-3">
          <InputArea
            name="reasonOfUnplannedVentilationAnaesthesia"
            label="Reason Of Unplanned Ventilation Anaesthesia"
            placeholder="Reason Of Unplanned Ventilation Anaesthesia"
          />
        </div>
        <div>
          <CheckBoxField
            name="adverseAnaesthesiaEvents"
            label="Adverse Anaesthesia Events"
          />
        </div>
        <div className="col-span-3">
          <InputArea
            name="adverseAnaesthesiaEventsReason"
            label="Adverse Anaesthesia Events Reason"
            placeholder="Adverse Anaesthesia Events Reason"
          />
        </div>
      </div>
    </>
  );
};

export default AnesthetiaNotes;

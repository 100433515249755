import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  getCategoryData,
  getPathTests,
  getPatientTestTATList,
  getPatientTestTATListSearch,
} from "../../Services/Pathalogy/WorkOrderViewServices";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import DatePickerField from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};

function PatientTestTATModal({ openPatientTATModal, setOpenPatientTATModal }) {
  const schema = yup.object({}).required();

  const defaultValues = {
    searchPatient: null,
    fromDate: new Date(),
    toDate: new Date(),
    testType: null,
    category: null,
    tenToSix: false,
  };

  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const fileInputRef = React.useRef();
  const categoryId = watch("category");
  const testId = watch("testType");
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
  const orderDtlsId = watch("searchPatient");
  const getTenToSix = watch("tenToSix");

  const [patientList, setPatientList] = React.useState([]);
  const [searchPateint, setSearchPatient] = React.useState([]);
  const [testOptions, setTestOptions] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [spinner, setSpinner] = React.useState(false);
  const [listingObject, setListingObject] = React.useState({
    categoryId: null,
    fromDate: fromDate,
    orderDtlsId: null,
    testId: null,
    toDate: toDate,
    page: page,
    size: rowsPerPage,
    is10To6PM: getTenToSix,
  });

  console.log(patientList, "patientList");

  useEffect(() => {
    getCategoryData()
      .then((response) => response.data)
      .then((data) => {
        setCategoryOptions(data.result);
      })
      .catch((error) => error);
  }, []);

  const populateTable = (forPagination) => {
    let tempObj = {
      ...listingObject,
      page: !forPagination ? 0 : page,
    };
    if (page === 0) {
      setSpinner(true);
    }
    getPatientTestTATList(tempObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setPatientList((prevData) => [...prevData, ...res.result]);
        } else {
          setPatientList(res.result);
        }
        setCount(res.count);
        setSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  const handleSearchPatient = (e) => {
    let tempObj = {
      categoryId: categoryId ? categoryId.id : null,
      fromDate: fromDate,
      searchString: e,
      testId: testId ? testId.id : null,
      toDate: toDate,
    };
    getPatientTestTATListSearch(tempObj)
      .then((response) => response.data)
      .then((res) => {
        setSearchPatient(res.result);
      })
      .catch((error) => error);
  };

  const handleSearchTest = (e) => {
    getPathTests(e)
      .then((response) => response.data)
      .then((res) => {
        setTestOptions(res.result);
      })
      .catch((error) => error);
  };

  const handleFileUpload = (event) => {};

  const handleReset = () => {
    reset(defaultValues);
    setPatientList([]);
    setPage(0);
    setListingObject({
      categoryId: null,
      fromDate: new Date(),
      orderDtlsId: null,
      testId: null,
      toDate: new Date(),
      page: 0,
      size: rowsPerPage,
      is10To6PM: false,
    });
  };

  React.useEffect(() => {
    if (openPatientTATModal) {
      populateTable();
    }
  }, [
    openPatientTATModal,
    listingObject?.categoryId,
    listingObject?.orderDtlsId,
    listingObject?.fromDate,
    listingObject?.toDate,
    listingObject?.testId,
    listingObject?.is10To6PM,
  ]);

  return (
    <div>
      <Modal
        open={openPatientTATModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="w-[95%] rounded-md space-y-2">
          <div className="flex justify-between">
            <h1 className="font-semibold text-lg w-full">Investigation TAT</h1>
            <CancelPresentationIconButton
              type="button"
              onClick={() => {
                setOpenPatientTATModal(false);
                reset(defaultValues);
                setPatientList([]);
                setListingObject({
                  categoryId: null,
                  fromDate: new Date(),
                  orderDtlsId: null,
                  testId: null,
                  toDate: new Date(),
                  page: 0,
                  size: rowsPerPage,
                  is10To6PM: false,
                });
              }}
            />
          </div>

          <div className="grid grid-cols-11 gap-3">
            <div className="col-span-3">
              <SearchDropdown
                control={control}
                name="searchPatient"
                placeholder={"Search Patient"}
                isClearable={true}
                dataArray={searchPateint}
                menuPlacement={"bottom"}
                searchIcon={true}
                handleInputChange={handleSearchPatient}
              />
            </div>

            <div className="grid grid-cols-2 col-span-2 gap-3">
              <DatePickerField
                control={control}
                name="fromDate"
                label={"From Date"}
                slotProps={{ textField: { size: "small" } }}
                inputFormat="dd-MM-yyyy"
              />

              <DatePickerField
                control={control}
                name="toDate"
                label={"To Date"}
                slotProps={{ textField: { size: "small" } }}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <CheckBoxField
              control={control}
              name="tenToSix"
              label="10 am To 6 pm"
            />
            <div className="col-span-2">
              <SearchDropdown
                control={control}
                name="testType"
                placeholder={"Test"}
                isClearable={true}
                dataArray={testOptions}
                handleInputChange={handleSearchTest}
                searchIcon={true}
              />
            </div>

            <div className="col-span-2">
              <DropdownField
                control={control}
                name="category"
                placeholder={"Category"}
                dataArray={categoryOptions}
                isClearable={true}
              />
            </div>

            <div className="flex gap-2">
              <CommonButton
                searchIcon={true}
                type="button"
                className="bg-customBlue text-white"
                onClick={() => {
                  setListingObject((listingObject) => ({
                    ...listingObject,
                    orderDtlsId: orderDtlsId ? orderDtlsId.id : null,
                    categoryId: categoryId ? categoryId.id : null,
                    fromDate: fromDate ? fromDate : null,
                    testId: testId ? testId.id : null,
                    toDate: toDate ? toDate : null,
                    is10To6PM: getTenToSix ? getTenToSix : false,
                  }));
                }}
              />
              <CommonButton
                type="button"
                label="Reset"
                className="border border-customRed text-customRed"
                onClick={handleReset}
              />
            </div>
          </div>

          <div className="flex pt-2">
            <div className="flex w-full 2xl:w-[80%] ">
              <div className="w-full flex items-center">
                <h2 className="font-bold w-36">Total Investigation</h2>
                <span>: 0</span>
              </div>

              <div className="w-full flex items-center">
                <h2 className="font-bold">In TAT</h2>
                <span>: 0</span>
              </div>

              <div className="w-full flex items-center">
                <h2 className="font-bold">Out TAT</h2>
                <span>: 0</span>
              </div>
            </div>
            <div className="flex justify-end w-full">
              <span>
                <span
                  className="cursor-pointer text-blue-600 font-semibold"
                  onClick={() => fileInputRef.current.click()}
                >
                  Export Excel
                </span>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                {/* <span className="text-black">|</span> */}
              </span>
            </div>
          </div>

          {spinner && (
            <div className="grid justify-center items-center h-96">
              <LoadingSpinner />
            </div>
          )}
          <div>
            {patientList.length > 0 && !spinner ? (
              <CommonDynamicTablePaginationNew
                dataResult={patientList}
                page={page}
                highlightRow={false}
                tableClass="capitalize"
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                count={count}
                setRowsPerPage={setRowsPerPage}
                populateTable={populateTable}
              />
            ) : (
              <>
                {!spinner && (
                  <div className="flex justify-center h-96 items-center font-semibold">
                    No Record Found <span className="animate-pulse">...</span>
                  </div>
                )}
              </>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default PatientTestTATModal;


import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { ForceCloseIcon } from "../../../assets/CommonAssets";
import CreateableSelect from "../../../common/components/FormFields/CreateableSelect";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {  errorAlert } from "../../../common/components/Toasts/CustomToasts";
import { getAllergiesData } from "./Services";

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "visible",
  p: 1,
};

export default function AllergyModal(props) {
    const { open, close, selectedAllergy, setSelectedAllergy,handleDeleteAllergy } = props;

    const defaultValues = {
        searchAllergy: null,
    };
    
    const { setValue, watch, control } = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
    });

    let allergyWatch = watch("searchAllergy");
    const [allergyOption, setAllergyOption] = React.useState([]);

    const [allergyList, setAllergyList] = React.useState([])
    
    const combinedArray = [...allergyList, ...selectedAllergy]

    const uniqueArray = Array.from(
        new Map(combinedArray.map((item) => [item.label, item])).values()
      );
    
      uniqueArray.sort((a, b) => {
        if (a.value === null) return 1;
        if (b.value === null) return -1;
        return a.value - b.value;
      });
    
    const handleAddAllergy = () => {
        setSelectedAllergy(uniqueArray);
        close();
    }
  
    const handleSearchAllergies = (search) => {
      getAllergiesData(search)
        .then((response) => response.data)
        .then((res) => {
          setAllergyOption(res?.result);
        })
        .catch((er) => er);
    }
    React.useEffect(() => {
        setAllergyList(selectedAllergy)
    }, [selectedAllergy])
    
    React.useEffect(() => {
        if (allergyWatch !== null && allergyWatch !== undefined) {
            let dataArray = [...allergyList];
            let allergy = allergyWatch;
            let filterdDiagnosis = dataArray.filter(
              (list) => list.value === allergyWatch.value
            );
            if (filterdDiagnosis.length > 0) {
                errorAlert("Diagnosis Already Exist!");
                setValue("searchAllergy", null);
            } else {
              dataArray.push(allergy);
              setAllergyList(dataArray);
              setValue("searchAllergy",null)
            }
        }
        
    }, [allergyWatch])

   
    
  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div>
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Allergy
                </div>
              </div>

              <button
                onClick={() => {
                  close();
                }}
              >
                <ForceCloseIcon />
              </button>
            </div>

            <div className="boredr-t w-full border-slate-400 p-2">
              <CreateableSelect
                control={control}
                name="searchAllergy"
                dataArray={allergyOption}
                placeholder={"Search Allergy"}
                showSearch={true}
                onInputChange={handleSearchAllergies}
                menuPlacement={"bottom"}
              />
            </div>

            <div className="my-2">
              {allergyList?.length > 0
                ? allergyList.map((allergy) => (
                    <div className="flex gap-2 items-center border border-gray-200 h-6">
                      <div>
                        <button
                          className="h-5 "
                          type="button"
                          onClick={() => {
                            handleDeleteAllergy(allergy);
                          }}
                        >
                          <DeleteOutlineIcon
                            fontSize="small"
                            sx={{ color: "red" }}
                          />
                        </button>
                      </div>
                      {/* ) : (
                            ""
                          )} */}

                      <div className="w-[90%] h-5 flex justify-start px-2  whitespace-nowrap truncate">
                        {allergy?.label}
                      </div>
                    </div>
                  ))
                : ""}
                      </div>
                      
                      <div className="flex justify-end my-2 px-2">
              <CommonButton
                label={"Add"}
                type={"button"}
                className={"px-3 bg-[#368505] text-white h-9 rounded"}
                onClick={() => {
                    handleAddAllergy()
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}


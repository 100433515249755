import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Divider, IconButton, TextareaAutosize, Tooltip } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import {
  EditIcon,
  EmrPatholgyIcon,
  EmrRadiologyIcon,
  ForceCloseIcon,
  PrescriptionIcon,
  TemplateIcon,
} from "../../../assets/CommonAssets";

// import {
//   getPathologyHistory,
//   getRadiologyHistory,
// } from "../../../IPD/services/discharge/dischargeServices";

// import LIMSHistoryModal from "../../../LIMS/Common/LIMSHistoryModal";

// import {
//   getComplaintsChips,
//   getICDCodeSerachBy,
//   getPatient,
//   getPatientVisitLisit,
// } from "../../services/EMRServices/emrServices";

import AdviceTemplateModal from "./AdviceTemplateModal";
import ClinicalModal from "./ClinicalModal";
import ComplaintsModal from "./ComplaintsModal";
import CustomCreateable from "./CustomCreateable";
import DiagnosisModal from "./DiagnosisModal";
import InvestigationModal from "./InvestigationsModal";
import LocalAdvicesModal from "./LocalAdvicesModal";
import MedicinesTable from "./MedicinesTable";
import OtherServicesModal from "./OtherServicesModal";
import PastHistoryModal from "./PastHistoryModal";
import PrintOptions from "./PrintOptions";

import {
  getFrequencyForDrugOrder,
  // getAssessmentDetails,
  // getCommonComplaints,
  // getCommonDiagnosis,
  getCommonRoutes,
  getGeneralFrequencies,
  getGenericDrugs,
  getInvestigations,
  // getLocalAdvices,
  getMedicationInstructions,
  getMedicines,
  getDiagnosis,
  getReferenceDoctor,
  getDrugForm,
  saveEmrLite,
  getPatientEmrDetails,
  getPrevEmrList,
  getAdviceData,
  getOtherServices,
  getComplaintsData,
  getAllergiesData,
  // getPreviousPrescriptions,
  // saveEmrLite,
  // updateEmrLite,
} from "./Services";

// import { saveIPDEMR } from "../../../IPD/services/IpdEmr/IpdEmrServices";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import InputField from "../../../common/components/FormFields/InputField";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import { UseCheckConsecutiveCharacters } from "../../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import DatePickerField from "../../../common/components/FormFields/DatePickerFieldNew";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import AllergyModal from "./AllergyModal";
import FamilyHistoryModal from "./FamilyHistoryModal";
import OtherReferralModal from "./OtherReferralModal";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";

const medicinesData = [
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
  {
    Medicine: "",
    Dosage: "",
    Frequency: "",
    Route: "",
    Duration: "",
    Quantity: "",
    Instructions: "",
    Generic: "",
    Form: "",
    since: "",
    isDeleted: false,
  },
];

const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // maxHeight: "90%",
  width: "97%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "auto",
  p: 1,
};

function transformPastHistory(pastHistory) {
  const conditions = [
    { key: "dm", label: "Diabetes" },
    { key: "htn", label: "Hypertension" },
    { key: "asthma", label: "Asthma" },
    { key: "ihd", label: "IHD" },
    { key: "other", label: "Other" },
  ];

  let pastHistoryArr = conditions
    .filter((condition) => {
      const sinceKey = `${condition.key}Since`;
      return pastHistory[sinceKey] != null;
    })
    .map((condition, index) => {
      const key = condition.key;
      const flagKey = `${key}Flag`;
      const durationKey = `${key}Duration`;
      const sinceKey = `${key}Since`;
      const descriptionKey = `${key}Description`;

      return {
        id: index + 1,
        label: condition.label,
        value: condition.label,
        description: pastHistory[descriptionKey] || "",
        duration: pastHistory[durationKey] || "",
        since: pastHistory[sinceKey].toString(),
        isChecked: pastHistory[flagKey] || false,
      };
    });

  return pastHistoryArr;
}

function EmrLite(props) {
  const { open, close, selectedRow } = props;
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

  const [openPastHistory, setOpenPastHistory] = React.useState(false);
  const [impNotes, setImpNotes] = React.useState("");
  const [familyHistory, setFamilyHistory] = React.useState("");
  const [openFamilyHistory, setOpenFamilyHistory] = React.useState(false);
  const [invOptions, setInvOptions] = React.useState([]);
  const [diagnoOptions, setDiagnoOptions] = React.useState([]);
  const [advicesOptions, setAdvicesOptions] = React.useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [postObject, setPostObject] = React.useState(null);
  const [openPost, setOpenPost] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [genericDrugs, setGenericDrugs] = React.useState([]);
  const [medicineInstructions, setMedicineInstructions] = React.useState([]);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = React.useState([]);
  const [selectedFamilyHistoryIndex, setSelectedFamilyHistoryIndex] =
    React.useState([]);
  const [complaints, setComplaints] = React.useState([]);
  const [selectedComplaints, setSelectedComplaints] = React.useState([]);
  const [resetCount, setResetCount] = React.useState(0);
  const [openComplaints, setOpenComplaints] = React.useState(false);
  const [resetComplaints, setResetComplaints] = React.useState(0);
  const [resetDiagnosis, setResetDiagnosis] = React.useState(0);
  const [diagnosis, setDiagnosis] = React.useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = React.useState([]);
  const [selectedAllergy, setSelectedAllergy] = React.useState([]);
  const [selectedOtherReferral, setSelectedOtherReferral] = React.useState([]);
  const [openDiagnosis, setOpenDiagnosis] = React.useState(false);
  const [openInvestigation, setOpenInvestigation] = React.useState(false);
  const [investigationList, setInvestigationList] = React.useState([]);
  const [searchMedicines, setSearchMedicines] = React.useState([]);
  const [frequencyOptions, setFrequencyOptions] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [openServices, setOpenServices] = React.useState(false);
  const [otherServices, setOtherServices] = React.useState([]);
  const [resetServices, setResetServices] = React.useState(0);
  const [lastVisits, setLastVisits] = React.useState([]);
  const [visitIndex, setVisitIndex] = React.useState(null);
  const [openAdviceTemplate, setOpenAdviceTemplate] = React.useState(false);
  const [openPathologyReport, setOpenPathologyReport] = React.useState(false);
  const [pathHistoryData, setPathHistoryData] = React.useState([]);
  const [LIMSType, setLIMSType] = React.useState("");
  const [radioHistoryData, setRadioHistoryData] = React.useState([]);
  const [patientDetails, setPatientDetails] = React.useState(null);
  const [adviceTemplates, setAdviceTemplates] = React.useState([]);
  const [openClinical, setOpenClinical] = React.useState(false);
  const [openAllergy, setOpenAllergy] = React.useState(false);
  const [allergyOption, setAllergyOption] = React.useState([]);
  const [selectedClinical, setSelectedClinical] = React.useState([]);
  const [openLocalAdvices, setOpenLocalAdvices] = React.useState(false);
  const [openOtherReferral, setOpenOtherRefferal] = React.useState(false);
  const [localAdvices, setLocalAdvices] = React.useState([]);
  const [selectedLocalAdvices, setSelectedLocalAdvices] = React.useState([]);
  const [referralDocOptions, setReferralDocOptions] = React.useState([]);
  const [clinicalValue, setClinicalValue] = React.useState("");
  const [selectedHistory, setSelectedHistory] = React.useState([]);
  const [selectedFamilyHistory, setSelectedFamilyHistory] = React.useState([]);
  const [selectedAdvices, setSelectedAdvices] = React.useState([]);
  const [emrId, setEmrId] = React.useState(null);
  const [preview, setPreview] = React.useState("");
  const [openPreview, setOpenPreview] = React.useState(false);
  const [printObject, setPrintObject] = React.useState(null);
  const [isPreview, setIsPreview] = React.useState(false);
  const [isOpenPrev, setIsOpenPrev] = React.useState(false);
  const [applyToAllMedicines, setApplyToAllMedicines] = React.useState(false);
  const [editComplaintIndex, setEditComplaintIndex] = React.useState(null);
  const [formOpt, setFormOpt] = React.useState([]);
  const [servicesOptions, setServicesOptions] = React.useState([]);
  const complaintRef = React.useRef(null);
  let genericNameRef = React.useRef();

  const handleClosePreview = (reset) => {
    if (openPreview === true) {
      setOpenPreview(false);
      setPreview("");
      if (isPreview === false) {
        handleResetAll();
        navigate("/opd/visitlist", {
          state: { menuId: location?.state?.visitListMenuId },
        });
      }
    }
  };

  const handleClosePost = () => {
    if (openPost === true) {
      setOpenPost(false);
    }
  };

  const handleCloseLocalAdvices = () => {
    if (openLocalAdvices === true) {
      setOpenLocalAdvices(false);
    }
  };

  const handleCloseOtherReferral = () => {
    if (openOtherReferral === true) {
      setOpenOtherRefferal(false);
    }
  };

  const handleCloseClinical = () => {
    if (openClinical === true) {
      setOpenClinical(false);
    }
  };

  const handleCloseAllergy = () => {
    if (openAllergy === true) {
      setOpenAllergy(false);
    }
  };

  React.useEffect(() => {
    if (selectedClinical?.length > 0) {
      let clinicalFindingTemplate = "";
      for (let obj of selectedClinical) {
        clinicalFindingTemplate =
          clinicalFindingTemplate + obj?.templateDescription + `\n`;
        setClinicalValue(clinicalFindingTemplate);
      }
    }
  }, [selectedClinical]);

  const handleClosePathologyReport = () => {
    if (openPathologyReport === true) {
      setOpenPathologyReport(false);
    }
  };

  const handleCloseAdviceTemplate = () => {
    if (openAdviceTemplate === true) {
      setOpenAdviceTemplate(false);
    }
  };

  const handleCloseServices = () => {
    if (openServices === true) {
      setOpenServices(false);
    }
  };

  React.useEffect(() => {
    // getLocalAdvices()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     let dataArray = [];
    //     let advices = [...res?.result];
    //     if (advices?.length > 0) {
    //       for (let advice of advices) {
    //         let newAdvice = {
    //           id: advice?.id,
    //           label: advice?.label,
    //           value: advice.value,
    //           isChecked: false,
    //         };
    //         dataArray.push(newAdvice);
    //         setLocalAdvices(dataArray);
    //       }
    //     }
    //   })
    //   .catch((err) => err);
  }, []);

  const [selectedMedicines, setSelectedMedicines] = React.useState([
    ...medicinesData,
  ]);

  const [resetInv, setResetInv] = React.useState(0);
  const [selectedInvestigations, setSelectedInvestigations] = React.useState(
    []
  );

  const handleCloseInvestigation = () => {
    if (openInvestigation === true) {
      setOpenInvestigation(false);
    }
  };

  const handleCloseDiagnosis = () => {
    if (openDiagnosis === true) {
      setOpenDiagnosis(false);
    }
  };

  const handleCloseComplaints = () => {
    if (openComplaints === true) {
      setOpenComplaints(false);
    }
  };

  const handleClosePastHistory = () => {
    if (openPastHistory === true) {
      setOpenPastHistory(false);
    }
  };

  const handleCloseFamilyHistory = () => {
    if (openFamilyHistory === true) {
      setOpenFamilyHistory(false);
    }
  };

  const defaultValues = {
    height: "",
    weight: "",
    bmi: "",
    bp: "",
    pulse: "",
    bsl: "",
    respiration: "",
    temperature: "",
    spo2: "",
    searchDiagnosis: null,
    referralDoctor: null,
    admission: false,
    physiotherapy: false,
    dietician: false,
    procedures: false,
    nextFollowupDate: null,
    Tobacco: false,
    Smoking: false,
    Alcohol: false,
    searchOtherServices: null,
  };

  const {
    watch,
    setValue,
    control,
    trigger,
    register,
    setError,
    clearErrors,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const [
    heightWatch,
    weightWatch,
    bmiWatch,
    bpWatch,
    pulseWatch,
    temperatureWatch,
    spo2Watch,
    admissionWatch,
    physiotherapyWatch,
    dieticianWatch,
    proceduresWatch,
    respirationWatch,
    bslWatch,
  ] = watch([
    "height",
    "weight",
    "bmi",
    "bp",
    "pulse",
    "temperature",
    "spo2",
    "admission",
    "physiotherapy",
    "dietician",
    "procedures",
    "respiration",
    "bsl",
  ]);

  const handlePrintPreview = () => {
    let medicines = [];
    let filtered = selectedMedicines.filter((item) => item?.Medicine !== "");
    if (filtered?.length > 0) {
      for (let object of filtered) {
        let medicineObject = {
          code: object?.Medicine?.code,
          drugName: object?.Medicine?.Medicine,
          drugType: "Brand Name",
          days: Number(object?.since) > 0 ? Number(object?.since) : "",
          frequency: object?.Frequency?.label,
          genericDescription: object?.Medicine?.genericDesc,
          id: object?.Medicine?.id,
          isNew: true,
          localFrequency: null,
          localInstruction: null,
          drugId: object?.Medicine?.id,
        };
        medicines.push(medicineObject);
      }
    }

    let investigations = [];
    if (selectedInvestigations?.length > 0) {
      for (let object of selectedInvestigations) {
        let invObject = {
          testType: "Pathology",
          testTypeId: object?.testTypeId,
          investigation: object?.label,
          investigationImageName: null,
          investigationPathBase64: null,
          testId: object.id,
          serviceId: object?.serviceId,
          deleteFlag: false,
          id: object?.id,
          isUrgent: false,
          isNew: true,
        };
        investigations.push(invObject);
      }
    }

    let diagnosisArray = [];
    if (selectedDiagnosis?.length > 0) {
      for (let diagno of selectedDiagnosis) {
        diagnosisArray.push(diagno?.label);
      }
    }

    let complaintsArray = [];
    if (selectedComplaints?.length > 0) {
      for (let complaint of selectedComplaints) {
        let complaintObj = {
          complaint: complaint?.label,
        };
        complaintsArray.push(complaintObj);
      }
    }

    let advicesArray = [];
    if (adviceTemplates?.length > 0) {
      for (let template of adviceTemplates) {
        let advices = template?.Advices;
        if (advices?.length > 0) {
          for (let adv of advices) {
            advicesArray.push(adv);
          }
        }
      }

      if (selectedLocalAdvices?.length > 0) {
        for (let advice of selectedLocalAdvices) {
          advicesArray.push(advice?.value);
        }
      }
    }

    let diastolicValue = Number(bpWatch?.toString().split("/")?.[1]);
    let systolicValue = Number(bpWatch?.toString().split("/")?.[0]);

    let printObject = {
      clientId: 3,
      visitId: location?.state?.visitId,
      employeeId: loggedUser?.employeeId,
      nextFollowUpDate: format(nextFollowup, "yyyy-MM-dd"),
      medicineDetails: medicines,
      pathologyList: investigations,
      radiologyList: [],
      instructionList: advicesArray,
      vitals: {
        avpu: "",
        bloodPressureDiastolic: Number(diastolicValue),
        bloodPressureSystolic: Number(systolicValue),
        bloodSugar: 0,
        bmi: Number(bmiWatch),
        bsa: 0,
        cvp: 0,
        cyanosis: true,
        date: format(new Date(), "yyyy-MM-dd"),
        height: Number(heightWatch),
        mews: 0,
        peripheralPulse: 0,
        pews: 0,
        pulseRate: Number(pulseWatch),
        pulseType: "",
        respiration: 0,
        respirationType: "",
        spO2: Number(spo2Watch),
        temperature: Number(temperatureWatch),
        weight: Number(weightWatch),
        diagnosisList: diagnosisArray,
      },
      patientEmr: {
        treatmentAdviceDescription: null,
        complaints: complaintsArray,
      },
      separatePage: false,
      pageSize: "A4",
    };

    setPrintObject(printObject);
    setOpenPreview(true);
  };

  React.useEffect(() => {
    if (preview !== "") {
      setOpenPreview(true);
    }
  }, [preview]);

  React.useEffect(() => {
    // getCommonDiagnosis()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     if (res?.result?.length > 0) {
    //       let responseData = [...res?.result];
    //       if (responseData?.length > 0) {
    //         let dataArray = [];
    //         for (let object of responseData) {
    //           let diagnosisObject = {
    //             value: object.id,
    //             isChecked: false,
    //             since: "",
    //             duration: "",
    //             isNew: false,
    //             icdcode: object.label,
    //             label: object.label,
    //           };
    //           dataArray.push(diagnosisObject);
    //           setDiagnosis(dataArray);
    //           setDiagnoOptions(dataArray);
    //         }
    //       }
    //     }
    //   });
    getMedicationInstructions()
      .then((response) => response.data)
      .then((res) => {
        setMedicineInstructions(res.result);
        console.log(res.result, "res.result");
      })
      .catch((err) => err);
  }, []);

  React.useEffect(() => {
    getDrugForm()
      .then((response) => response.data)
      .then((res) => {
        setFormOpt(res.result);
      })
      .catch((err) => err);
  }, []);

  React.useEffect(() => {
    getAdviceData()
      .then((response) => response.data)
      .then((res) => {
        setAdvicesOptions(res?.result);
      })
      .catch((err) => err);
  }, []);

  // #######################  Icon Modals  ########################

  function viewLIMSHistory() {
    // getPatient(
    //   location?.state?.patientId,
    //   location?.state?.visitId,
    //   location?.state?.visitNumber
    // )
    //   .then((response) => {
    //     let infoObject = response.data.result.patientInfo;
    //     infoObject.PatientId = location?.state?.patientId;
    //     infoObject.VisitId = location?.state?.visitId;
    //     infoObject["Patient Name"] =
    //       response.data.result.patientInfo?.patientName;
    //     infoObject.UHID = response.data.result.patientInfo?.uhid;
    //     infoObject.Age = response.data.result.patientInfo?.age;
    //     infoObject.Gender = response.data.result.patientInfo?.gender;
    //     setPatientDetails(infoObject);
    //   })
    //   .catch((res) => {});
    // getPathologyHistory(
    //   location?.state?.patientId,
    //   location?.state?.visitId
    // ).then((response) => {
    //   setPathHistoryData(response.data.result);
    // });
  }

  function viewLimsRadiologyHistory() {
    // getPatient(
    //   location?.state?.patientId,
    //   location?.state?.visitId,
    //   location?.state?.visitNumber
    // )
    //   .then((response) => {
    //     let infoObject = response.data.result.patientInfo;
    //     infoObject.PatientId = location?.state?.patientId;
    //     infoObject.VisitId = location?.state?.visitId;
    //     infoObject["Patient Name"] =
    //       response.data.result.patientInfo?.patientName;
    //     infoObject.UHID = response.data.result.patientInfo?.uhid;
    //     infoObject.Age = response.data.result.patientInfo?.age;
    //     infoObject.Gender = response.data.result.patientInfo?.gender;
    //     setPatientDetails(infoObject);
    //   })
    //   .catch((res) => {});
    // getRadiologyHistory(
    //   location?.state?.patientId,
    //   location?.state?.visitId
    // ).then((response) => {
    //   setRadioHistoryData(response.data.result);
    // });
  }

  const handleGetPrecsriptions = () => {
    // getPreviousPrescriptions(location?.state?.patientId)
    //   .then((response) => response.data)
    //   .then((res) => {
    //     if (res?.result?.length > 0) {
    //       let dataArray = [...selectedMedicines];
    //       let responseData = [...res?.result];
    //       for (let i = 0; i <= responseData?.length; i++) {
    //         let newObject = {
    //           Medicine: {
    //             id: responseData[i]?.drugId,
    //             label: responseData[i]?.drugName,
    //             value: responseData[i]?.drugId,
    //             genericDesc: responseData[i]?.genericDescription,
    //             code: "",
    //             Medicine: responseData[i]?.drugName,
    //           },
    //           id: responseData[i]?.id,
    //           since: responseData[i]?.duration,
    //           Generic: "",
    //           Frequency: {
    //             value: null,
    //             id: null,
    //             label: responseData[i]?.frequency,
    //           },
    //           Dosage: "",
    //           Days: "D",
    //           Duration: responseData[i]?.duration,
    //           Instructions: responseData[i]?.instruction,
    //         };
    //         dataArray.splice(i, 1, newObject);
    //       }
    //       setSelectedMedicines(dataArray);
    //     } else {
    //       handleResetMedicines();
    //     }
    //   })
    //   .catch((err) => err);
  };

  // const getPreviousDetails = (lastVisit) => {
  //   let searchObject = {
  //     department: lastVisit?.department,
  //     patientInfoId: location?.state?.patientId,
  //     visitIdList: [lastVisit?.visitId],
  //     visitNumber: [lastVisit?.visitNumber],
  //   };

  //   // getAssessmentDetails(searchObject)
  //   //   .then((response) => response.data)
  //   //   .then((res) => {
  //   //     if (res?.result) {
  //   //       let vitals = res?.result?.vitals;
  //   //       setEmrId(res?.result?.emrId);
  //   //       setValue("height", vitals?.height);
  //   //       setValue("weight", vitals?.weight);
  //   //       setValue("bmi", vitals?.bmi);
  //   //       setValue("spo2", vitals?.spO2);
  //   //       setValue("temperature", vitals?.temperature);
  //   //       setValue("pulse", vitals?.pulseRate);
  //   //       setValue(
  //   //         "bp",
  //   //         vitals?.bloodPressureSystolic + "/" + vitals?.bloodPressureDiastolic
  //   //       );
  //   //       setValue("nextFollowupDate", new Date(res?.result?.nextFollowDate));

  //   //       setValue("Smoking", res?.result?.pastHistory?.smokingFlag);
  //   //       setValue("Alcohol", res?.result?.pastHistory?.alcoholFlag);
  //   //       setValue("Tobacco", res?.result?.pastHistory?.tobaccoFlag);

  //   //       if (
  //   //         res?.result?.familyHistoryDesc !== null ||
  //   //         res?.result?.familyHistoryDesc !== undefined
  //   //       ) {
  //   //         setFamilyHistory(res?.result?.familyHistoryDesc);
  //   //       }

  //   //       if (res.result?.pastHistory !== null) {
  //   //         setSelectedHistory(transformPastHistory(res.result?.pastHistory));
  //   //       }

  //   //       let complaints = res?.result?.complaints;
  //   //       if (complaints !== null && complaints?.length > 0) {
  //   //         let editComplaints = [...selectedComplaints];
  //   //         for (let comp of complaints) {
  //   //           let complaintObject = {
  //   //             value: 7,
  //   //             id: comp?.id,
  //   //             isCommon: false,
  //   //             since: comp?.since !== null ? comp?.since : "",
  //   //             isChecked: true,
  //   //             duration:
  //   //               comp?.duration === "Days"
  //   //                 ? "D"
  //   //                 : comp?.duration === "Months"
  //   //                 ? "M"
  //   //                 : comp?.duration === "Years"
  //   //                 ? "Y"
  //   //                 : "",
  //   //             isNew: false,
  //   //             label: comp?.complaint,
  //   //           };
  //   //           editComplaints.push(complaintObject);
  //   //           setComplaints([...editComplaints, ...complaints]);
  //   //           setSelectedComplaints(editComplaints);
  //   //           setResetComplaints(resetComplaints + 1);
  //   //         }
  //   //       } else {
  //   //         setComplaints([]);
  //   //         setSelectedComplaints([]);
  //   //         setResetComplaints(resetComplaints + 1);
  //   //       }

  //   //       let diagnosis = res?.result?.diagnosis;
  //   //       let editDiagnosis = [];

  //   //       if (diagnosis?.length > 0) {
  //   //         for (let diagno of diagnosis) {
  //   //           let diagnoObject = {
  //   //             value: null,
  //   //             isChecked: true,
  //   //             since: diagno?.since !== null ? diagno?.since : "",
  //   //             duration:
  //   //               diagno?.duration === "Days"
  //   //                 ? "D"
  //   //                 : diagno?.duration === "Months"
  //   //                 ? "M"
  //   //                 : diagno?.duration === "Years"
  //   //                 ? "Y"
  //   //                 : "",
  //   //             isNew: false,
  //   //             icdcode: diagno?.icd,
  //   //             label: diagno?.icd,
  //   //           };
  //   //           editDiagnosis.push(diagnoObject);
  //   //           setSelectedDiagnosis(editDiagnosis);
  //   //           setDiagnoOptions(editDiagnosis);
  //   //         }
  //   //       } else {
  //   //         setSelectedDiagnosis([]);
  //   //         setDiagnoOptions([]);
  //   //       }

  //   //       let investigations = res?.result?.pathology;
  //   //       let editInvestigations = [];
  //   //       if (investigations?.length > 0) {
  //   //         for (let inv of investigations) {
  //   //           let investigationObject = {
  //   //             id: inv?.id,
  //   //             label: inv?.investigation,
  //   //             value: inv?.id,
  //   //             isChecked: true,
  //   //             isUrgent: inv?.isBilled,
  //   //             Quantity: 1,
  //   //             serviceId: inv?.serviceId,
  //   //             serviceName: inv?.investigation,
  //   //             testType: "Pathology",
  //   //             testTypeId: null,
  //   //           };
  //   //           editInvestigations.push(investigationObject);
  //   //           setSelectedInvestigations(editInvestigations);
  //   //         }
  //   //       } else {
  //   //         setSelectedInvestigations([]);
  //   //       }

  //   //       let services = res?.result?.services;
  //   //       let editServices = [];
  //   //       if (services?.length > 0) {
  //   //         for (let inv of services) {
  //   //           if (inv["isLab"] === false) {
  //   //             let investigationObject = {
  //   //               id: inv?.id,
  //   //               label: inv?.serviceName,
  //   //               value: inv?.id,
  //   //               isChecked: true,
  //   //               isUrgent: inv?.isBilled,
  //   //               Quantity: 1,
  //   //               serviceId: inv?.serviceId,
  //   //               serviceName: inv?.serviceName,
  //   //               testType: "Pathology",
  //   //               testTypeId: null,
  //   //             };
  //   //             editServices.push(investigationObject);
  //   //             setOtherServices(editServices);
  //   //           }
  //   //         }
  //   //       } else {
  //   //         setOtherServices([]);
  //   //       }

  //   //       let prescriptions = res?.result?.prescriptions;

  //   //       if (prescriptions?.length > 0) {
  //   //         for (let i = 0; i < prescriptions.length; i++) {
  //   //           let prescriptionObject = {
  //   //             Medicine: {
  //   //               id: prescriptions[i]?.drugId,
  //   //               label: prescriptions[i]?.drugName,
  //   //               value: prescriptions[i]?.drugId,
  //   //               genericDesc: prescriptions[i]?.genericDescription,
  //   //               code: "",
  //   //               Medicine: prescriptions[i]?.drugName,
  //   //             },
  //   //             id: prescriptions[i]?.id,
  //   //             since: prescriptions[i]?.duration,
  //   //             Generic: "",
  //   //             Frequency: {
  //   //               value: null,
  //   //               id: null,
  //   //               label: prescriptions[i]?.frequency,
  //   //             },
  //   //             Dosage: "",
  //   //             Days: "D",
  //   //             Duration: prescriptions[i]?.duration,
  //   //             Instructions: prescriptions[i]?.instruction,
  //   //           };
  //   //           if (prescriptionObject?.Medicine?.Medicine !== "") {
  //   //             let dataArray = selectedMedicines;
  //   //             dataArray.splice(i, 1, prescriptionObject);
  //   //             setSelectedMedicines(dataArray);
  //   //           }
  //   //         }
  //   //       } else {
  //   //         handleResetMedicines();
  //   //       }

  //   //       let localAdvices = res?.result?.generalInstructions;
  //   //       let editLocal = [];
  //   //       if (localAdvices?.length > 0) {
  //   //         for (let advice of localAdvices) {
  //   //           let adviceObject = {
  //   //             value: advice,
  //   //           };
  //   //           editLocal.push(adviceObject);
  //   //           setSelectedLocalAdvices(editLocal);
  //   //         }
  //   //       } else {
  //   //         setSelectedLocalAdvices([]);
  //   //       }

  //   //       let clinicalFindings = res?.result?.clinicalFindings;
  //   //       if (clinicalFindings?.length > 0) {
  //   //         let editFindings = [];

  //   //         for (let object of clinicalFindings) {
  //   //           let finding = {
  //   //             templateDescription: object?.templateDescription,
  //   //             Template: object?.template,
  //   //             isChecked: true,
  //   //             id: null,
  //   //           };
  //   //           editFindings.push(finding);
  //   //           setSelectedClinical(editFindings);
  //   //         }
  //   //       } else {
  //   //         setSelectedClinical([]);
  //   //       }

  //   //       let generalAdvices = res?.result?.emrAdvices;
  //   //       if (generalAdvices?.length > 0) {
  //   //         let editTemplates = [];
  //   //         for (let template of generalAdvices) {
  //   //           let newTemplate = {
  //   //             id: null,
  //   //             Template: template?.template,
  //   //             isChecked: true,
  //   //             Advices: template?.advices,
  //   //           };
  //   //           editTemplates.push(newTemplate);
  //   //           setAdviceTemplates(editTemplates);
  //   //         }
  //   //       } else {
  //   //         setAdviceTemplates([]);
  //   //       }
  //   //       setImpNotes(res?.result?.notes);
  //   //       setFamilyHistory(res?.result?.familyHistoryDesc);
  //   //     }
  //   //   })
  //   //   .catch((err) => err);
  // };

  const getPreviousDetails = (lastVisit) => {
    if (lastVisit.opdIpdId !== null || lastVisit.opdIpdId !== undefined) {
      getPatientEmrDetails(0, lastVisit?.opdIpdId, token.doctorId)
        .then((response) => response.data)
        .then((res) => {
          if (res?.result) {
            let prevData = res?.result[0];
            setEmrId(prevData.id ? prevData.id : null);
            setValue("temperature", prevData?.temp);
            setValue("bp", prevData?.bp);
            setValue("pulse", prevData?.pulse);
            setValue("respiration", prevData?.respiration);
            setValue("spo2", prevData?.spo2);
            setValue("bsl", prevData?.bsl);
            setValue("height", prevData?.height);
            setValue("weight", prevData?.weight);
            setValue("bmi", prevData?.bmi);
            setValue("Smoking", prevData?.smoking);
            setValue("Tobacco", prevData?.tobacco);
            setValue("Alcohol", prevData?.alcohol);
            setImpNotes(prevData?.importantNotes);
            setValue("nextFollowupDate", new Date(prevData?.newFollowupDate));

            let pastHistory = JSON.parse(prevData?.pastHistory);
            let editPastHistory = [];
            if (pastHistory.length > 0) {
              for (let pastHist of pastHistory) {
                let pastHistoryObj = {
                  label: pastHist?.history,
                  value: pastHist?.history,
                  description: pastHist?.description,
                  duration: pastHist?.sinceIn,
                  since: pastHist?.since,
                  isChecked: true,
                };
                console.log();

                editPastHistory.push(pastHistoryObj);
                setSelectedHistory(editPastHistory);
              }
            }

            let familyHistory = JSON.parse(prevData?.familyHistory);
            let editfamilyHistory = [];

            if (familyHistory.length > 0) {
              for (let famHistory of familyHistory) {
                let familyHistoryObj = {
                  label: famHistory?.history,
                  value: famHistory?.history,
                  description: famHistory?.description,
                  duration: famHistory?.sinceIn,
                  since: famHistory?.since,
                  isChecked: true,
                };
                console.log(familyHistoryObj, "familyHistoryObj212");

                editfamilyHistory.push(familyHistoryObj);
                setSelectedFamilyHistory(editfamilyHistory);
              }
            }

            let complaint = JSON.parse(prevData?.complaints);
            let editComplaint = [];

            if (complaint?.length > 0) {
              for (let comp of complaint) {
                let complaintObj = {
                  label: comp?.complaint,
                  // id: comp?.serviceId,
                  // value: comp?.serviceId,
                  since: comp?.since,
                  duration: comp?.duration,
                  isChecked: true,
                };

                editComplaint.push(complaintObj);
                setSelectedComplaints(editComplaint);
              }
            }

            let allergies = JSON.parse(prevData?.allergies);
            let editAllergy = [];

            if (allergies?.length > 0) {
              for (let allergy of allergies) {
                let allergyObj = {
                  label: allergy,
                };
                editAllergy.push(allergyObj);
                setSelectedAllergy(editAllergy);
              }
            }

            let investigations = JSON.parse(prevData?.investigations);

            let editInvestigation = [];

            if (investigations?.length > 0) {
              for (let investigation of investigations) {
                let invObj = {
                  label: investigation?.serviceName,
                  id: investigation?.serviceId,
                  value: investigation?.serviceId,
                };
                editInvestigation.push(invObj);
                setSelectedInvestigations(editInvestigation);
              }
            }

            let daignosis = JSON.parse(prevData?.diagnosis);
            let editDiagnosis = [];

            if (daignosis?.length > 0) {
              for (let diagno of daignosis) {
                let diagnosisObj = {
                  label: diagno?.diagnosis,
                  // id: comp?.serviceId,
                  // value: comp?.serviceId,
                  since: diagno?.since,
   
                  isChecked: true,
                };

                console.log("EditDiagnosis", diagnosisObj);
                editDiagnosis.push(diagnosisObj);
                setSelectedDiagnosis(editDiagnosis);
                
                
              }
            }

            let otherReferral = JSON.parse(prevData?.otherReferral);
            let editOtherReferral = [];

            if (otherReferral.length > 0) {
              for (let otherReff of otherReferral) {
                let otherRefferralObj = {
                  label: otherReff?.doctorName,
                  id: otherReff?.doctorId,
                  value: otherReff?.doctorId,
                };

                editOtherReferral.push(otherRefferralObj);
                setSelectedOtherReferral(editOtherReferral);
              }
            }

            let prescriptions = JSON.parse(prevData?.emr_medications);

            console.log(prescriptions, "prescriptionsprescriptions");

            if (prescriptions?.length > 0) {
              for (let i = 0; i < prescriptions.length; i++) {
                let prescriptionObject = {
                  id: prescriptions[i]?.id,

                  Medicine: {
                    id: prescriptions[i]?.drug_id,
                    label: prescriptions[i]?.drug_name,
                    value: prescriptions[i]?.drug_id,
                    // genericDesc: prescriptions[i]?.genericDescription,
                    // code: "",
                    Medicine: prescriptions[i]?.drug_name,
                  },
                  Dosage: prescriptions[i]?.dosage,
                  Frequency: {
                    id: prescriptions[i]?.frequency_id,
                    label: prescriptions[i]?.frequency,
                    value: prescriptions[i]?.frequency_id,
                  },
                  Route: {
                    Id: prescriptions[i]?.route_id,
                    label: prescriptions[i]?.route_name,
                    value: prescriptions[i]?.route_id,
                  },
                  Duration: prescriptions[i]?.duration,
                  Quantity: prescriptions[i]?.quantity,
                  Instructions: {
                    Id: prescriptions[i]?.instruction_id,
                    label: prescriptions[i]?.instruction,
                    value: prescriptions[i]?.instruction_id,
                  },
                  Generic: {
                    id: prescriptions[i]?.generic_id,
                    label: prescriptions[i]?.generic_name,
                    value: prescriptions[i]?.generic_id,
                  },
                  Form: {
                    Id: prescriptions[i]?.form_id,
                    label: prescriptions[i]?.drug_form,
                    value: prescriptions[i]?.form_id,
                  },
                  isDeleted: false,
                };

                console.log(
                  "prescriptionObjectprescriptionObject",
                  prescriptionObject
                );

                if (prescriptionObject?.Medicine?.Medicine !== "") {
                  let dataArray = selectedMedicines;
                  dataArray.splice(i, 1, prescriptionObject);
                  setSelectedMedicines(dataArray);
                }

                setValue(`medicine[${i}].quantity`, prescriptions[i]?.quantity);

                setValue(`medicine[${i}].instructions`, {
                  Id: prescriptions[i]?.instruction_id,
                  label: prescriptions[i]?.instruction,
                  value: prescriptions[i]?.instruction_id,
                });

                setValue(`medicine[${i}].form`, {
                  Id: prescriptions[i]?.form_id,
                  label: prescriptions[i]?.drug_form,
                  value: prescriptions[i]?.form_id,
                });

                setValue(`medicine[${i}].generic`, {
                  id: prescriptions[i]?.generic_id,
                  label: prescriptions[i]?.generic_name,
                  value: prescriptions[i]?.generic_id,
                });
              }
            } else {
              handleResetMedicines();
            }

            let advices = JSON.parse(prevData?.advices);
            let editAdvice = [];

            if (advices.length > 0) {
              for (let adv of advices) {
                let adviceObj = {
                  label: adv,
                };
                editAdvice.push(adviceObj);
                setSelectedAdvices(editAdvice);
              }
            }

            let otherServices = JSON.parse(prevData?.otherServices);
            let editOtherServices = [];

            if (otherServices.length > 0) {
              for (let otherService of otherServices) {
                let otherServicesObj = {
                  id: otherService?.serviceId,
                  value: otherService?.serviceId,
                  label: otherService?.serviceName,
                };

                editOtherServices.push(otherServicesObj);
                setOtherServices(editOtherServices);
              }
            }
          }
        })
        .catch((eer) => eer);
    }
  };

  React.useEffect(() => {
    if (selectedRow !== null) {
      let searchObject = {
        // department: location?.state?.department,
        // patientInfoId: location?.state?.patientId,
        // visitId: location?.state?.visitId,
        // visitNumber: location?.state?.visitNumber,
        opdIpdId:selectedRow?.VisitId
      };
      getPreviousDetails(searchObject);
    }
  }, [selectedRow]);


  React.useEffect(() => {
    if (selectedRow !== null) {
      getPrevEmrList(0, selectedRow?.PatientId)
        .then((response) => response.data)
        .then((res) => {
          setLastVisits(res.result);
          console.log(res, "resresrersersees");
        })
        .catch((eer) => eer);
    }
  }, [selectedRow]);

  // React.useEffect(() => {
  //   if (selectedRow !== null) {
  //     getPatientEmrDetails(0, selectedRow?.VisitId, token.doctorId)
  //       .then((response) => response.data)
  //       .then((res) => {
  //         if (res?.result) {
  //           let prevData = res?.result[0];
  //           setEmrId(prevData.id ? prevData.id : null);
  //           setValue("temperature", prevData?.temp);
  //           setValue("bp", prevData?.bp);
  //           setValue("pulse", prevData?.pulse);
  //           setValue("respiration", prevData?.respiration);
  //           setValue("spo2", prevData?.spo2);
  //           setValue("bsl", prevData?.bsl);
  //           setValue("height", prevData?.height);
  //           setValue("weight", prevData?.weight);
  //           setValue("bmi", prevData?.bmi);
  //           setValue("Smoking", prevData?.smoking);
  //           setValue("Tobacco", prevData?.tobacco);
  //           setValue("Alcohol", prevData?.alcohol);
  //           setImpNotes(prevData?.importantNotes);
  //           setValue("nextFollowupDate", new Date(prevData?.newFollowupDate));

  //           let pastHistory = JSON.parse(prevData?.pastHistory);
  //           let editPastHistory = [];
  //           if (pastHistory.length > 0) {
  //             for (let pastHist of pastHistory) {
  //               let pastHistoryObj = {
  //                 label: pastHist?.history,
  //                 value: pastHist?.history,
  //                 description: pastHist?.description,
  //                 duration: pastHist?.sinceIn,
  //                 since: pastHist?.since,
  //                 isChecked: true,
  //               };
  //               console.log();

  //               editPastHistory.push(pastHistoryObj);
  //               setSelectedHistory(editPastHistory);
  //             }
  //           }

  //           let familyHistory = JSON.parse(prevData?.familyHistory);
  //           let editfamilyHistory = [];

  //           if (familyHistory.length > 0) {
  //             for (let famHistory of familyHistory) {
  //               let familyHistoryObj = {
  //                 label: famHistory?.history,
  //                 value: famHistory?.history,
  //                 description: famHistory?.description,
  //                 duration: famHistory?.sinceIn,
  //                 since: famHistory?.since,
  //                 isChecked: true,
  //               };
  //               console.log(familyHistoryObj, "familyHistoryObj212");

  //               editfamilyHistory.push(familyHistoryObj);
  //               setSelectedFamilyHistory(editfamilyHistory);
  //             }
  //           }

  //           let complaint = JSON.parse(prevData?.complaints);
  //           let editComplaint = [];

  //           if (complaint?.length > 0) {
  //             for (let comp of complaint) {
  //               let complaintObj = {
  //                 label: comp?.complaint,
  //                 // id: comp?.serviceId,
  //                 // value: comp?.serviceId,
  //                 since: comp?.since,
  //                 duration: comp?.duration,
  //                 isChecked: true,
  //               };

  //               editComplaint.push(complaintObj);
  //               setSelectedComplaints(editComplaint);
  //             }
  //           }

  //           let allergies = JSON.parse(prevData?.allergies);
  //           let editAllergy = [];

  //           if (allergies?.length > 0) {
  //             for (let allergy of allergies) {
  //               let allergyObj = {
  //                 label: allergy,
  //               };
  //               editAllergy.push(allergyObj);
  //               setSelectedAllergy(editAllergy);
  //             }
  //           }

  //           let investigations = JSON.parse(prevData?.investigations);

  //           let editInvestigation = [];

  //           if (investigations?.length > 0) {
  //             for (let investigation of investigations) {
  //               let invObj = {
  //                 label: investigation?.serviceName,
  //                 id: investigation?.serviceId,
  //                 value: investigation?.serviceId,
  //               };
  //               editInvestigation.push(invObj);
  //               setSelectedInvestigations(editInvestigation);
  //             }
  //           }

  //           let daignosis = JSON.parse(prevData?.diagnosis);
  //           let editDiagnosis = [];

  //           if (daignosis?.length > 0) {
  //             for (let diagno of daignosis) {
  //               let diagnosisObj = {
  //                 label: diagno?.diagnosis,
  //                 // id: comp?.serviceId,
  //                 // value: comp?.serviceId,
  //                 since: diagno?.since,
  //                 isChecked: true,
  //               };

  //               editDiagnosis.push(diagnosisObj);
  //               setSelectedDiagnosis(editDiagnosis);
  //             }
  //           }

  //           let otherReferral = JSON.parse(prevData?.otherReferral);
  //           let editOtherReferral = [];

  //           if (otherReferral.length > 0) {
  //             for (let otherReff of otherReferral) {
  //               let otherRefferralObj = {
  //                 label: otherReff?.doctorName,
  //                 id: otherReff?.doctorId,
  //                 value: otherReff?.doctorId,
  //               };

  //               editOtherReferral.push(otherRefferralObj);
  //               setSelectedOtherReferral(editOtherReferral);
  //             }
  //           }

  //           let prescriptions = JSON.parse(prevData?.emr_medications);

  //           console.log(prescriptions, "prescriptionsprescriptions");

  //           if (prescriptions?.length > 0) {
  //             for (let i = 0; i < prescriptions.length; i++) {
  //               let prescriptionObject = {
  //                 id: prescriptions[i]?.id,

  //                 Medicine: {
  //                   id: prescriptions[i]?.drug_id,
  //                   label: prescriptions[i]?.drug_name,
  //                   value: prescriptions[i]?.drug_id,
  //                   // genericDesc: prescriptions[i]?.genericDescription,
  //                   // code: "",
  //                   Medicine: prescriptions[i]?.drug_name,
  //                 },
  //                 Dosage: prescriptions[i]?.dosage,
  //                 Frequency: {
  //                   id: prescriptions[i]?.frequency_id,
  //                   label: prescriptions[i]?.frequency,
  //                   value: prescriptions[i]?.frequency_id,
  //                 },
  //                 Route: {
  //                   Id: prescriptions[i]?.route_id,
  //                   label: prescriptions[i]?.route_name,
  //                   value: prescriptions[i]?.route_id,
  //                 },
  //                 Duration: prescriptions[i]?.duration,
  //                 Quantity: prescriptions[i]?.quantity,
  //                 Instructions: {
  //                   Id: prescriptions[i]?.instruction_id,
  //                   label: prescriptions[i]?.instruction,
  //                   value: prescriptions[i]?.instruction_id,
  //                 },
  //                 Generic: {
  //                   id: prescriptions[i]?.generic_id,
  //                   label: prescriptions[i]?.generic_name,
  //                   value: prescriptions[i]?.generic_id,
  //                 },
  //                 Form: {
  //                   Id: prescriptions[i]?.form_id,
  //                   label: prescriptions[i]?.drug_form,
  //                   value: prescriptions[i]?.form_id,
  //                 },
  //                 isDeleted: false,
  //               };

  //               console.log(
  //                 "prescriptionObjectprescriptionObject",
  //                 prescriptionObject
  //               );

  //               if (prescriptionObject?.Medicine?.Medicine !== "") {
  //                 let dataArray = selectedMedicines;
  //                 dataArray.splice(i, 1, prescriptionObject);
  //                 setSelectedMedicines(dataArray);
  //               }

  //               setValue(`medicine[${i}].quantity`, prescriptions[i]?.quantity);

  //               setValue(`medicine[${i}].instructions`, {
  //                 Id: prescriptions[i]?.instruction_id,
  //                 label: prescriptions[i]?.instruction,
  //                 value: prescriptions[i]?.instruction_id,
  //               });

  //               setValue(`medicine[${i}].form`, {
  //                 Id: prescriptions[i]?.form_id,
  //                 label: prescriptions[i]?.drug_form,
  //                 value: prescriptions[i]?.form_id,
  //               });

  //               setValue(`medicine[${i}].generic`, {
  //                 id: prescriptions[i]?.generic_id,
  //                 label: prescriptions[i]?.generic_name,
  //                 value: prescriptions[i]?.generic_id,
  //               });
  //             }
  //           } else {
  //             handleResetMedicines();
  //           }

  //           let advices = JSON.parse(prevData?.advices);
  //           let editAdvice = [];

  //           if (advices.length > 0) {
  //             for (let adv of advices) {
  //               let adviceObj = {
  //                 label: adv,
  //               };
  //               editAdvice.push(adviceObj);
  //               setSelectedAdvices(editAdvice);
  //             }
  //           }

  //           let otherServices = JSON.parse(prevData?.otherServices);
  //           let editOtherServices = [];

  //           if (otherServices.length > 0) {
  //             for (let otherService of otherServices) {
  //               let otherServicesObj = {
  //                 id: otherService?.serviceId,
  //                 value: otherService?.serviceId,
  //                 label: otherService?.serviceName,
  //               };

  //               editOtherServices.push(otherServicesObj);
  //               setOtherServices(editOtherServices);
  //             }
  //           }
  //         }
  //       })
  //       .catch((eer) => eer);
  //   }
  // }, [selectedRow, setValue]);

  React.useEffect(() => {
    const weightValue = parseFloat(Number(weightWatch));
    const heightValue = parseFloat(Number(heightWatch));
    let heightINCM = heightValue * 0.01;
    if (Number(heightWatch) > 0 && Number(weightWatch) > 0) {
      const bmiValue = weightValue / (heightINCM * heightINCM);
      setValue("bmi", bmiValue.toFixed(2));
    }
  }, [heightWatch, weightWatch]);

  let complaintWatch = watch("createComplaint");
  let investigationWatch = watch("searchInvestigations");
  let diagnosisWatch = watch("searchDiagnosis");
  let nextFollowup = watch("nextFollowupDate");
  let allergyWatch = watch("searchAllergy");
  let otherReferralWatch = watch("referralDoctor");
  let advicesWatch = watch("searchAdvices");
  let watchOtherServices = watch("searchOtherServices");

  function findNextDiagnosis() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-6-input" && field.name === ""
    );
    console.log("filteredfilteredD", filtered);

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (diagnosisWatch !== null && diagnosisWatch !== undefined) {
      findNextDiagnosis();
    }
    if (diagnosisWatch) {
      let dataArray = [...selectedDiagnosis];
      let diagno = diagnosisWatch;

      const filterdDiagnosis = dataArray.filter(
        (list) => list.value === diagno.value
      );

      if (filterdDiagnosis.length > 0) {
        errorAlert("Diagnosis Already Exist!");
      } else {
        diagno.isChecked = true;
        dataArray.push(diagno);

        const combinedComplaints = dataArray.filter(
          (item) => item.label && item.isChecked
        );

        const uniqueDiagnosis = Array.from(
          new Map(combinedComplaints.map((item) => [item.label, item])).values()
        ).sort((a, b) => {
          if (a.value === null) return 1;
          if (b.value === null) return -1;
          return a.value - b.value;
        });

        setSelectedDiagnosis(uniqueDiagnosis);

        const previousDiagnosis = [...diagnosis];
        const mergedDiagnosis = [
          ...new Map(
            [...previousDiagnosis, ...combinedComplaints].map((item) => [
              item.label,
              item,
            ])
          ).values(),
        ];

        setDiagnosis(mergedDiagnosis);
      }

      setValue("searchDiagnosis", null);
    }
  }, [diagnosisWatch, selectedDiagnosis]);

  function findNextAllergic() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-4-input" && field.name === ""
    );

    console.log("filteredfilteredA", filtered);

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (allergyWatch !== null && allergyWatch !== undefined) {
      findNextAllergic();
    }
    if (allergyWatch) {
      let dataArray = [...selectedAllergy];
      let allergy = allergyWatch;

      const filteredAllergy = dataArray.filter(
        (list) => list.value === allergy.value
      );

      if (filteredAllergy.length > 0) {
        errorAlert("Allergy Already Exist!");
        setValue("searchAllergy", null);
      } else if (allergyWatch !== undefined) {
        let allergyObject = {
          label: allergyWatch.label,
          value: allergyWatch.value,
        };

        dataArray.push(allergyObject);
        setValue("searchAllergy", null);

        setSelectedAllergy(dataArray);
      }
    }
  }, [allergyWatch]);

  function findNextInvestigation() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-5-input" && field.name === ""
    );
    console.log("filteredfilteredD", filtered);

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (investigationWatch?.label !== "") {
      findNextInvestigation();
    }
    let dataArray = [...selectedInvestigations];
    let filtered = dataArray.filter(
      (inv) => inv.label === investigationWatch?.label
    );
    if (filtered.length > 0 && investigationWatch !== null) {
      errorAlert("Investigation Already Exist");
      setValue("searchInvestigations", null);
    } else if (investigationWatch?.label !== undefined) {
      let investigationObject = {
        // id: investigationWatch?.id,
        testId: investigationWatch?.id,
        label: investigationWatch?.label,
        value: investigationWatch?.value,
        // isChecked: true,
        // isUrgent: false,
        // Quantity: 1,
        // serviceId: investigationWatch?.serviceId,
        // serviceName: investigationWatch?.serviceName,
        // testType: investigationWatch?.testType,
        // testTypeId: investigationWatch?.testTypeId,
      };

      dataArray.push(investigationObject);

      let tempArr = selectedInvestigations.filter(
        (list) => list.testId !== undefined && list.serviceId !== undefined
      );

      setValue("searchInvestigations", null);
      setSelectedInvestigations(dataArray);
    }
  }, [investigationWatch]);

  function findNextComplaint() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-3-input" && field.name === ""
    );

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (complaintWatch !== null && complaintWatch !== undefined) {
      findNextComplaint();
    }

    let filterdComplaints = selectedComplaints.filter(
      (list) => list.label === complaintWatch?.label
    );

    if (editComplaintIndex === null) {
      if (
        complaintWatch !== null &&
        complaintWatch !== undefined &&
        filterdComplaints?.length > 0
      ) {
        errorAlert("Complaint Already Exist..!");
        setValue("createComplaint", null);
      } else {
        if (complaintWatch !== null && complaintWatch !== undefined) {
          // let combinedComplaints = [...selectedComplaints, ...complaints];

          let combinedComplaints = [...selectedComplaints];

          if (complaintWatch && complaintWatch.label) {
            combinedComplaints.push({
              id: complaintWatch?.__isNew__ === true ? null : complaintWatch.id,
              label: complaintWatch.label,
              value: complaintWatch.value || null,
              since: "",
              duration: "D",
              isChecked: true,
            });
          }

          combinedComplaints = combinedComplaints.filter(
            (item) => item.label && item.isChecked
          );

          const uniqueArray = Array.from(
            new Map(
              combinedComplaints.map((item) => [item.label, item])
            ).values()
          ).sort((a, b) => {
            if (a.value === null) return 1;
            if (b.value === null) return -1;
            return a.value - b.value;
          });

          setComplaints(uniqueArray);
          setSelectedComplaints(uniqueArray);
          setValue("createComplaint", null);
        } else {
          let dataArray = [...selectedComplaints, ...complaints];
          for (let object of dataArray) {
            if (object?.label === complaintWatch?.label) {
              object.isChecked = true;
            }
            setComplaints(dataArray);
            setValue("createComplaint", null);
          }
        }

        setResetComplaints(resetComplaints + 1);
      }
    }
  }, [complaintWatch]);

  function findNextOtherReferral() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-14-input" && field.name === ""
    );

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (otherReferralWatch !== null && otherReferralWatch !== undefined) {
      findNextOtherReferral();
    }
    if (otherReferralWatch) {
      let dataArray = [...selectedOtherReferral];
      let otherReferral = otherReferralWatch;

      const filteredAllergy = dataArray.filter(
        (list) => list.value === otherReferral.value
      );

      if (filteredAllergy.length > 0) {
        errorAlert("Doctor Already Exist!");
        setValue("referralDoctor", null);
      } else if (otherReferralWatch !== undefined) {
        let otherReferralObject = {
          id: otherReferralWatch.id,
          label: otherReferralWatch.label,
          value: otherReferralWatch.value,
        };

        dataArray.push(otherReferralObject);
        setValue("referralDoctor", null);
        setSelectedOtherReferral(dataArray);
      }
    }
  }, [otherReferralWatch]);

  function findNextAdvices() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-13-input" && field.name === ""
    );

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (advicesWatch !== null && advicesWatch !== undefined) {
      findNextAdvices();
    }
    if (advicesWatch) {
      let dataArray = [...selectedAdvices];
      let advices = advicesWatch;

      const filteredAllergy = dataArray.filter(
        (list) => list.value === advices.value
      );

      if (filteredAllergy.length > 0) {
        errorAlert("Advice Already Exist!");
        setValue("searchAdvices", null);
      } else if (advicesWatch !== undefined) {
        let otherReferralObject = {
          label: advicesWatch.label,
          value: advicesWatch.value,
        };

        dataArray.push(otherReferralObject);
        setValue("searchAdvices", null);
        setSelectedAdvices(dataArray);
      }
    }
  }, [advicesWatch]);

  function findNextOtherServices() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });

    let filtered = list.filter(
      (field) => field.id === "react-select-15-input" && field.name === ""
    );

    return filtered[0]?.focus();
  }

  React.useEffect(() => {
    if (watchOtherServices !== null && watchOtherServices !== undefined) {
      findNextOtherServices();
    }
    if (watchOtherServices) {
      let dataArray = [...otherServices];
      let otherService = watchOtherServices;

      const filteredAllergy = dataArray.filter(
        (list) => list.value === otherService.value
      );

      if (filteredAllergy.length > 0) {
        errorAlert("Service Already Exist!");
        setValue("searchOtherServices", null);
      } else if (watchOtherServices !== undefined) {
        let otherServicesObj = {
          label: watchOtherServices.label,
          value: watchOtherServices.value,
        };

        dataArray.push(otherServicesObj);
        setValue("searchOtherServices", null);
        setOtherServices(dataArray);
      }
    }
  }, [watchOtherServices]);

  const handleEditComplaint = (complaintObject, complaintIndex) => {
    let patchComplaint = {
      id: complaintObject?.value,
      label: complaintObject?.label,
      value: complaintObject?.value,
    };
    setValue("createComplaint", patchComplaint);
    setEditComplaintIndex(complaintIndex);
  };

  const handleUpdateComplaint = () => {
    let newComplaint = {
      id: complaintWatch?.__isNew__ === true ? null : complaintWatch.id,
      label: complaintWatch.label,
      value: complaintWatch.value || null,
      since: "",
      duration: "D",
      isChecked: true,
    };

    let dataArray = [...selectedComplaints];
    let filtered = dataArray.filter(
      (complaint) => complaint?.label === complaintWatch?.label
    );
    if (filtered?.length === 0) {
      dataArray.splice(editComplaintIndex, 1, newComplaint);
      setSelectedComplaints(dataArray);
      setValue("createComplaint", null);
      setEditComplaintIndex(null);
    } else {
      errorAlert("Complaint already exists.");
      setEditComplaintIndex(null);
      setValue("createComplaint", null);
    }
  };

  const handleDeleteComplaints = (complaint) => {
    const combinedArray = [...complaints];
    const uniqueArray = Array.from(
      new Map(combinedArray.map((item) => [item.label, item])).values()
    );

    uniqueArray.sort((a, b) => {
      if (a.value === null) return 1;
      if (b.value === null) return -1;
      return a.value - b.value;
    });
    let dataArrayTwo = [...uniqueArray];
    for (let object of dataArrayTwo) {
      if (object?.label === complaint?.label) {
        object.isChecked = !object.isChecked;
      }
    }

    setComplaints(dataArrayTwo);

    let dataArray = [...selectedComplaints];
    let filtered = dataArray.filter((item) => item !== complaint);
    setSelectedComplaints(filtered);
  };

  const handleDeleteDiagnosis = (diagno) => {
    // let dataArrayTwo = [...diagnosis];
    // for (let object of dataArrayTwo) {
    //   if (object?.label === diagno?.label) {
    //     object.isChecked = !object.isChecked;
    //   }
    // }
    // setDiagnosis(dataArrayTwo);

    // let dataArray = [...selectedDiagnosis];
    // let filtered = dataArray.filter((item) => item !== diagno);
    // setSelectedDiagnosis(filtered);

    const combinedArray = [...diagnosis];
    const uniqueArray = Array.from(
      new Map(combinedArray.map((item) => [item.label, item])).values()
    );

    uniqueArray.sort((a, b) => {
      if (a.value === null) return 1;
      if (b.value === null) return -1;
      return a.value - b.value;
    });
    let dataArrayTwo = [...uniqueArray];
    for (let object of dataArrayTwo) {
      if (object?.label === diagno?.label) {
        object.isChecked = !object.isChecked;
      }
    }

    setDiagnosis(dataArrayTwo);

    let dataArray = [...selectedDiagnosis];
    let filtered = dataArray.filter((item) => item !== diagno);
    setSelectedDiagnosis(filtered);
  };

  const handleDeleteAdvice = (advice) => {
    let dataArray = [...selectedAdvices];
    let filtered = dataArray.filter((item) => item.value !== advice.value);
    setSelectedAdvices(filtered);
  };

  const handleDeleteLocalAdvices = (localAdvice) => {
    const updatedLocalAdvices = selectedLocalAdvices.filter(
      (item) => item.value !== localAdvice.value
    );
    setSelectedLocalAdvices(updatedLocalAdvices);
  };

  const handleDeleteAllergy = (allergy) => {
    let dataArray = [...selectedAllergy];
    let filtered = dataArray.filter((item) => item != allergy);
    setSelectedAllergy(filtered);
  };

  const handleDeleteInv = (inv) => {
    let dataArray = [...selectedInvestigations];
    let filtered = dataArray.filter((item) => item !== inv);
    setSelectedInvestigations(filtered);
    setResetInv(resetInv + 1);
  };

  const handleDeleteOtherService = (row) => {
    let dataArray = [...otherServices];
    let filtered = dataArray.filter((item) => item !== row);
    setOtherServices(filtered);
    setResetServices(resetServices + 1);
  };

  const handleDeleteOtherReferral = (row) => {
    let dataArray = [...selectedOtherReferral];
    let filtered = dataArray.filter((item) => item !== row);
    setSelectedOtherReferral(filtered);
  };

  const handleGetMedicine = (search) => {
    // if (search !== "") {
    //   let searchObject = {
    //     isGenericSearch: false,
    //     isBrandSearch: true,
    //     drugType: "Brand",
    //     searchString: search,
    //   };
    //   getMedicines(searchObject)
    //     .then((response) => response.data)
    //     .then((res) => {
    //       let dataArray = [];
    //       if (res?.result?.length > 0) {
    //         for (let obj of res?.result) {
    //           let medicineObject = {
    //             id: obj.value,
    //             label: obj?.Medicine,
    //             value: obj?.value,
    //             genericDesc: obj?.genericDescription,
    //             code: obj?.code,
    //             Medicine: obj?.Medicine,
    //           };
    //           dataArray.push(medicineObject);
    //           setSearchMedicines(dataArray);
    //         }
    //       }
    //     })
    //     .catch((err) => err);
    // }

    if (search !== "" && UseCheckConsecutiveCharacters(search)) {
      getMedicines(search)
        .then((response) => {
          setSearchMedicines(response.data.result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setSearchMedicines([]);
    }
  };

  React.useEffect(() => {
    getGeneralFrequencies(0)
      .then((response) => response.data)
      .then((res) => {
        setFrequencyOptions(res?.result);
      })
      .catch((res) => {});
    getCommonRoutes()
      .then((response) => response.data)
      .then((res) => {
        setRoutes(res?.result);
      })
      .catch((err) => err);
    // getCommonComplaints()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     let responseData = [...res?.result];
    //     let dataArray = [...complaints];
    //     if (responseData?.length > 0) {
    //       for (let object of responseData) {
    //         let complaintObject = {
    //           value: object?.id,
    //           id: object?.id,
    //           isCommon: false,
    //           since: "",
    //           isChecked: false,
    //           duration: "",
    //           isNew: false,
    //           label: object.label,
    //         };
    //         dataArray.push(complaintObject);
    //         setComplaints(dataArray);
    //       }
    //     }
    //   })
    //   .catch((err) => err);
  }, []);

  const [complaintsOptions, setComplaintsOptions] = React.useState([]);
  React.useEffect(() => {
    // getComplaintsChips()
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setComplaintsOptions(res.result);
    //   });
  }, []);

  const getFrequencies = () => {};

  const handleGetRoutes = () => {};

  const handleGetInstructions = () => {};

  const handleGetGenerics = (searchGenericName) => {
    if (
      searchGenericName &&
      searchGenericName !== undefined &&
      UseCheckConsecutiveCharacters(searchGenericName)
    ) {
      getGenericDrugs(searchGenericName)
        .then((response) => response.data)
        .then((res) => {
          setGenericDrugs(res.result);
        });
    }
  };

  const handleAllDurations = (isApply) => {
    let dataArray = [...selectedMedicines];
    let firstMedicine = dataArray[0];
    if (isApply === true) {
      for (let medicine of dataArray) {
        if (
          firstMedicine?.Medicine !== null &&
          Number(firstMedicine?.since) > 0
        ) {
          if (
            medicine?.Medicine !== "" &&
            medicine?.Medicine?.id !== firstMedicine?.Medicine?.id
          ) {
            medicine.since = firstMedicine?.since;
          }
        }
      }
      setSelectedMedicines(dataArray);
    } else if (isApply === false) {
      for (let medicine of dataArray) {
        if (
          medicine?.Medicine !== "" &&
          medicine?.Medicine?.id !== firstMedicine?.Medicine?.id
        ) {
          medicine.since = "";
        }
      }
      setSelectedMedicines(dataArray);
    }
  };

  const renderInput = (row, index, header) => {
    // if (row?.Medicine !== "") {
    //   setValue(`medicine[${index}].generic`, {
    //     id: null,
    //     label: row?.Medicine?.genericDesc,
    //     value: row?.Medicine?.genericDesc,
    //   });
    // } else {
    //   setValue(`medicine[${index}].generic`, null);
    // }

    // if (row?.isDeleted === false) {
      if (row?.Medicine === "") {
        setValue(`medicine[${index}].medicine`, null);
      } else {
        setValue(`medicine[${index}].medicine`, row?.Medicine);
      }

      if (row?.Medicine === "") {
        setValue(`medicine[${index}].generic`, null);
        setValue(`medicine[${index}].form`, null);
      }

      if (row?.Dosage === "") {
        setValue(`medicine[${index}].dosage`, "");
      } else {
        setValue(`medicine[${index}].dosage`, row?.Dosage);
      }

      if (row?.Frequency?.label !== "") {
        setValue(`medicine[${index}].frequency`, row?.Frequency);
      } else {
        setValue(`medicine[${index}].frequency`, null);
      }

      // if (Number(row?.since) > 0) {
      setValue(`duration[${index}].since`, row?.since);
      // }

      if (row?.Route === "") {
        setValue(`medicine[${index}].route`, null);
      } else {
        setValue(`medicine[${index}].route`, row?.Route);
      }

      if (row?.Duration === "") {
        setValue(`medicine[${index}].duration`, "");
        setValue(`medicine[${index}].quantity`, "");
      } else {
        setValue(`medicine[${index}].duration`, row?.Duration);
      }
    // }

    // if (row?.Instructions !== "" && row?.Instructions !== undefined) {
    //   let found = medicineInstructions.find(
    //     (instruction) => instruction?.label === row?.Instructions
    //   );
    //   setValue(`duration[${index}].instructions`, found);
    // } else {
    //   setValue(`duration[${index}].instructions`, null);
    // }

    // calculate quantity using frequency dropdown value

    const calculateQuantity = (frequency, duration) => {
      if (!frequency || !duration) return 0;

      let frequencyCountValue = 0;
      let freqArray = frequency.label.split("-");

      freqArray.forEach((element) => {
        let value = element.trim();

        if (value === "1/2" || value === "½") {
          frequencyCountValue += 0.5;
        } else if (value === "¼") {
          frequencyCountValue += 0.25;
        } else if (value === "1") {
          frequencyCountValue += 1;
        } else if (value === "1½") {
          frequencyCountValue += 1.5;
        } else if (value === "1/8") {
          frequencyCountValue += 0.125;
        } else if (value === "2") {
          frequencyCountValue += 2;
        } else if (value === "2½") {
          frequencyCountValue += 2.5;
        } else if (value === "3") {
          frequencyCountValue += 3;
        } else if (value === "3½") {
          frequencyCountValue += 3.5;
        } else if (value === "4") {
          frequencyCountValue += 4;
        } else if (value === "4½") {
          frequencyCountValue += 4.5;
        } else if (value === "STAT") {
          frequencyCountValue += 1;
        } else if (value === "Stat/sos") {
          frequencyCountValue += 1;
        } else if (value === "SOS") {
          frequencyCountValue += 1;
        }
      });

      const quantityCount = Number(duration) * frequencyCountValue;
      return Math.ceil(quantityCount);
    };

    return (
      <div
        className={`flex justify-start z-50 ${
          index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
            ? "hidden"
            : ``
        } `}
      >
        {header === "Medicine" ? (
          <div className="flex justify-start w-[25vw] lg:w-full">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              menuPlacement={"bottom"}
              width={"250px"}
              control={control}
              name={`medicine[${index}].medicine`}
              placeholder={"Medicine"}
              handleInputChange={handleGetMedicine}
              inputRef={{
                ...register(`medicine[${index}].medicine`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    if (e.target.value !== null) {
                      let genericNameObj = {
                        id: e.target.value.genericId,
                        value: e.target.value.genericName,
                        label: e.target.value.genericName,
                      };

                      let formObj = {
                        Id: e.target.value.formId,
                        value: e.target.value.form,
                        label: e.target.value.form,
                      };

                      let filtered = dataArray.filter(
                        (item) =>
                          item?.Medicine?.label === e.target.value?.label
                      );

                      if (filtered.length === 0) {
                        let newMedicine = {
                          id:
                            e.target.value?.__isNew__ === true
                              ? null
                              : e.target.value?.id,
                          label: e.target.value?.label,
                          value:
                            e.target.value?.__isNew__ === true
                              ? null
                              : e.target.value?.id,
                        };

                        // Set values at the same level in dataArray
                        dataArray[index] = {
                          ...dataArray[index],
                          Medicine: newMedicine,
                          Generic: e.target.value.genericId
                            ? genericNameObj
                            : null,
                          Form: e.target.value.formId ? formObj : null,
                        };

                        // Update the form fields
                        if (
                          e.target.value.genericId !== null &&
                          e.target.value.genericId !== undefined
                        ) {
                          setValue(
                            `medicine[${index}].generic`,
                            genericNameObj
                          );
                          clearErrors([`medicine[${index}].generic`]);
                        }
                        if (
                          e.target.value.formId !== null &&
                          e.target.value.formId !== undefined
                        ) {
                          setValue(`medicine[${index}].form`, formObj);
                          clearErrors([`medicine[${index}].form`]);
                        }
                        setSelectedMedicines(dataArray);
                        const nextField = document.querySelector(
                          `input[name="medicine[${index}].dosage"]`
                        );
                        nextField?.focus();
                      } else {
                        setValue(`medicine[${index}].medicine`, null);
                        errorAlert("Medicine / Drug already exists");
                      }
                    } else {
                      // Clear the values when medicine is cleared
                      setValue(`medicine[${index}].generic`, null);
                      setValue(`medicine[${index}].form`, null);
                      dataArray[index] = {
                        ...dataArray[index],
                        Medicine: "",
                        Generic: null,
                        Form: null,
                      };
                      setSelectedMedicines(dataArray);
                    }
                  },
                }),
              }}
              dataArray={searchMedicines}
              isSearchable={true}
            />
          </div>
        ) : null}

        {header === "Dosage" ? (
          <div className="flex justify-start w-full ml-2">
            <input
              className="h-7 w-32 border border-gray-400 rounded px-2"
              style={{ outline: "none" }}
              placeholder="Dosage"
              name={`medicine[${index}].dosage`}
              {...register(`medicine[${index}].dosage`)}
              onChange={(e) => {
                let dataArray = [...selectedMedicines];
                dataArray[index].Dosage = e.target.value;
                setSelectedMedicines(dataArray);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  const nextField = document.querySelector(
                    `[name="medicine[${index}].frequency"]`
                  );
                  setValue(nextField)
                  // nextField?.focus();
                }
              }}
            />
            {/* <InputField
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              width={"170px"}
              control={control}
              name={`medicine[${index}].dosage`}
              label={"Dosage"}
              handleInputChange={handleGetRoutes}
              inputRef={{
                ...register(`medicine[${index}].dosage`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Dosage = e.target.value;
                    setSelectedMedicines(dataArray);
                  },
                }),
              }}
            /> */}
          </div>
        ) : null}

        {header === "Generic" ? (
          <div className="flex justify-start pl-2 w-[20vw] xl:w-full">
            <CustomCreateable
              control={control}
              name={`medicine[${index}].generic`}
              isSearchable={true}
              placeholder={"Generic"}
              isClearable={true}
              handleInputChange={handleGetGenerics}
              inputRef={{
                ...register(`medicine[${index}].generic`, {
                  // onChange: (e) => {
                  //   if (
                  //     e.target.value !== null ||
                  //     e.target.value !== undefined
                  //   ) {
                  //     let dataArray = [...selectedMedicines];
                  //     if (dataArray[index].Medicine !== "") {
                  //       dataArray[index].Medicine.genericDesc =
                  //         e.target.value?.label;
                  //     }
                  //     setSelectedMedicines(dataArray);
                  //   } else {
                  //     let dataArray = [...selectedMedicines];
                  //     if (dataArray[index].Medicine !== "") {
                  //       dataArray[index].Medicine.genericDesc = "";
                  //     }
                  //     setSelectedMedicines(dataArray);
                  //   }
                  // },

                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Generic = e.target.value;
                    setSelectedMedicines(dataArray);
                  },
                }),
              }}
              dataArray={genericDrugs}
            />
          </div>
        ) : null}

        {header === "Frequency" ? (
          <div className="flex justify-start ml-2 w-[20vw] xl:w-full">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              width={"170px"}
              control={control}
              name={`medicine[${index}].frequency`}
              placeholder={"Frequency"}
              handleInputChange={getFrequencies}
              isClearable={true}
              inputRef={{
                ...register(`medicine[${index}].frequency`, {
                  onChange: (e) => {
                    if (
                      e.target.value !== null &&
                      e.target.value !== undefined
                    ) {
                      let dataArray = [...selectedMedicines];
                      dataArray[index].Frequency = e.target.value;

                      const duration = dataArray[index].Duration;
                      if (duration) {
                        const quantity = calculateQuantity(
                          e.target.value,
                          duration
                        );
                        setValue(`medicine[${index}].quantity`, quantity);
                        dataArray[index].Quantity = quantity;
                      }

                      setSelectedMedicines(dataArray);
                      const nextField = document.querySelector(
                        `input[name="medicine[${index}].route"]`
                      );
                      nextField?.focus();
                    } else {
                      let dataArray = [...selectedMedicines];
                      dataArray[index].Frequency = "";
                      setValue(`medicine[${index}].quantity`, 0);
                      dataArray[index].Quantity = 0;
                      setSelectedMedicines(dataArray);
                    }
                  },
                }),
              }}
              dataArray={frequencyOptions}
              isSearchable={true}
            />
          </div>
        ) : null}

        {header === "Route" ? (
          <div className="flex justify-start w-[17vw] xl:w-full ml-2">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              width={"170px"}
              control={control}
              name={`medicine[${index}].route`}
              placeholder={"Route"}
              handleInputChange={handleGetRoutes}
              inputRef={{
                ...register(`medicine[${index}].route`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Route = e.target.value;
                    setSelectedMedicines(dataArray);
                    const nextField = document.querySelector(
                      `input[name="medicine[${index}].duration"]`
                    );
                    nextField?.focus();
                  },
                }),
              }}
              dataArray={routes}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        ) : null}

        {header === "Duration" ? (
          <div className="flex gap-2 items-center ml-2">
            <input
              disabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              name={`medicine[${index}].duration`}
              {...register(`medicine[${index}].duration`)}
              style={{ outline: "none" }}
              placeholder="Duration"
              className="text-center w-24 border border-gray-400 rounded"
              // name={`duration[${index}].since`}
              // {...register(`duration[${index}].since`)}
              // onChange={(e) => {
              //   if (applyToAllMedicines === true) {
              //     let dataArray = [...selectedMedicines];
              //     for (let medicine of dataArray) {
              //       if (medicine?.Medicine !== "") {
              //         medicine.since = e.target.value;
              //         medicine.Days = "D";
              //       }
              //     }
              //     setSelectedMedicines(dataArray);
              //   } else if (
              //     applyToAllMedicines === false &&
              //     Number(e.target.value) > 0
              //   ) {
              //     let dataArray = [...selectedMedicines];
              //     dataArray[index].since = e.target.value;
              //     dataArray[index].Days = "D";
              //     setSelectedMedicines(dataArray);
              //   } else if (applyToAllMedicines === false) {
              //     let dataArray = [...selectedMedicines];
              //     dataArray[index].since = "";
              //     dataArray[index].Days = "";
              //     setSelectedMedicines(dataArray);
              //   }
              // }}

              onChange={(e) => {
                let dataArray = [...selectedMedicines];
                dataArray[index].Duration = e.target.value;

                // Calculate quantity when duration changes
                const frequency = dataArray[index].Frequency;
                if (frequency) {
                  const quantity = calculateQuantity(frequency, e.target.value);
                  setValue(`medicine[${index}].quantity`, quantity);
                  dataArray[index].Quantity = quantity;
                }

                setSelectedMedicines(dataArray);
              }}
            />
            <div
              className="px-3 rounded-full bg-[#073763] text-sm py-0.5 text-white"
              // onClick={() => {
              //   let dataArray = [...selectedMedicines];
              //   dataArray[index].Days = "D";
              //   setSelectedMedicines(dataArray);
              // }}
              // className={`px-3 text-sm rounded-full ${
              //   row?.Days === "D" && row.since !== ""
              //     ? `bg-[#073763] text-white`
              //     : `border border-slate-600`
              // } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
            >
              Days
            </div>
            {/* <div
              onClick={() => {
                let dataArray = [...selectedMedicines];
                dataArray[index].Days = "M";
                setSelectedMedicines(dataArray);
              }}
              className={`h-6 w-6 rounded-full ${
                row?.Days === "M" && row.since !== ""
                  ? `bg-[#073763] text-white`
                  : `border border-slate-600`
              } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
            >
              M
            </div>
            <div
              onClick={() => {
                let dataArray = [...selectedMedicines];
                dataArray[index].Days = "Y";
                setSelectedMedicines(dataArray);
              }}
              className={`h-6 w-6 rounded-full ${
                row?.Days === "Y" && row.since !== ""
                  ? `bg-[#073763] text-white`
                  : `border border-slate-600`
              } flex justify-center items-center font-semibold cursor-pointer pt-[1px]`}
            >
              Y
            </div>  */}
          </div>
        ) : null}

        {header === "Quantity" ? (
          <div className="flex justify-start w-full ml-2">
            <input
              className="h-7 w-20 border border-gray-400 rounded px-2"
              style={{ outline: "none" }}
              placeholder="Qty"
              name={`medicine[${index}].quantity`}
              {...register(`medicine[${index}].quantity`)}
              // onChange={(e) => {
              //   let dataArray = [...selectedMedicines];
              //   dataArray[index].Quantity = e.target.value;
              //   setSelectedMedicines(dataArray);
              // }}
              disabled={true}
            />
          </div>
        ) : null}

        {header === "Instructions" ? (
          <div className="flex justify-start ml-2 w-[20vw] xl:w-full">
            {/* <input
              className="h-7 border border-gray-400 rounded px-2"
              style={{ outline: "none" }}
              placeholder="Instruction"
              name={`duration[${index}].instructions`}
              {...register(`duration[${index}].instructions`)}
              onChange={(e) => {
                let dataArray = [...selectedMedicines];
                dataArray[index].Instructions = e.target.value;
                setSelectedMedicines(dataArray);
              }}
            /> */}
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              control={control}
              name={`medicine[${index}].instructions`}
              placeholder={"Instructions"}
              handleInputChange={handleGetInstructions}
              inputRef={{
                ...register(`medicine[${index}].instructions`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Instructions = e.target.value;
                    setSelectedMedicines(dataArray);
                  },
                }),
              }}
              dataArray={medicineInstructions}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        ) : null}

        {header === "Form" ? (
          <div className="flex justify-start w-[18vw] xl:w-full ml-2">
            <CustomCreateable
              isDisabled={
                index !== 0 && selectedMedicines[index - 1]?.Medicine === ""
                  ? true
                  : false
              }
              width={"170px"}
              control={control}
              name={`medicine[${index}].form`}
              placeholder={"Form"}
              handleInputChange={() => {}}
              inputRef={{
                ...register(`medicine[${index}].form`, {
                  onChange: (e) => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].Form = e.target.value;
                    setSelectedMedicines(dataArray);
                  },
                }),
              }}
              dataArray={formOpt}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex items-center">
        <>
          {row?.Medicine !== "" ? (
            <div className="flex gap-2 cursor-pointer">
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    let dataArray = [...selectedMedicines];
                    dataArray[index].isDeleted = true;
                    setSelectedMedicines(dataArray);
                  }}
                  sx={{ padding: 0, margin: 0 }}
                >
                  <DeleteOutlineIcon fontSize="small" sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </>
      </div>
    );
  };

  let tobaccoWatch = watch("Tobacco");
  let smokingWatch = watch("Smoking");
  let alcoholWatch = watch("Alcohol");

  const handleSubmitEmr = () => {
    setIsPreview(false);
    trigger();

    // let asthamaObject = selectedHistory?.find(
    //   (item) => item?.label === "Asthama"
    // );
    // let diabetesObject = selectedHistory?.find(
    //   (item) => item?.label === "Diabetes"
    // );
    // let hyperObject = selectedHistory?.find(
    //   (item) => item?.label === "Hypertension"
    // );

    // let otherObject = selectedHistory?.find((item) => item?.label === "Other");
    // let ihdObject = selectedHistory?.find((item) => item?.label === "IHD");

    // // const pastHistory = {
    //   dmFlag: diabetesObject !== undefined && diabetesObject?.isChecked,
    //   dmDuration:
    //     diabetesObject?.isChecked === false
    //       ? null
    //       : diabetesObject !== undefined && diabetesObject?.duration,
    //   dmSince:
    //     diabetesObject?.isChecked === false
    //       ? null
    //       : Number(diabetesObject?.since) > 0
    //       ? Number(diabetesObject?.since)
    //       : null,
    //   dmDescription:
    //     diabetesObject?.isChecked === false
    //       ? null
    //       : diabetesObject?.description,

    //   htnFlag: hyperObject !== undefined && hyperObject?.isChecked,
    //   htnDuration:
    //     hyperObject?.isChecked === false
    //       ? null
    //       : hyperObject !== undefined && hyperObject?.duration,
    //   htnSince:
    //     hyperObject?.isChecked === false
    //       ? null
    //       : Number(hyperObject?.since) > 0
    //       ? Number(hyperObject?.since)
    //       : null,
    //   htnDescription:
    //     hyperObject?.isChecked === false ? null : hyperObject?.description,

    //   asthmaFlag: asthamaObject !== undefined && asthamaObject?.isChecked,
    //   asthmaDuration:
    //     asthamaObject?.isChecked === false
    //       ? null
    //       : asthamaObject !== undefined && asthamaObject?.duration,
    //   asthmaSince:
    //     asthamaObject?.isChecked === false
    //       ? null
    //       : Number(asthamaObject?.since) > 0
    //       ? Number(asthamaObject?.since)
    //       : null,
    //   asthmaDescription:
    //     asthamaObject?.isChecked === false ? null : asthamaObject?.description,

    //   otherFlag: otherObject !== undefined && otherObject?.isChecked,
    //   otherDuration:
    //     otherObject?.isChecked === false
    //       ? null
    //       : otherObject !== undefined && otherObject?.duration,
    //   otherSince:
    //     otherObject?.isChecked === false
    //       ? null
    //       : Number(otherObject?.since) > 0
    //       ? Number(otherObject?.since)
    //       : null,
    //   otherDescription:
    //     otherObject?.isChecked === false ? null : otherObject?.description,

    //   ihdFlag: ihdObject !== undefined && ihdObject?.isChecked,
    //   ihdDuration:
    //     ihdObject?.isChecked === false
    //       ? null
    //       : ihdObject !== undefined && ihdObject?.duration,
    //   ihdSince:
    //     ihdObject?.isChecked === false
    //       ? null
    //       : Number(ihdObject?.since) > 0
    //       ? Number(ihdObject?.since)
    //       : null,
    //   ihdDescription:
    //     ihdObject?.isChecked === false ? null : ihdObject?.description,

    //   tobaccoFlag: tobaccoWatch,
    //   smokingFlag: smokingWatch,
    //   alcoholFlag: alcoholWatch,
    // };

    const pastHistory = selectedHistory
      ? selectedHistory.map((history) => ({
          description: history.description,
          history: history.label,
          since: history.since,
          sinceIn: history.duration,
        }))
      : [];

    const familyHistory = selectedFamilyHistory
      ? selectedFamilyHistory.map((history) => ({
          description: history.description,
          history: history.label,
          since: history.since,
          sinceIn: history.duration,
        }))
      : [];

    const allergies = selectedAllergy
      ? selectedAllergy.map((val) => val.label)
      : null;

    const investigations = selectedInvestigations
      ? selectedInvestigations.map((inv) => ({
          serviceId: inv.testId ? inv.testId : 0,
          serviceName: inv.label,
        }))
      : [];

    const diagnosis = selectedDiagnosis
      ? selectedDiagnosis.map((diagno) => ({
          diagnosis: diagno.label,
          since: diagno?.since ? diagno.since : null,
        }))
      : [];

    const advices = selectedAdvices
      ? selectedAdvices.map((advice) => advice.label)
      : null;

    const otherReferrals = selectedOtherReferral
      ? selectedOtherReferral.map((ref) => ({
          doctorId: ref?.id,
          doctorName: ref.label,
        }))
      : [];

    const otherServicesData = otherServices
      ? otherServices.map((services) => ({
          serviceId: services?.id,
          serviceName: services?.label,
        }))
      : [];

    let medications = [];
    let filteredMedicines = selectedMedicines.filter(
      (medicine) => medicine?.Medicine !== ""
    );
    if (filteredMedicines?.length > 0) {
      for (let object of filteredMedicines) {
        let newMedicine = {
          id: object.id ? object.id : null,
          drugId: object?.Medicine?.id,
          drugName: object?.Medicine?.label,
          dosage: object?.Dosage,
          duration:
            Number(object?.Duration) > 0 ? Number(object?.Duration) : null,
          durationIn: "Days",
          formId: object.Form.Id ? object.Form.Id : null,
          frequency: object?.Frequency?.label,
          frequencyId: object?.Frequency?.id,
          genericId: object?.Generic?.id,
          instruction: object?.Instructions?.label,
          instructionId: object?.Instructions?.Id,
          quantity: object?.Quantity,
          routeId: object?.Route.Id,
          isDelete: object?.isDeleted,
        };
        medications?.push(newMedicine);
      }
    }

    let complaints = [];
    for (let complaint of selectedComplaints) {
      // if (complaint?.isChecked === true) {
      let complaintDuration =
        complaint?.duration === "D"
          ? "Days"
          : complaint?.duration === "M"
          ? "Months"
          : complaint?.duration === "Y"
          ? "Years"
          : "";

      let object = {
        complaint: complaint?.label,
        since: Number(complaint?.since) > 0 ? Number(complaint.since) : null,
        sinceIn: complaint.since ? complaintDuration : null,
      };
      complaints.push(object);
      // }
    }

    console.log("postDiagnosispostDiagnosis", complaints);

    // let postDiagnosis = [];
    // for (let diagno of selectedDiagnosis) {
    //   // if (diagno?.isChecked === true) {
    //     let diagnoDuration =
    //       diagno?.duration === "D"
    //         ? "Days"
    //         : diagno?.duration === "M"
    //           ? "Months"
    //           : diagno?.duration === "Y"
    //             ? "Years"
    //             : "";
    //     let object = {
    //       icd: diagno?.icdcode,
    //       duration: diagnoDuration,
    //       since: Number(diagno?.since) > 0 ? Number(diagno?.since) : null,
    //       isChronic: true,
    //       status: "Provisional",
    //     };
    //     postDiagnosis.push(object);
    //   // }
    // }

    // console.log("postDiagnosispostDiagnosis", postDiagnosis);

    // let pathologyServices = [];
    // if (selectedInvestigations?.length > 0) {
    //   for (let inv of selectedInvestigations) {
    //     let pathObject = {
    //       testType: inv?.testType,
    //       testTypeId: inv?.testTypeId,
    //       investigation: inv?.label,
    //       investigationImageName: null,
    //       investigationPathBase64: null,
    //       testId: inv?.testId,
    //       serviceId: inv?.serviceId,
    //       deleteFlag: false,
    //       id: inv?.id,
    //       isUrgent: false,
    //       quantity: 1,
    //     };
    //     pathologyServices.push(pathObject);
    //   }
    // }

    // const post = {
    //   id: emrId,
    //   visitId: location?.state?.visitId,
    //   familyHistoryDesc: familyHistory,
    //   visitNumber: location?.state?.visitNumber,
    //   medicalHistory: "",
    //   patientId: location?.state?.patientId,
    //   localExamination: "",
    //   department: location?.state?.department,
    //   pastHistory: pastHistory,
    //   complaints: complaints,
    //   diagnosis: postDiagnosis,
    //   pathology: pathologyServices,
    //   prescriptions: medications,
    //   services: [...pathologyServices, ...services],
    //   notes: impNotes,
    //   vitals: {
    //     bloodPressureDiastolic: Number(diastolicValue),
    //     bloodPressureSystolic: Number(systolicValue),
    //     bmi: Number(bmiWatch),
    //     height: heightWatch,
    //     pulseRate: Number(pulseWatch),
    //     respirationType: "",
    //     spO2: spo2Watch,
    //     temperature: temperatureWatch,
    //     weight: weightWatch,
    //     respiration: respirationWatch,
    //     bsl: bslWatch,
    //   },
    //   emrAdvices: emrAdvices,
    //   clinicalFindings: clinicalFindings,
    //   treatmentAdvices: advices,
    //   generalInstructions: localAdvices,
    //   nextFollowDate: format(nextFollowup, "yyyy-MM-dd"),
    //   unitId: loggedUser?.loginUnitId,
    //   draftFlag: false,
    // };
    console.log(nextFollowup, "nextFollowup");
    const post = {
      id: emrId,
      doctorId: token.doctorId,
      opdIpd: 0,
      opdIpdId: selectedRow?.VisitId,
      temp: temperatureWatch,
      bp: bpWatch,
      pulse: Number(pulseWatch),
      respiration: respirationWatch,
      spo2: spo2Watch,
      bsl: bslWatch,
      height: heightWatch,
      weight: weightWatch,
      bmi: Number(bmiWatch),
      importantNotes: impNotes,
      smoking: smokingWatch,
      tobacco: tobaccoWatch,
      alcohol: alcoholWatch,
      pastHistory: pastHistory,
      familyHistory: familyHistory,
      emrComplaints: complaints,
      allergies: allergies,
      investigations: investigations,
      emrDiagnosis: diagnosis,
      emrMedicationsRequestDtoList: medications,
      advices: advices,
      otherReferral: otherReferrals,
      otherServices: otherServicesData,
      newFollowupDate: format(nextFollowup, "yyyy-MM-dd"),
    };

    setPostObject(post);

    setOpenPost(true);
  };

  console.log("postObject", postObject);

  const handleResetMedicines = () => {
    let dataArray = [];
    for (let object of medicinesData) {
      object.Medicine = "";
      object.since = "";
      object.Generic = "";
      object.Frequency = "";
      object.Dosage = "";
      object.Days = "";
      object.Instructions = "";
      object.Route = "";
      object.Form = "";
      object.isDeleted = false;
      dataArray.push(object);
      setSelectedMedicines(dataArray);
    }
  };

  const handleResetAll = () => {
    setSelectedComplaints([]);
    setSelectedHistory([]);
    setSelectedFamilyHistory([]);
    setSelectedDiagnosis([]);
    setSelectedInvestigations([]);
    handleResetMedicines();
    setOtherServices([]);
  };

  const postEmr = () => {
    setOpenBackdrop(true);
    // if (location?.state?.from === "Admission List") {
    //   saveIPDEMR(postObject)
    //     .then((response) => response.data)
    //     .then((res) => {
    //       setOpenBackdrop(false);
    //       successAlert(res?.message);
    //       handlePrintPreview();
    //     })
    //     .catch((err) => {
    //       setOpenBackdrop(false);
    //       errorAlert(err?.message);
    //     });
    // } else {
    //   saveEmrLite(postObject)
    //     .then((response) => response.data)
    //     .then((res) => {
    //       setOpenBackdrop(false);
    //       successAlert(res?.message);
    //       handlePrintPreview();
    //     })
    //     .catch((err) => {
    //       setOpenBackdrop(false);
    //       errorAlert(err?.message);
    //     });
    // }
    saveEmrLite(postObject)
      .then((response) => response.data)
      .then((res) => {
        setOpenBackdrop(false);
        successAlert(res?.message);
        setOpenPost(false);
        close();
        setOpenBackdrop(false);
        // handlePrintPreview();
      })
      .catch((err) => {
        setOpenBackdrop(false);
        errorAlert(err?.message);
      });
  };

  const updateEmr = () => {
    setOpenBackdrop(true);
    // updateEmrLite(postObject)
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setOpenBackdrop(false);
    //     successAlert(res?.message);
    //     handleResetAll();
    //     navigate("/opd/visitlist", {
    //       state: { menuId: location?.state?.visitListMenuId },
    //     });
    //   })
    //   .catch((err) => {
    //     setOpenBackdrop(false);
    //     errorAlert(err?.message);
    //   });

    saveEmrLite(postObject)
      .then((response) => response.data)
      .then((res) => {
        setOpenBackdrop(false);
        successAlert(res?.message);
        setOpenPost(false);
        close();
        setOpenBackdrop(false);
        // handlePrintPreview();
      })
      .catch((err) => {
        setOpenBackdrop(false);
        errorAlert(err?.message);
      });
  };

  const handleSearchComplaints = (search) => {
    if(search !== ""){
      getComplaintsData(search)
        .then((response) => response.data)
        .then((res) => {
          if (res?.statusCode === 200) {
            setComplaintsOptions(res?.result);
          }
        })
        .catch((er) => er);
    }
  };

  const handleSearchAllergies = (search) => {
    if(search !== ""){
      getAllergiesData(search)
        .then((response) => response.data)
        .then((res) => {
          if (res?.statusCode === 200) {
            setAllergyOption(res?.result);
          }
        })
        .catch((er) => er);
    }
  };

  const handleSearchInvestigations = (search) => {
    if(search !== ""){
      getInvestigations(search, 2)
        .then((response) => response.data)
        .then((res) => {
          if (res?.statusCode === 200) {
            setInvOptions(res?.result);
          }
        })
        .catch((er) => er);
    }
  };

  const handelSearchDiagnosis = (search) => {
    if(search !== ""){
      getDiagnosis(search, loggedUser?.loginUnitId, location?.state?.departmentId)
        .then((response) => response.data)
        .then((res) => {
          setDiagnoOptions(res?.result);
        })
        .catch((er) => er);
    }
  };

  const handleOtherReferral = (search) => {
    if(search !== ""){
      getReferenceDoctor(
        search,
        loggedUser?.loginUnitId,
        location?.state?.departmentId
      )
        .then((response) => response.data)
        .then((res) => {
          setReferralDocOptions(res?.result);
        })
        .catch((er) => er);
    }
  };

  const handleSearchServices = (search) => {
    if(search !== ""){
      getOtherServices(search)
        .then((response) => response.data)
        .then((res) => {
          setServicesOptions(res?.result);
        })
        .catch((err) => err);
    }
  };

  const handleSearchAdvices = (search) => {};

  const handleAddDuration = (duration, complaintIndex) => {
    let dataArray = [...selectedHistory];
    dataArray[complaintIndex].duration = duration;
    setSelectedHistory(dataArray);
  };

  const handleAddFamilyHistoryDuration = (duration, complaintIndex) => {
    let dataArray = [...selectedFamilyHistory];
    dataArray[complaintIndex].duration = duration;
    setSelectedFamilyHistory(dataArray);
  };

  const handleAddComplaintsDuration = (duration, complaintIndex) => {
    let dataArray = [...complaints];

    dataArray[complaintIndex].duration = duration;
    setComplaints(dataArray);
  };

  const calculateFutureDate = (dateString, maxDays) => {
    const [day, month, year] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + maxDays);
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 0) {
      date.setDate(date.getDate() - 1);
    }
    const newDay = date.getDate().toString().padStart(2, "0");
    const newMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    const newYear = date.getFullYear();

    return `${newDay}-${newMonth}-${newYear}`;
  };

  React.useEffect(() => {
    // let durations = [];
    // for (let medicine of selectedMedicines) {
    //   if (medicine?.since !== "") {
    //     durations.push(Number(medicine?.since));
    //   }
    // }
    // let biggestDuration = Math.max(...durations);
    // let futureDateString = calculateFutureDate(
    //   format(new Date(), "dd-MM-yyyy"),
    //   biggestDuration
    // );
    // if (durations?.length > 0) {
    //   setValue(
    //     "nextFollowupDate",
    //     new Date(
    //       futureDateString
    //         .toString()
    //         .replaceAll("-", "/")
    //         ?.split("/")
    //         .reverse()
    //         .join("/")
    //     )
    //   );
    // } else {
    //   setValue("nextFollowupDate", new Date());
    // }
  }, [selectedMedicines]);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyles} className="h-[92%]">
          <div className="px-2">
            <div className="flex justify-between">
              <div className=" ">
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  EMR
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => {
                    close();
                    handleResetAll();
                  }}
                  type="button"
                >
                  <ForceCloseIcon />
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 2xl:grid-cols-4 gap-2 items-center">
              <div className="bg-[#F1F1F1] p-2 whitespace-nowrap w-full col-span-2 grid grid-cols-3 lg:grid-cols-4 justify-start items-center lg:h-9">
                <div className="xl:w-[30%] flex justify-start font-semibold">
                  MRNo :&nbsp;
                  <span className="text-purple-800 px-2">
                    {/* {location?.state?.mrno} */}
                    {selectedRow?.MRNo}
                  </span>
                </div>
                <div className="xl:w-[40%] flex justify-start font-semibold md:col-span-2">
                  Patient Name :&nbsp;
                  <span className="text-purple-800 px-2">
                    {/* {location?.state?.patientName} */}
                    {selectedRow?.["Patient Name"]}
                  </span>
                </div>
                <div className=" flex justify-start font-semibold">
                  Age / Gender :&nbsp;
                  <span className="text-purple-800 px-2">
                    {/* {location?.state?.age} */}
                    {selectedRow?.AgeYear}
                    /&nbsp;
                    {/* {location?.state?.Gender?.charAt(0)} */}
                    {selectedRow?.Gender}
                  </span>
                </div>
              </div>
              <div className="col-span-2 grid md:grid-cols-3 xl:grid-cols-9 gap-2">
                <div>
                  <InputField
                    control={control}
                    name="temperature"
                    label="Temp °F"
                    type="number"
                    error={errors.temperature}
                    inputRef={{
                      ...register("temperature", {
                        onChange: (e) => {
                          if (Number(e.target.value) > 200) {
                            setError("temperature", {
                              type: "custom",
                              message: "Required",
                            });
                          } else {
                            clearErrors("temperature");
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div>
                  <InputField
                    control={control}
                    name="bp"
                    error={errors?.bp}
                    // type="number"
                    label="BP mmHg"
                    inputRef={{
                      ...register("bp", {
                        onChange: (e) => {
                          if (Number(e.target.value) > 500) {
                            setError("bp", {
                              type: "custom",
                              message: "Required",
                            });
                          } else {
                            clearErrors("bp ");
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div>
                  <InputField
                    control={control}
                    name="pulse"
                    label="Pulse/min"
                    error={errors.pulse}
                    type="number"
                    inputRef={{
                      ...register("pulse", {
                        onChange: (e) => {
                          if (Number(e.target.value) > 220) {
                            setError("pulse", {
                              type: "custom",
                              message: "Required",
                            });
                          } else {
                            clearErrors("pulse");
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div>
                  <InputField
                    control={control}
                    name="respiration"
                    label={"Respiration"}
                    type={"number"}
                    error={errors.respiration}
                    inputRef={{
                      ...register("respiration", {
                        onChange: (e) => {
                          if (Number(e.target.value) > 100) {
                            setError("respiration", {
                              type: "custom",
                              message: "Required",
                            });
                          } else {
                            clearErrors("respiration");
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div>
                  <InputField
                    control={control}
                    name="spo2"
                    label="SpO2 %"
                    type="number"
                    error={errors.spo2}
                    inputRef={{
                      ...register("spo2", {
                        onChange: (e) => {
                          if (Number(e.target.value) > 200) {
                            setError("spo2", {
                              type: "custom",
                              message: "Required",
                            });
                          } else {
                            clearErrors("spo2");
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div>
                  <InputField
                    control={control}
                    name="bsl"
                    label={"BSL"}
                    type="number"
                    error={errors?.bsl}
                    inputRef={{
                      ...register("bsl", {
                        onChange: (e) => {
                          if (Number(e.target.value) > 300) {
                            setError("bsl", {
                              type: "custom",
                              message: "Required",
                            });
                          } else {
                            clearErrors("bsl");
                          }
                        },
                      }),
                    }}
                  />
                </div>

                <div>
                  <InputField
                    control={control}
                    name="height"
                    label="Height (cms)"
                    error={errors.height}
                    type="number"
                    inputRef={{
                      ...register("height", {
                        onChange: (e) => {
                          if (Number(e.target.value) > 300) {
                            setError("height", {
                              type: "custom",
                              message: "Required",
                            });
                          } else {
                            clearErrors("height");
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    name="weight"
                    label="Weight (kg)"
                    type="number"
                    error={errors.weight}
                    inputRef={{
                      ...register("weight", {
                        onChange: (e) => {
                          if (Number(e.target.value) > 300) {
                            setError("weight", {
                              type: "custom",
                              message: "Required",
                            });
                          } else {
                            clearErrors("weight ");
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <InputField
                    control={control}
                    name="bmi"
                    label="BMI"
                    disabled={true}
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-start -ml-1">
              <Tooltip title="Previous Visits">
                {isOpenPrev === true ? (
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpenPrev(!isOpenPrev);
                    }}
                  >
                    <ArrowCircleLeftIcon
                      fontSize="large"
                      sx={{
                        color: "#073763",
                      }}
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpenPrev(!isOpenPrev);
                    }}
                  >
                    <ArrowCircleRightIcon
                      fontSize="large"
                      sx={{
                        color: "#073763",
                      }}
                    />
                  </button>
                )}
              </Tooltip>
            </div>
            <div className="flex gap-2 ">
              <div
                className={`rounded p-2 w-[25%] lg:w-[9%] ${
                  isOpenPrev === true ? `` : `hidden`
                }`}
              >
                <div
                  className={` mt-2 text-lg font-bold tracking-wide flex justify-start px-2 py-1 my-1 rounded `}
                >
                  Prev. Visits
                </div>
                {lastVisits?.length > 0
                  ? lastVisits.map((date, dateIndex) => {
                      return (
                        <div
                          onClick={() => {
                            setVisitIndex(dateIndex);
                            getPreviousDetails(date);
                          }}
                          className={`${
                            selectedRow.VisitId=== date.opdIpdId ||
                            dateIndex === visitIndex ||
                            format(new Date(), "dd-MM-yyyy") ===
                              date?.visitDate?.toString()?.split(" ")?.[0]
                              ? `bg-[#073467] text-white`
                              : ``
                          } px-2 py-1 my-1 rounded border border-gray-400`}
                        >
                          {date?.Date?.toString().split(" ")?.[0]}
                        </div>
                      );
                    })
                  : ""}
              </div>
              <Divider
                orientation="vertical"
                flexItem
                className={`bg-[#0B83A5] ${
                  isOpenPrev === true ? `` : `hidden`
                }`}
              />
              <div
                className={`-mt-8 rounded px-2 ${
                  isOpenPrev ? `w-[75%] lg:w-[90%]` : `w-[98%] lg:w-[100%] ml-6`
                } h-[700px]`}
              >
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2 ">
                  <div className="shadow-lg col-span-2 lg:col-span-1 shadow-slate-300 rounded px-2">
                    <div>
                      <TextareaAutosize
                        value={impNotes}
                        placeholder="Important Notes"
                        minRows={3}
                        className="border border-gray-400 w-full my-2 rounded px-2"
                        style={{
                          outline: "none",
                        }}
                        maxRows={3}
                        onChange={(e) => {
                          setImpNotes(e.target.value);
                        }}
                        control={control}
                        name="importantNotes"
                        label="Important Notes"
                      />
                    </div>
                    <div className="grid grid-cols-4 gap-2">
                      <div className="lg:h-[40px] w-[35px] 2xl:w-full 2xl:h-full">
                        <Tooltip title="Copy Last Prescription">
                          <button
                            type="button"
                            onClick={() => {
                              handleGetPrecsriptions();
                            }}
                          >
                            <PrescriptionIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className="h-[40px] w-[35px] 2xl:w-full 2xl:h-full">
                        <Tooltip title="Pathology Report">
                          <button
                            type="button"
                            onClick={() => {
                              viewLIMSHistory();
                              setLIMSType("Pathology");
                              setOpenPathologyReport(true);
                            }}
                          >
                            <EmrPatholgyIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className="h-[40px] w-[35px] 2xl:w-full 2xl:h-full">
                        <Tooltip title="Radiology Report">
                          <button
                            type="button"
                            onClick={() => {
                              viewLimsRadiologyHistory();
                              setLIMSType("Radiology");
                              setOpenPathologyReport(true);
                            }}
                          >
                            <EmrRadiologyIcon />
                          </button>
                        </Tooltip>
                      </div>
                      <div className="h-[40px] w-[35px] 2xl:w-full 2xl:h-full">
                        <Tooltip title="Templates">
                          <button type="button">
                            <TemplateIcon />
                          </button>
                        </Tooltip>
                      </div>
                      {/* <div>
                  <Tooltip title="Past OPD Record">
                    <button type="button">
                      <PastOpdRecordIcon />
                    </button>
                  </Tooltip>
                </div> */}
                    </div>
                  </div>
                  <div className=" grid shadow-lg shadow-slate-300 h-40 mt-2 rounded col-span-2  lg:col-span-3 grid-cols-2 gap-2 items-center">
                    <div className="w-[100%] h-[95%] ">
                      <div className="flex gap-2 items-center px-4 mb-1 b">
                        <fieldset>
                          <CheckBoxField
                            style={{
                              padding: "0.5px",
                            }}
                            control={control}
                            name={"Smoking"}
                            label={"Smoking"}
                          />
                        </fieldset>
                        <fieldset>
                          <CheckBoxField
                            style={{
                              padding: "0.5px",
                            }}
                            control={control}
                            name={"Tobacco"}
                            label={"Tobacco"}
                          />
                        </fieldset>
                        <fieldset>
                          <CheckBoxField
                            style={{
                              padding: "0.5px",
                            }}
                            control={control}
                            name={"Alcohol"}
                            label={"Alcohol"}
                          />
                        </fieldset>
                      </div>
                      <div className=" bg-[#FFD4E3] w-full px-2 ">
                        <div
                          onClick={() => {
                            setOpenPastHistory(true);
                          }}
                          className="text-md font-semibold pr-5 whitespace-nowrap"
                        >
                          Past History
                        </div>
                      </div>
                      <div className="h-[60%] w-full overflow-y-scroll">
                        {selectedHistory?.length > 0
                          ? selectedHistory?.map((obj, index) => {
                              return (
                                <div
                                  className={`flex gap-2 justify-between items-center py-1 mt-1 px-2 border border-gray-200 h-6 ${
                                    obj.isChecked === true ? `bg-green-300` : ``
                                  } `}
                                >
                                  <div
                                    // onClick={() => {
                                    //   let dataArray = [...selectedHistory];
                                    //   dataArray[index].isChecked =
                                    //     !dataArray[index].isChecked;
                                    //   setSelectedHistory(dataArray);
                                    // }}
                                    className="w-auto flex justify-start"
                                  >
                                    {obj?.["label"]}
                                  </div>
                                  <div className="flex space-x-2 items-center">
                                    <div className="flex gap-2 items-center py-[5px]">
                                      <input
                                        name={`duration${obj.id}`}
                                        style={{ outline: "none" }}
                                        disabled={
                                          obj?.isChecked === false
                                            ? true
                                            : false
                                        }
                                        defaultValue={obj?.since}
                                        placeholder="Since"
                                        className="text-center h-6 w-20 border border-gray-400 rounded"
                                        onChange={(e) => {
                                          if (
                                            Number(e.target.value) > 0 &&
                                            selectedHistory?.length > 0
                                          ) {
                                            let dataArray = [
                                              ...selectedHistory,
                                            ];
                                            dataArray[index].since =
                                              e.target.value;
                                            dataArray[index].duration = "D";
                                            setSelectedHistory(dataArray);
                                          } else {
                                            let dataArray = [
                                              ...selectedHistory,
                                            ];
                                            dataArray[index].since = "";
                                            dataArray[index].duration = "";
                                            setSelectedHistory(dataArray);
                                          }
                                        }}
                                      />
                                      <div
                                        onClick={() => {
                                          handleAddDuration("D", index);
                                        }}
                                        className={`h-5 text-md w-5 rounded-full 
                                 ${
                                   typeof obj.duration === "string" &&
                                   obj.duration.charAt(0) === "D" &&
                                   obj.since !== ""
                                     ? `bg-[#073763] text-white`
                                     : `border border-slate-600`
                                 } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                      >
                                        D
                                      </div>
                                      <div
                                        onClick={() => {
                                          handleAddDuration("M", index);
                                        }}
                                        className={`h-5 text-md w-5 rounded-full 
                                ${
                                  typeof obj.duration === "string" &&
                                  obj.duration.charAt(0) === "M" &&
                                  obj.since !== ""
                                    ? `bg-[#073763] text-white`
                                    : `border border-slate-600`
                                } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                      >
                                        M
                                      </div>
                                      <div
                                        onClick={() => {
                                          handleAddDuration("Y", index);
                                        }}
                                        className={`h-5 text-md w-5 rounded-full 
                                ${
                                  typeof obj.duration === "string" &&
                                  obj.duration.charAt(0) === "Y" &&
                                  obj.since !== ""
                                    ? `bg-[#073763] text-white`
                                    : `border border-slate-600`
                                } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                      >
                                        Y
                                      </div>
                                    </div>
                                    <div>
                                      <input
                                        className="w-full text-start px-2 h-6 rounded border border-gray-400"
                                        style={{ outline: "none" }}
                                        placeholder="Description"
                                        onChange={(e) => {
                                          let dataArray = [...selectedHistory];
                                          dataArray[index].description =
                                            e.target.value;
                                          setSelectedHistory(dataArray);
                                        }}
                                        defaultValue={obj?.description}
                                        disabled={
                                          obj.isChecked === false ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                    <div className=" w-[100%]  shadow-lg shadow-slate-300 h-40 mt-2 rounded">
                      <div
                        onClick={() => {
                          setOpenFamilyHistory(true);
                        }}
                        className=" bg-[#DBEAFE] text-md font-semibold flex justify-start px-2"
                      >
                        Family History
                      </div>
                      {/* <div>
                <TextareaAutosize
                  className="w-full px-2"
                  placeholder="Family History"
                  style={{ outline: "none" }}
                  minRows={5}
                  maxRows={5}
                  defaultValue={familyHistory}
                  onChange={(e) => {
                    setFamilyHistory(e.target.value);
                  }}
                />
              </div> */}

                      <div className="h-[60%] w-full overflow-y-scroll">
                        {selectedFamilyHistory?.length > 0
                          ? selectedFamilyHistory?.map((obj, index) => {
                              return (
                                <div
                                  className={`flex gap-2 justify-between items-center py-1 mt-1 px-2 border border-gray-200 h-6 ${
                                    obj.isChecked === true ? `bg-green-300` : ``
                                  } `}
                                >
                                  <div
                                    // onClick={() => {
                                    //   let dataArray = [...selectedFamilyHistory];
                                    //   dataArray[index].isChecked =
                                    //     !dataArray[index].isChecked;
                                    //   setSelectedHistory(dataArray);
                                    // }}
                                    className="w-auto flex justify-start"
                                  >
                                    {obj?.["label"]}
                                  </div>
                                  <div className="flex space-x-2 items-center">
                                    <div className="flex gap-2 items-center py-[5px]">
                                      <input
                                        name={`duration${obj.id}`}
                                        style={{ outline: "none" }}
                                        disabled={
                                          obj?.isChecked === false
                                            ? true
                                            : false
                                        }
                                        defaultValue={obj?.since}
                                        placeholder="Since"
                                        className="text-center h-6 w-20 border border-gray-400 rounded"
                                        onChange={(e) => {
                                          if (
                                            Number(e.target.value) > 0 &&
                                            selectedFamilyHistory?.length > 0
                                          ) {
                                            let dataArray = [
                                              ...selectedFamilyHistory,
                                            ];
                                            dataArray[index].since =
                                              e.target.value;
                                            dataArray[index].duration = "D";
                                            setSelectedHistory(dataArray);
                                          } else {
                                            let dataArray = [
                                              ...selectedFamilyHistory,
                                            ];
                                            dataArray[index].since = "";
                                            dataArray[index].duration = "";
                                            setSelectedHistory(dataArray);
                                          }
                                        }}
                                      />
                                      <div
                                        onClick={() => {
                                          handleAddFamilyHistoryDuration(
                                            "D",
                                            index
                                          );
                                        }}
                                        className={`h-5 text-md w-5 rounded-full 
                                 ${
                                   typeof obj.duration === "string" &&
                                   obj.duration.charAt(0) === "D" &&
                                   obj.since !== ""
                                     ? `bg-[#073763] text-white`
                                     : `border border-slate-600`
                                 } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                      >
                                        D
                                      </div>
                                      <div
                                        onClick={() => {
                                          handleAddFamilyHistoryDuration(
                                            "M",
                                            index
                                          );
                                        }}
                                        className={`h-5 text-md w-5 rounded-full 
                                ${
                                  typeof obj.duration === "string" &&
                                  obj.duration.charAt(0) === "M" &&
                                  obj.since !== ""
                                    ? `bg-[#073763] text-white`
                                    : `border border-slate-600`
                                } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                      >
                                        M
                                      </div>
                                      <div
                                        onClick={() => {
                                          handleAddFamilyHistoryDuration(
                                            "Y",
                                            index
                                          );
                                        }}
                                        className={`h-5 text-md w-5 rounded-full 
                                ${
                                  typeof obj.duration === "string" &&
                                  obj.duration.charAt(0) === "Y" &&
                                  obj.since !== ""
                                    ? `bg-[#073763] text-white`
                                    : `border border-slate-600`
                                } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                      >
                                        Y
                                      </div>
                                    </div>
                                    <div>
                                      <input
                                        className="w-full text-start px-2 h-6 rounded border border-gray-400"
                                        style={{ outline: "none" }}
                                        placeholder="Description"
                                        onChange={(e) => {
                                          let dataArray = [
                                            ...selectedFamilyHistory,
                                          ];
                                          dataArray[index].description =
                                            e.target.value;
                                          setSelectedHistory(dataArray);
                                        }}
                                        defaultValue={obj?.description}
                                        disabled={
                                          obj.isChecked === false ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid  lg:grid-cols-2 xl:grid-cols-4 gap-2 items-center mt-2">
                  <div className="shadow-lg shadow-slate-300 rounded h-40">
                    <div className=" bg-[#DCFCE7] text-md font-semibold flex justify-between px-2">
                      <div
                        className="whitespace-nowrap"
                        onClick={() => {
                          setOpenComplaints(true);
                        }}
                      >
                        Complaints
                      </div>
                      <fieldset
                        className="w-[75%] ml-2 flex gap-2 items-center"
                        ref={complaintRef}
                      >
                        <CustomCreateable
                          width={"170px"}
                          control={control}
                          name={"createComplaint"}
                          ref={complaintRef}
                          placeholder={"Complaint"}
                          dataArray={complaintsOptions}
                          isSearchable={true}
                          showSearch={true}
                          handleInputChange={handleSearchComplaints}
                        />
                        {editComplaintIndex !== null && (
                          <Tooltip title="Update">
                            <button
                              onClick={() => {
                                handleUpdateComplaint();
                              }}
                              className=" rounded-full bg-customBlue text-white"
                            >
                              <NavigateNextIcon />
                            </button>
                          </Tooltip>
                        )}
                      </fieldset>
                    </div>
                    <div className=" h-[82%] overflow-y-scroll">
                      {/* {selectedComplaints?.length > 0
                  ? selectedComplaints.map((complaint) => {
                      if (complaint?.isChecked === true) {
                        return (
                          <div className="flex gap-2 items-center border border-gray-200 py">
                            <div>
                              <button
                                className="h-5 "
                                type="button"
                                onClick={() => {
                                  handleDeleteComplaints(complaint);
                                }}
                              >
                                <DeleteOutlineIcon
                                  fontSize="small"
                                  sx={{ color: "red" }}
                                />
                              </button>
                            </div>
                            <div className="w-[50%] flex justify-start px-2  whitespace-nowrap truncate">
                              {complaint?.label}
                            </div>
                            <div>
                              {complaint.since + " " + complaint?.duration}
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""} */}

                      {selectedComplaints?.length > 0
                        ? selectedComplaints?.map((obj, index) => {
                            return (
                              <div
                                className={`flex gap-2 justify-between items-center my-1  px-2 border border-gray-200 h-[26px]
                       
                          `}
                              >
                                <div className="flex ">
                                  <div>
                                    <Tooltip title="Edit Complaint">
                                      <button
                                        className="h-5 "
                                        type="button"
                                        onClick={() => {
                                          handleEditComplaint(obj, index);
                                        }}
                                      >
                                        <EditIcon />
                                      </button>
                                    </Tooltip>
                                  </div>
                                  <div>
                                    <button
                                      className="h-5 "
                                      type="button"
                                      onClick={() => {
                                        handleDeleteComplaints(obj);
                                      }}
                                    >
                                      <DeleteOutlineIcon
                                        fontSize="small"
                                        sx={{ color: "red" }}
                                      />
                                    </button>
                                  </div>
                                  <div
                                    // onClick={() => {
                                    //   let dataArray = [...selectedComplaints];
                                    //   dataArray[index].isChecked =
                                    //     !dataArray[index].isChecked;
                                    //   setComplaints(dataArray);
                                    // }}
                                    className="w-full flex items-center whitespace-nowrap text-start justify-start text-sm"
                                  >
                                    {obj?.["label"]}
                                  </div>
                                </div>
                                <div className="flex gap-2 items-center py-[5px]">
                                  <input
                                    name={`duration${obj.id}`}
                                    style={{ outline: "none" }}
                                    disabled={
                                      obj?.isChecked === false ? true : false
                                    }
                                    defaultValue={obj?.since}
                                    placeholder="Since"
                                    className="text-center h-6 w-[60px] border border-gray-400 rounded"
                                    onChange={(e) => {
                                      if (Number(e.target.value) > 0) {
                                        let dataArray = [
                                          ...selectedComplaints,
                                          ...complaints,
                                        ];
                                        dataArray[index].since = e.target.value;
                                        dataArray[index].duration = "D";
                                        setComplaints(dataArray);
                                      } else {
                                        let dataArray = [
                                          ...selectedComplaints,
                                          ...complaints,
                                        ];
                                        dataArray[index].since = "";
                                        dataArray[index].duration = "";
                                        setComplaints(dataArray);
                                      }
                                    }}
                                  />
                                  <div
                                    onClick={() => {
                                      handleAddComplaintsDuration("D", index);
                                    }}
                                    className={`h-5 text-md w-5 rounded-full 
                                 ${
                                   obj.duration === "D" && obj.since !== ""
                                     ? `bg-[#073763] text-white`
                                     : `border border-slate-600`
                                 } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                  >
                                    D
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleAddComplaintsDuration("M", index);
                                    }}
                                    className={`h-5 text-md w-5 rounded-full 
                                ${
                                  obj.duration === "M" && obj.since !== ""
                                    ? `bg-[#073763] text-white`
                                    : `border border-slate-600`
                                } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                  >
                                    M
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleAddComplaintsDuration("Y", index);
                                    }}
                                    className={`h-5 text-md w-5 rounded-full 
                                ${
                                  obj.duration === "Y" && obj.since !== ""
                                    ? `bg-[#073763] text-white`
                                    : `border border-slate-600`
                                } flex justify-center items-center text-md cursor-pointer pt-[1px]`}
                                  >
                                    Y
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>

                  {/* <div className="shadow-lg shadow-slate-300 rounded h-40">
              <div className=" bg-[#EDE9FE] text-md font-semibold flex justify-between px-2">
                <div
                  className="w-[]"
                  onClick={() => {
                    setOpenClinical(true);
                  }}
                >
                  Clinical Findings
                </div>

                <div className=" w-[65%]">
                  <CustomCreateable
                    control={control}
                    name="searchClinicalFinding"
                    dataArray={[]}
                    placeholder={"Clinical Finding"}
                    isSearchable={true}
                  />
                </div>
              </div>

              <div className="h-[78%] overflow-y-scroll">
                {selectedClinical?.length > 0 && (
                  <div className="px-2 py-1 border border-gray-200 ">
                    <TextareaAutosize
                      className="border border-gray-200 px-2 w-full "
                      style={{ outline: "none" }}
                      value={clinicalValue}

                      // disabled
                    />
                  </div>
                )}
              </div>
            </div> */}

                  <div className="shadow-lg shadow-slate-300 rounded h-40">
                    <div className=" bg-[#EDE9FE] text-md font-semibold flex justify-between px-2">
                      <div
                        className="w-[]"
                        onClick={() => {
                          setOpenAllergy(true);
                        }}
                      >
                        Allergic
                      </div>

                      <div className=" w-[65%]">
                        <CustomCreateable
                          control={control}
                          name="searchAllergy"
                          dataArray={allergyOption}
                          placeholder={"Allergies"}
                          handleInputChange={handleSearchAllergies}
                          isSearchable={true}
                          isClearable={true}
                        />
                      </div>
                    </div>

                    <div className="h-[78%] overflow-y-scroll">
                      {selectedAllergy?.length > 0
                        ? selectedAllergy.map((allergy) => (
                            <div className="flex gap-2 items-center border border-gray-200 h-6">
                              <div>
                                <button
                                  className="h-5 "
                                  type="button"
                                  onClick={() => {
                                    handleDeleteAllergy(allergy);
                                  }}
                                >
                                  <DeleteOutlineIcon
                                    fontSize="small"
                                    sx={{ color: "red" }}
                                  />
                                </button>
                              </div>
                              {/* ) : (
                            ""
                          )} */}

                              <div className="w-[90%] h-5 flex justify-start px-2  whitespace-nowrap truncate">
                                {allergy?.label}
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>

                  <div className="shadow-lg shadow-slate-300 rounded h-40">
                    <div className="cursor-pointer bg-[#FFE6C9] text-md font-semibold flex justify-between px-2">
                      <div
                        onClick={() => {
                          setOpenInvestigation(true);
                        }}
                      >
                        Investigations
                      </div>
                      <div className="w-[65%] z-50">
                        <CustomCreateable
                          width={"170px"}
                          control={control}
                          name={"searchInvestigations"}
                          placeholder={"Investigations"}
                          dataArray={invOptions}
                          handleInputChange={handleSearchInvestigations}
                          isSearchable={true}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="h-[82%] overflow-y-scroll">
                      {selectedInvestigations?.length > 0
                        ? selectedInvestigations.map((inv) => {
                            setValue(`isUrgent${inv?.id}`, inv?.isUrgent);
                            return (
                              <div className="flex gap-2 items-center border border-gray-200 py h-6">
                                {/* {inv?.isUrgent === false ? (  by Abhijeet sir */}
                                <div>
                                  <button
                                    className="h-5 "
                                    type="button"
                                    onClick={() => {
                                      handleDeleteInv(inv);
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      fontSize="small"
                                      sx={{ color: "red" }}
                                    />
                                  </button>
                                </div>
                                {/* ) : (
                            ""
                          )} */}

                                <div className="w-[90%] h-5 flex justify-start px-2  whitespace-nowrap truncate">
                                  {inv?.label}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                  <div className="shadow-lg shadow-slate-300 rounded h-40">
                    <div className=" bg-[#ECFCCB] text-md font-semibold flex gap-2 px-2 cursor-pointer">
                      <div
                        className="w-[]"
                        onClick={() => {
                          setOpenDiagnosis(true);
                        }}
                      >
                        Diagnosis
                      </div>
                      <div className="flex justify-end w-full">
                        <CustomCreateable
                          control={control}
                          name={"searchDiagnosis"}
                          dataArray={diagnoOptions}
                          placeholder={"Diagnosis"}
                          handleInputChange={handelSearchDiagnosis}
                          width={"170px"}
                          isSearchable={true}
                        />
                      </div>
                    </div>
                    <div className=" h-[82%] overflow-y-scroll">
                      {selectedDiagnosis?.length > 0
                        ? selectedDiagnosis.map((diagno) => {
                            // if (diagno?.isChecked === true) {
                            console.log(diagno.duration, "selectedDiagnosis");
                            
                            return (
                              <div className="flex gap-2 items-center border border-gray-200 py">
                                <div>
                                  <button
                                    className="h-5 "
                                    type="button"
                                    onClick={() => {
                                      handleDeleteDiagnosis(diagno);
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      fontSize="small"
                                      sx={{ color: "red" }}
                                    />
                                  </button>
                                </div>
                                <Tooltip title={diagno?.label}>
                                  <div className="w-[70%] truncate flex justify-start px-2 ">
                                    {diagno?.label}
                                  </div>
                                </Tooltip>
                                {diagno.since !== undefined &&
                                  diagno.duration && (
                                    <div>
                                      {diagno.since + " " + diagno?.duration}
                                    </div>
                                  )}
                              </div>
                            );
                            // }
                          })
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="my-2 rounded  shadow-lg shadow-slate-300">
                  <div>
                    <MedicinesTable
                      highlightRow={false}
                      tableClass="h-[270px]"
                      renderInput={renderInput}
                      renderActions={renderActions}
                      handleAllDurations={handleAllDurations}
                      setApplyToAllMedicines={setApplyToAllMedicines}
                      editableColumns={[
                        "Medicine",
                        "Dosage",
                        "Generic",
                        "Frequency",
                        "Duration",
                        "Instructions",
                        "Route",
                        "Quantity",
                        "Form",
                        "isDeleted",
                      ]}
                      removeHeaders={["since", "Since", "", "Days", "Id", "id"]}
                      dataResult={selectedMedicines}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 xl:grid-cols-4 gap-2 items-center">
                  <div className="rounded shadow-lg shadow-slate-300">
                    <div className="col-span-2 h-36 ">
                      <div className="  h-9 flex justify-between px-2 pt-1 bg-[#F1F1F1]">
                        <div className="text-md font-semibold flex gap-2">
                          Advices
                        </div>
                        <div className="w-[65%]">
                          {/* <button
                      type="button"
                      className="text-md font-semibold bg-[#063763] px-3 text-white rounded-lg"
                      onClick={() => {
                        setOpenAdviceTemplate(true);
                      }}
                    >
                      Template
                    </button> */}

                          <CustomCreateable
                            width={"170px"}
                            control={control}
                            name={"searchAdvices"}
                            placeholder={"Advices"}
                            dataArray={advicesOptions}
                            handleInputChange={handleSearchAdvices}
                            isSearchable={true}
                            showSearch={true}
                          />
                        </div>
                      </div>
                      <div className="font-semibold rounded h-[75%] overflow-y-scroll">
                        {/* {adviceTemplates.map((template) => {
                    return (
                      <div key={template.Template}>
                        {template.Advices.map((advice, index) => {
                          return (
                            <div
                              key={index}
                              className="whitespace-nowrap flex space-x-3 px-2 border border-gray-200 py-1"
                            >
                              <div>
                                <button
                                  className="h-5"
                                  onClick={() =>
                                    handleDeleteAdvice(template, index)
                                  }
                                  type="button"
                                >
                                  <DeleteOutlineIcon
                                    sx={{ color: "red" }}
                                    fontSize="small"
                                  />
                                </button>
                              </div>
                              <div>{advice}</div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })} */}

                        {selectedAdvices?.length > 0
                          ? selectedAdvices.map((advice) => (
                              <div className="flex gap-2 items-center border border-gray-200 h-6">
                                <div>
                                  <button
                                    className="h-5 "
                                    type="button"
                                    onClick={() => {
                                      handleDeleteAdvice(advice);
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      fontSize="small"
                                      sx={{ color: "red" }}
                                    />
                                  </button>
                                </div>
                                {/* ) : (
                            ""
                          )} */}

                                <div className="w-[90%] h-5 flex justify-start px-2 font-normal whitespace-nowrap truncate">
                                  {advice?.label}
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="shadow-lg shadow-slate-300 rounded">
                    <div className="col-span-2 h-36 ">
                      <div className="flex justify-between px-2 pt-1 bg-[#F1F1F1]">
                        <div className="text-sm lg:text-md font-semibold flex gap-2">
                          <div
                            onClick={() => {
                              setOpenOtherRefferal(true);
                            }}
                          >
                            {/* Local Advices */}
                            Other Referral
                          </div>
                        </div>

                        <div className=" w-[65%]">
                          <SearchDropdown
                            control={control}
                            name="referralDoctor"
                            dataArray={referralDocOptions}
                            placeholder={"Referral Doctor"}
                            isClearable={true}
                            searchIcon={true}
                            isSearchable={true}
                            handleInputChange={handleOtherReferral}
                          />
                        </div>
                      </div>
                      <div className="h-[75%] overflow-y-scroll">
                        {selectedOtherReferral?.length > 0
                          ? selectedOtherReferral.map((doc) => (
                              <div className="flex gap-2 items-center border border-gray-200 h-6">
                                <div>
                                  <button
                                    className="h-5 "
                                    type="button"
                                    onClick={() => {
                                      handleDeleteOtherReferral(doc);
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      fontSize="small"
                                      sx={{ color: "red" }}
                                    />
                                  </button>
                                </div>
                                {/* ) : (
                            ""
                          )} */}

                                <div className="w-[90%] h-5 flex justify-start px-2  whitespace-nowrap truncate">
                                  {doc?.label}
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="shadow-lg shadow-slate-300 rounded">
                    <div className=" rounded h-36">
                      <div className="flex justify-between bg-[#FFE6C9]">
                        <div
                          onClick={() => {
                            setOpenServices(true);
                          }}
                          className="cursor-pointer bg-[#FFE6C9] text-md font-semibold flex justify-start px-2"
                        >
                          Other Services
                        </div>
                        <div className="w-[65%]">
                          <CustomCreateable
                            width={"170px"}
                            control={control}
                            name={"searchOtherServices"}
                            placeholder={"Other Services"}
                            dataArray={servicesOptions}
                            handleInputChange={handleSearchServices}
                            isSearchable={true}
                            showSearch={true}
                          />
                        </div>
                      </div>

                      <div className="h-[75%] overflow-y-scroll">
                        {otherServices?.length > 0
                          ? otherServices.map((service) => {
                              return (
                                <div className="flex gap-2 items-center border border-gray-200 py h-6">
                                  <div>
                                    <button
                                      className="h-5 "
                                      type="button"
                                      onClick={() => {
                                        handleDeleteOtherService(service);
                                      }}
                                    >
                                      <DeleteOutlineIcon
                                        fontSize="small"
                                        sx={{ color: "red" }}
                                      />
                                    </button>
                                  </div>

                                  <div className="w-[90%] h-5 flex justify-start px-2  whitespace-nowrap truncate">
                                    {service?.label}
                                  </div>
                                  {/* <div className="px-4">
                                    {service?.Quantity}
                                  </div> */}
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div className="h-36 grid items-center">
                        <div>
                          <DatePickerField
                            control={control}
                            name="nextFollowupDate"
                            label={"Next Followup Date"}
                            inputFormat={"dd-MM-yyyy"}
                          />
                        </div>
                        {/* status */}
                        <div className="flex space-x-2 flex-wrap items-center justify-end">
                          <div className="">
                            <CommonButton
                              className={
                                "px-3 h-9 text-[#E3902F] border border-[#E3902F] rounded"
                              }
                              type={"button"}
                              onClick={() => {
                                setIsPreview(true);
                                handlePrintPreview();
                              }}
                              label="Preview"
                            />
                          </div>
                          {emrId === null && (
                            <div className="">
                              <CommonButton
                                className={"text-white bg-[#368505] rounded"}
                                type={"button"}
                                label="Complete"
                                onClick={() => {
                                  handleSubmitEmr();
                                }}
                              />
                            </div>
                          )}
                          {emrId !== null && (
                            <div className="">
                              <CommonButton
                                className={" text-white bg-[#368505] rounded"}
                                type={"button"}
                                label="Update"
                                onClick={() => {
                                  handleSubmitEmr();
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-2 grid grid-cols-6 gap-2"></div>
              </div>
            </div>

            {openPastHistory && (
              <PastHistoryModal
                selectedIndex={selectedHistoryIndex}
                setSelectedIndex={setSelectedHistoryIndex}
                selectedHistory={selectedHistory}
                setSelectedHistory={setSelectedHistory}
                open={openPastHistory}
                close={handleClosePastHistory}
                resetCount={resetCount}
              />
            )}

            {openFamilyHistory && (
              <FamilyHistoryModal
                selectedIndex={selectedFamilyHistoryIndex}
                setSelectedIndex={setSelectedFamilyHistoryIndex}
                selectedFamilyHistory={selectedFamilyHistory}
                setSelectedFamilyHistory={setSelectedFamilyHistory}
                open={openFamilyHistory}
                close={handleCloseFamilyHistory}
              />
            )}
            {openComplaints && (
              <ComplaintsModal
                complaints={complaints}
                setComplaints={setComplaints}
                open={openComplaints}
                close={handleCloseComplaints}
                selectedComplaints={selectedComplaints}
                setSelectedComplaints={setSelectedComplaints}
                resetComplaints={resetComplaints}
              />
            )}
            {openDiagnosis && (
              <DiagnosisModal
                open={openDiagnosis}
                close={handleCloseDiagnosis}
                diagnosisList={diagnosis}
                setDiagnosisList={setDiagnosis}
                selectedDiagnosis={selectedDiagnosis}
                setSelectedDiagnosis={setSelectedDiagnosis}
                location={location}
                resetDiagnosis={resetDiagnosis}
              />
            )}
            {openInvestigation && (
              <InvestigationModal
                open={openInvestigation}
                close={handleCloseInvestigation}
                investigationList={investigationList}
                setInvestigationList={setInvestigationList}
                selectedInvestigations={selectedInvestigations}
                setSelectedInvestigations={setSelectedInvestigations}
                resetInv={resetInv}
                departmentId={location?.state?.departmentId}
              />
            )}
            {openServices && (
              <OtherServicesModal
                open={openServices}
                close={handleCloseServices}
                otherServices={otherServices}
                setOtherServices={setOtherServices}
                resetServices={resetServices}
                departmentId={location?.state?.departmentId}
              />
            )}
            {openAdviceTemplate && (
              <AdviceTemplateModal
                open={openAdviceTemplate}
                close={handleCloseAdviceTemplate}
                adviceTemplates={adviceTemplates}
                setAdviceTemplates={setAdviceTemplates}
              />
            )}
            {openClinical && (
              <ClinicalModal
                open={openClinical}
                close={handleCloseClinical}
                selectedClinical={selectedClinical}
                setSelectedClinical={setSelectedClinical}
              />
            )}
            {openAllergy && (
              <AllergyModal
                open={openAllergy}
                close={handleCloseAllergy}
                selectedAllergy={selectedAllergy}
                setSelectedAllergy={setSelectedAllergy}
                handleDeleteAllergy={handleDeleteAllergy}
              />
            )}
            {/* {openOtherReferral && (
        <OtherReferralModal
          open={openOtherReferral}
          close={handleCloseOtherReferral}
          // localAdvices={localAdvices}
          // setLocalAdvices={setLocalAdvices}
          selectedLocalAdvices={selectedLocalAdvices}
          setSelectedLocalAdvices={setSelectedLocalAdvices}
        />
      )} */}
            {/* {openPathologyReport && (
        <LIMSHistoryModal
          openLIMSHistoryModal={openPathologyReport}
          setOpenLIMSHistoryModal={setOpenPathologyReport}
          pathHistoryData={pathHistoryData}
          patientInfo={patientDetails}
          LIMSType={LIMSType}
          setRadioHistoryData={setRadioHistoryData}
          radioHistoryData={radioHistoryData}
          userRole={loggedUser}
        />
      )} */}
            {/* <BarCodeModal
        open={openPreview}
        handleClose={handleClosePreview}
        urlforPrint={preview}
        isBarcode={false}
        isPrint={true}
      /> */}
            {openPreview && (
              <PrintOptions
                open={openPreview}
                printObject={printObject}
                close={handleClosePreview}
              />
            )}
            <ConfirmationModal
              confirmationOpen={openPost}
              confirmationHandleClose={handleClosePost}
              confirmationSubmitFunc={emrId === null ? postEmr : updateEmr}
              confirmationLabel="Confirmation "
              confirmationMsg={`Are you sure want to ${
                emrId === null ? `add` : `update`
              } this record ?`}
              confirmationButtonMsg={emrId === null ? "Save" : "Update"}
            />
            <CommonBackDrop openBackdrop={openBackdrop} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default EmrLite;


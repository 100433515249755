import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../common/components/FormFields/searchDropdown";
// import { baseUrl } from "../../http-common";
import { yupResolver } from "@hookform/resolvers/yup";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import Divider from "@mui/material/Divider";
import { useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  getBankList,
  getPaymentMode,
  getUpiType,
} from "../../billing/services/BillingServices";
import {
  autoSearchPatientAdvance,
  fetchPatientAdvanceDetails,
  getPatientInfo,
  getPatientInfoForOPD,
  getPaymentTypes,
  patientAdvancePdfPrint,
  savePaymentAdvance,
} from "../../billing/services/PatientAdvanceServices";
import { patientAdvanceAutocomplete } from "../../opd/services/registrationServices/PatientRegistrationServices";
import { UseCheckConsecutiveCharacters } from "../../common/components/Custom Hooks/UseCheckConsecutiveCharacters";
import useDidMountEffect from "../../common/components/Custom Hooks/useDidMountEffect";
import InputField from "../../common/components/FormFields/InputField";
import InputLimit from "../../common/components/FormFields/InputLimit";
import {
  errorAlert,
  successAlert,
} from "../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../commonServices/functionalityServices/FunctionalityServices";

import { format } from "date-fns";
import CommonButton from "../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../common/components/CommonTable/CommonDynamicTableNew";
import CommonPrintModal from "../../common/components/printModal/CommonPrintModal";
let inputSearchid = "";
const removeHeaders = ["Advance No", "PatientAdvanceDetails", "AdvanceID"];
const incomingTableData = [
  {
    amount: "",
    paymentMethod: "",
  },
];
const Actions = [
  {
    id: 0,
    action: "print",
    isAction: true,
  },
  {
    id: 1,
    action: "submit",
    isAction: false,
  },
];
const regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function PatientAdvance(props) {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [customCashError, setCustomCashError] = React.useState(false);
  const handleOpen = () => {
    if (customCashError === false) {
      setConfirmationOpen(true);
    }
  };
  const handleClose = () => {
    setConfirmationOpen(false);
  };
  const [showPanCard, setShowPanCard] = useState(false);
  const [menuIdVal, setMenuIdVal] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [dataArr, setDataArr] = useState([]);
  const [comingData, setComingData] = useState([]);
  const [showPage, setShowPage] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [patinetId, setPatientId] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [upiTypes, setUpiTypes] = useState();
  const [selectedRow, setSelectedRow] = React.useState();
  const [userActions, setUserActions] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let paymentSchema = {
    paymentDetails: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup
            .number()
            .typeError("Amount Required")
            .min(1, "Min value 1.")
            .required("Amount Required"),

          type: yup
            .object()
            .nullable()
            .shape({
              value: yup.string().required("Required"),
              label: yup.string().required("Required"),
            })
            .required("Required"),
          // bankname: yup
          //   .object()
          //   .nullable()
          //   .shape({
          //     value: yup.string().required("Select Bank"),
          //     label: yup.string().required("Select Bank"),
          //   })
          //   .required("Select Bank"),
          upiname: yup
            .object()
            .nullable()
            .shape({
              value: yup.string(),
              label: yup.string(),
            })
            .when("type", (type) => {
              let paymentModeName;

              if (type) {
                paymentModeName = type.label;
              }

              if (paymentModeName === "UPI") {
                return yup
                  .object()
                  .required(" ")
                  .nullable()
                  .shape({
                    value: yup.string().required(" "),
                    label: yup.string().required(" "),
                  });
              } else {
                return yup
                  .object()
                  .notRequired()
                  .nullable()
                  .shape({
                    value: yup.string().notRequired(),
                    label: yup.string().notRequired(),
                  })
                  .notRequired();
              }
            }),
          bankname: yup
            .object()
            .nullable()
            .typeError("Required")
            .when("type", (type) => {
              if (type !== null) {
                if (type.label !== "CASH" && type.label !== "UPI") {
                  return yup
                    .object()
                    .nullable()
                    .shape({
                      value: yup.string().required("Select Bank"),
                      label: yup.string().required("Select Bank"),
                    })
                    .required("Select Bank");
                }
              }
            }),
          pancard: yup.string().when(() => {
            if (showPanCard) {
              return yup
                .string()
                .matches(
                  /[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                  "Please Provide Valid Pan No. "
                )
                .min(10, "Please Provide Valid Pan No.")
                .max(10, "Please Provide Valid Pan No.")
                .required("Please Provide Valid Pan No.");
            }
          }),

          referrenceNo: yup.string().when("type", (type) => {
            let paymentModeName;

            if (type) {
              paymentModeName = type.label;
            }
            if (
              paymentModeName === "CREDIT CARD" ||
              paymentModeName === "DEBIT CARD"
            ) {
              return yup
                .string()
                .required("Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(4)
                .max(30);
            } else if (paymentModeName === "UPI") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(30);
              // .length(20, "Only 6 Digits allowed");
            } else if (paymentModeName === "CHEQUE") {
              return yup
                .string()
                .required("Reference Number Required")
                .max(30, "Only 6 Digits allowed");
            } else if (paymentModeName === "ECS") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(30);
            } else if (paymentModeName === "ONLINE") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(30);
              // .length(20, "Only 6 Digits allowed");
            } else {
              return yup.string().notRequired();
            }
          }),
        })
      )
      .min(1, "Please Add amount Details"),
    mobileNo: yup
      .string()
      .required("Required")
      .matches(phoneRegExp, "Please Enter Valid Contact No")
      .min(10, "Min value 1.")
      .max(10, "Min value 1.")
      .required("Amount Required"),
    remarks: yup.string(),
  };
  const paymentAppointValidation = yup.object().shape(paymentSchema).required();
  const paymentSchemaObj = paymentAppointValidation;
  const dateFormatter = (e) => {
    let regYear = String(e.getFullYear());
    let regMonth = String(e.getMonth() + 1).padStart(2, "0");
    let regDay = String(e.getDate()).padStart(2, "0");
    let finalDate = [regYear, regMonth, regDay].join("-");
    return finalDate;
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(paymentSchemaObj),
    defaultValues: {
      // paymentagainest: "",
      remarks: "",

      mobileNo: "",
      paymentDetails: [
        {
          type: "",
          amount: "",
          pancard: "",
          referrenceNo: "",
          paymentDate: selectedFromDate,
          bankname: "",
          upiname: "",
        },
      ],
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const {
    fields: paymentFields,
    append: append,
    remove: remove,
  } = useFieldArray({
    control: control,
    name: "paymentDetails",
  });
  let paymentDetails = watch("paymentDetails");

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [options, setOptions] = React.useState();
  const [filterdPaymentTypes, setFilterdPaymentTypes] = useState([]);
  const [paymentTableData, setPaymentTableData] = useState(incomingTableData);
  const [savePayment, setSavePayment] = useState();
  const [Bank, setBank] = useState();
  const [verfiedFilteredPayments, setVerfiedFilteredPayments] = useState();
  const [typeString, setTypeString] = React.useState([]);

  // get menu id
  let location = useLocation();

  // get payment typ,bank dropdown and upi type
  useEffect(() => {
    getPaymentTypes()
      .then((response) => response.data)
      .then((res) => {
        setPaymentTypes(res.result);
        setTypeString(res.resut);
        let defaultType = res.result.find((item) => item.label === "CASH");
        setValue(`paymentDetails.0.type`, defaultType);
        let newData = [...paymentTableData];

        setPaymentTableData(newData);
        //setValue(`paymentDetails[${0}].type`, obj);
        PaymentTypeResult(res.result);
      });
    getBankList()
      .then((response) => response.data)
      .then((res) => {
        setBank(res.result);
      });
    getUpiType()
      .then((response) => response.data)
      .then((res) => {
        setUpiTypes(res.result);
      });
  }, []);
  let payments = "";
  React.useEffect(() => {
    if (paymentDetails.length > 0) {
      for (let paymentObj of paymentDetails) {
        if (paymentObj?.type?.label) {
          payments = payments + paymentObj?.type?.label;
        }
      }
    }

    let newPaymentArr = [];
    for (let payment of paymentTypes) {
      if (
        !payments
          ?.toLocaleLowerCase()
          .includes(payment?.label?.toLocaleLowerCase())
      ) {
        newPaymentArr.push(payment);

        setPaymentTypes(newPaymentArr);
      }
    }
  }, [paymentDetails]);

  // set Default payment type
  const settingPaymentDefault = () => {
    let gotPaymentTypes = [...paymentTypes];
    let newData = [...incomingTableData];
    let obj = gotPaymentTypes.find((o) => o.default === true);
    newData[0].paymentMethod = obj;
    setPaymentTableData(newData);
    setValue(`paymentDetails[${0}].type`, obj);
    PaymentTypeResult(gotPaymentTypes);
  };

  // search patient
  function handleChange(searchString) {
    if (searchString !== "" && UseCheckConsecutiveCharacters(searchString)) {
      patientAdvanceAutocomplete(searchString)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  }

  // opd patient get by patient id
  function getPatientInfoById(patientId) {
    getPatientInfoForOPD(patientId)
      .then((response) => response.data)
      .then((res) => {
        setComingData(res.result);
        if (!showPage) {
          setShowPage(true);
        }
      });
  }

  const PaymentTypeResult = (allPaymentTypes) => {
    let object = [];
    for (let i = 0; i < paymentTableData.length; i++) {
      let innerObj = paymentTableData[i].paymentMethod;
      object = [...object, innerObj];
    }
    let uniqueResultOne = allPaymentTypes.filter(function (obj) {
      return !object.some(function (obj2) {
        return obj === obj2;
      });
    });

    setFilterdPaymentTypes(uniqueResultOne);
  };

  // find duplicate cash payment mode
  //function to detect the number of occurences of "CASH" option.
  //CASH option is allowed only once.
  function detectDuplicateCashOption() {
    let cashOptionCountIndicator = 0;

    for (let cartObj of paymentDetails) {
      if (cartObj?.type?.label === "CASH") {
        cashOptionCountIndicator = cashOptionCountIndicator + 1;
      }
    }

    if (cashOptionCountIndicator > 1) {
      setCustomCashError(true);
    } else {
      setCustomCashError(false);
    }
  }

  // add ref no field to payment table data
  const AddRefferenceNo = (index, PaymentTypeIs) => {
    let newData = [...paymentTableData];
    newData[index].referrenceNo = "";
    newData[index].paymentMethod = PaymentTypeIs;
    setPaymentTableData(newData);
  };
  const RemoveRefferenceNo = (index, PaymentTypeIs) => {
    let newData = [...paymentTableData];
    delete newData[index].referrenceNo;
    newData[index].paymentMethod = PaymentTypeIs;
    setPaymentTableData(newData);
  };

  const AddRowPayment = () => {
    setPaymentTableData([
      ...paymentTableData,
      {
        paymentMethod: "",
        referrenceNo: "",
        amount: "",
      },
    ]);
  };

  // button and menu id
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  //Get changed Registration Date
  let fullRegDate;
  const getNewRegDate = (e, index) => {
    let regYear = String(e.getFullYear());
    let regMonth = String(e.getMonth() + 1).padStart(2, "0");
    let regDay = String(e.getDate()).padStart(2, "0");
    fullRegDate = [regYear, regMonth, regDay].join("-");
    let finalDate = [regYear, regMonth, regDay].join("-");

    let tempPayments = [...paymentTableData];
    tempPayments[index].paymentDate = fullRegDate;

    setValue(`paymentDetails[${index}].paymentDate`, finalDate);

    return fullRegDate;
  };

  const resetPaymentModes = () => {
    getPaymentMode().then((response) => {
      setPaymentTypes(response.data.result);
      setValue(`paymentDetails.0.type`, response.data.result[1]);
    });
  };

  const onSubmitPayment = (data) => {
    let DataTable = data.paymentDetails;
    let finalData = {};
    let TotalAmt = 0;
    let payments = [];
    let cashCounterObj = JSON.parse(localStorage.getItem("userInfo"));

    let loginId = JSON.parse(localStorage.getItem("loginId key"));

    for (let i = 0; i < DataTable.length; i++) {
      let obj = {};

      if (
        DataTable[i].type.label !== "CASH" &&
        DataTable[i].type.label !== "UPI"
      ) {
        obj.bankId = DataTable[i].bankname.id;
        obj.paymentDate = DataTable[i].paymentDate;

        obj.referenceNumber = DataTable[i].referrenceNo;
      }

      if (DataTable[i].type.label === "UPI") {
        obj.upiTypeId = DataTable[i].upiname?.id;
      }

      obj.paymentType = DataTable[i].type;
      // obj.remarks = data.remarks;
      obj.amount = Number(DataTable[i].amount);
      TotalAmt = TotalAmt + Number(DataTable[i].amount);
      payments = [...payments, obj];
    }
    finalData.pancardNo = data.pancard ? data?.pancard : "";
    finalData.mobileNumber = data.mobileNo;
    finalData.remarks = data.remarks;
    finalData.paymentInfoList = payments;
    finalData.patientId = comingData.PatientId;

    finalData.loginId = loginId;
    finalData.opdIpd = 0;

    finalData.patientAdvance = Number(TotalAmt);
    finalData.menuId = location?.state?.menuId;
    finalData.privilege = menuIdVal?.action;
    finalData.cashCounterId = cashCounterObj.cashCounterId;
    setSavePayment(finalData);
    handleOpen();
  };

  // api call to submit advance payment
  const submitDataApit = () => {
    setOpenBackdrop(true);
    savePaymentAdvance(savePayment)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
        }
        //  else if (res?.statusCode === 400) {
        //   errorAlert(response?.res?.reesult?.message);
        // }

        resetForm();
        setShowPage(false);
        handleOpenPrintModal(res.result.patientAdvanceDetailsId);
        fetchPatientDetails();
        setOpenBackdrop(false);
        resetPaymentModes();
        setValue("opdbillingSearch", null);
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenBackdrop(false);
      });
  };

  // print receipt of advance payment
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (patientAdvanceDetailsId) => {
    patientAdvancePdfPrint(patientAdvanceDetailsId).then((response) => {
      setUrlforPrint(response);
    });
    setOpenPrintModal(true);
  };

  useDidMountEffect(() => {
    fetchPatientDetails();
  }, [searchString]);

  const fetchPatientDetails = (PatientId) => {
    let menuId = location?.state?.menuId
      ? location?.state?.menuId
      : props.menuId;
    PatientId &&
      menuId &&
      fetchPatientAdvanceDetails(PatientId, menuId).then((response) => {
        let receivedData = response.data.result;
        let obj = {
          id: receivedData.id,
          patientName: receivedData.patientName,
          MoblieNo: receivedData.MobileNo,
          LastName: receivedData.LastName,
          FirstName: receivedData.FirstName,
          MiddleName: receivedData.MiddleName,

          MRNo: receivedData.MRNo,
        };

        if (!showPage) {
          setShowPage(true);
        }
        setDataArr(receivedData);
        // setComingData(obj);
      });
  };

  // reset all form after payment
  const resetForm = () => {
    reset();
    setPaymentTableData(incomingTableData);
    // setShowPanCard(false);
    settingPaymentDefault();
  };
  // reset all form after payment
  const clearPage = () => {
    setFilterdPaymentTypes(paymentTypes);
    setPaymentTableData(incomingTableData);
    // setShowPanCard(false);
    setDataArr([]);
    setComingData({});
    setShowPage(false);
    settingPaymentDefault();
  };

  const validData = useRef(true);
  const addToPaymentArr = () => {
    let amountDetails, paymentTypeDetails, paymentRef;
    validData.current = true;
    let newTemplateData = [...paymentTableData];
    for (let item of paymentDetails) {
      if (item.type.label !== "CASH") {
        paymentRef = item.referrenceNo;
      } else {
        paymentRef = "notValidforCash";
      }

      if (
        item.paymentMethod !== null &&
        item.paymentMethod !== "" &&
        item.amount !== null &&
        item.amount !== "" &&
        paymentRef !== ""
      ) {
      } else {
        validData.current = false;
      }
    }
    if (validData.current) {
      append({ type: "", amount: "" });
      AddRowPayment();
    } else {
      trigger(["paymentDetails"]);
    }
  };

  const deleteTableRow = (index, rowPaymentType) => {
    remove(index);

    if (index !== paymentDetails.length - 1) {
      for (let i = index; i < paymentTableData.length - 1; i++) {
        setValue(`paymentDetails[${i}].type`, paymentDetails[i + 1].type);
        setValue(`paymentDetails[${i}].amount`, paymentDetails[i + 1].amount);

        if ("referrenceNo" in paymentTableData[i + 1]) {
          if (paymentTableData[i + 1].referrenceNo !== "") {
            setValue(
              `paymentDetails[${i}].referrenceNo`,
              paymentTableData[i + 1].referrenceNo
            );
          } else {
            setValue(`paymentDetails[${i}].referrenceNo`, "");
          }
        } else {
          RemoveRefferenceNo(i, paymentDetails[i + 1].type);
        }
      }
    }
    deleteRowPayment(index);
    // setFilteredData

    let paymentFiltered = [...filterdPaymentTypes];
    let addType = rowPaymentType;
    setFilterdPaymentTypes([...paymentFiltered, addType]);
    setPaymentTypes([...paymentFiltered, addType])
  };

  const deleteRowPayment = (index) => {
    let newTemplateData = [...paymentTableData];
    newTemplateData.splice(index, 1);
    setPaymentTableData(newTemplateData);
  };

  function hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }
  useDidMountEffect(() => {
    if (
      filterdPaymentTypes.length > 0 &&
      paymentTypes.length > 0 &&
      paymentDetails.length > 0
    ) {
      let arr = [...filterdPaymentTypes];
      let arrLength = arr.length;
      let tableLength = paymentTableData.length;
      let paymentTypesLength = paymentTypes.length;

      if (arrLength !== paymentTypesLength - tableLength) {
      }
      let AvailablePaymentTypes = [];
      let duplicates = hasDuplicates(arr);
      //
      let tableArr = [...paymentDetails];
      let usedTypes = [];
      for (let obj of tableArr) {
        let type = obj.type;
        if (type === "CASH") {
          usedTypes.push(type);
        }
      }
      let paymentTypesArr = [...paymentTypes];
      let typesArr = [];
      for (let obj of paymentTypesArr) {
        let exists = usedTypes.find((o) => {
          if (o == obj.label) {
            return true;
          } else {
            return false;
          }
        });
        if (!exists) {
          typesArr.push(obj);
        }
      }
      setVerfiedFilteredPayments(typesArr);
    }
  }, [filterdPaymentTypes]);

  // actions and print
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === false ? (
                <>
                  <div className="flex gap-2 cursor-pointer">
                    {actions.action === "Print" ? (
                      <Tooltip title={"Print"}>
                        <LocalPrintshopIcon
                          sx={{ color: "#4B5563" }}
                          onClick={() => {
                            handleOpenPrintModal(row.PatientAdvanceDetails);
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  return (
    <div className="mt-10 bg-white px-6 min-h-screen">
      <div className="text-center text-black font-bold text-xl py-4">
        Patient Advance
      </div>
      <div className="lg:flex md:grid md:grid-cols-5 gap-2">
        <div className="lg:w-5/12 md:col-span-4 z-50">
          <SearchDropdown
            control={control}
            name="opdbillingSearch"
            dataArray={options}
            searchIcon={true}
            isClearable={true}
            isSearchable={true}
            placeholder="Search By Patient Name / MRNo / Mob No."
            handleInputChange={handleChange}
            inputRef={{
              ...register("opdbillingSearch", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setSearchString(e.target.value);
                    getPatientInfoById(e.target.value.id);
                    setPatientId(e.target.value.id);
                    fetchPatientDetails(e.target.value.id);
                  } else {
                    resetForm();
                    clearPage();
                    setSearchString("");
                  }
                },
              }),
            }}
          />
        </div>
      </div>
      {showPage ? (
        <>
          {comingData ? (
            <>
              <div className="py-2">
                <fieldset className=" mx-auto border w-full rounded my-1 bg-gray-100">
                  <div className="lg:grid lg:grid-cols-2 xl:flex gap-2 p-3">
                    <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-24 font-semibold">Patient Name</span>
                      <span>:</span>
                      <span className="text-gray-700 font-normal whitespace-nowrap">
                        {comingData?.PatientName}
                      </span>
                    </div>
                    <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                        MR No.
                      </span>
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {`${comingData.MRNo}`}
                      </span>
                    </div>

                    <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                      <span className="w-24 xl:w-8 font-semibold">Age</span>
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {`${comingData.AgeYear}`}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                      <span className="w-24 lg:w-20 font-semibold">
                        Mobile No
                      </span>

                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {`${comingData.ContactNo}`}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 ">
                      <span className="w-28 lg:w-28 font-semibold whitespace-nowrap">
                        Advance Balance
                      </span>

                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {(comingData && comingData.CashBalance) || "0"}
                      </span>
                    </div>
                  </div>
                </fieldset>
              </div>
            </>
          ) : (
            <p className="mx-auto">"Data Not Found"</p>
          )}

          {/* Payment Form */}
          <div className="w-full">
            {/* Payment Inf0 */}

            <form onSubmit={handleSubmit(onSubmitPayment)} className="">
              <fieldset className="mx-auto border w-full rounded my-3">
                <legend className="ml-6 my-2 rounded-xl">
                  <p className="text-sm font-semibold tracking-wide mx-2">
                    Payment Details
                  </p>
                </legend>
                <div className=" px-10 py-2">
                  {paymentTableData.map((item, index) => {
                    return (
                      <div
                        className="lg:grid lg:grid-cols-6 md:grid md:grid-cols-2 gap-2 py-2"
                        key={item?.id}
                      >
                        <div className="whitespace-nowrap">
                          <DropdownField
                            control={control}
                            // error={errors.paymentDetails?.[index]?.type}
                            error={errors.paymentDetails?.[index]?.type}
                            name={`paymentDetails[${index}].type`}
                            label="Payment Type *"
                            dataArray={paymentTypes}
                            isSearchable={false}
                            placeholder="Payment Type*"
                            inputRef={{
                              ...register(`paymentDetails[${index}].type`, {
                                onChange: (e) => {
                                  let PaymentTypeIs = e.target.value;
                                  let newData = [...paymentTableData];

                                  newData[index].paymentMethod = e.target.value;
                                  setPaymentTableData(newData);
                                  PaymentTypeResult(paymentTypes);
                                  if (e?.target?.value?.label !== "CASH") {
                                    if (
                                      "referrenceNo" in paymentTableData[index]
                                    ) {
                                    } else {
                                      AddRefferenceNo(index, PaymentTypeIs);
                                    }
                                  } else {
                                    if (
                                      "referrenceNo" in paymentTableData[index]
                                    ) {
                                      RemoveRefferenceNo(index, PaymentTypeIs);
                                    }
                                  }
                                  detectDuplicateCashOption();
                                },
                              }),
                            }}
                          />
                        </div>

                        {paymentDetails[index].type?.label === "UPI" ? (
                          <>
                            <div>
                              <DropdownField
                                control={control}
                                error={errors.paymentDetails?.[index]?.upiname}
                                name={`paymentDetails[${index}].upiname`}
                                label="Payment Type *"
                                dataArray={upiTypes}
                                isSearchable={false}
                                placeholder="UPI Type*"
                                inputRef={{
                                  ...register(
                                    `paymentDetails[${index}].upiname`,
                                    {
                                      onChange: (e) => {},
                                    }
                                  ),
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="">
                          <InputField
                            name={`paymentDetails[${index}].amount`}
                            variant="outlined"
                            label="Amount *"
                            type="number"
                            inputRef={{
                              ...register(`paymentDetails[${index}].amount`, {
                                onChange: (e) => {
                                  if (
                                    e.target.value !== "" &&
                                    e.target.value !== "e" &&
                                    e.target.value !== "E" &&
                                    e.target.value !== "+" &&
                                    e.target.value !== "-" &&
                                    e.target.value > 0
                                  ) {
                                    let amountValue = e.target.value;
                                    let newData = [...paymentTableData];
                                    newData[index].amount = amountValue;

                                    setPaymentTableData(newData);
                                  }
                                  if (e.target.value >= 200000) {
                                    setShowPanCard(true);
                                  } else {
                                    setShowPanCard(false);
                                  }
                                },
                              }),
                            }}
                            error={errors.paymentDetails?.[index]?.amount}
                            control={control}
                          />
                        </div>
                        {showPanCard &&
                        paymentDetails[index].type.label === "CASH" ? (
                          <div className="">
                            <InputField
                              control={control}
                              // name="pancard"
                              name={`paymentDetails[${index}].pancard`}
                              variant="outlined"
                              label=" Pan Card *"
                              error={errors.paymentDetails?.[index]?.pancard}
                            />
                          </div>
                        ) : null}

                        {/* { "referrenceNo" in  item ? ( */}
                        {paymentDetails[index].type &&
                        paymentDetails[index].type.label !== "CASH" ? (
                          <>
                            <div sx={{ marginY: "0.5rem" }}>
                              <InputField
                                type="text"
                                name={`paymentDetails[${index}].referrenceNo`}
                                error={
                                  errors.paymentDetails?.[index]?.referrenceNo
                                }
                                control={control}
                                label="Reference Number *"
                                inputRef={{
                                  ...register(
                                    `paymentDetails[${index}].referrenceNo`,
                                    {
                                      onChange: (e) => {
                                        if (e.target.value !== "") {
                                          let newData = [...paymentTableData];
                                          newData[index].referrenceNo =
                                            e.target.value;
                                          setPaymentTableData(newData);
                                        }
                                      },
                                    }
                                  ),
                                }}
                              />
                            </div>
                            {(paymentDetails[index].type &&
                              paymentDetails[index].type.label ===
                                "DEBIT CARD") ||
                            paymentDetails[index].type.label ===
                              "CREDIT CARD" ||
                            paymentDetails[index].type.label === "CHEQUE" ||
                            paymentDetails[index].type.label === "ECS" ||
                            paymentDetails[index].type.label === "ONLINE" ? (
                              <>
                                <div>
                                  <DropdownField
                                    control={control}
                                    error={
                                      errors.paymentDetails?.[index]?.bankname
                                    }
                                    name={`paymentDetails[${index}].bankname`}
                                    label="Bank Name"
                                    dataArray={Bank}
                                    isSearchable={true}
                                    placeholder="Bank Name *"
                                    searchIcon={true}
                                  />
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {(paymentDetails[index].type &&
                              paymentDetails[index].type.label ===
                                "DEBIT CARD") ||
                            paymentDetails[index].type.label ===
                              "CREDIT CARD" ||
                            paymentDetails[index].type.label === "ECS" ||
                            paymentDetails[index].type.label === "CHEQUE" ||
                            paymentDetails[index].type.label === "DD" ? (
                              <>
                                <div>
                                  <DatePickerFieldNew
                                    control={control}
                                    name={`paymentDetails[${index}].paymentDate`}
                                    label="Date"
                                    value={new Date()}
                                    disableFuture={true}
                                    disablePast={false}
                                    inputFormat="dd-MM-yyyy"
                                    inputRef={{
                                      ...register(
                                        `paymentDetails[${index}].paymentDate`,
                                        {
                                          onChange: (e) => {
                                            if (
                                              e.target.value.toString()
                                                .length === 55
                                            ) {
                                              let dateVal = format(
                                                e.target.value,
                                                "yyyy-MM-dd"
                                              );
                                              let timeVal =
                                                dateVal + "T" + "00:00:00:000Z";
                                              setSelectedFromDate(timeVal);
                                            }
                                          },
                                        }
                                      ),
                                    }}
                                  />
                                  {/***  <Controller
                                    control={control}
                                    defaultValue={new Date()}
                                    rules={{
                                      required: true,
                                    }}
                                    render={({ field }) => (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DatePicker
                                          open={openDate}
                                          onOpen={() => setOpenDate(true)}
                                          onClose={() => setOpenDate(false)}
                                          inputProps={{ readOnly: true }}
                                          // disablePast
                                          // readOnly={true}

                                          renderInput={(props) => (
                                            <TextField
                                              {...props}
                                              type="date"
                                              variant="outlined"
                                              label="Registration Date"
                                              name="paymentDate"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              fullWidth
                                              size="small"
                                              onClick={(e) => setOpenDate(true)}
                                              sx={{
                                                svg: {
                                                  color: "#1e3a8a",
                                                  height: 22,
                                                  width: "100%",
                                                  marginRight: "16px",
                                                },
                                                backgroundColor: "white",
                                                overflow: "visible",

                                                "& .MuiOutlinedInput-root": {
                                                  "& .MuiOutlinedInput-input": {
                                                    // border: 0,
                                                    fontSize: 14,
                                                    height: "18px",
                                                    width: "100%",

                                                    borderColor: "  ",
                                                    overflow: "hidden",
                                                  },
                                                  "& .MuiFormLabel-root": {
                                                    fontSize: "14px",
                                                  },
                                                },
                                              }}
                                            />
                                          )}
                                          inputFormat="dd/MM/yyyy"
                                          disablePast
                                          {...field}
                                          onAccept={(e) => {
                                            getNewRegDate(e, index);
                                          }}
                                          error={
                                            errors.paymentDetails?.[index]
                                              ?.paymentDate
                                          }
                                          helperText={
                                            errors.paymentDate?.message
                                          }
                                        />
                                      </LocalizationProvider>
                                    )}
                                    name={`paymentDetails[${index}].paymentDate`}
                                  />*/}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        <div className="flex">
                          {paymentTableData.length !== 1 && (
                            <RemoveOutlinedIcon
                              className="mt-2 rounded-full border-2 border-red-600"
                              onClick={() => {
                                deleteTableRow(
                                  index,
                                  paymentDetails[index].type
                                );
                              }}
                            />
                          )}
                          {paymentTableData.length !== 4 ? (
                            <>
                              {paymentTableData.length - 1 === index && (
                                <AddOutlinedIcon
                                  className="mt-2 mx-1 rounded-full border-2 border-cyan-600"
                                  onClick={addToPaymentArr}
                                />
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </fieldset>
              {/* <fieldset className="mx-auto border w-full rounded ">
                <legend className="ml-6 my-2 rounded-xl">
                  <p className="text-sm font-semibold tracking-wide mx-2">
                    Payment Details
                  </p>
                </legend> */}
              <div className="ml-10 my-2 grid grid-cols-6 gap-2">
                <div className="">
                  <InputLimit
                    maxDigits={10}
                    name="mobileNo"
                    variant="outlined"
                    label="Mobile No *"
                    error={errors.mobileNo}
                    control={control}
                    //disabled={isDisabled}
                    inputRef={{
                      ...register("age", {
                        onChange: (e) => {
                          // setEnteredAge(e.target.value);
                          // getBirthDetails(e);
                        },
                      }),
                    }}
                    onKeyDown={(e) => {
                      if (
                        e?.key === "-" ||
                        e?.key === "+" ||
                        e?.code === "ArrowUp" ||
                        e?.code === "ArrowDown" ||
                        e?.code === "NumpadDecimal"
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="">
                  <InputField
                    name="remarks"
                    control={control}
                    error={errors.remarks}
                    label="Remarks"
                  />
                </div>
              </div>

              {/* </fieldset> */}
              <div className="flex col-span-1 justify-end py-2 gap-2">
                <CommonButton
                  label="Reset"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    reset();
                    setValue("opdbillingSearch", null);
                    setShowPage(false);
                    resetPaymentModes();
                  }}
                />

                {userActions &&
                  userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <CommonButton
                          type="submit"
                          label="Save"
                          className="bg-green-600 text-white"
                          onClick={() => {
                            setMenuIdVal(obj);
                          }}
                        />
                      ) : null}
                    </>
                  ))}
              </div>
            </form>
            <CommonBackDrop openBackdrop={openBackdrop} />
          </div>

          <Divider
            sx={{
              "&::before, &::after": {
                borderColor: "secondary.light",
              },
            }}
          />
          {/* Advance Payment History  */}
          {dataArr && dataArr.length > 0 ? (
            <>
              <div className="py-4">
                <p className="text-lg font-semibold tracking-wide mx-0">
                  Advance Payment History
                </p>
              </div>
              <div className="px-0">
                <CommonDynamicTableNew
                  dataResult={dataArr}
                  renderActions={renderActions}
                  setSelectedRow={setSelectedRow}
                  removeHeaders={removeHeaders}
                  tableClass={"rounded lg:h-52 md:h-72"}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      <ConfirmationModal
        confirmationOpen={confirmationOpen}
        confirmationHandleClose={handleClose}
        confirmationSubmitFunc={() => {
          handleClose(reset);
          submitDataApit();
        }}
        confirmationLabel="Confirmation "
        confirmationMsg="Save Payment Details?"
        confirmationButtonMsg="Save Payment"
      />
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </div>
    // </Box>
    // </Modal>
    // </div>
  );
}

//imports from the react js library
import * as React from "react";

//imports from the react hook form library
import { useForm } from "react-hook-form";

//imports from the material ui (mui) library
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { errorAlert } from "../../../common/components/Toasts/CustomToasts";

//importing the BatchInfoModal component
import { Tooltip } from "@mui/material";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { getItemBatchNumber } from "../../PharmacyServices";
import { OpenDrugModalIcon } from "../../common/OpenDrugModalIcon";
import BatchInfoModal from "../BatchInfoModal";
import GenericDrugModal from "./GenericDrugModal";
import { format } from "date-fns";

export default function PrescriptionTable(props) {
  //state variable to store the information of the drug ; when we press the enter key while we are on the BatchInfoTable.
  const [selectedRow, setSelectedRow] = React.useState("");

  //state variable to store the index of the row ; when we press the enter key ; while we are on the batchNo input tag.
  const [indexVal, setIndexVal] = React.useState("");

  //state variable to decide whether or not to open the BatchInfoModal.
  const [open, setOpen] = React.useState(false);

  //state variable to store the drug info ; that is to be displayed in the BatchInfoTable.
  //The BatchInfoModal is opened ; if and only if the drugBatchInfo has a truthy value.
  const [drugBatchInfo, setDrugBatchInfo] = React.useState(null);

  //state variable to store the drug information ; whenever enter key is pressed ; while we are on the batchNo input tag.
  //this state variable is sent to the BatchInfoModal to display the drug Item Description and drug Item Code
  const [drugItemInfo, setDrugItemInfo] = React.useState(null);

  //state variable to store the changing discountPercent amounts in the handleDiscountChange event listener function.
  const [changingDiscAmount, setChangingDiscAmount] = React.useState(null);

  const [openChild, setOpenChild] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const [deleteRow, setDeleteRow] = React.useState(null);
  const [openGenericDrugModal, setOpenGenericDrugModal] = React.useState(false);
  const [selectedGenericDrug, setSelectedGenericDrug] = React.useState("");
  const [rowToReplace, setRowToReplace] = React.useState("");
  const [indexToReplace, setIndexToReplace] = React.useState("");
  const [preQuantity, setPreQuantity] = React.useState(null);
  const [medicineBatchIndex, setMedicineBatchIndex] = React.useState(null);

  const handleCloseGenericDrugModal = () => {
    if (openGenericDrugModal === true) {
      setOpenGenericDrugModal(false);
    }
  };

  React.useEffect(() => {
    if (selectedGenericDrug !== "") {
      let focusBatch = document.getElementById(`BatchNo${indexToReplace}`);
      if (focusBatch) {
        focusBatch.focus();
      }
    }
  }, [selectedGenericDrug]);

  let loginObj = JSON.parse(localStorage.getItem("userInfo"));

  let unitId = loginObj && loginObj.loginUnitId;
  let storeId = loginObj && loginObj.storeId;

  const handleCloseChild = () => {
    if (openChild === true) {
      setOpenChild(false);
    }
  };

  const deleteDrug = () => {
    let medicineArray = [...props.data.result];
    medicineArray.splice(deleteIndex, 1);
    props.setData({ result: medicineArray });
    setDeleteIndex(null);
    setOpenChild(false);
  };

  //function to open the batchInfo modal
  const handleOpen = () => {
    setOpen(true);
  };

  //function to close the batchInfo modal
  const handleClose = () => {
    setOpen(false);
  };

  //defaultValues object for react hook form
  const defaultValues = {
    discPercent: null,
    discAmt: null,
    netAmt: null,
  };

  //The react hook form functions that are to be used.
  const {
    control,
    watch,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //useEffect to arrive at the total calculated values ; when discountAmount changes.
  React.useEffect(() => {
    props.setFinalPrescriptions(props.data);

    //calculate and store the calculated form data in the state variable calculatedFormData
    let prescriptionArray = props.data.result;

    let totalDrugItem = 0;
    let totalQuantityOfDrugs = 0;
    let totalAmount = 0;
    let totalGSTAmount = 0;
    let totalNetAmount = 0;
    let totalRoundingAmount = 0;
    let totalDiscountAmount = 0;
    let totalDiscountPercentage = 0;
    let totalPaidAmount = 0;

    for (let prescriptionObj of prescriptionArray) {
      //calculating the total number of drug items
      if (prescriptionObj.quantity > 0) {
        totalDrugItem = prescriptionArray.length;

        totalAmount = Number(totalAmount) + Number(prescriptionObj.amount);

        totalGSTAmount = totalGSTAmount + Number(prescriptionObj.gstAmount);

        totalDiscountPercentage =
          (Number(props.discountAmount) * 100) / Number(totalAmount.toFixed(2));
      }
    }

    let temp = [...props.data.result];
    for (let obj of temp) {
      setValue(`discPercent${obj.id}`, totalDiscountPercentage.toFixed(2));
      let newDiscAmount = Number(obj.amount) * (totalDiscountPercentage / 100);
      setValue(`discAmt${obj.id}`, newDiscAmount.toFixed(2));
      let netAmount = obj.amount - newDiscAmount;
      setValue(`netAmt${obj.id}`, netAmount.toFixed(2));

      totalNetAmount = totalNetAmount + Number(netAmount);

      totalQuantityOfDrugs =
        Number(totalQuantityOfDrugs) + Number(obj.quantity);
    }

    totalRoundingAmount = Math.round(totalNetAmount);
    totalRoundingAmount = totalRoundingAmount.toFixed(2);
    totalPaidAmount = Number(totalNetAmount).toFixed(2);
    totalDiscountAmount = props.discountAmount;
    totalDiscountPercentage = totalDiscountPercentage.toFixed(2);
    totalGSTAmount = totalGSTAmount.toFixed(2);
    totalAmount = totalAmount.toFixed(2);
    totalNetAmount = totalNetAmount.toFixed(2);

    let calculationObj = {
      totalDrugItem: totalDrugItem,
      totalQuantityOfDrugs: totalQuantityOfDrugs,
      totalAmount: totalAmount,
      totalGSTAmount: totalGSTAmount,
      totalNetAmount: totalNetAmount,
      totalRoundingAmount: totalRoundingAmount,
      totalDiscountAmount: totalDiscountAmount,
      totalDiscountPercentage: totalDiscountPercentage,
      totalPaidAmount: totalPaidAmount,
    };

    //When all the values are truthy values then only set the calculated data in the state variable

    props.setCalculatedFormData(calculationObj);

    //this length is required to make the decision about clearing the ui ; when all the drugs are deleted.
    props.setPrescriptionLength(props.data.result.length);
  }, [props.discountAmount]);

  //useEffect to arrive at the total calculated values ; when discountPercent changes.
  React.useEffect(() => {
    props.setFinalPrescriptions(props.data);

    //calculate and store the calculated form data in the state variable calculatedFormData
    let prescriptionArray = props.data.result;

    let totalDrugItem = 0;
    let totalQuantityOfDrugs = 0;
    let totalAmount = 0;
    let totalGSTAmount = 0;
    let totalNetAmount = 0;
    let totalRoundingAmount = 0;
    let totalDiscountAmount = 0;
    let totalDiscountPercentage = 0;
    let totalPaidAmount = 0;

    for (let prescriptionObj of prescriptionArray) {
      //calculating the total number of drug items
      if (prescriptionObj.quantity > 0) {
        totalDrugItem = prescriptionArray.length;

        totalDiscountPercentage = Number(props.discountPercent);
      }
    }

    let temp = [...props.data.result];
    let discVal = 0;

    for (let obj of temp) {
      setValue(`discPercent${obj.id}`, props.discountPercent);
      let newDiscAmount = Number(obj.amount) * (props.discountPercent / 100);
      setValue(`discAmt${obj.id}`, newDiscAmount.toFixed(2));
      let netAmount = Number(obj.amount) - Number(newDiscAmount);
      setValue(`netAmt${obj.id}`, netAmount.toFixed(2));
      discVal = Number(discVal) + Number(newDiscAmount);
      totalDiscountAmount = Number(discVal);
      totalNetAmount = totalNetAmount + Number(netAmount);

      totalQuantityOfDrugs =
        Number(totalQuantityOfDrugs) + Number(obj.quantity);

      totalAmount = Number(totalAmount) + Number(obj.amount);

      totalGSTAmount = totalGSTAmount + Number(obj.gstAmount);
    }
    let totalDiscAmountVal = totalDiscountAmount.toFixed(2);

    totalRoundingAmount = Math.round(totalNetAmount);
    totalRoundingAmount = totalRoundingAmount.toFixed(2);
    totalPaidAmount = Number(totalNetAmount).toFixed(2);
    totalDiscountAmount = totalDiscAmountVal;
    totalDiscountPercentage = totalDiscountPercentage;
    totalGSTAmount = totalGSTAmount.toFixed(2);
    totalAmount = totalAmount.toFixed(2);
    totalNetAmount = totalNetAmount.toFixed(2);

    let calculationObj = {
      totalDrugItem: totalDrugItem,
      totalQuantityOfDrugs: totalQuantityOfDrugs,
      totalAmount: totalAmount,
      totalGSTAmount: totalGSTAmount,
      totalNetAmount: totalNetAmount,
      totalRoundingAmount: totalRoundingAmount,
      totalDiscountAmount: totalDiscountAmount,
      totalDiscountPercentage: props.discountPercent,
      totalPaidAmount: totalPaidAmount,
    };
    props.setCalculatedFormData(calculationObj);

    //this length is required to make the decision about clearing the ui ; when all the drugs are deleted.
    props.setPrescriptionLength(props.data.result.length);
  }, [props.discountPercent]);

  //The useEffect to calculate the total bill amounts

  console.log("props.data.result",props.data.result);
  
  React.useEffect(() => {
    props.setFinalPrescriptions(props.data);

    //calculate and store the calculated form data in the state variable calculatedFormData
    let prescriptionArray = props.data.result;

    let totalDrugItem = 0;
    let totalQuantityOfDrugs = 0;
    let totalAmount = 0;
    let totalGSTAmount = 0;
    let totalNetAmount = 0;
    let totalRoundingAmount = 0;
    let totalDiscountAmount = 0;
    let totalDiscountPercentage = 0;
    let totalPaidAmount = 0;
    for (let prescriptionObj of prescriptionArray) {
      //calculating the total number of drug items

      totalDrugItem = prescriptionArray.length;
      totalQuantityOfDrugs =
        Number(totalQuantityOfDrugs) + Number(prescriptionObj.quantity);

      totalAmount = Number(totalAmount) + Number(prescriptionObj.amount);

      totalGSTAmount = totalGSTAmount + Number(prescriptionObj.gstAmount);

      totalNetAmount = totalNetAmount + Number(prescriptionObj.netAmount);
      totalDiscountAmount =
        totalDiscountAmount + Number(prescriptionObj.discountAmount);

      let discPercentVal =
        totalDiscountPercentage + Number(prescriptionObj.discountPercentage);

      totalDiscountPercentage = discPercentVal;
    }
    let totalDiscAmountVal = totalDiscountAmount.toFixed(2);

    totalRoundingAmount = Math.round(totalNetAmount);
    totalRoundingAmount = totalRoundingAmount.toFixed(2);
    totalPaidAmount = Number(totalNetAmount).toFixed(2);
    totalDiscountAmount = totalDiscAmountVal;
    totalDiscountPercentage = totalDiscountPercentage.toFixed(2);
    totalGSTAmount = totalGSTAmount.toFixed(2);
    totalAmount = totalAmount.toFixed(2);
    totalNetAmount = totalNetAmount.toFixed(2);

    let calculationObj = {
      totalDrugItem: totalDrugItem,
      totalQuantityOfDrugs: totalQuantityOfDrugs,
      totalAmount: totalAmount,
      totalGSTAmount: totalGSTAmount,
      totalNetAmount: totalNetAmount,
      totalRoundingAmount: totalRoundingAmount,
      totalDiscountAmount: totalDiscountAmount,
      totalPaidAmount: totalPaidAmount,
      totalDiscountPercentage: (
        Number(totalDiscountPercentage) / Number(props.data.result.length)
      ).toFixed(2),
    };

    props.setCalculatedFormData(calculationObj);

    //this length is required to make the decision about clearing the ui ; when all the drugs are deleted.
    props.setPrescriptionLength(props.data.result.length);
  }, [props.data, changingDiscAmount]);

  React.useEffect(() => {
    props.setFinalPrescriptions(props.data);

    //calculate and store the calculated form data in the state variable calculatedFormData
    let prescriptionArray = props.data.result;

    let totalDrugItem = 0;
    let totalQuantityOfDrugs = 0;
    let totalAmount = 0;
    let totalGSTAmount = 0;
    let totalNetAmount = 0;
    let totalRoundingAmount = 0;
    let totalDiscountAmount = 0;
    let totalDiscountPercentage = 0;
    let totalPaidAmount = 0;
    for (let prescriptionObj of prescriptionArray) {
      //calculating the total number of drug items
      if (prescriptionObj.quantity > 0) {
        totalDrugItem = prescriptionArray.length;

        totalQuantityOfDrugs =
          Number(totalQuantityOfDrugs) + Number(prescriptionObj.quantity);

        totalAmount = Number(totalAmount) + Number(prescriptionObj.amount);

        totalGSTAmount = totalGSTAmount + Number(prescriptionObj.gstAmount);

        totalNetAmount = totalNetAmount + Number(prescriptionObj.netAmount);
        totalDiscountAmount =
          totalDiscountAmount + Number(prescriptionObj.discountAmount);

        totalDiscountPercentage =
          totalDiscountPercentage +
          Number(prescriptionObj.discountPercentage) / props.data.result.length;
      }
    }

    let totalDiscAmountVal = totalDiscountAmount.toFixed(2);
    totalRoundingAmount = Math.round(totalNetAmount);
    totalRoundingAmount = totalRoundingAmount.toFixed(2);
    totalPaidAmount = Number(totalNetAmount).toFixed(2);
    totalDiscountAmount = totalDiscAmountVal;
    totalDiscountPercentage = totalDiscountPercentage.toFixed(2);
    totalGSTAmount = totalGSTAmount.toFixed(2);
    totalAmount = totalAmount.toFixed(2);
    totalNetAmount = totalNetAmount.toFixed(2);

    let calculationObj = {
      totalDrugItem: totalDrugItem,
      totalQuantityOfDrugs: totalQuantityOfDrugs,
      totalAmount: totalAmount,
      totalGSTAmount: totalGSTAmount,
      totalNetAmount: totalNetAmount,
      totalRoundingAmount: totalRoundingAmount,
      totalDiscountAmount: totalDiscountAmount,
      totalDiscountPercentage: totalDiscountPercentage,
      totalPaidAmount: totalPaidAmount,
    };

    props.setCalculatedFormData(calculationObj);

    //this length is required to make the decision about clearing the ui ; when all the drugs are deleted.
    props.setPrescriptionLength(props.data.result.length);
  }, [selectedRow]);

  //useEffect to display the values on the Prescription table. These values are fetched from the BatchInfoModal.
  const previousBalanceQty = React.useRef(null);
  const previousBatchRowData = React.useRef([]);

  React.useEffect(() => {
    let medicineArray = props.data.result;

    if (
      indexVal !== "" &&
      (selectedRow && selectedRow) !== "" &&
      selectedRow !== undefined
    ) {
      let medicineObj = medicineArray[indexVal];
      if (!Array.isArray(previousBatchRowData.current)) {
        previousBatchRowData.current = [];
      }
      previousBatchRowData.current.push(selectedRow);

      medicineObj.batchNo =
        selectedRow?.["BatchCode"] && selectedRow?.BatchCode !== ""
          ? selectedRow && selectedRow?.BatchCode
          : selectedRow && selectedRow?.["Batch Code"];

      // medicineObj.genericId = selectedRow && selectedRow?.genericId;

      medicineObj.mrpExcludeGst = selectedRow && selectedRow?.UnitMRP;
      previousBalanceQty.current = selectedRow?.["Balance Qty"];

      if (
        props.selectedPatientType === "IPD" &&
        loggedUser?.isDiscountApplicable === true
      ) {
        setValue(`discPercent${props.data.result[indexVal].id}`, 10);
      } else {
        setValue(`discPercent${props.data.result[indexVal].id}`, 0);
      }

      let expiry =
        selectedRow?.["BatchExpDate"] && selectedRow?.BatchExpDate !== ""
          ? selectedRow?.BatchExpDate
          : selectedRow?.["Batch Exp Date"];

      medicineObj.expiryDate = expiry;

      medicineObj.sgstPercentage = Number(
        selectedRow?.["sgstPercentage"] && selectedRow?.sgstPercentage > 0
          ? selectedRow?.sgstPercentage
          : selectedRow.VATPer / 2
      );

      medicineObj.cgstPercentage = Number(
        selectedRow?.["cgstPercentage"] && selectedRow?.cgstPercentage > 0
          ? selectedRow?.sgstPercentage
          : selectedRow.VATPer / 2
      );

      medicineObj.isConsignment =
        selectedRow && selectedRow?.isConsignment
          ? selectedRow && selectedRow?.isConsignment
          : selectedRow && selectedRow?.IsConsignment;

      medicineObj.conversion = selectedRow && selectedRow?.conversion;

      medicineObj.purchaseRate = selectedRow && selectedRow?.purchaseRate;

      medicineObj.mrp = selectedRow && selectedRow?.UnitMRP;

      medicineObj.balanceQuantity = selectedRow && selectedRow?.["Balance Qty"];

      medicineObj.discountPercentage = Number(0);

      medicineObj.prescribeQuantity = medicineObj.prescribeQuantity;

      medicineObj.gstPercentage =
        selectedRow?.["VATPer"] && selectedRow?.VATPer
          ? selectedRow?.VATPer
          : selectedRow.VATPercentage;

      medicineObj.saleQuantity = Number(medicineObj && medicineObj.quantity);

      medicineObj.itemMaster = { id: medicineObj.id };

      if (selectedRow && selectedRow.hasOwnProperty("rackId")) {
        medicineObj.rack = { id: selectedRow && selectedRow?.rackId };
      }

      if (selectedRow && selectedRow.hasOwnProperty("shelfId")) {
        medicineObj.shelf = { id: selectedRow && selectedRow?.shelfId };
      }

      medicineObj.rackName = selectedRow && selectedRow?.rack;
      medicineObj.shelfName = selectedRow && selectedRow?.shelf;

      if (selectedRow && selectedRow.hasOwnProperty("UnitLandedRate")) {
        medicineObj.UnitLandedRate = selectedRow && selectedRow?.UnitLandedRate;
      }

      if (selectedRow && selectedRow.hasOwnProperty("UnitPurchaseRate")) {
        medicineObj.UnitPurchaseRate =
          selectedRow && selectedRow?.UnitPurchaseRate;
      }

      //approximate upto 2 decimal places
      let amountVal = selectedRow && selectedRow?.UnitMRP;

      let amount = 0;

      amount = (Number(amountVal) * Number(medicineObj.quantity)).toFixed(2);

      if (medicineObj.quantity > 0 || medicineObj.quantity !== null) {
        medicineObj.amount = Number(amount);
      } else {
        medicineObj.amount = Number(selectedRow && selectedRow?.UnitMRP);
      }

      let mrpExcludeGstVal =
        selectedRow && selectedRow.mrpExcludeGst > 0
          ? Number(
              selectedRow &&
                selectedRow.mrpExcludeGst * Number(medicineObj.quantity)
            ).toFixed(2)
          : Number(selectedRow.UnitMRP * Number(medicineObj.quantity)).toFixed(
              2
            );
      let cgstPercentageVal =
        selectedRow?.["cgstPercentage"] && selectedRow?.cgstPercentage
          ? selectedRow?.cgstPercentage
          : selectedRow.VATPer / 2;
      let sgstPercentageVal =
        selectedRow?.["sgstPercentage"] && selectedRow?.sgstPercentage
          ? selectedRow?.sgstPercentage
          : selectedRow.VATPer / 2;
      let cgstAmount = (mrpExcludeGstVal * (cgstPercentageVal / 100)).toFixed(
        2
      );
      medicineObj.cgstAmount = Number(cgstAmount);
      let sgstAmount = (mrpExcludeGstVal * (sgstPercentageVal / 100)).toFixed(
        2
      );
      medicineObj.sgstAmount = Number(sgstAmount);
      let discountAmount = (medicineObj.amount * (10 / 100)).toFixed(2);

      medicineObj.conversion = selectedRow && selectedRow?.Conversion;
      medicineObj.IsConsignment = selectedRow && selectedRow?.IsConsignment;

      if (
        props.selectedPatientType === "IPD" &&
        loggedUser?.isDiscountApplicable === true
      ) {
        medicineObj.discountAmount = Number(discountAmount);
        setValue(
          `discAmt${props.data.result[indexVal].id}`,
          medicineObj && medicineObj.discountAmount
        );
        let netAmount = (
          medicineObj.amount - medicineObj.discountAmount
        ).toFixed(2);
        medicineObj.netAmount = Number(netAmount);

        setValue(
          `netAmt${props.data.result[indexVal].id}`,
          medicineObj && medicineObj.netAmount
        );
      } else {
        medicineObj.discountAmount = Number(0);
        setValue(`discAmt${props.data.result[indexVal].id}`, 0);
        let netAmount = medicineObj.amount.toFixed(2);
        medicineObj.netAmount = Number(netAmount);

        setValue(
          `netAmt${props.data.result[indexVal].id}`,
          medicineObj && medicineObj.netAmount
        );
      }

      medicineObj.gstAmount = Number(
        (Number(medicineObj.netAmount) * Number(medicineObj.gstPercentage)) /
          100
      ).toFixed(2);

      medicineObj.taxableAmount = (
        Number(selectedRow && selectedRow?.mrpExcludeGst) *
        Number(medicineObj.quantity)
      ).toFixed(2);
      medicineArray[indexVal] = medicineObj;

      if (
        Number(medicineObj.IndentQty) > Number(selectedRow["Balance Qty"]) &&
        drugBatchInfo?.result?.length > 1
      ) {
        const originalQuantity = Number(medicineObj.quantity);
        const balanceQty = Number(selectedRow["Balance Qty"]);
        const indentQty = Number(
          medicineObj.quantity - Number(selectedRow["Balance Qty"]) || 0
        );
        medicineObj.quantity = Math.min(balanceQty, originalQuantity);

        medicineArray[indexVal] = {
          ...medicineArray[indexVal],
          quantity: Number(medicineObj.quantity),
          IndentQty: Number(medicineObj.quantity),
          PendIssueQty:
            previousBatchRowData.current[indexVal]["Balance Qty"] -
            Number(medicineObj.quantity),
          BalanceQty: balanceQty,
          batchNo: (medicineObj.batchNo =
            selectedRow?.["BatchCode"] && selectedRow?.BatchCode !== ""
              ? selectedRow && selectedRow?.BatchCode
              : selectedRow && selectedRow?.["Batch Code"]),
        };

        const remainingQty = originalQuantity - medicineObj.quantity;
        if (remainingQty > 0) {
          medicineArray.splice(indexVal + 1, 0, {
            ...medicineObj,
            quantity: remainingQty,
            BalanceQty: previousBalanceQty.current - medicineObj.quantity,
            IndentQty: indentQty,
            PendIssueQty: Number(medicineObj.IndentQty) - medicineObj.quantity,
            batchNo: "",
          });
        }
        props.setData({
          result: medicineArray,
        });
      } else {
        medicineObj.quantity = medicineObj.quantity;
        props.setData({
          result: medicineArray,
        });
      }

      let nextField = document.getElementById(`Quantity${medicineBatchIndex}`);
      nextField?.focus();
    }
  }, [indexVal, selectedRow]);

  const handleDiscountChange = (prescriptionObj, index) => {
    let discPercent = watch(`discPercent${prescriptionObj.id}`);

    let discountAmount = (
      Number(prescriptionObj.amount) *
      (discPercent / 100)
    ).toFixed(2);

    setValue(`discAmt${prescriptionObj.id}`, discountAmount);
    setChangingDiscAmount(discountAmount);

    let netAmount = (Number(prescriptionObj.amount) - discountAmount).toFixed(
      2
    );

    setValue(`netAmt${prescriptionObj.id}`, netAmount);

    let modifiedPrescriptionObj = prescriptionObj;
    modifiedPrescriptionObj.quantity = prescriptionObj.quantity;
    modifiedPrescriptionObj.amount = prescriptionObj.amount;
    modifiedPrescriptionObj.taxableAmount = prescriptionObj.taxableAmount;
    modifiedPrescriptionObj.discountAmount = discountAmount;
    modifiedPrescriptionObj.discountPercentage = discPercent;
    modifiedPrescriptionObj.cgstAmount = prescriptionObj.cgstAmount;
    modifiedPrescriptionObj.sgstAmount = prescriptionObj.sgstAmount;

    modifiedPrescriptionObj.netAmount = netAmount;

    let medicineArray = props.data.result;

    medicineArray[index] = modifiedPrescriptionObj;
  };

  const handleAlternativeChange = (e, prescriptionObj, index) => {
    let requiredIndex = Number(index + 1);

    if (Number(e.target.value) < Number(prescriptionObj.prescribeQuantity)) {
      if (Number(e.target.value) > 0) {
        let obj = {
          id: prescriptionObj.id,
          code: prescriptionObj.code,
          pharmacyItem: prescriptionObj.pharmacyItem,
          prescribeQuantity:
            Number(prescriptionObj.prescribeQuantity) - Number(e.target.value),
          balanceQuantity: prescriptionObj.balanceQuantity,
        };

        let objTwo = {
          id: prescriptionObj.id,
          landedRate: prescriptionObj.landedRate,
          purcheseRate: prescriptionObj.purchaseRate,
          code: prescriptionObj.code,
          pharmacyItem: prescriptionObj.pharmacyItem,
          schedule: prescriptionObj.schedule,
          prescribeQuantity: Number(e.target.value),
          batchNo: prescriptionObj.batchNo,
          genericId: prescriptionObj.genericId,
          mrpExcludeGst: prescriptionObj.mrpExcludeGst,
          expiryDate: prescriptionObj.expiryDate,
          sgstPercentage: prescriptionObj.sgstPercentage,
          cgstPercentage: prescriptionObj.cgstPercentage,
          isConsignment: prescriptionObj.isConsignment,
          conversion: prescriptionObj.conversion,
          purchaseRate: prescriptionObj.purchaseRate,
          mrp: prescriptionObj.mrp,
          balanceQuantity: prescriptionObj.balanceQuantity,
          discountPercentage: prescriptionObj.discountPercentage,
          gstAmount: prescriptionObj.gstAmount,
          gstPercentage: prescriptionObj.gstPercentage,
          saleQuantity: prescriptionObj.saleQuantity,
          itemMaster: prescriptionObj.itemMaster,
          rack: prescriptionObj.rack,
          shelf: prescriptionObj.shelf,
          amount: prescriptionObj.amount,
          cgstAmount: prescriptionObj.cgstAmount,
          sgstAmount: prescriptionObj.sgstAmount,
          discountAmount: prescriptionObj.discountAmount,
          netAmount: prescriptionObj.netAmount,
          taxableAmount: prescriptionObj.taxableAmount,
          quantity: prescriptionObj.quantity,
        };
        let temp = [...props.data.result];
        temp.splice(requiredIndex, 0, obj);
        temp.splice(index, 1, objTwo);

        props.setAlternateTableList(temp);
      }
    }
  };

  React.useEffect(() => {
    if (
      props.resetIndentCount > 0 &&
      props.data?.result?.length > 0 &&
      props.selectedPatientType === "IPD"
    ) {
      let elementToFocus = document.getElementById(`BatchNo${0}`);

      if (elementToFocus) {
        elementToFocus.focus();
      }
      props.setResetIndentCount(0);
    }
  }, [props.resetIndentCount, props.data]);

  const handleOpenGenericDrugModal = (row, index) => {
    setRowToReplace(row);
    setPreQuantity(row.prescribeQuantity);
    setIndexToReplace(index);
    setSelectedGenericDrug("");
    setOpenGenericDrugModal(true);
  };

  React.useEffect(() => {
    if (selectedGenericDrug !== "") {
      let obj = {
        id: Number(selectedGenericDrug.Id),
        genericId: Number(selectedGenericDrug?.ItemGenericId),
        batchNo: "",
        code: selectedGenericDrug["Code"],
        pharmacyItem: selectedGenericDrug["Drug"],
        balanceQuantity: selectedGenericDrug["BalanceQty"],
        BatchesRequired: selectedGenericDrug?.BatchesRequired,
        prescribeQuantity: preQuantity,
        quantity: rowToReplace?.quantity,
      };
      let temp = [...props.data.result];
      if (indexToReplace !== undefined && indexToReplace >= 0) {
        temp[indexToReplace] = obj;
      }
      props.setData({ statusCode: 200, result: temp });
      setOpenGenericDrugModal(false);
      // setTimeout(() => {
      setRowToReplace("");
      setIndexToReplace("");
      // }, 1);
    }
  }, [selectedGenericDrug, indexToReplace, preQuantity, rowToReplace]);

  React.useEffect(() => {
    console.log("the row to be replaced is :", rowToReplace);
  }, [rowToReplace]);

  const handleFocusNextBatch = (focusIndex) => {
    let focusBatch = document.getElementById(`BatchNo${focusIndex + 1}`);
    if (focusBatch) {
      focusBatch.focus();
    }
  };

  const loggedUser = JSON.parse(localStorage.getItem("userInfo"));

  React.useEffect(() => {
    console.log("The medicines added :", props.data.result);
  }, [props.data]);

  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 2, border: "1px solid lightgray" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-56 2xl:h-72"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell
                      className="whitespace-nowrap"
                      style={{ background: "#F1F1F1" }}
                    >
                      <span className="text-gray-600 font-bold">Actions</span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Code
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Item Description
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Pres Qty.
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Batch No.
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Bal Qty
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Pend Iss Qty
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Ind Qty
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Qty
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Unit MRP.
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Amt
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Disc %
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Disc.Amt
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Net Amt
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Bins
                      </span>
                    </TableCell>
                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Rack
                      </span>
                    </TableCell>

                    <TableCell style={{ background: "#F1F1F1" }}>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Shelf
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Iterating the array using map function to display the rows in the table. */}
                  {props.data.result.map((prescriptionObj, index) => {
                    {
                      props.patientInfo === null
                        ? setValue(`Quantity${index}`, prescriptionObj.quantity)
                        : setValue(
                            `Quantity${index}`,
                            prescriptionObj.quantity
                          );
                    }
                    return (
                      <TableRow
                        key={prescriptionObj.id}
                        // className={!prescriptionObj.id ? "line-through" : ""}
                        sx={{
                          "& td": {
                            paddingY: 0.4,
                            backgroundColor:
                              prescriptionObj?.xSchedule === true
                                ? "#99f2b1"
                                : prescriptionObj?.hSchedule === true
                                ? "#99ddf7"
                                : "",
                          },
                        }}
                      >
                        <TableCell>
                          <div className="flex gap-1 items-center">
                            <Tooltip title="Delete">
                              <button
                                type="button"
                                className="text-red-500"
                                //event listener function to delete the given object from the array upon clicking the delete icon.
                                onClick={() => {
                                  setOpenChild(true);
                                  setDeleteIndex(index);
                                  setDeleteRow(prescriptionObj);
                                }}
                              >
                                {
                                  <DeleteOutlineOutlinedIcon
                                  // color={!prescriptionObj.id ? "disabled" : ""}
                                  />
                                }
                              </button>
                            </Tooltip>
                            {Number(prescriptionObj.balanceQuantity) === 0 && (
                              <Tooltip title="Substitute">
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleOpenGenericDrugModal(
                                      prescriptionObj,
                                      index
                                    );
                                    handleFocusNextBatch(index - 1);
                                  }}
                                >
                                  <OpenDrugModalIcon />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("code")
                            ? prescriptionObj.code
                            : null}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("pharmacyItem")
                            ? prescriptionObj.pharmacyItem
                            : null}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.prescribeQuantity
                            ? prescriptionObj.prescribeQuantity
                            : ""}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.BatchesRequired === true ? (
                            <input
                              className="border rounded border-customBlue w-28 h-[25px] text-center"
                              size="small"
                              name={`BatchNo${index}`}
                              id={`BatchNo${index}`}
                              {...register(`BatchNo${index}`)}
                              control={control}
                              value={
                                prescriptionObj.hasOwnProperty("batchNo")
                                  ? prescriptionObj.batchNo
                                  : ""
                              }
                              autoFocus={
                                selectedGenericDrug !== "" ? true : false
                              }
                              //disable the input tag when itemId for the drug is not present
                              // disabled={!prescriptionObj.id}
                              //event listener function for the batchNo input tag.
                              onKeyDown={(e) => {
                                //empty the selectedRow state variable ; when the enter key is pressed , so that we do not enter the if block of that useEffect which updates the prescription table's row.
                                setSelectedRow("");
                                if (e.key === "Enter") {
                                  if (prescriptionObj.id) {
                                    setIndexVal(index);
                                    //get the batch information from itemId
                                    let obj = {
                                      expiryDate: new Date(),
                                      isConsignment: 0,
                                      itemId: Number(prescriptionObj.id),
                                      storeId: loginObj.storeId,
                                    };
                                    getItemBatchNumber(obj).then((response) => {
                                      if (
                                        previousBatchRowData.current &&
                                        previousBatchRowData.current.length > 0
                                      ) {
                                        const previousBatchCodes =
                                          previousBatchRowData.current.map(
                                            (item) => item["Batch Code"]
                                          );
                                        const filteredBatchData =
                                          response.data.result.filter(
                                            (item) =>
                                              !previousBatchCodes.includes(
                                                item["Batch Code"]
                                              )
                                          );
                                        setDrugBatchInfo({
                                          result: filteredBatchData,
                                        });
                                      } else {
                                        setDrugBatchInfo(response.data);
                                      }

                                      handleOpen();
                                      setMedicineBatchIndex(index);
                                    });
                                  }
                                  //The below state variable's information is usefull for displaying the itemCode and
                                  // itemDescription on the batchInfoModal.
                                  setDrugItemInfo(prescriptionObj);
                                }
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("balanceQuantity")
                            ? prescriptionObj.balanceQuantity
                            : null}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("balanceQuantity")
                            ? prescriptionObj.PendIssueQty
                            : null}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("balanceQuantity")
                            ? prescriptionObj.IndentQty
                            : null}
                        </TableCell>

                        <TableCell>
                          <>
                            <input
                              className="border rounded border-customBlue w-20 h-[25px] text-center"
                              size="small"
                              type="number"
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                              // autoFocus
                              defaultValue={
                                prescriptionObj.quantity
                                  ? prescriptionObj.quantity
                                  : ""
                              }
                              id={`Quantity${index}`}
                              name={`Quantity${index}`}
                              {...register(`Quantity${index}`)}
                              style={{
                                border:
                                  prescriptionObj.quantity >
                                  prescriptionObj.balanceQuantity
                                    ? "1px solid red"
                                    : "1px solid #3184a6",
                              }}
                              control={control}
                              //disable the input tag when itemId for the drug is not present or when the batchNo is not present
                              disabled={
                                !prescriptionObj.id ||
                                !prescriptionObj.hasOwnProperty("batchNo") ||
                                prescriptionObj?.isAdded === true
                              }
                              onBlur={(e) => {
                                handleAlternativeChange(
                                  e,
                                  prescriptionObj,
                                  index
                                );
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleFocusNextBatch(index);
                                }
                              }}
                              //event listener function to update the given row ;
                              //whose quantity input tag is getting changed by typing values.
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  setValue(`Quantity${index}`, 1);
                                }
                                let quantityVal = Number(e.target.value);
                                let quantityFieldIndex = index;

                                //if quantityVal is greater than the balanceQuantity ;
                                //change the color of the quantity input tag to red color.
                                //else dont set any color.

                                // modify the respective row whose quantity input tag's value changes.
                                let modifiedPrescriptionObj = prescriptionObj;

                                if (e.target.value === "") {
                                  modifiedPrescriptionObj.quantity = 0;
                                } else {
                                  modifiedPrescriptionObj.quantity = Number(
                                    e.target.value
                                  );
                                }

                                modifiedPrescriptionObj.saleQuantity =
                                  quantityVal;
                                // modifiedPrescriptionObj.prescribeQuantity = quantityVal;

                                if (
                                  prescriptionObj.quantity >
                                    prescriptionObj.balanceQuantity ||
                                  prescriptionObj.quantity >
                                    prescriptionObj.prescribeQuantity
                                ) {
                                  props.setIsValidQuantity(false);
                                } else {
                                  props.setIsValidQuantity(true);
                                }

                                if (quantityVal !== 0) {
                                  modifiedPrescriptionObj.amount = (
                                    Number(modifiedPrescriptionObj.mrp) *
                                    quantityVal
                                  ).toFixed(2);
                                } else if (quantityVal === 1) {
                                  modifiedPrescriptionObj.amount = 0;
                                }

                                let gstPercent =
                                  Number(
                                    modifiedPrescriptionObj.cgstPercentage
                                  ) +
                                  Number(
                                    modifiedPrescriptionObj.sgstPercentage
                                  );

                                let divider = gstPercent + 100;
                                let divideVal = divider / 100;

                                modifiedPrescriptionObj.taxableAmount = (
                                  Number(
                                    modifiedPrescriptionObj.mrpExcludeGst
                                  ) * Number(quantityVal)
                                ).toFixed(2);

                                let taxableAmountVal =
                                  Number(
                                    modifiedPrescriptionObj.mrpExcludeGst
                                  ) * Number(quantityVal);

                                let discPercent =
                                  modifiedPrescriptionObj.discountPercentage;

                                modifiedPrescriptionObj.discountAmount = (
                                  Number(modifiedPrescriptionObj.amount) *
                                  (discPercent / 100)
                                ).toFixed(2);

                                setValue(
                                  `discAmt${prescriptionObj.id}`,
                                  modifiedPrescriptionObj.discountAmount
                                );

                                modifiedPrescriptionObj.cgstAmount = (
                                  (Number(taxableAmountVal) *
                                    Number(
                                      modifiedPrescriptionObj.cgstPercentage
                                    )) /
                                  100
                                ).toFixed(2);

                                modifiedPrescriptionObj.sgstAmount = (
                                  (Number(taxableAmountVal) *
                                    Number(
                                      modifiedPrescriptionObj.sgstPercentage
                                    )) /
                                  100
                                ).toFixed(2);

                                modifiedPrescriptionObj.netAmount = (
                                  Number(modifiedPrescriptionObj.amount) -
                                  Number(modifiedPrescriptionObj.discountAmount)
                                ).toFixed(2);

                                let gstPer =
                                  Number(
                                    modifiedPrescriptionObj.cgstPercentage
                                  ) +
                                  Number(
                                    modifiedPrescriptionObj.sgstPercentage
                                  );

                                modifiedPrescriptionObj.gstAmount =
                                  Number(
                                    modifiedPrescriptionObj.netAmount *
                                      prescriptionObj.gstPercent
                                  ) / 100;
                                setValue(
                                  `netAmt${prescriptionObj.id}`,
                                  modifiedPrescriptionObj.netAmount
                                );

                                let medicineArray = props.data.result;

                                medicineArray[index] = modifiedPrescriptionObj;

                                props.setData({ result: medicineArray });
                              }}
                            />
                            {prescriptionObj.quantity >
                            prescriptionObj.balanceQuantity ? (
                              <span className="text-[10px] text-red-600 whitespace-nowrap h-6">
                                Please Enter valid Quantity.
                              </span>
                            ) : (
                              ""
                            )}
                            {prescriptionObj.prescribeQuantity > 0 &&
                            prescriptionObj.quantity >
                              prescriptionObj.prescribeQuantity ? (
                              <span className="text-[10px] text-red-600 whitespace-nowrap h-6">
                                Please Enter valid Quantity.
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("mrp")
                            ? prescriptionObj.mrp
                            : null}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("amount")
                            ? prescriptionObj.amount
                            : null}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          <fieldset
                            className="w-16"
                            onChange={() => {
                              //event listener function for the 'discPercent' InputField.
                              handleDiscountChange(prescriptionObj, index);
                            }}
                          >
                            {/* (props.patientInfo !== null &&
                                  props.selectedPatientType === "OPD" &&
                                  loggedUser?.storeName ===
                                    "OUTPATIENT PHARMACY STORE") ||
                                (loggedUser?.storeName.toLowerCase() ===
                                  "central pharmacy" &&
                                  props.patientInfo?.isEmployee === 1 &&
                                  props.patientInfo !== null)
                                  ? false
                                  : true */}
                            <input
                              type="number"
                              control={control}
                              disabled={
                                props.selectedPatientType === "IPD" &&
                                loggedUser?.isDiscountApplicable === true
                                  ? false
                                  : true
                              }
                              {...register(`discPercent${prescriptionObj.id}`)}
                              name={`discPercent${prescriptionObj.id}`}
                              defaultValue={
                                (props.selectedPatientType === "IPD" &&
                                  loggedUser?.isDiscountApplicable === true) ||
                                loggedUser?.cashCounter?.toLowerCase() ===
                                  "central pharmacy"
                                  ? Number(
                                      prescriptionObj.discountPercentage
                                    ).toFixed(4)
                                  : 0
                              }
                              className="border rounded border-customBlue w-20 h-[25px] text-center"
                            />
                          </fieldset>
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          <fieldset>
                            <input
                              type="number"
                              control={control}
                              name={`discAmt${prescriptionObj.id}`}
                              {...register(`discAmt${prescriptionObj.id}`)}
                              defaultValue={prescriptionObj.discountAmount}
                              disabled={true}
                              className="border rounded border-customBlue w-20 h-[25px] text-center"
                            />
                          </fieldset>
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          <fieldset>
                            <input
                              type="number"
                              control={control}
                              name={`netAmt${prescriptionObj.id}`}
                              {...register(`netAmt${prescriptionObj.id}`)}
                              defaultValue={prescriptionObj.netAmount}
                              className="border rounded border-white w-20 h-[25px] text-center"
                              disabled={true}
                            />
                          </fieldset>
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("bins")
                            ? prescriptionObj.bins
                            : null}
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("rackName")
                            ? prescriptionObj.rackName
                            : null}
                        </TableCell>

                        <TableCell className="whitespace-nowrap">
                          {prescriptionObj.hasOwnProperty("shelfName")
                            ? prescriptionObj.shelfName
                            : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>

        {open &&
        drugBatchInfo !== null &&
        drugBatchInfo.hasOwnProperty("result") ? (
          // && drugBatchInfo.result.length > 0
          <BatchInfoModal
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            drugBatchInfo={drugBatchInfo}
            setDrugBatchInfo={setDrugBatchInfo}
            open={open}
            setOpen={setOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            drugItemInfo={drugItemInfo}
          />
        ) : null}

        <ConfirmationModal
          confirmationOpen={openChild}
          confirmationHandleClose={handleCloseChild}
          confirmationSubmitFunc={deleteDrug}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to delete this drug ?"
          confirmationButtonMsg="Delete"
        />
        <GenericDrugModal
          open={openGenericDrugModal}
          close={handleCloseGenericDrugModal}
          setSelectedGenericDrug={setSelectedGenericDrug}
          selectedGenericDrug={selectedGenericDrug}
          rowToReplace={rowToReplace}
          indexToReplace={indexToReplace}
          setAlternateTableList={props.setAlternateTableList}
          data={props.data.result}
        />
      </div>
    </>
  );
}


import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import InputField from "../../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import { fetchOtServices } from "../../../services/otDetailsServices/OtDetailsServices";
import { OtDetailsContext } from "./OtDetails";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";
import InputArea from "../../../../common/components/FormFields/InputArea";

const Instruments = () => {
  const {
    instrumentsData,
    setInstrumentsData,
    PatientTypeRadio,
    patientInfo,
    sugeryEquipmentsData,
  } = useContext(OtDetailsContext);

  //
  const [searchString, setSearchString] = useState("");
  const [serviceInfo, setServiceInfo] = useState();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  // arr Duplication Check
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let unitId = token.unitId;

  useEffect(() => {
    if (searchString !== "") {
      const otServiceObj = {
        applicableTo: PatientTypeRadio,
        classId: patientInfo ? patientInfo?.classId : 0,
        description: searchString !== "" ? searchString : "",
        groupId: 0,
        isConcessionService: 0,
        isEmergency: false,
        isExternal: false,
        isFree: 0,
        serviceCode: "%",
        tariffId: patientInfo ? patientInfo?.tariffId : 0,
        unitId: unitId,
      };

      fetchOtServices(otServiceObj)
        .then((response) => response.data)
        .then((res) => {
          setServiceInfo(res.result);
        });
    }
  }, [searchString]);

  //add Doctors List

  const addOtServices = () => {
    let searchedServiceData = watch("searchService");
    let qty = watch("instServiceQty");

    if (searchedServiceData !== null) {
      let serviceListData = {
        ServiceId: Number(searchedServiceData.ServiceId),
        "Service Name": searchedServiceData.label,
        Rate: Number(searchedServiceData.Rate),
        Qty: Number(qty),
        TotalAmt: Number(searchedServiceData.Rate) * Number(qty),
        aneasAmount: "",
        NetAmount: searchedServiceData.NetAmt,
        TariffId: searchedServiceData.TariffId,
        otOtherDetailsId: 0,
        isDelete: false,
      };

      let isItemAlreadyExist = false;
      isItemAlreadyExist = instrumentsData.some(
        (item) => !item.isDelete && item.ServiceId === serviceListData.ServiceId
      );

      if (isItemAlreadyExist) {
        warningAlert("Service Already Exists...");
      } else {
        setInstrumentsData((prev) => [...prev, serviceListData]);
      }

      setValue("searchService", null, { shouldValidate: true });
      setValue("instServiceQty", "", { shouldValidate: true });
    }
  };

  const renderActions = (row, index) => {
    const handleDeleteRow = () => {
      setInstrumentsData((prevData) =>
        prevData.map((item) =>
          item["ServiceId"] === row["ServiceId"]
            ? { ...item, isDelete: true }
            : item
        )
      );

      // let temp = [...instrumentsData];
      // let rowToDelete = row.ServiceId;
      // const item = temp.find((item) => {
      //   if (item.ServiceId === rowToDelete) {
      //     item.isDelete = true;
      //   }
      // });

      // setInstrumentsData(temp);
    };
    return (
      <>
        <Tooltip title="Delete">
          <div
            className="text-red-500 mr-3"
            onClick={() => handleDeleteRow(row, index)}
          >
            {<DeleteOutlineOutlinedIcon />}
          </div>
        </Tooltip>
      </>
    );
  };

  const renderInput = (row, index, header) => {
    const handleTableQuantityChange = (index, newValue) => {
      const updatedList = [...instrumentsData];
      updatedList[index]["Qty"] = newValue;
      updatedList[index].TotalAmt = (
        newValue * updatedList[index]["Rate"]
      ).toFixed(2);
      setInstrumentsData(updatedList);
    };

    return (
      header === "Qty" && (
        <span>
          <input
            className={
              row.error !== true
                ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
            }
            onChange={(e) => handleTableQuantityChange(index, e.target.value)}
            value={row["Qty"]}
            type="number"
            min="0"
            onWheel={(event) => event.currentTarget.blur()}
            onKeyDown={(e) => {
              if (
                e?.key === "-" ||
                e?.key === "+" ||
                e?.code === "ArrowUp" ||
                e?.code === "ArrowDown" ||
                e?.code === "NumpadDecimal"
              ) {
                e.preventDefault();
              }
            }}
            name={`Qty${[index]}`}
          />
        </span>
      )
    );
  };

  //
  console.log("instrumentsData321", instrumentsData);

  return (
    <>
      <div className=" text-gray-700 font-semibold whitespace-nowrap">
        Surgery Details
      </div>
      {/* ///Doctors List */}

      <div className="grid grid-cols-12 gap-3 mt-3">
        {/* //Group// */}
        <div className="grid grid-cols-3 gap-3 col-span-4">
          <div className="col-span-2">
            <SearchDropdown
              name="searchService"
              placeholder="Search Service"
              dataArray={serviceInfo}
              handleInputChange={(e) => {
                setSearchString(e);
              }}
              searchIcon={true}
              error={errors.searchService}
              control={control}
              isClearable={true}
            />
          </div>
          <div>
            <InputField
              name="instServiceQty"
              variant="outlined"
              label="Quantity"
              // error={errors.email}
              control={control}
              inputProps={{ maxLength: 100 }}
            />
          </div>
        </div>

        <div className=" flex gap-3 col-span-4">
          <CommonButton
            className="bg-customGreen text-white"
            label="Add"
            onClick={() => {
              addOtServices();
            }}
          />
        </div>

        {/* table */}
        {instrumentsData.length > 0 &&
          instrumentsData &&
          instrumentsData.filter((item) => !item.isDelete).length > 0 && (
            <>
              <div className="w-full mt-2 col-span-12">
                <CommonDynamicTableNew
                  dataResult={instrumentsData.filter((item) => !item.isDelete)}
                  renderActions={renderActions}
                  renderInput={renderInput}
                  editableColumns={["Qty"]}
                  highlightRow={false}
                  removeHeaders={[
                    "id",
                    "otOtherDetailsId",
                    "TariffServiceId",
                    "ChargeId",
                    "ServiceId",
                    "aneasAmount",
                    "NetAmount",
                    "TariffId",
                    "otOtherDetailsId",
                    "isDelete",
                  ]}
                />
              </div>
            </>
          )}
      </div>

      {!!sugeryEquipmentsData && sugeryEquipmentsData.length > 0 && (
        <>
          <div className=" text-gray-700 font-semibold whitespace-nowrap mt-3 mb-1">
            Equipment Details
          </div>

          <CommonDynamicTableNew
            dataResult={sugeryEquipmentsData}
            highlightRow={false}
            removeHeaders={["id", "ReservedEquipmentId"]}
          />
        </>
      )}

      <div className="mt-3">
        <InputArea
          name="intraOpExtraItemList"
          placeholder="Intra Operation Extra Item List"
          label="Intra Operation Extra Item List"
        />
      </div>
    </>
  );
};

export default Instruments;

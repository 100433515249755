import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//list
export const fetchPrevIncidentReportList = (prevListObj) => {
  return apiClient.post(
    `/incidentReporting/getIncidentReportList
    `,
    prevListObj,
    {
      headers: authHeader(),
    }
  );
};

//save
export const fetchIncidentCategory = () => {
  return apiClient.get(
    `/incidentReporting/getIncidentCategory
    `,
    {
      headers: authHeader(),
    }
  );
};

//save
export const saveIncidentReport = (finalObj) => {
  return apiClient.post(
    `/incidentReporting/saveIncidentReport
    `,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};

export const IncidentReportingData = (incidentId) => {
  return apiClient.get(`/taskManager/incidentReporting/${incidentId}`, {
    headers: authHeader(),
  });
};
/////new

//accept by quality
export const fetchIncidentReportingDetails = (incidentId) => {
  return apiClient.get(
    `/incidentReporting/getIncidentReportingDetails/${incidentId}`,
    {
      headers: authHeader(),
    }
  );
};
//info filled by dept i.. ca-pa
export const fetchIncidentDepartmentDetails = (incidentId, departmentId) => {
  return apiClient.get(
    `/incidentReporting/getIncidentDepartmentDetails/${incidentId}/${departmentId}`,
    {
      headers: authHeader(),
    }
  );
};

//save
export const saveEmployeeIncidentDetails = (finalObj) => {
  return apiClient.post(
    `/incidentReporting/saveEmployeeIncidentDetails
    `,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};

//re-assign
export const resignIncident = (incidentId, departmentId, reason) => {
  return apiClient.get(
    `/incidentReporting/reassignIncident/${incidentId}/${departmentId}/${reason}
    `,
    {
      headers: authHeader(),
    }
  );
};

//error
export const fettchErrorCategory = () => {
  return apiClient.get(
    `/incidentReporting/getIncidentCategoryNew`,
    {
      headers: authHeader(),
    }
  );
};

//sub error
export const fetchSubError = (errorId) => {
  return apiClient.get(
    `/incidentReporting/getIncidentSubCategory/${errorId}`,
    {
      headers: authHeader(),
    }
  );
};


//staff 

export const fetchStaffData = () => {
  return apiClient.get(
    `/misc/employee`,
    {
      headers: authHeader(),
    }
  );
};


import { yupResolver } from "@hookform/resolvers/yup";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { Box, Grid, Modal } from "@mui/material";
import * as React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";

import { format } from "date-fns";
import {
  getAdvancedDropdown,
  getBankList,
  getPaymentMode,
} from "../../../../billing/services/BillingServices";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
//style for model
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 2,
};
export default function BillInformationModal(props) {
  /****************************** useForm ********************************/
  let paymentSchema = {
    paymentCart: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup
            .number()
            .typeError("Amount Required")
            .min(1, "Min value 1.")
            .required("Amount Required"),

          paymentMode: yup
            .object()
            .nullable()
            .shape({
              value: yup.string().required("Required"),
              label: yup.string().required("Required"),
            })
            .required("Required"),

          referenceNumber: yup.string().when("paymentMode", (paymentMode) => {
            let paymentModeName;

            if (paymentMode) {
              paymentModeName = paymentMode.label;
            }

            if (
              paymentModeName === "CREDIT CARD" ||
              paymentModeName === "DEBIT CARD"
            ) {
              return yup
                .string()
                .required("Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .max(30);
            } else if (paymentModeName === "UPI") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .max(30);
            } else if (paymentModeName === "CHEQUE") {
              return yup
                .string()
                .required("Reference Number Required")
                .max(30, "Only 6 Digits allowed");
            } else if (paymentModeName === "ECS") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .max(30);
            } else if (paymentModeName === "ONLINE") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .max(30);
            } else {
              return yup.string().notRequired();
            }
          }),
          bankName: yup
            .object()
            .nullable()
            .typeError("Required")
            .when("paymentMode", (paymentMode) => {
              if (paymentMode !== null) {
                if (
                  paymentMode.label !== "CASH" &&
                  paymentMode.label !== "UPI"
                ) {
                  return yup
                    .object()
                    .nullable()
                    .shape({
                      value: yup.string().required("Select Bank"),
                      label: yup.string().required("Select Bank"),
                    })
                    .required("Select Bank");
                }
              }
            }),
        })
      )
      .min(1, "Please Add amount Details"),
  };
  const paymentAppointValidation = yup.object().shape(paymentSchema).required();
  const paymentSchemaObj = paymentAppointValidation;
  const [selectedFromDate, setSelectedToDate] = React.useState(new Date());
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(paymentSchemaObj),
    defaultValues: {
      paymentCart: [
        {
          paymentMode: "",
          amount: "",
          referenceNumber: "",
          bankName: "",
          paymentDate: selectedFromDate,
        },
      ],
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = methods;

  const { fields, append: append, remove: remove } = useFieldArray({
    control: control,
    name: "paymentCart",
  });

  /****************************** state variables ********************************/

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [paymentInfo, setPaymentInfo] = React.useState([]);
  const [showAddIcon, setShowAddIcon] = React.useState(false);
  const [netPayableAmountVal, setNetPayableAmountVal] = React.useState(0);
  const [netPayErrorMessage, setNetPayErrorMessage] = React.useState("");
  const [customCashError, setCustomCashError] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [advancedList, setAdvancedList] = React.useState([]);
  const [bankList, setBankList] = React.useState([]);
  const [paymentModeList, setPaymentModeList] = React.useState([]);

  /****************************** other variables ********************************/
  const watchPaymentCart = watch("paymentCart");
  let consumeAdvanceFlag = watch("consumeAdvance");
  let refundVal = props.getValues("refundAmount");

  let totalAmount = 0;
  let netPayErrorMessage1 = "";
  let paymentInfoList = [];
  const controlledPaymentCart = fields.map((field, index) => {
    return {
      ...field,
      ...watchPaymentCart[index],
    };
  });

  /****************************** useEffects ********************************/
  let payments = "";
  React.useEffect(() => {
    if (watchPaymentCart.length > 0) {
      for (let paymentObj of watchPaymentCart) {
        if (paymentObj?.paymentMode?.label) {
          payments = payments + paymentObj?.paymentMode?.label;
        }
      }
    }
    let newPaymentArr = [];
    for (let payment of paymentModeList) {
      if (
        !payments
          ?.toLocaleLowerCase()
          .includes(payment?.label?.toLocaleLowerCase())
      ) {
        newPaymentArr.push(payment);
        setPaymentModeList(newPaymentArr);
      }
    }
  }, [watchPaymentCart]);
  React.useEffect(() => {
    getAdvancedDropdown().then((response) => {
      setAdvancedList(response.data.result);
    });

    getBankList().then((response) => {
      setBankList(response.data.result);
    });

    getPaymentMode().then((response) => {
      setPaymentModeList(response.data.result);
      setValue(`paymentCart.0.paymentMode`, response.data.result[1]);
    });
    setValue(
      `paymentCart.0.amount`,
      props.refundAmount?.toFixed(2) > 0
        ? props.refundAmount?.toFixed(2)
        : props?.amount?.toFixed(2)
    );
    // setRefundAmount(props.refundAmount);
  }, [refundVal, props.rowData, props.refundAmount, props.amount]);

  /****************************** functions ********************************/

  const resetPaymentModes = () => {
    getPaymentMode().then((response) => {
      setPaymentModeList(response.data.result);
      setValue(`paymentCart.0.paymentMode`, response.data.result[1]);
    });
  };
  const handleOpenConfirmation = () => {
    if (netPayErrorMessage === "" && customCashError === false) {
      setConfirmationOpen(true);
    }
  };
  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    setOpenBackdrop(false);
  };
  //calculate sum of all add button
  function calculateFieldAmountSum() {
    let totalAmount = 0;
    for (let cartObj of watchPaymentCart) {
      if (watchPaymentCart.length > 1) {
        if (cartObj && cartObj.amount) {
          totalAmount = Number(totalAmount) + Number(cartObj.amount);
        }
      } else if (watchPaymentCart.length === 1) {
        totalAmount = Number(cartObj.amount);
      }
    }
    return totalAmount;
  }

  if (watchPaymentCart[0].amount < props.getValues("refundAmount")) {
    netPayErrorMessage1 = "Total Amount Must Be Equal To Refund Amount";
  } else {
  }

  //update amount change from amount input filed
  function handleAmountChange() {
    //The below if block is to display the "+" icon only at the first field ; when the length of the field is 1.
    if (watchPaymentCart.length === 1) {
      let firstFieldAmount = Number(watchPaymentCart[0].amount);
      console.log(
        "firstFieldAmountfirstFieldAmountfirstFieldAmount",
        firstFieldAmount
      );
      if (
        firstFieldAmount ===
        (props.refundAmount > 0 ? props.refundAmount : props?.amount)
      ) {
        setShowAddIcon(false);
      } else if (
        firstFieldAmount <
        (props.refundAmount > 0 ? props.refundAmount : props?.amount)
      ) {
        setShowAddIcon(true);
      }
    }

    let totalSumVal = calculateFieldAmountSum();
    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (props.refundAmount !== null) {
      if (
        totalSumVal >
        (props.refundAmount > 0 ? props.refundAmount : props?.amount)
      ) {
        setNetPayErrorMessage("Total Amount Exceeded Settlement Amount");
      } else {
        setNetPayErrorMessage("");
      }
    }
  }

  function  appendPaymentField(fieldIndex) {
    let requiredObj = watchPaymentCart[fieldIndex];

    //if the payment mode is "CASH"

    let totalAmountVal = calculateFieldAmountSum();

    //check CASH Payment mode
    if (requiredObj.paymentMode && requiredObj.paymentMode.label === "CASH") {
      if (requiredObj.amount || requiredObj.amount === 0) {
        if (
          totalAmountVal <
          (props.refundAmount > 0 ? props.refundAmount : props?.amount)
        ) {
          let nextFieldAmountVal =
            (props.refundAmount > 0 ? props.refundAmount : props?.amount) -
            totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: selectedFromDate,
          });
        } else {
          trigger();
        }
      }
    }

    //payment mode is UPI
    if (requiredObj.paymentMode && requiredObj.paymentMode.label === "UPI") {
      if (
        (requiredObj.amount || requiredObj.amount === 0) &&
        requiredObj.referenceNumber
      ) {
        let totalAmountVal = calculateFieldAmountSum();

        if (
          totalAmountVal <
            (props.refundAmount > 0 ? props.refundAmount : props?.amount) &&
          customCashError === false
        ) {
          let nextFieldAmountVal =
            (props.refundAmount > 0 ? props.refundAmount : props?.amount) -
            totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: selectedFromDate,
          });
        }
      } else {
        trigger();
      }
    }

    //if the payment mode is one of "CHEQUE" , "CREDIT CARD" , "DD" , "DEBIT CARD"
    if (
      requiredObj.paymentMode &&
      (requiredObj.paymentMode.label === "ECS" ||
        requiredObj.paymentMode.label === "CREDIT CARD" ||
        
        requiredObj.paymentMode.label === "ONLINE" ||
        requiredObj.paymentMode.label === "DEBIT CARD")
    ) {
      if (
        (requiredObj.amount || requiredObj.amount === 0) &&
        requiredObj.referenceNumber &&
        requiredObj.bankName &&
        requiredObj.bankName.label !== " "
      ) {
        let totalAmountVal = calculateFieldAmountSum();

        if (
          totalAmountVal <
            (props.refundAmount > 0 ? props.refundAmount : props?.amount) &&
          customCashError === false
        ) {
          let nextFieldAmountVal =
            (props.refundAmount > 0 ? props.refundAmount : props?.amount) -
            totalAmountVal;
          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: selectedFromDate,
          });
        }
      } else {
        trigger();
      }
    }

    //if the paymentMode is not selected -- that is when it is undefined ; then at that time also trigger the validation.
    if (!requiredObj.paymentMode) {
      trigger();
    }

    //if the paymentMode is selected as blank ; then at that time also trigger the validation.
    if (requiredObj.paymentMode.label === " ") {
      trigger();
    }
  }

  function removePaymentFieldRow(fieldIndex) {
    let requiredObj = watchPaymentCart[fieldIndex];

    let totalSumVal = 0;
    for (let index = 0; index < watchPaymentCart.length; index++) {
      if (watchPaymentCart.length > 1) {
        if (watchPaymentCart[index] && watchPaymentCart[index].amount) {
          if (index !== fieldIndex) {
            totalSumVal =
              Number(totalSumVal) + Number(watchPaymentCart[index].amount);
          }
        }
      } else if (
        watchPaymentCart.length === 1 &&
        watchPaymentCart[index].amount
      ) {
        totalSumVal = Number(watchPaymentCart[index].amount);
      }
    }
    remove(fieldIndex);
  }

  // cash options allow only once
  function detectDuplicateCashOption() {
    let cashCounterIndicator = 0;
    for (let cart of watchPaymentCart) {
      if (cart.paymentMode.label === "CASH") {
        cashCounterIndicator = cashCounterIndicator + 1;
      }
      if (cashCounterIndicator > 1) {
        setCustomCashError(true);
      } else {
        setCustomCashError(false);
      }
    }
  }

  //function that is called after clicking the submit button of the payment info modal
  function onSubmitDataHandler(data) {
    let paymentCart = data.paymentCart;

    for (let formObj of paymentCart) {
      let obj = {
        amount: 0,
        bankId: 0,
        paymentDate: "",
        paymentType: {
          id: 0,
          label: "",
          value: "",
        },
        referenceNumber: "",
      };

      obj.amount = formObj.amount;
      obj.paymentType = formObj.paymentMode;

      if (formObj.hasOwnProperty("referenceNumber")) {
        obj.referenceNumber = formObj.referenceNumber;
      }

      if (formObj.hasOwnProperty("bankName") && formObj["bankName"] !== null) {
        obj.bankId = formObj.bankName.id;
      }

      if (formObj.hasOwnProperty("paymentDate")) {
        obj.paymentDate = formObj.paymentDate;
      }

      paymentInfoList.push(obj);

      setPaymentInfo(paymentInfoList);
      handleOpenConfirmation();
      setOpenBackdrop(true);
    }
  }

  function saveData(paymentInfo) {
    setOpenBackdrop(true);

    if (netPayErrorMessage === "" && paymentInfo !== "") {
      props.submitPaymentInfoModal(paymentInfo);
      handleCloseConfirmation();
      setOpenBackdrop(false);
    }
  }

  return (
    <div className=" bg-white px-6">
      <Modal
        open={props.open}
        onClose={() => {
          // props.handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="grid grid-cols-1 md:grid-cols-1  w-full">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleCloseChild();
                resetPaymentModes();
                reset();
              }}
              style={{
                paddingLeft: 10,
              }}
            />
          </div>
          <div className="row">
            <fieldset className="border border-gray-300 text-left   lg:mx-auto lg:px-2 md:ml-0 md:mr-0  rounded   lg:m-2 ">
              <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                Bill Information
              </legend>
              <form
                onSubmit={handleSubmit(onSubmitDataHandler)}
                className="grid grid-cols-1 md:grid-cols-1  gap-2"
              >
                <div className=" bg-white px-4 ">
                  <>
                    {/* Paitent Inf0 */}

                    {/* Payment Form */}
                    <div className="w-full">
                      <div className="flex gap-2 justify-end">
                        <div className="">
                          Bill Settlement Amount :
                          <span className="font-bold">
                            <span className="px-1">₹</span>
                            {props.refundAmount?.toFixed(2) > 0
                              ? props.refundAmount?.toFixed(2)
                              : props?.amount?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <fieldset className="mx-auto border w-full rounded my-3">
                        <legend className="ml-6 my-0 rounded-xl">
                          <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                            Payment Details
                          </p>
                        </legend>

                        <div className="mx-auto px-6 py-2">
                          {fields.map((field, index) => {
                            let dropdownObj = watch(
                              `paymentCart.[${index}].paymentMode`
                            );

                            return (
                              <Grid container spacing={2} key={field.id}>
                                <>
                                  <Grid
                                    item
                                    lg={2.5}
                                    md={6}
                                    sm={6}
                                    sx={{ marginY: "0.5rem" }}
                                  >
                                    <DropdownField
                                      control={control}
                                      name={`paymentCart.[${index}].paymentMode`}
                                      error={
                                        watchPaymentCart[index].paymentMode
                                          ?.label === "CASH" &&
                                        customCashError === true
                                          ? {
                                              message:
                                                "Cash Allowed only once.",
                                            }
                                          : errors.paymentCart?.[index]
                                              ?.paymentMode
                                      }
                                      label="Payment Type"
                                      dataArray={paymentModeList}
                                      isSearchable={false}
                                      placeholder="Payment Type *"
                                      inputRef={{
                                        ...register(
                                          `paymentCart.[${index}].paymentMode`,
                                          {
                                            onChange: (e) => {
                                              detectDuplicateCashOption();
                                            },
                                          }
                                        ),
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    lg={2}
                                    md={6}
                                    sm={6}
                                    sx={{ marginY: "0.5rem" }}
                                  >
                                    <InputField
                                      name={`paymentCart.[${index}].amount`}
                                      error={
                                        errors.paymentCart?.[index]?.amount
                                      }
                                      variant="outlined"
                                      label="Amount (₹)"
                                      control={control}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      inputRef={{
                                        ...register(
                                          `paymentCart.[${index}].amount`,
                                          {
                                            onChange: (e) => {
                                              handleAmountChange();
                                            },
                                          }
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </>

                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "CHEQUE" ||
                                  dropdownObj.label === "UPI" ||
                                  dropdownObj.label === "ONLINE") ? (
                                  <>
                                    <Grid
                                      item
                                      lg={2}
                                      md={6}
                                      sm={6}
                                      sx={{ marginY: "0.5rem" }}
                                    >
                                      <InputField
                                        name={`paymentCart.[${index}].referenceNumber`}
                                        variant="outlined"
                                        label="Reference Number"
                                        control={control}
                                        error={
                                          errors.paymentCart?.[index]
                                            ?.referenceNumber
                                        }
                                        type={
                                          dropdownObj.label !== "CHEQUE"
                                            ? "text"
                                            : "number"
                                        }
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "UPI" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "CHEQUE" ||
                                  dropdownObj.label === "ONLINE") ? (
                                  <>
                                    <Grid
                                      item
                                      lg={2.5}
                                      md={6}
                                      sm={6}
                                      sx={{ marginY: "0.5rem" }}
                                    >
                                      <DropdownField
                                        control={control}
                                        name={`paymentCart.[${index}].bankName`}
                                        label="Bank Name"
                                        dataArray={bankList}
                                        isSearchable={true}
                                        placeholder="Bank Name *"
                                        searchIcon={true}
                                        error={
                                          errors.paymentCart?.[index]?.bankName
                                        }
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                {/* Date Picker Component */}
                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "CHEQUE" ||
                                  dropdownObj.label === "ONLINE") ? (
                                  <>
                                    <Grid
                                      lg={2}
                                      sm={6}
                                      sx={{
                                        marginY: "1.6rem",
                                        marginLeft: 2,
                                      }}
                                    >
                                      <DatePickerFieldNew
                                        control={control}
                                        name={`paymentCart[${index}].paymentDate`}
                                        label="From Date"
                                        value={new Date()}
                                        onChange={(newValue) => {
                                          setValue(
                                            `paymentCart[${index}].paymentDate`,
                                            newValue
                                          );
                                        }}
                                        disableFuture={true}
                                        disablePast={false}
                                        inputFormat="dd-MM-yyyy"
                                        inputRef={{
                                          ...register(
                                            `paymentCart[${index}].paymentDate`,
                                            {
                                              onChange: (e) => {
                                                if (
                                                  e.target.value.toString()
                                                    .length === 55
                                                ) {
                                                  let dateVal = format(
                                                    e.target.value,
                                                    "yyyy-MM-dd"
                                                  );
                                                  let timeVal =
                                                    dateVal +
                                                    "T" +
                                                    "00:00:00:000Z";
                                                  setSelectedToDate(timeVal);
                                                }
                                              },
                                            }
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                <div className="flex items-center">
                                {showAddIcon === true && index === 0 ? (
                                  <AddOutlinedIcon
                                    className="mt-2 mx-1  rounded-full border-2 border-cyan-600"
                                    onClick={() => {
                                      if (netPayErrorMessage === "") {
                                        appendPaymentField(index);
                                      }
                                    }}
                                  />
                                ) : null}

                                {index > 0 && fields.length > 1 ? (
                                  <>
                                    <RemoveOutlinedIcon
                                      className="mt-2 mx-1 rounded-full border-2 border-red-600"
                                      onClick={() => {
                                        if (fields.length > 1) {
                                          removePaymentFieldRow(index);
                                        }
                                      }}
                                    />
                                    {fields?.length<4?<AddOutlinedIcon
                                      className="mt-2 mx-1  rounded-full border-2 border-cyan-600"
                                      onClick={() => {
                                        if (netPayErrorMessage === "") {
                                          appendPaymentField(index);
                                        }
                                      }}
                                    />:null}
                                  </>
                                ) : null}
                              </div>
                                {/* </Grid> */}
                              </Grid>
                            );
                          })}
                          <span className=" text-red-500">
                            {netPayErrorMessage}
                          </span>
                        </div>
                      </fieldset>

                      <Grid
                        item
                        lg={1.5}
                        md={1.5}
                        className="flex justify-end gap-2 py-2"
                      >
                        <CommonButton
                          label="Cancel"
                          className="border border-customRed text-customRed"
                          onClick={() => {
                            props.handleCloseChild();
                            resetPaymentModes();
                            reset();
                          }}
                        />

                        <CommonButton
                          className="bg-green-700 text-white"
                          label="Save"
                          type="submit"
                          onClick={() => {
                            //sum of all the amounts in the field
                            let totalOfFieldAmounts = calculateFieldAmountSum();

                            if (
                              netPayErrorMessage === "" &&
                              totalOfFieldAmounts === netPayableAmountVal
                            ) {
                              //open the confirmation modal
                              setConfirmationOpen(true);
                            }
                          }}
                        />
                      </Grid>
                    </div>
                  </>
                </div>
              </form>
            </fieldset>
            <CommonBackDrop openBackdrop={openBackdrop} />
          </div>
        </Box>
      </Modal>
      {confirmationOpen ? (
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={handleCloseConfirmation}
          confirmationSubmitFunc={() => {
            handleCloseConfirmation();
            saveData(paymentInfo);
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Save Payment Details?"
          confirmationButtonMsg="Save Payment"
        />
      ) : null}
    </div>
  );
}

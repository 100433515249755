import { yupResolver } from "@hookform/resolvers/yup";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, Chip, Modal, Switch, Tooltip } from "@mui/material";
import { format } from "date-fns";
import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../common/components/CommonTable/CommonDynamicTable";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUnit } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  deleteSlotData,
  fetchDepartmentOfDoctor,
  fetchDoctorsByUnit,
  fetchExstingSlotData,
  fetchWeekDays,
  saveSlotsForDoctor,
} from "../../services/doctor/DoctorScheduleServices";
import AddSlotDetails from "./AddSlotDetails";

const Actions = [
  {
    id: 0,
    action: "Delete",
    isAction: true,
  },
  {
    id: 1,
    action: "Active",
    isAction: true,
  },
  {
    id: 2,
    action: "Create",
    isAction: false,
  },
];

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  width: "95%",
  height: "90%",
  p: 3,
  overflowY: "scroll",
};
const schema = yup.object().shape({
  ///shift//
  unit: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
  ///department//
  doctor: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
});
const CreateScheduleModal = (props) => {
  const location = useLocation();
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const {
    open,
    handleClose,
    privilege,
    existingSlotEditRow,
    setExistingSlotEditRow,
  } = props;
  const defaultValues = {
    unit: null,
    doctor: null,
  };
  const {
    control,
    reset,
    handleSubmit,
    watch,
    register,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const selectedUnit = watch("unit");

  const [selectedSlotDetails, setSelectedSlotDetails] = useState([]);
  const [units, setUnits] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [selectedWeekDaysIds, setSelectedWeekDaysIds] = useState([]);
  //
  const [departments, setDepartments] = useState([]);
  //
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [finalData, setFinalData] = useState();

  const handleCloseConfirmation = () => {
    setOpenConfirmModal(false);
  };
  //
  const [openDeleteSlotConfirmationModal, setopenDeleteSlotConfirmationModal] =
    useState(false);
  ////////table related
  const renderActions = (row, index) => {
    const deleteRow = (index) => {
      if (row.SlotId) {
        setopenDeleteSlotConfirmationModal(true);
        setSelectedRowData(row);
      } else {
        let newTemplateData = [...selectedSlotDetails];
        newTemplateData.splice(index, 1);
        setSelectedSlotDetails(newTemplateData);
      }
    };

    const handleSwitchActivity = (event, row, index) => {
      let temp = selectedSlotDetails;
      temp[index].isActive = event.target.checked;
      setSelectedSlotDetails([...temp]);
    };

    return (
      <div className="flex gap-2 items-center">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "Delete" ? (
                    <Tooltip title="Delete">
                      {
                        <DeleteOutlineOutlinedIcon
                          sx={{ color: "#f44336" }}
                          onClick={() => deleteRow(index)}
                        />
                      }
                    </Tooltip>
                  ) : null}
                  {actions.action === "Active" ? (
                    <Tooltip title="Active/InActive">
                      {
                        <Switch
                          size="small"
                          checked={row.isActive}
                          onChange={(event) =>
                            handleSwitchActivity(event, row, index)
                          }
                        />
                      }
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {
    getUnit();
    getWeekDays();
  }, []);

  useEffect(() => {
    getDoctor();
  }, [selectedUnit]);

  const getUnit = () => {
    fetchUnit()
      .then((response) => response.data)
      .then((res) => {
        //
        setUnits(res.result);
      })
      .catch((response) => {
        //
      });
  };

  const getDoctor = () => {
    selectedUnit?.id &&
      fetchDoctorsByUnit(selectedUnit?.id)
        .then((response) => response.data)
        .then((res) => {
          setDoctors(res.result);
        });
  };

  const getDeptOfDoctor = (doctorId) => {
    doctorId &&
      fetchDepartmentOfDoctor(doctorId)
        .then((response) => response.data)
        .then((res) => {
          setDepartments(res.result);
        });
  };

  const getWeekDays = () => {
    fetchWeekDays()
      .then((response) => response.data)
      .then((res) => {
        setWeekDays(res.result);
      });
  };

  useEffect(() => {
    (existingSlotEditRow !== null || token?.unitId) &&
      setValue("unit", {
        id: token.unitId || existingSlotEditRow.unitId,
        label: token.unitName || existingSlotEditRow.Unit,
        value: token.unitName || existingSlotEditRow.Unit,
      });
    existingSlotEditRow &&
      setValue("doctor", {
        id: existingSlotEditRow.DoctorId,
        label: existingSlotEditRow["Doctor Name"],
        value: existingSlotEditRow["Doctor Name"],
      });
    getDeptOfDoctor(existingSlotEditRow.DoctorId);
  }, []);

  useEffect(() => {
    existingSlotEditRow &&
      selectedUnit &&
      privilege === "Edit" &&
      getExstingSlotData(existingSlotEditRow.DoctorId);
  }, [selectedUnit]);

  const getExstingSlotData = (docId) => {
    if (docId && selectedUnit) {
      fetchExstingSlotData(docId, selectedUnit?.id)
        .then((response) => response.data)
        .then((res) => {
          const updatedSlots = res.result?.map(
            ({ fromTime, toTime, ...item }) => ({
              fromTime: `1900-01-01'T'${fromTime}.000`,
              toTime: `1900-01-01'T'${toTime}.000`,
              ...item,
            })
          );
          console.log("updatedSlots", updatedSlots);

          setSelectedSlotDetails(updatedSlots);
        });
    }
  };

  console.log("selectedSlotDetails", selectedSlotDetails);

  const handleChipClick = (dayId) => {
    const isSelected = selectedWeekDaysIds.some(
      (item) => item.weekDaysId === dayId.weekDaysId
    );

    if (!isSelected) {
      setSelectedWeekDaysIds([...selectedWeekDaysIds, dayId]);
    } else {
      const updatedArray = selectedWeekDaysIds.filter(
        (item) => item.weekDaysId !== dayId.weekDaysId
      );
      setSelectedWeekDaysIds(updatedArray);
    }
  };

  useEffect(() => {}, [selectedWeekDaysIds, selectedSlotDetails]);

  const onSubmit = (data) => {
    if (selectedSlotDetails.length > 0) {
      setOpenConfirmModal(true);
      setFinalData(data);
    } else {
      warningAlert("Please Create Slot...");
    }

    // saveSlotsForDoctor
  };

  const handleFormSubmit = (data) => {
    console.log("data", data, selectedSlotDetails);

    let finalArr = [];

    for (let item of selectedSlotDetails) {
      const finalObj = {
        doctorId: data?.doctor?.id,
        unitId: data?.unit?.id,
        departmentId: item.departmentId,
        doctorShifts: { id: item?.shift_id },
        //  fromTime: format(new Date(item?.fromTime), "HH:mm:ss"),

        fromTime:
          typeof item?.fromTime === "string"
            ? item?.fromTime
                ?.split("T")[1]
                ?.split(".")[0]
                ?.replace(/^'/, "")
                ?.trim()
            : format(new Date(item?.fromTime), "HH:mm:ss"),
        isActive: item.isActive,
        id: item?.SlotId,
        slotsInMinutes: item["Slots In Minutes"],
        //toTime: format(new Date(item?.toTime), "HH:mm:ss"),
        toTime:
          typeof item?.toTime === "string"
            ? item?.toTime
                ?.split("T")[1]
                ?.split(".")[0]
                ?.replace(/^'/, "")
                ?.trim()
            : format(new Date(item?.toTime), "HH:mm:ss"),
        weekDaysId: { id: item?.weekDaysId },
      };

      finalArr.push(finalObj);
    }

    setOpenBackdrop(true);

    // saveSlotsForDoctor

    saveSlotsForDoctor(location?.state?.menuId, "Create", finalArr)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenConfirmModal(false);
        setOpenBackdrop(false);
        reset();
        setSelectedSlotDetails([]);
        setSelectedWeekDaysIds([]);
        handleClose();
        setExistingSlotEditRow(null);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenConfirmModal(false);
        setOpenBackdrop(false);
      });
  };

  const handleDeleteSlot = (row) => {
    row &&
      row !== null &&
      deleteSlotData(Number(row.SlotId))
        .then((response) => response.data)
        .then((res) => {
          setSelectedSlotDetails(
            selectedSlotDetails.filter((item) => item.SlotId !== row.SlotId)
          );
          successAlert(res.message);
        });
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              setExistingSlotEditRow(null);
            }}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="flex justify-center font-semibold text-lg">
                Create Schedule
              </div>
              <div className="grid lg:grid-cols-6 md:grid-cols-4 gap-3">
                <div className="col-span-2">
                  <DropdownField
                    control={control}
                    name="unit"
                    label="Unit"
                    error={errors.unit}
                    dataArray={units}
                    isSearchable={false}
                    isDisabled={privilege === "Edit" ? true : false}
                    isClearable={false}
                    placeholder="Unit"
                    inputRef={{
                      ...register("unit", {
                        onChange: (e) => {
                          // getDoctor(e.target.value.id)
                          resetField("doctor");
                        },
                      }),
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <SearchDropdown
                    control={control}
                    error={errors.doctor}
                    isDisabled={privilege === "Edit" ? true : false}
                    name="doctor"
                    label="Doctor"
                    dataArray={doctors}
                    handleInputChange={(e) => {
                      getDoctor(e);
                    }}
                    isSearchable={true}
                    searchIcon={true}
                    placeholder="Doctor *"
                    isClearable={true}
                    inputRef={{
                      ...register("doctor", {
                        onChange: (e) => {
                          //
                          if (e.target.value !== null) {
                            getExstingSlotData(e?.target?.value?.id);
                            getDeptOfDoctor(e?.target?.value?.id);
                          } else {
                            setSelectedSlotDetails([]);
                            setSelectedWeekDaysIds([]);
                            setDepartments([]);
                          }
                        },
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="flex font-semibold text-sm mt-3 mb-2">
                Weekdays
              </div>

              <div className=" grid grid-cols-3 gap-2">
                <div className="flex gap-2">
                  {weekDays &&
                    weekDays.map((data) => {
                      return (
                        <Chip
                          label={data.label}
                          onClick={() =>
                            handleChipClick({
                              weekDaysId: data.id,
                              "Week Day": data.label,
                            })
                          }
                          variant={
                            selectedWeekDaysIds.some(
                              (item) => item.weekDaysId === data.id
                            )
                              ? "filled"
                              : "outlined"
                          }
                          color={data.label === "Sunday" ? "error" : "primary"}
                        />
                      );
                    })}
                </div>
                {/* </Paper> */}
              </div>

              <div className="flex font-semibold text-sm mt-3 mb-2">Slots</div>

              <AddSlotDetails
                selectedSlotDetails={selectedSlotDetails}
                setSelectedSlotDetails={setSelectedSlotDetails}
                selectedWeekDaysIds={selectedWeekDaysIds}
                setSelectedWeekDaysIds={setSelectedWeekDaysIds}
                //
                departments={departments}
              />

              <div>
                {selectedSlotDetails.length > 0 && (
                  <CommonDynamicTable
                    data={selectedSlotDetails}
                    actionsRequired={true}
                    renderActions={renderActions}
                    highlightRow={false}
                    removeHeaders={[
                      "fromTime",
                      "toTime",
                      "weekDaysId",
                      "shift_id",
                      "departmentId",
                      "isActive",
                      "unitId",
                      "shift_id",
                      "doctorId",
                      "DoctorName",
                      "Unit",
                      "SlotId",
                    ]}
                    tableClass="rounded lg:h-96 md:h-72"
                  />
                )}
              </div>

              {Actions.some(
                (item) =>
                  !item.isAction &&
                  item.action === "Create" &&
                  selectedSlotDetails?.length > 0
              ) ? (
                <div className="mt-3 flex justify-end">
                  <CommonButton
                    label="Save"
                    type="submit"
                    className="bg-customGreen text-white"
                  />
                </div>
              ) : null}
            </div>
          </form>
        </Box>
      </Modal>

      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openConfirmModal}
        confirmationHandleClose={handleCloseConfirmation}
        confirmationSubmitFunc={() => handleFormSubmit(finalData)}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure ?"
        confirmationButtonMsg="Add"
      />

      {/* /// modal */}
      <Modal open={openDeleteSlotConfirmationModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "40%", lg: "25%" },
            // minWidth:"25%",
            // height: "30%",
            bgcolor: "background.paper",
            borderRadius: 2,
            border: "none",
            "&:focus": {
              outline: "none",
            },
            // boxShadow: 24,
            // p: 4,
          }}
        >
          <div className="mx-3 mb-3 mt-2 items-center align-middle">
            <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
              Doctor Schedule
            </div>

            <hr className="border mx-1  border-blue-900" />
            <div className=" p-2 rounded  text-black  whitespace-nowrap">
              Do You Want Delete Slot ?
            </div>
            <div className="flex justify-end my-2 gap-3">
              <div>
                <CommonButton
                  className="border border-customRed text-customRed"
                  label="Cancel"
                  onClick={() => {
                    setopenDeleteSlotConfirmationModal(false);
                  }}
                />
              </div>

              <div>
                <div>
                  <CommonButton
                    type="submit"
                    label="Ok"
                    className="bg-customGreen text-white "
                    onClick={() => {
                      handleDeleteSlot(selectedRowData);
                      setopenDeleteSlotConfirmationModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreateScheduleModal;

import React, { useState } from "react";
import AddressDetails from "./AddressDetails";
import PersonalInfo from "./PersonalInfo";
import RepresentativeDetails from "./RepresentativeDetails";
import Other from "./Other";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useFormContext } from "react-hook-form";
import PatientOfficeDetails from "./PatientOfficeDetails";
import { useLocation } from "react-router-dom";

const PatientDetails = (props) => {
  const { bedDetailsFromBedModal } = props;
  let location = useLocation();
  const [expandPanal1, setExpandPanal1] = useState(true);
  const [expandPanal2, setExpandPanal2] = useState(true);
  const [expandPanal3, setExpandPanal3] = useState(true);
  const [expandPanal4, setExpandPanal4] = useState(true);
  const [expandPanal5, setExpandPanal5] = useState(true);

  const handleChangePanal1 = () => {
    setExpandPanal1(!expandPanal1);
  };

  const handleChangePanal3 = () => {
    setExpandPanal3(!expandPanal3);
  };
  const handleChangePanal4 = () => {
    setExpandPanal4(!expandPanal4);
  };

  const handleChangePanal5 = () => {
    setExpandPanal5(!expandPanal5);
  };

  const {
    profilePic,
    setProfilePic,
    setProfilePicName,
    setIsNri,
    searchPatientId,
    setsearchPatientId,
    setSearchedPatientData,
    isDisableSearch,
    setIsDisableSearch,
    patchData,
    setPatchData,

    isForEdit,
  } = props;

  const { watch } = useFormContext();

  var IsEmergency = watch("emergency");

  IsEmergency === true ? (IsEmergency = 1) : (IsEmergency = 0);

  return (
    <div className="py-0">
      {/* /// Emergency checkbox //// */}
      <div className=" flex items-center text-center">
        <div className="mb-1">
          {bedDetailsFromBedModal !== null &&
          bedDetailsFromBedModal !== undefined ? (
            <div className="grid grid-cols-2 lg:flex lg:items-center gap-1 lg:gap-4 my-auto ml-2">
              <div className="border-r border-black">
                <p className="text-sm font-bold text-[#676060]">
                  Ward :
                  <span className=" mx-2 font-semibold text-[#1976d2]">
                    {bedDetailsFromBedModal && bedDetailsFromBedModal.Ward}
                  </span>
                </p>
              </div>
              <div className="border-r border-black">
                <p className="text-sm font-bold text-[#676060]">
                  Bed Category :
                  <span className=" mx-2 font-semibold text-[#1976d2]">
                    {bedDetailsFromBedModal && bedDetailsFromBedModal.Class}
                  </span>
                </p>
              </div>
              <div className="">
                <p className="text-sm font-bold text-[#676060]">
                  Bed No. :
                  <span className=" mx-2 font-semibold text-[#1976d2]">
                    {bedDetailsFromBedModal && bedDetailsFromBedModal.BedNo}
                  </span>
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* /// Personal Info //// */}
      <Accordion
        expanded={expandPanal1}
        onChange={handleChangePanal1}
        elevation={6}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          sx={{
            "&.Mui-expanded": {
              marginBottom: "-1rem",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <p className="font-bold tracking-wide text-sm font-Poppins">
            Personal Details
          </p>
        </AccordionSummary>
        <AccordionDetails>
          {/* <PersonalInfo /> */}
          <PersonalInfo
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            setProfilePicName={setProfilePicName}
            searchPatientId={searchPatientId}
            setsearchPatientId={setsearchPatientId}
            setSearchedPatientData={setSearchedPatientData}
            IsEmergency={IsEmergency}
            location={location}
            isDisableSearch={isDisableSearch}
            setIsDisableSearch={setIsDisableSearch}
            patchData={patchData}
            setPatchData={setPatchData}
            setDuplicateAdhar={props.setDuplicateAdhar}
            //
            setCasualtyDate={props.setCasualtyDate}
            setCasualtyTime={props.setCasualtyTime}
            //
            isForEdit={isForEdit}
          />
          <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />
          {/* // Address Information // */}
          <p className="font-bold tracking-wide text-sm my-3 font-Poppins">
            Address Details
          </p>
          <AddressDetails setIsNri={setIsNri} />
        </AccordionDetails>
      </Accordion>
      {/* /// Patient Office Details  //// */}
      <Accordion
        expanded={expandPanal4}
        onChange={handleChangePanal4}
        elevation={6}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          sx={{
            "&.Mui-expanded": {
              marginBottom: "-1rem",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <p className="font-bold tracking-wide text-sm font-Poppins">
            Patient Office Details
          </p>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Other /> */}
          <PatientOfficeDetails />
        </AccordionDetails>
      </Accordion>
      {/* /// RepresentativeDetails  //// */}
      <Accordion
        expanded={expandPanal3}
        onChange={handleChangePanal3}
        elevation={6}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          sx={{
            "&.Mui-expanded": {
              marginBottom: "-1rem",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <p className="font-bold tracking-wide text-sm font-Poppins">
            Representative Details
          </p>
        </AccordionSummary>
        <AccordionDetails>
          {/* <RepresentativeDetails /> */}
          <RepresentativeDetails />
        </AccordionDetails>
      </Accordion>
      {/* /// Other  //// */}
      <Accordion
        expanded={expandPanal5}
        onChange={handleChangePanal5}
        elevation={6}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          sx={{
            "&.Mui-expanded": {
              marginBottom: "-1rem",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <p className="font-bold tracking-wide text-sm font-Poppins">Other</p>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Other /> */}
          <Other />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PatientDetails;

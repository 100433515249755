import React from "react";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Tooltip } from "@mui/material";
import { fetchAllVisitList } from "../../services/patientappointmentservices/PatientVisitListServices";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import EmrLite from "../EmrLite/EmrLite";
import { EMRIcon } from "../../../assets/CommonAssets";

function PatientQueue() {
  const location = useLocation();
  const schema = yup.object().shape({});
  const defaultValues = {};

  const {
    control,
    reset,
    register,
    watch,
    setValue,
    formState: {},
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const todayDate = new Date();
  console.log(todayDate, "todayDatetodayDatetodayDate");
  

  const [options, setOptions] = React.useState([]);
  const [loadingSpinner, setLoadingSpinner] = React.useState(false);
  const [recordWarning, showRecordWarning] = React.useState(false);
  const [data, setData] = React.useState({ result: [] });
  const [dataResult, setDataResult] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const [userActions, setUserActions] = React.useState([]);
  const [openEmrModal, setOpenEmrModal] = React.useState(false);
  const [selectedRow, setselectedRow] = React.useState(null);

 

  const removeHeaders = [
    "Id",
    "PatientId",
    "Cabin",
    "Authority Name",
    "PoliceStation",
    "VisitId",
    "WaitingTime",
    "isDeath",
    "MLC No",
    "BodyHandOverDate",
    "BodyHandOverTime",
    "GenderId",
    "mlcDetails",
    "EmergencyId",
    "AuthorutyBuckleNo",
    "BodyHandOverBuckelNo",
    "BodyHandOverPoliceStation",
    "cmoHandingOverBody",
    "Reporting Date",
    "Reporting Time",
    "mlcTypeId",
    "broughtBy",
    "BodyHandOver Time",
    "BodyHandOver Date",
    "mlcType",
    "TariffId",
    "UnitId",
    "MLCId",
    "GenderId",
    "TariffId",
    "PatientCatagoryId",
    "PatientSourceId",
    "CompanyId",
    "DepartmentId",
  ];

  function handleChange(searchString) {
    // let searchObj = {
    //   doctorId: doctorVal ? doctorVal.id : null,
    //   fromDate: getFromDate,
    //   searchString: searchString,
    //   toDate: getToDate,
    // };
    // searchVisitList(searchObj)
    //   .then((response) => response.data)
    //   .then((res) => {
    //     setOptions(res?.result);
    //   })
    //   .catch((response) => {});
  }

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-2 items-center cursor-pointer">
        {userActions &&
          userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-1 items-center cursor-pointer">
                    {actions.action === "Edit" ? (
                      <>
                        <Tooltip title="EMR">
                          <button
                            onClick={() => {
                              setOpenEmrModal(true);
                              setselectedRow(row)
                            }}
                          >
                            <EMRIcon />
                          </button>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : null}
            </>
          ))}
      </div>
    );
  };

  const renderInput = (row, index, header) => {
    return <></>;
  };

  const fetchTableList = () => {
    let listObj = {
      cabin: null,
      doctorId: null,
      fromDate: "2024-11-15T07:06:54.000Z",
      // menuId: location?.state?.menuId,
      menuId: 233,
      page: 0,
      searchId: searchString?.id ? searchString?.id : "",
      searchString: "",
      size: 25,
      toDate: todayDate,
      unitId: 2,
    };

    // cabin: null;
    // doctorId: null;
    // fromDate: "2024-11-10T03:51:37.000Z";
    // menuId: 233;
    // page: 0;
    // searchId: "";
    // searchString: "";
    // size: 25;
    // toDate: "2024-12-05T03:51:37.384Z";
    // unitId: 2;

    setLoadingSpinner(true);
    showRecordWarning(false);
    fetchAllVisitList(listObj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        setDataResult(res.result);
        setLoadingSpinner(false);
        setUserActions(res.actions);
      })
      .catch(() => {
        setLoadingSpinner(false);
        showRecordWarning(true);
      });
  };

  React.useEffect(() => {
    fetchTableList();
  }, []);

  return (
    <div className="mt-10 mx-auto w-full">
      <div className="py-2">
        <p className="text-center text-2xl text-gray-700 font-Poppins">
          Patient Queue
        </p>
      </div>
      <div className="grid gap-2 md:grid-cols-4 p-1">
        <SearchBar
          searchIcon={true}
          isClearable={true}
          name="searchPatientName"
          placeholder="Search by Name / MR No."
          dataArray={options}
          isSearchable={true}
          handleInputChange={handleChange}
        />
      </div>

      {loadingSpinner ? (
        <div className="grid justify-center items-center pt-12">
          <LoadingSpinner />
        </div>
      ) : null}

      {dataResult.length > 0 && loadingSpinner === false ? (
        <CommonDynamicTableNew
          dataResult={dataResult}
          highlightRow={false}
          renderActions={renderActions}
          renderInput={renderInput}
          removeHeaders={removeHeaders}
        />
      ) : null}

      {openEmrModal && (
        <EmrLite
          open={openEmrModal}
          close={() => {
            setOpenEmrModal(false);
          }}
          selectedRow={selectedRow}
        />
      )}
    </div>
  );
}

export default PatientQueue;


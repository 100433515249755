import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import MedicationIcon from "@mui/icons-material/Medication";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  BarcodeIcon,
  DisablePrintIcon,
  PrintOnIcon,
  WhatsAppDisableIcon,
  WhatsAppEnableIcon,
} from "../../../../assets/icons/CustomIcons";
import QueueManagement from "../../../../assets/icons/QueueManagement.svg";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import BarCodePrint from "../../../../common/components/printBarcode/BarCodePrint";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import {
  cancelTest,
  cancelWorkOrder,
  counterBookPrint,
  fetchCategoryOptions,
  fetchPatientOptions,
  fetchWorkOrderTests,
  fetchUnitOptions,
  generatePathologyReport,
  getPatientInfo,
  getWorkOrderViewListing,
  labReportsCommonPrint,
  startEndProcedure,
  getTestParameterReportDetails,
  sendReportOnWhatsapp,
  sendReportOnEmail,
  cancelSampleCollectionTest,
  recollectSampleCollectionTest,
  saveRetestReason,
} from "../../Services/Pathalogy/WorkOrderViewServices";
import PathologyQueueModal from "./PathologyQueueModal";
import ReportDetailsModal from "./ReportDetailsModal";
import {
  CancelSampleCollectionIcon,
  DetailedReportIcon,
  DisableEditIcon,
  EditIcon,
  EmailDisableIcon,
  EmailEnableIcon,
  LIMSRetestDisableIcon,
  LIMSRetestEnableIcon,
  LIMSRetestIcon,
  RecollectSampleCollectionIcon,
  TATIcon,
} from "../../../../assets/CommonAssets";
import LIMSHistoryModal from "../../../common/LIMSHistoryModal";
import { format } from "date-fns";
import InputArea from "../../../../common/components/FormFields/InputArea";
import PatientTestTATModal from "./PatientTestTATModal";
import { fetchCompany } from "../../../../commonServices/miscellaneousServices/MiscServices";
const patientType = [
  {
    id: 4,
    label: "All",
    value: "All",
  },
  {
    id: 1,
    label: "IPD",
    value: "IPD",
  },
  {
    id: 0,
    label: "OPD",
    value: "OPD",
  },
];
const status = [
  {
    id: 2,
    label: "All",
    value: "All",
  },
  {
    id: 1,
    label: "Authorized",
    value: "Authorized",
  },
  {
    id: 0,
    label: "Unauthorized",
    value: "Unauthorized",
  },
  {
    id: 3,
    label: "Pending",
    value: "Pending",
  },
  {
    id: 4,
    label: "Reauthorized",
    value: "Reauthorized",
  },
];
const testTypeOptions = [
  {
    id: 0,
    value: "Normal",
    label: "Normal",
  },
  {
    id: 1,
    value: "Retest",
    label: "Retest",
  },
  {
    id: 2,
    value: "Dummy",
    label: "Dummy",
  },
  {
    id: 3,
    value: "All",
    label: "All",
  },
];
const testStatus = [
  {
    id: 0,
    label: "All",
    value: "All",
  },
  {
    id: 1,
    label: "Sample Collected",
    value: "Sample Collected",
  },
  {
    id: 2,
    label: "Result Added",
    value: "Result Added",
  },
  {
    id: 3,
    label: "Result Authorized",
    value: "Result Authorized",
  },
  {
    id: 4,
    label: "Reports Delivered",
    value: "Reports Delivered",
  },
];

const Actions = [
  {
    id: 0,
    action: "print",
    isAction: true,
  },
  {
    id: 1,
    action: "cancel",
    isAction: true,
  },
  {
    id: 2,
    action: "ok",
    isAction: false,
  },
  {
    id: 3,
    action: "procedure",
    isAction: false,
  },
];

const testActions = [
  {
    id: 0,
    action: "print",
    isAction: true,
  },
  {
    id: 1,
    action: "barcode",
    isAction: true,
  },
  {
    id: 2,
    action: "cancel",
    isAction: true,
  },
  {
    id: 3,
    action: "medicine",
    isAction: true,
  },
  {
    id: 4,
    action: "select",
    isAction: true,
  },
];

function WorkOrderView(props) {
  const { updateComponent } = props;
  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [pdfOpenBackdrop, setPdfOpenBackdrop] = useState(false);
  const [orderIdToPost, setOrderIdToPost] = useState(null);
  //
  const [patientDetails, setPatientDetails] = useState();
  const [searchBar, setSearchBar] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = useState([]);
  const [newDataResult, setNewDataResult] = useState([]);
  const [newTestArray, setNewTestArray] = useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [testsArray, setTestsArray] = useState([]);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [openReportDetailsModal, setOpenReportDetailsModal] = useState(false);
  const [openPatientTATModal, setOpenPatientTATModal] = useState(false);
  const [checkedArr, setCheckedArr] = useState([]);
  const [authorizationArr, setAuthorizationArr] = useState([]);
  const [checkedIsmicroArr, setCheckedIsmicroArr] = useState([]);
  const [postArr, setPostArr] = useState([]);
  const [openQueueModal, setOpenQueueModal] = useState(false);
  const [forStatus, setForStatus] = useState(null);
  const [confirmText, setConfirmText] = useState(null);
  const [procedureConfirmation, setProcedureConfirmation] = useState(false);
  const [procedureStatusCode, setProcedureStatusCode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [cancelTestConfirmation, setCancelTestConfirmation] = useState(false);
  const [openOrderPrintModal, setOpenOrderPrintModal] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [reportPdf, setReportPdf] = useState("");
  const [urlforPrint, setUrlforPrint] = useState();
  const [privilege, setPrivilege] = useState("");
  const [cancelOrderConfirmation, setCancelOrderConfirmation] = useState(false);
  const [testPrintArray, setTestPrintArray] = useState([]);
  const [openGenerateReport, setOpenGenerateReport] = useState(false);
  const [printResult, setPrintResult] = useState(null);
  const [printPatientInfo, setPrintpatientInfo] = useState(null);
  const [images, setImages] = useState([]);
  const [cultureTestImages, setCultureTestImages] = useState([]);
  const [templateImages, setTemplateImages] = useState([]);
  const [reportImages, setReportImages] = useState([]);
  const [selectedWorkOrderIndex, setSelectedWorkOrderIndex] = useState(null);
  const [allTest, setAllTest] = useState([]);
  const [openLIMSHistoryModal, setOpenLIMSHistoryModal] = useState(false);
  const [pathHistoryData, setPathHistoryData] = useState([]);
  const [LIMSType, setLIMSType] = useState("");
  const [isWaiting, setIsWaiting] = useState(null);
  const [isView, setIsView] = useState(false);
  const [sampleProcedure, setSampleProcedure] = useState("");
  const [openSampleAction, setOpenSampleAction] = useState(false);
  const [actionInfo, setActionInfo] = useState(null);
  const handleCloseOrderPrintModal = () => {
    setOpenOrderPrintModal(false);
  };
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [testLoadingSpinner, setTestLoadingSpinner] = useState(false);
  const [nonAuthorizedTestLoadingSpinner, setNonAuthorizedTestLoadingSpinner] =
    useState(false);
  const [messageConfirmation, setMessageConfirmation] = useState(false);
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  const [nonAuthorizedcount, setNonAuthorizedCount] = useState();
  const [nonAuthorizedPage, setNonAuthorizedPage] = useState(0);
  const [nonAuthorizedRowsPerPage, setNonAuthorizedPerPage] = useState(30);
  const [openRetestModal, setOpenRetestModal] = useState(false);
  const [listingObject, setListingObject] = useState({
    category: null,
    fromDate: null,
    isAuthority: 2,
    isCancelled: false,
    opdIpd: 4,
    orderId: 0,
    testType: 3,
    mrno: null,
    toDate: null,
    unitId: 2,
    size: rowsPerPage,
    menuId: location?.state?.menuId,
    sampleNo: null,
    patientId: null,
    searchString: "",
    isNonAuthorisedTests: false,
    companyId: null,
  });

  let defaultUnit = {
    id: 2,
    label: "IMH(FATIMA NAGAR)",
    value: "IMH(FATIMA NAGAR)",
  };

  const defaultValues = {
    patientType: 4,
    status: 2,
    unit: defaultUnit,
    category: null,
    testType: null,
    mrno: null,
    fromDate: new Date(),
    toDate: new Date(),
    provisionalRemark: "",
    chiefComplaints: "",
    cancelledOrder: false,
    nonAuthorized: false,
    retestReason: "",
    companyId: null,
  };
  const { control, watch, reset, getValues, register, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const getCategory = watch("category");
  const getCompany = watch("companyId");
  const getTestType = watch("testType");
  const getMrno = Number(watch("mrno"));
  const getUnit = watch("unit");
  const getPatientType = watch("patientType");
  const getStatus = watch("status");
  const getCancelledOrder = watch("cancelledOrder");
  const getNonAuthorized = watch("nonAuthorized");
  const selectedFromDate = format(
    new Date(watch("fromDate")),
    "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"
  );
  const selectedToDate = format(
    new Date(watch("toDate")),
    "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'"
  );
  let getSampleNo = watch("sampleNo");
  let getRetestReason = watch("retestReason");

  console.log("getRetestReason", getRetestReason);

  // const filterData = () => {
  //   let requiredObj = {};

  //   if (getCancelledOrder === true) {
  //     requiredObj.isCancelled = true;
  //   } else {
  //     requiredObj.isCancelled = false;
  //   }

  //   requiredObj.category = getCategory ? getCategory.id : 0;
  //   requiredObj.fromDate = selectedFromDate;
  //   requiredObj.isAuthority = getStatus ? getStatus : null;
  //   requiredObj.opdIpd = getPatientType ? getPatientType : null;
  //   requiredObj.toDate = selectedToDate;
  //   requiredObj.testType = getTestType ? getTestType.id : 3;
  //   requiredObj.unitId = getUnit ? getUnit.id : null;
  //   requiredObj.page = page;
  //   requiredObj.size = rowsPerPage;
  //   requiredObj.orderId = patientDetails ? patientDetails.OrderId : 0;

  //   getWorkOrderViewListing(requiredObj)
  //     .then((response) => response.data)
  //     .then((res) => {
  //       setData(res);
  //       setCount(res.count);
  //       setDataResult(res.result);
  //     });
  // };

  // useEffect(() => {
  //   if (isWaiting === false) {
  //     fetchWorkOrderViewList();
  //   }
  // }, [isWaiting]);

  useEffect(() => {
    getCategoryOptions();
    getUnitOptions();
    getCompanyOptions();
  }, []);

  console.log("selectedRow123", selectedRow);

  useEffect(() => {
    if (selectedRow !== null) {
      fetchAllTest();
      setTestsArray([]);
      setSelectedTestId(null);
      setForStatus(null);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (page !== 0) {
      setTestsArray([]);
    }
  }, [page]);

  // useEffect(() => {
  //   if (patientDetails !== undefined) {
  //     fetchFirstWorkOrderViewList();
  //     // fetchWorkOrderViewList();
  //     setTestsArray([]);
  //   }
  // }, [patientDetails]);

  console.log("patientDetails123", selectedRow);

  const handleChange = (string) => {
    let searchObj = {
      searchString: string,
      opdIpd: getPatientType,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
    };
    if (string !== "") {
      setSearchBar(string);
      fetchPatientOptions(searchObj)
        .then((response) => response.data)
        .then((res) => {
          setSearchOptions(res.result);
        });
    }
  };

  function autoSelectedValue(value) {
    console.log("value123", value);
    if (value !== null) {
      setPatientDetails(value);
      setSearchBar("");
    } else if (value === null) {
      setPatientDetails(null);
    }
  }

  function getCategoryOptions() {
    fetchCategoryOptions()
      .then((response) => response.data)
      .then((res) => {
        setCategoryOptions(res.result);
      });
  }

  function getCompanyOptions() {
    fetchCompany().then((response) => {
      setCompanyOptions(response.data.result);
    });
  }

  function getUnitOptions() {
    fetchUnitOptions()
      .then((response) => response.data)
      .then((res) => {
        setUnitOptions(res.result);
      });
  }

  const fetchPatientInfo = (row) => {
    getPatientInfo(row?.OrderDtlsId, row?.OpdIpd)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfo(res.result);
      });
  };

  console.log("selectedTestId321", selectedTestId);

  const fetchWorkOrderViewList = (forPaginantion) => {
    const defaultParams = {
      category: getCategory ? getCategory.id : 0,
      companyId: getCompany ? getCompany?.id : null,
      fromDate: selectedFromDate,
      isAuthority: Number(getStatus ? getStatus : 2),
      isCancelled: getCancelledOrder === true ? true : false,
      opdIpd: getPatientType ? getPatientType : 4,
      orderId: patientDetails ? patientDetails.OrderId : 0,
      testType: getTestType ? getTestType?.id : 3,
      mrno: getMrno ? getMrno : null,
      toDate: selectedToDate,
      unitId: getUnit ? getUnit.id : 2,
      size: getNonAuthorized === true ? nonAuthorizedRowsPerPage : rowsPerPage,
      menuId: location?.state?.menuId,
      sampleNo: getSampleNo ? Number(getSampleNo) : null,
      patientId: patientDetails ? patientDetails?.id : null,
      searchString: searchBar ? searchBar : "",
      isNonAuthorisedTests: getNonAuthorized,
    };
    if (getNonAuthorized === false) {
      defaultParams.page = page + 1;
    } else {
      defaultParams.page = nonAuthorizedPage + 1;
    }
    // if (getNonAuthorized === true) {
    //   setNonAuthorizedTestLoadingSpinner(true);
    // } else if (getNonAuthorized === false) {
    //   setLoadingSpinner(true);
    // }
    getWorkOrderViewListing(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        // if (forPaginantion) {
        //   if (getNonAuthorized === true) {
        //     setNonAuthorizedTestLoadingSpinner(false);
        //     setLoadingSpinner(false);
        //     setTestsArray((prevData) => [...prevData, ...res.result]);
        //   } else if (getNonAuthorized === false) {
        //     setNewDataResult((prevData) => [...prevData, ...res.result]);
        //     setLoadingSpinner(false);
        //     setNonAuthorizedTestLoadingSpinner(false);
        //   }
        // } else {
        if (getNonAuthorized === true) {
          // setNonAuthorizedTestLoadingSpinner(false);
          // setLoadingSpinner(false);
          setNewTestArray(res.result);
        } else if (getNonAuthorized === false) {
          setNewDataResult(res.result);
          // setLoadingSpinner(false);
          // setNonAuthorizedTestLoadingSpinner(false);
        }
        // }
        if (getNonAuthorized === true) {
          setNonAuthorizedCount(res?.result?.length);
        } else if (getNonAuthorized === false) {
          setCount(res?.result?.length);
        }
        setUserActions(res.actions);
      });
  };

  const fetchFirstWorkOrderViewList = () => {
    // setIsWaiting(true);
    const defaultParams = {
      category: getCategory ? getCategory.id : 0,
      companyId: getCompany ? getCompany?.id : null,
      fromDate:
        updateComponent === true
          ? format(new Date(), "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'")
          : selectedFromDate,
      isAuthority: Number(getStatus ? getStatus : 2),
      isCancelled: getCancelledOrder === true ? true : false,
      opdIpd: getPatientType ? getPatientType : 4,
      orderId: patientDetails ? patientDetails.OrderId : 0,
      testType: getTestType ? getTestType?.id : 3,
      mrno: getMrno ? getMrno : null,
      toDate:
        updateComponent === true
          ? format(new Date(), "yyyy-MM-dd'T'hh:mm:ss.SSS'Z'")
          : selectedToDate,
      unitId: getUnit ? getUnit.id : 2,
      size: getNonAuthorized === true ? nonAuthorizedRowsPerPage : rowsPerPage,
      menuId: location?.state?.menuId,
      sampleNo: getSampleNo ? Number(getSampleNo) : null,
      patientId: patientDetails ? patientDetails?.id : null,
      searchString: searchBar ? searchBar : "",
      isNonAuthorisedTests: getNonAuthorized,
    };
    if (getNonAuthorized === false) {
      defaultParams.page = 0;
    } else {
      defaultParams.page = 0;
    }
    if (getNonAuthorized === true) {
      setNonAuthorizedTestLoadingSpinner(true);
    } else if (getNonAuthorized === false) {
      setLoadingSpinner(true);
    }
    getWorkOrderViewListing(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          // setIsWaiting(false);
        }
        // if (forPaginantion) {
        //   if (getNonAuthorized === true) {
        //     setNonAuthorizedTestLoadingSpinner(false);
        //     setLoadingSpinner(false);
        //     setTestsArray((prevData) => [...prevData, ...res.result]);
        //   } else if (getNonAuthorized === false) {
        //     setDataResult((prevData) => [...prevData, ...res.result]);
        //     setLoadingSpinner(false);
        //     setNonAuthorizedTestLoadingSpinner(false);
        //   }
        // } else {
        if (getNonAuthorized === true) {
          setNonAuthorizedTestLoadingSpinner(false);
          setLoadingSpinner(false);
          setTestsArray(res.result);
        } else if (getNonAuthorized === false) {
          setDataResult(res.result);
          setLoadingSpinner(false);
          setNonAuthorizedTestLoadingSpinner(false);
        }
        // }
        if (getNonAuthorized === true) {
          setNonAuthorizedCount(res?.result?.length);
        } else if (getNonAuthorized === false) {
          setCount(res?.result?.length);
        }
        setUserActions(res.actions);
      });
  };

  // useEffect(() => {
  //   fetchFirstWorkOrderViewList();
  // }, []);

  console.log("testsArray123", testsArray);

  useEffect(() => {
    setDataResult([...dataResult, ...newDataResult]);
    setNewDataResult([]);
  }, [page]);

  useEffect(() => {
    setTestsArray([...testsArray, ...newTestArray]);
    setNewTestArray([]);
  }, [nonAuthorizedPage]);

  function fetchAllTest() {
    let obj = {};
    obj.orderId = selectedRow?.OrderId;
    obj.isAuthority = Number(getStatus ? getStatus : 2);
    if (getCategory) {
      obj.categoryId = getCategory.id;
    } else {
      obj.categoryId = 0;
    }
    obj.status = 0;

    setTestLoadingSpinner(true);
    fetchWorkOrderTests(obj)
      .then((response) => response.data)
      .then((res) => {
        setTestsArray(res.result?.filter((test) => test.ishideorder !== true));
        setTestLoadingSpinner(false);
      })
      .catch((error) => {
        setTestLoadingSpinner(false);
      });
  }

  const handleOpen = () => {
    setOpenReportDetailsModal(true);
  };

  const handleClose = () => {
    setOpenReportDetailsModal(false);
  };

  function forProcedure(e) {
    setOpenBackdrop(true);
    let status;
    if (forStatus === "Start Procedure") {
      status = true;
    } else if (forStatus === "End Procedure") {
      status = false;
    }
    startEndProcedure(status, selectedTestId?.OrderDtlsId)
      .then((response) => {
        setProcedureStatusCode(response.statusCode);
        const testIndex = testsArray.findIndex(
          (val) => val.OrderDtlsId === response.data.result?.OrderDtlsId
        );
        testsArray[testIndex] = response.data.result;
        setSelectedTestId(response.data.result);
        setProcedureConfirmation(false);
        setForStatus(null);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        setOpenBackdrop(false);
      });
  }

  useEffect(() => {
    setForStatus(null);
  }, [selectedTestId]);

  function forCancelOrder() {
    cancelWorkOrder(selectedRow?.OrderId, location?.state?.menuId, privilege)
      .then((response) => {
        successAlert(response.data.message);
        setCancelOrderConfirmation(false);
        fetchFirstWorkOrderViewList();
        // fetchWorkOrderViewList();
        fetchAllTest();
        setPage(0);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  }

  function handleOpenPrintModal(row) {
    setPdfOpenBackdrop(true);
    counterBookPrint(row?.OrderId).then((response) => {
      setPdfOpenBackdrop(false);
      setOpenOrderPrintModal(true);
      setUrlforPrint(response);
    });
  }

  const renderTableHeader = () => {
    return (
      <div className="flex justify-between w-full">
        <div className="font-semibold text-lg w-max">List Of Orders</div>
        <div className="flex space-x-4">
          <div className="flex space-x-1 items-center">
            <div className="h-4 w-4 bg-[#FF9D9D]"></div>
            <label className="font-semibold text-sm">Urgent</label>
          </div>
          <div className="flex space-x-1 items-center">
            <div className="h-4 w-4 bg-[#9CEF95]"></div>
            <label className="font-semibold text-sm">Completed</label>
          </div>
        </div>
      </div>
    );
  };
  const RenderActions1 = (row, index) => {
    setValue(
      `workOrderCheck${index}`,
      selectedWorkOrderIndex === index ? true : false
    );
    return (
      <div className="flex">
        {userActions.map((action, i) => (
          <>
            {action.isAction === true ? (
              <>
                <div className="flex space-x-2 cursor-pointer items-center">
                  {action.action === "Print" ? (
                    <>
                      <Tooltip title="Print">
                        <IconButton
                          onClick={() => {
                            handleOpenPrintModal(row);
                          }}
                        >
                          <LocalPrintshopIcon
                            sx={{ color: "#4B5563", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                  {action.action === "Cancel" ? (
                    <>
                      {row?.["Opd/Ipd"] === "IPD" ? (
                        <Tooltip title="Cancel Order">
                          <IconButton
                            onClick={() => {
                              setSelectedRow(row);
                              setCancelOrderConfirmation(true);
                              setPrivilege(action.action);
                            }}
                          >
                            <HighlightOffIcon
                              sx={{ color: "#FF0000", height: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton disabled>
                          <HighlightOffIcon
                            sx={{ color: "#D7D7D7", height: "20px" }}
                          />
                        </IconButton>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
        <fieldset
          className="mx-2"
          onChange={(e) => {
            console.log("checkOrder", e.target.checked);
            if (e.target.checked === true) {
              setValue("provisionalRemark", row?.ProvisionalDiagnosis);
              setValue("chiefComplaints", row?.cheifComplaints);
              setSelectedRow(row);
              setSelectedWorkOrderIndex(index);
              setPatientInfo(null);
              setCheckedArr([]);
            } else if (e.target.checked === false) {
              setSelectedRow(null);
              setSelectedWorkOrderIndex(null);
              setTestsArray([]);
              setCheckedArr([]);
            }
          }}
        >
          <CheckBoxField control={control} name={`workOrderCheck${index}`} />
        </fieldset>
      </div>
    );
  };

  const rowBackgroundColor1 = (row, index) => {
    if (row.Count === 1) {
      return "#9CEF95";
    }
    if (row.Urgent === 1) {
      return "#FF9D9D";
    }
  };

  const RenderBarcodeButton = () => {
    return (
      <div className="">
        <Tooltip title="Print Barcode">
          <BarcodeIcon />
        </Tooltip>
      </div>
    );
  };

  function forCancelTest() {
    cancelTest(selectedId?.OrderDtlsId, location?.state?.menuId, privilege)
      .then((response) => {
        successAlert(response.data.message);
        setCancelTestConfirmation(false);
        fetchFirstWorkOrderViewList();
        // fetchWorkOrderViewList();
        fetchAllTest();
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  }

  function handleOpenPrintTestModal(row) {
    setPdfOpenBackdrop(true);
    let printArr = [];
    {
      row !== undefined &&
        (printArr = [
          {
            orderDetailsId: row.OrderDtlsId,
            reportId: row.ReportId,
          },
        ]);
    }
    labReportsCommonPrint(row !== undefined ? printArr : testPrintArray)
      .then((response) => {
        console.log("response123", response);
        if (response.status === 200) {
          console.log("response456", response);
          setPdfOpenBackdrop(false);
          //Get Reponse JSON
          const blb = new Blob([response.data], { type: "text/plain" });
          const reader = new FileReader();
          // Start reading the blob as text.
          reader.readAsText(blb);
          //Convert Blob Response to JSON String
          reader.addEventListener("loadend", (e) => {
            if (e.target.result.charAt(0) !== "{") {
              setOpenPrintModal(true);
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              setReportPdf(fileURL);
            } else {
              setOpenPrintModal(true);
              setReportPdf("");
            }
          });
        } else if (response.status !== 200) {
          setPdfOpenBackdrop(false);
        }
      })
      .catch((error) => {
        console.log("error123", error);
        // if (error?.response?.status === 400) {
        //   warningAlert("Can't Print Report more than 2 time.!");
        //   setPdfOpenBackdrop(false);
        // } else {
        errorAlert(error.response.data.message || error?.message);
        setPdfOpenBackdrop(false);
        // }
      });
  }

  const handleCheckChange = (requiredIndex) => {
    // setChecked({ id: row.id, check: event.target.checked });
    let orderDtlsArr = [];
    let printsArray = [];
    let tempAuthArr = [];

    setOrderIdToPost(testsArray.length > 0 && testsArray[0].OrderId);

    testsArray.map((val) => {
      if (val.checked === true) {
        tempAuthArr.push(val);
        orderDtlsArr.push(val.OrderDtlsId);
        printsArray.push({
          orderDetailsId: val.OrderDtlsId,
          reportId: val.ReportId,
        });
      }
    });
    console.log("orderDtlsArr", orderDtlsArr);
    setTestPrintArray(printsArray);
    setCheckedArr(orderDtlsArr);
    setAuthorizationArr(tempAuthArr);
  };

  console.log("authorizationArr123", authorizationArr);

  const handleSelect = (e, row) => {
    if (
      watch(`selectOptions${row?.["Test Category"] + row.OrderDtlsId}`) === true
    ) {
      if (getNonAuthorized === false) {
        let array = testsArray;
        console.log("getNonAuthorized_false");
        if (row.Description !== "MICROBIOLOGY") {
          let filtered = array.filter((item) =>
            row.IsRetest !== true
              ? item.Description !== row.Description
              : item.IsRetest !== row.IsRetest &&
                item?.OrderDtlsId !== row?.OrderDtlsId
          );
          for (let obj of filtered) {
            setValue(
              `selectOptions${obj?.["Test Category"] + obj.OrderDtlsId}`,
              false
            );
            obj.checked = false;
          }
        } else {
          let filtered = array.filter((item) => item !== row);
          for (let obj of filtered) {
            setValue(
              `selectOptions${obj?.["Test Category"] + obj.OrderDtlsId}`,
              false
            );
            obj.checked = false;
          }
        }
      } else if (getNonAuthorized === true) {
        let array = testsArray;
        console.log("getNonAuthorized_true");
        if (row.Description !== "MICROBIOLOGY") {
          let filtered = array.filter(
            (item) =>
              item.Description !== row.Description ||
              item.SampleNo !== row?.SampleNo
          );
          for (let obj of filtered) {
            setValue(
              `selectOptions${obj?.["Test Category"] + obj.OrderDtlsId}`,
              false
            );
            obj.checked = false;
          }
        } else {
          let filtered = array.filter((item) => item !== row);
          for (let obj of filtered) {
            setValue(
              `selectOptions${obj?.["Test Category"] + obj.OrderDtlsId}`,
              false
            );
            obj.checked = false;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (checkedArr?.length === 0) {
      setSelectedTestId(null);
    }
  }, [checkedArr]);

  useEffect(() => {
    if (testsArray?.length > 0) {
      if (testsArray !== undefined && testsArray?.length > 0) {
        for (let i = 0; i < testsArray?.length; i++) {
          if (testsArray[i].checked === undefined) {
            testsArray[i].checked = false;
          }
        }
        let isMicroArr = [];
        testsArray.map((val) => {
          if (val.checked === true) {
            let obj = {
              orderDtlsId: val.OrderDtlsId,
              site: val.site,
            };
            isMicroArr.push(obj);
          }
        });
        setCheckedIsmicroArr(isMicroArr);
      }
    }
  }, []);

  useEffect(() => {
    if (testsArray?.length > 0) {
      if (testsArray !== undefined && testsArray?.length > 0) {
        if (testsArray.length > 0) {
          for (let obj of testsArray) {
            if (obj.site === undefined) {
              obj.site = null;
            }
          }
        }
      }
    }
  }, [testsArray]);

  useEffect(() => {
    if (testsArray?.length > 0) {
      for (let i = 0; i < testsArray.length; i++) {
        setValue(
          `selectOptions${
            testsArray[i]?.["Test Category"] + testsArray[i].OrderDtlsId
          }`,
          false
        );
        setSelectedId(null);
      }
    }
  }, [testsArray]);

  const RenderActions2 = (row, index) => {
    return (
      <div className="flex gap-2">
        <div>
          <fieldset
            className="flex justify-center"
            onChange={(e) => {
              handleSelect(e, row);
              handleCheckChange(index);
              setSelectedTestId(row);
            }}
            onClick={(e) => {
              testsArray[index].checked = e.target.checked;
            }}
          >
            <CheckBoxField
              control={control}
              defaultValue={row?.checked}
              name={`selectOptions${row?.["Test Category"] + row.OrderDtlsId}`}
            />
          </fieldset>
        </div>
        {userActions.map((action, index) => (
          <>
            {action.isAction === false && action.action === "Create" && (
              <>
                <Tooltip title="Report Entry">
                  <button
                    type="button"
                    onClick={() => {
                      if (
                        (row !== null &&
                          row?.IsCultureSenTest === 1 &&
                          row?.IsProcedureStart === true) ||
                        row?.IsCultureSenTest === 0
                      ) {
                        fetchPatientInfo(row);
                        setOrderIdToPost(row?.OrderId);
                        setCheckedArr([row?.OrderDtlsId]);
                        setSelectedTestId(row);
                        handleOpen();
                        setAuthorizationArr([row]);
                      } else if (
                        row !== null &&
                        row?.IsCultureSenTest === 1 &&
                        row?.IsProcedureStart === false
                      ) {
                        warningAlert("Please start procedure...!");
                      }
                    }}
                    disabled={checkedArr?.length > 1}
                  >
                    <EditIcon />
                  </button>
                </Tooltip>
              </>
            )}
            {action.isAction === true ? (
              <>
                <div className="flex cursor-pointer items-center">
                  {action.action === "Print" ? (
                    <>
                      {row.FirstLevel === false &&
                      row.SecondLevel === false &&
                      row.ThirdLevel === false ? (
                        <button type="button" disabled>
                          <DisablePrintIcon />
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            // getPathologyPrint(row);
                            // setOpenPrintModal(true);
                            // setSelectedRowIndex(index);
                            // setOpenBackdrop(true);
                            handleOpenPrintTestModal(row);
                          }}
                        >
                          <PrintOnIcon />
                        </button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {action.action === "Cancel" ? (
                    <>
                      {row.OpdIpd === 1 &&
                      !row.FirstLevel &&
                      !row.SecondLevel &&
                      !row.ThirdLevel ? (
                        <Tooltip title="Cancel Test">
                          <IconButton
                            onClick={() => {
                              setSelectedId(row);
                              setCancelTestConfirmation(true);
                              setPrivilege(action.action);
                            }}
                          >
                            <HighlightOffIcon
                              sx={{ color: "#FF0000", height: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton disabled>
                          <HighlightOffIcon
                            sx={{ color: "#cacfd2", height: "20px" }}
                          />
                        </IconButton>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {action.action === "Medicine" ? (
                    <>
                      <Tooltip title="Medicine Details">
                        <IconButton
                          onClick={() => {
                            setSelectedId(row);
                            // setCancelTestConfirmation(true);
                          }}
                          disabled={
                            row.FirstLevel === false &&
                            row.SecondLevel === false &&
                            row.ThirdLevel === false
                          }
                        >
                          <MedicationIcon
                            sx={{ color: "#3498DB ", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                  {action.action === "Barcode" ? (
                    <Tooltip title="Print Barcode">
                      <fieldset
                        className="mt-2"
                        disabled={
                          row.FirstLevel === false &&
                          row.SecondLevel === false &&
                          row.ThirdLevel === false
                        }
                      >
                        <BarCodePrint
                          template={"labSampleNumberBarCode"}
                          row={row}
                          row1={selectedRow}
                          barcodeText={row?.SampleNo}
                          RenderBarcodeButton={RenderBarcodeButton}
                        />
                      </fieldset>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {getNonAuthorized === false && (
                    <div className="mt-2">
                      {action.action === "Send On Whatsapp" && (
                        <>
                          {row?.IsCompleted === true ? (
                            <Tooltip title="Send Report">
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedId(row);
                                  setMessageConfirmation(true);
                                  setIsWhatsApp(true);
                                }}
                              >
                                <WhatsAppEnableIcon />
                              </button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="">
                              <button type="button">
                                <WhatsAppDisableIcon />
                              </button>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {getNonAuthorized === false && (
                    <div className="mt-2">
                      {action.action === "SendOnEmail" && (
                        <>
                          {row?.IsCompleted === true ? (
                            <Tooltip title="Send Email">
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedId(row);
                                  setMessageConfirmation(true);
                                  setIsWhatsApp(false);
                                }}
                              >
                                <EmailEnableIcon />
                              </button>
                            </Tooltip>
                          ) : (
                            <button type="button">
                              <EmailDisableIcon />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {row?.["Sample No"] > 0 && (
                    <>
                      {row?.IsRecollect === true ? (
                        <>
                          {action.action === "Recollect Sample" ? (
                            <Tooltip title="Recollect Sample">
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedTestId(row);
                                  setOpenSampleAction(true);
                                  setSampleProcedure("Recollect");
                                  setActionInfo(action);
                                }}
                              >
                                <RecollectSampleCollectionIcon />
                              </button>
                            </Tooltip>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {action.action === "Cancel Sample Collection" ? (
                            <Tooltip title="Cancel Sample">
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedTestId(row);
                                  setOpenSampleAction(true);
                                  setSampleProcedure("Cancel");
                                  setActionInfo(action);
                                }}
                              >
                                <CancelSampleCollectionIcon />
                              </button>
                            </Tooltip>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
        {row.FirstLevel &&
        row?.IsPathologyAuthority !== true &&
        row?.IsRetest !== true ? (
          <Tooltip title="Retest">
            <button
              type="button"
              onClick={() => {
                setSelectedTestId(row);
                setOpenRetestModal(true);
              }}
            >
              <LIMSRetestEnableIcon />
            </button>
          </Tooltip>
        ) : (
          <button type="button" disabled>
            <LIMSRetestDisableIcon />
          </button>
        )}
      </div>
    );
  };

  const rowBackgroundColor2 = (row, index) => {
    if (
      row?.IsPathologyReAuthorised !== null &&
      row?.IsPathologyReAuthorised !== undefined
    ) {
      return "#f0f06c";
    } else if (row?.IsPathologyAuthority === true) {
      return "#9CEF95";
    } else if (row?.IsRetest === true) {
      return "#ffc1c8";
    } else if (
      (row.FirstLevel || row.SecondLevel || row.ThirdLevel) &&
      row?.IsPathologyAuthority === false
    ) {
      return "#aed6f1";
    }
  };

  const handleSelectedRow = (row, index) => {
    // setValue("provisionalRemark", row?.ProvisionalDiagnosis);
    // setValue("chiefComplaints", row?.cheifComplaints);
    // setSelectedRow(row);
  };

  function generatePathReport() {
    generatePathologyReport(checkedArr).then((response) => {
      successAlert(response.data.message);
      setOpenGenerateReport(false);
    });
  }

  const cancelSampleTest = () => {
    cancelSampleCollectionTest(
      selectedTestId.OrderDtlsId,
      actionInfo?.action,
      actionInfo?.menuId
    ).then((response) => {
      successAlert(response.message);
      setOpenSampleAction(false);
      setSampleProcedure("");
      setActionInfo(null);
      setSelectedTestId(null);
      fetchAllTest();
    });
  };

  const recollectSampleTest = () => {
    recollectSampleCollectionTest(
      selectedTestId.OrderDtlsId,
      actionInfo?.action,
      actionInfo?.menuId
    ).then((response) => {
      successAlert(response.message);
      setOpenSampleAction(false);
      setSampleProcedure("");
      setActionInfo(null);
      setSelectedTestId(null);
      fetchAllTest();
      setValue("retestReason", "");
    });
  };

  // const [counter, setCounter] = useState(0);

  // useEffect(() => {
  //   let pdfContent = document.getElementById("pathologyPrint");
  //   if (pdfContent !== null) {
  //     UseCreatePdfBase64(pdfContent).then((response) => {
  //       if (response !== "") {
  //         let obj = {
  //           base64: response.split(",")[1],
  //           orderId: selectedRow?.OrderId,
  //         };
  //         setIsWhatsApp(true);
  //         sendReportOnWhatsapp(obj)
  //           .then((response) => {
  //             successAlert(response.data.message);
  //             setOpenBackdrop(false);
  //             setIsWhatsApp(false);
  //             setPrintResult(null);
  //             setOpenPrintModal(false);
  //           })
  //           .catch(() => {
  //             setOpenBackdrop(false);
  //           });
  //       } else {
  //         setTimeout(() => {
  //           setCounter(counter + 1);
  //         }, 10000);
  //       }
  //     });
  //   }
  // }, [counter]);

  // function getPathologyPrint(row) {
  //   labReportsCommonPrint(
  //     testPrintArray?.length > 0
  //       ? testPrintArray
  //       : [
  //           {
  //             orderDetailsId: row?.OrderDtlsId,
  //             reportId: row?.ReportId,
  //           },
  //         ]
  //   ).then((response) => {
  //     setPrintResult(response?.data?.result?.data[0]?.labTestList);
  //     setOpenPrintModal(true);
  //     setPrintpatientInfo(response?.data?.result?.data[0]?.patientInfo);
  //     setCounter(counter + 1);
  //     setTestPrintArray([]);
  //     setOpenBackdrop(false);
  //   });
  // }

  // const renderPrint = () => {
  //   setTimeout(() => {
  //     setOpenPrintModal(false);
  //     // setSelectedPatient(null);
  //   }, 10000);

  //   return (
  //     <div className="">
  //       <PathologyPrint
  //         // renderImage={renderImage}
  //         // printImage={printImage}
  //         // dataResult={printDetails}
  //         // selectedRow={selectedRow2}
  //         setOpenBackdrop={setOpenBackdrop}
  //         // isPrint={isPrint}
  //         // setIsPrint={setIsPrint}
  //         images={images[selectedRowIndex]}
  //         selectedRowIndex={selectedRowIndex}
  //         setSelectedRowIndex={setSelectedRowIndex}
  //         isWhatsApp={isWhatsApp}
  //         printPatientInfo={printPatientInfo}
  //         printResult={printResult}
  //         setPrintResult={setPrintResult}
  //         templateImages={templateImages}
  //         reportImages={reportImages}
  //         cultureTestImages={cultureTestImages}
  //         allTest={allTest}
  //       />
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   let testArray = [...allTest];
  //   let imagesArray = [];
  //   for (let row of testsArray) {
  //     labReportsCommonPrint([
  //       {
  //         orderDetailsId: row?.OrderDtlsId,
  //         reportId: row?.ReportId,
  //       },
  //     ]).then((response) => {
  //       testArray.push(response?.data?.result?.data[0]?.labTestList?.[0]);
  //       setAllTest(testArray);
  //       let cultureList =
  //         response?.data?.result?.data[0]?.labTestList?.[0]?.cultureTest;
  //       let templateList =
  //         response?.data?.result?.data[0]?.labTestList?.[0]?.templateData;
  //       let reportList =
  //         response?.data?.result?.data[0]?.labTestList?.[0]?.reportValueList;

  //       let newcultureList = [];
  //       let newtemplateList = [];
  //       let newreportList = [];

  //       if (cultureList !== null) {
  //         for (let object of cultureList) {
  //           if (object?.path1Image) {
  //             let image = "data:image/png;base64," + object.path1Image;
  //             newcultureList.push(image);
  //             setCultureTestImages(newcultureList);
  //           } else if (object?.path2Image) {
  //             let image = "data:image/png;base64," + object.path2Image;
  //             newcultureList.push(image);
  //             setCultureTestImages(newcultureList);
  //           } else {
  //             newcultureList.push("");
  //             setCultureTestImages(newcultureList);
  //           }
  //         }
  //       }

  //       if (templateList !== null) {
  //         for (let object of templateList) {
  //           if (object?.path1Image) {
  //             let image = "data:image/png;base64," + object.path1Image;
  //             newtemplateList.push(image);
  //             setTemplateImages(newtemplateList);
  //           } else if (object?.path2Image) {
  //             let image = "data:image/png;base64," + object.path2Image;
  //             newtemplateList.push(image);
  //             setTemplateImages(newtemplateList);
  //           } else {
  //             newtemplateList.push("");
  //             setTemplateImages(newtemplateList);
  //           }
  //         }
  //       }

  //       if (reportList !== null) {
  //         for (let object of reportList) {
  //           if (object?.path1Image) {
  //             let image = "data:image/png;base64," + object.path1Image;
  //             newreportList.push(image);
  //             setReportImages(newreportList);
  //           } else if (object?.path2Image) {
  //             let image = "data:image/png;base64," + object.path2Image;
  //             newreportList.push(image);
  //             setReportImages(newreportList);
  //           } else {
  //             newreportList.push("");
  //             setReportImages(newreportList);
  //           }
  //         }
  //       }

  //       let object = {
  //         culture: newcultureList,
  //         report: newreportList,
  //         template: newtemplateList,
  //       };
  //       imagesArray.push(object);
  //       setImages(imagesArray);
  //     });
  //   }
  // }, [testsArray]);

  function viewLIMSHistory() {
    setOpenBackdrop(true);
    getTestParameterReportDetails({
      opdIpdExternal: selectedRow?.["Opd/Ipd"] === "IPD" ? 1 : 0,
      opdIpdExternalId: selectedRow?.Opd_Ipd_External_Id,
      patientId: selectedRow?.PatientId,
    })
      .then((response) => {
        setOpenBackdrop(false);
        setPathHistoryData(response.data.result);
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  }

  console.log("selectedId123", selectedId);
  const sendReportToOnline = () => {
    setOpenBackdrop(true);
    if (isWhatsApp === true) {
      sendReportOnWhatsapp({
        orderDetailsId: selectedId?.OrderDtlsId,
        functionalityId: userActions?.filter(
          (action) => action.action === "SendOnEmail"
        )[0]?.functionalityId,
        opdIpd: selectedId?.OpdIpd,
        opdIpdId: selectedRow?.Opd_Ipd_External_Id,
        isWhatsapp: true,
        orderId: selectedRow?.OrderId,
        reportId: selectedId?.ReportId,
      })
        .then((response) => {
          successAlert(response.data.message);
          setMessageConfirmation(false);
          setOpenBackdrop(false);
          setSelectedId(null);
        })
        .catch(() => {
          setMessageConfirmation(false);
          setOpenBackdrop(false);
          setSelectedId(null);
        });
    } else {
      sendReportOnEmail({
        orderDetailsId: selectedId?.OrderDtlsId,
        functionalityId: userActions?.filter(
          (action) => action.action === "SendOnEmail"
        )[0]?.functionalityId,
        opdIpd: selectedId?.OpdIpd,
        opdIpdId: selectedRow?.Opd_Ipd_External_Id,
        orderId: selectedRow?.OrderId,
        reportId: selectedId?.ReportId,
      })
        .then((response) => {
          successAlert(response.data.message);
          setMessageConfirmation(false);
          setOpenBackdrop(false);
          setSelectedId(null);
        })
        .catch(() => {
          setMessageConfirmation(false);
          setOpenBackdrop(false);
          setSelectedId(null);
        });
    }
  };
  const submitRetestReason = () => {
    setOpenBackdrop(true);
    saveRetestReason({
      retestReason: getRetestReason,
      orderDtlsId: selectedTestId?.OrderDtlsId,
      reportId: selectedTestId?.ReportId,
    })
      .then((response) => {
        setOpenBackdrop(false);
        fetchAllTest();
        successAlert(response.data.message);
        fetchPatientInfo(selectedTestId);
        setOrderIdToPost(selectedTestId?.OrderId);
        setCheckedArr([selectedTestId?.OrderDtlsId]);
        setSelectedTestId(selectedTestId);
        setAuthorizationArr([selectedTestId]);
        setOpenRetestModal(false);
        setValue("retestReason", "");
        handleOpen();
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenBackdrop(false);
      });
  };

  React.useEffect(() => {
    if (openReportDetailsModal === false) {
      // reset(defaultValues);
      // setPage(0);
      // setNonAuthorizedPage(0);
      setValue("provisionalRemark", "");
      setValue("chiefComplaints", "");
    }
  }, [openReportDetailsModal]);

  useMemo(() => {
    fetchFirstWorkOrderViewList();
  }, [
    listingObject?.category,
    listingObject?.fromDate,
    listingObject?.isAuthority,
    listingObject?.isCancelled,
    listingObject?.opdIpd,
    listingObject?.orderId,
    listingObject?.testType,
    listingObject?.mrno,
    listingObject?.toDate,
    listingObject?.unitId,
    listingObject?.size,
    listingObject?.menuId,
    listingObject?.sampleNo,
    listingObject?.patientId,
    listingObject?.searchString,
    listingObject?.isNonAuthorisedTests,
    listingObject?.companyId,
  ]);

  console.log("aaaaaaaaaA123", checkedArr);

  useEffect(() => {
    if (updateComponent === true) {
      console.log("updateComponent123", updateComponent);
      setValue("fromDate", new Date());
      setValue("toDate", new Date());
      fetchFirstWorkOrderViewList();
    }
  }, [updateComponent]);

  console.log("sampleProcedure", sampleProcedure);

  return (
    <>
      <div>
        <div className="mt-12">
          <div className="text-xl font-semibold flex justify-center">
            Work Order View
          </div>
          <div className="grid grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-2 gap-y-2">
            <div className="whitespace-nowrap z-50 col-span-2 flex space-x-2">
              <InputField control={control} name="mrno" label="MR No." />
              <SearchBar
                control={control}
                name="searchBar"
                placeholder="Search By Patient Name / Mr.No."
                isSearchable={true}
                isClearable={true}
                searchIcon={true}
                handleInputChange={handleChange}
                onChange={autoSelectedValue}
                dataArray={searchOptions}
              />
            </div>
            <div className="flex space-x-2">
              <DatePickerFieldNew
                control={control}
                name="fromDate"
                label="From Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("fromDate", newValue);
                }}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
              />
              <DatePickerFieldNew
                control={control}
                name="toDate"
                label="To Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("toDate", newValue);
                }}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <DropdownField
                control={control}
                name="testType"
                label="Test Type"
                placeholder="Test Type"
                dataArray={testTypeOptions}
                isSearchable={true}
              />
            </div>
            <div>
              <DropdownField
                isSearchable={true}
                control={control}
                name="category"
                label="Category"
                placeholder="Category"
                dataArray={categoryOptions}
              />
            </div>
            <div className="">
              <DropdownField
                isSearchable={true}
                control={control}
                name="unit"
                label="Unit"
                placeholder="Unit"
                dataArray={unitOptions}
              />
            </div>
            <div className="flex justify-between space-x-5">
              <CheckBoxField
                control={control}
                label="Cancelled Order"
                name="cancelledOrder"
              />
              <fieldset
                onChange={(e) => {
                  setTestsArray([]);
                  setDataResult([]);
                }}
              >
                <CheckBoxField
                  control={control}
                  label="Non Authorized"
                  name="nonAuthorized"
                />
              </fieldset>
            </div>
            <div className="flex space-x-1 items-center">
              <span className="font-semibold mx-2 text-lg">OR</span>
              <InputField control={control} name="sampleNo" label="Sample No" />
            </div>
            <div>
              <DropdownField
                control={control}
                name="companyId"
                placeholder="Company"
                dataArray={companyOptions}
                isClearable={true}
                isSearchable={true}
              />
            </div>
            <fieldset className="flex gap-1 justify-center items-center bg-white border rounded px-2 -mt-2">
              <legend className="font-semibold text-xs px-2">
                Patient Type
              </legend>
              <div className="-my-2">
                <RadioField
                  control={control}
                  name="patientType"
                  dataArray={patientType}
                />
              </div>
            </fieldset>
            <fieldset className="flex gap-1 justify-center items-center bg-white border rounded px-2 -mt-2 col-span-2">
              <legend className="font-semibold text-xs px-2">Status</legend>
              <fieldset
                className={`-my-2 ${
                  getNonAuthorized === true ? `text-gray-400` : ``
                }`}
                disabled={getNonAuthorized === true}
              >
                <RadioField
                  control={control}
                  name="status"
                  dataArray={status}
                />
              </fieldset>
            </fieldset>
            <div className="flex justify-end items-center space-x-2 col-span-3 2xl:col-span-6">
              <div className="">
                <CommonButton
                  type="button"
                  label="Reset"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    reset(defaultValues);
                  }}
                />
              </div>
              <div className="">
                <CommonButton
                  type="button"
                  className="bg-customBlue text-white"
                  searchIcon={true}
                  onClick={() => {
                    setPage(0);
                    setNonAuthorizedPage(0);
                    // fetchFirstWorkOrderViewList();
                    // fetchWorkOrderViewList();
                    setTestsArray([]);
                    setValue("provisionalRemark", "");
                    setValue("chiefComplaints", "");
                    setSelectedWorkOrderIndex(null);
                    setListingObject((listingObject) => ({
                      listingObject,
                      category: getCategory ? getCategory.id : 0,
                      companyId: getCompany ? getCompany?.id : null,
                      fromDate: selectedFromDate,
                      isAuthority: Number(getStatus ? getStatus : 2),
                      isCancelled: getCancelledOrder === true ? true : false,
                      opdIpd: getPatientType ? getPatientType : 4,
                      orderId: patientDetails ? patientDetails.OrderId : 0,
                      testType: getTestType ? getTestType?.id : 3,
                      mrno: getMrno ? getMrno : null,
                      toDate: selectedToDate,
                      unitId: getUnit ? getUnit.id : 2,
                      size:
                        getNonAuthorized === true
                          ? nonAuthorizedRowsPerPage
                          : rowsPerPage,
                      menuId: location?.state?.menuId,
                      sampleNo: getSampleNo ? Number(getSampleNo) : null,
                      patientId: patientDetails ? patientDetails?.id : null,
                      searchString: searchBar ? searchBar : "",
                      isNonAuthorisedTests: getNonAuthorized,
                    }));
                  }}
                />
              </div>
              {userActions.map((action, i) => (
                <>
                  {action.action === "Queue" && action.isAction === false ? (
                    <Tooltip title="Queue List">
                      <div
                        className="cursor-pointer items-center flex"
                        onClick={() => {
                          setOpenQueueModal(true);
                        }}
                      >
                        <img src={QueueManagement} />
                      </div>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </>
              ))}
              <div>
                <Tooltip title="TAT">
                  <button
                    onClick={() => {
                      setOpenPatientTATModal(true);
                    }}
                    className="mt-2"
                  >
                    <TATIcon />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
          <hr className="w-full my-1 border-t-2 border-gray-900" />
          {getNonAuthorized === false ? (
            <div className="space-y-2">
              {!loadingSpinner ? (
                <>
                  {dataResult !== undefined && dataResult?.length > 0 ? (
                    <CommonDynamicTablePaginationNew
                      dataResult={dataResult}
                      handleSelectedRow={handleSelectedRow}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={dataResult?.length}
                      populateTable={fetchWorkOrderViewList}
                      count={dataResult?.length}
                      renderActions={RenderActions1}
                      renderTableHeader={renderTableHeader}
                      rowBackgroundColor={rowBackgroundColor1}
                      tableClass={"h-96"}
                      newDataResult={newDataResult}
                      removeHeaders={[
                        "IsPathologyAuthority",
                        "IsDelivered",
                        "IsCompleted",
                        "IsPrinted",
                        "IsOrderGenerated",
                        "Urgent",
                        "IsCancelled",
                        "Count",
                        "Opd_Ipd_External_Id",
                        "Opd_Ipd_External",
                        "PatientId",
                        "OrderId",
                        "Order No",
                      ]}
                    />
                  ) : (
                    <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded my-2">
                      No Records Found...
                    </div>
                  )}
                </>
              ) : (
                <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded">
                  <LoadingSpinner />
                </div>
              )}
              <div>
                <div className="flex justify-between w-full">
                  <div className="flex gap-3 whitespace-nowrap items-center w-full">
                    <div className="font-semibold mr-10 text-lg">Tests</div>
                    <div>
                      <CheckBoxField
                        control={control}
                        name="multiTests"
                        label="Multi Tests"
                      />
                    </div>
                    <div className="w-80">
                      <DropdownField
                        isSearchable={true}
                        control={control}
                        name="status"
                        label="Status"
                        placeholder="Status"
                        dataArray={testStatus}
                      />
                    </div>
                  </div>
                  <div className="flex space-x-5 items-center">
                    <div className="flex space-x-5 items-center font-semibold w-max">
                      <div className="flex space-x-1 w-max items-center">
                        <div className="h-4 w-4 bg-[#aed6f1]"></div>
                        <label>Report Entry</label>
                      </div>
                      <div className="flex space-x-1 w-max items-center">
                        <div className="h-4 w-4 bg-[#ffc1c8]"></div>
                        <label>Retest</label>
                      </div>
                      <div className="flex space-x-1 items-center">
                        <div className="h-4 w-4 bg-[#9CEF95]"></div>
                        <label>Authorized</label>
                      </div>
                      <div className="flex space-x-1 items-center">
                        <div className="h-4 w-4 bg-[#f0f06c]"></div>
                        <label>Re-Authorized</label>
                      </div>
                    </div>
                    <Tooltip title="Reports History">
                      <button
                        type="button"
                        onClick={() => {
                          if (selectedRow !== null) {
                            setLIMSType("Pathology");
                            viewLIMSHistory();
                            setOpenLIMSHistoryModal(true);
                          } else {
                            warningAlert("Select Order...!");
                          }
                        }}
                      >
                        <DetailedReportIcon />
                      </button>
                    </Tooltip>
                    {testPrintArray.length > 0 && (
                      <div className="flex w-full justify-end">
                        <Tooltip title="Print Multiple Test">
                          <IconButton
                            onClick={() => {
                              handleOpenPrintTestModal();
                            }}
                          >
                            <LocalPrintshopIcon
                              sx={{ color: "#4B5563", height: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {!testLoadingSpinner ? (
                  <>
                    {!loadingSpinner &&
                    testsArray !== undefined &&
                    testsArray?.length > 0 ? (
                      <CommonDynamicTable
                        dataResult={testsArray}
                        setSelectedTestId={setSelectedTestId}
                        checkedArr={checkedArr}
                        setCheckedArr={setCheckedArr}
                        checkedIsmicroArr={checkedIsmicroArr}
                        setCheckedIsmicroArr={setCheckedIsmicroArr}
                        postArr={postArr}
                        setPostArr={setPostArr}
                        fetchAllTest={fetchAllTest}
                        actionsRequired={true}
                        renderActions={RenderActions2}
                        rowBackgroundColor={rowBackgroundColor2}
                        highlightRow={false}
                        tableClass={"h-60"}
                        style={true}
                        removeHeaders={[
                          "site",
                          "Quantity",
                          "Remark",
                          "TechnitionName",
                          "OutSourceSampleReceivedBy",
                          "IsCompleted",
                          "IsDelivered",
                          "IsPrinted",
                          "HasThreeLevels",
                          "IsCancelled",
                          "isPackageService",
                          "IsPackage",
                          "SampleCollectionNO",
                          "ExtAgencyId",
                          "ChargeId",
                          "IsOutSourced",
                          "isconsent",
                          "IsHistopathTest",
                          "ReportId",
                          "OpdIpd",
                          "IsMicro",
                          "IsProcedureEnd",
                          "IsProcedureStart",
                          "FirstLevel",
                          "SecondLevel",
                          "ThirdLevel",
                          "ClinicalNote",
                          "PackageTestId",
                          "OrderDtlsId",
                          "HasWordPrecessor",
                          "HasTemplate",
                          "IsCultureSenTest",
                          "SampleNo",
                          "Id",
                          "Description",
                          "PackageTestName",
                          "IsPathologyAuthority",
                          "Test Cost",
                          "First Level",
                          "Second Level",
                          "Third Level",
                          "checked",
                          "HistoryOfPresentIllness",
                          "IsPathologyReAuthorised",
                          "IsRetest",
                        ]}
                      />
                    ) : (
                      <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded my-2">
                        No Records Found...
                      </div>
                    )}
                  </>
                ) : (
                  <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded">
                    <LoadingSpinner />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {!nonAuthorizedTestLoadingSpinner ? (
                <>
                  {testsArray !== undefined && testsArray?.length > 0 ? (
                    <CommonDynamicTablePaginationNew
                      populateTable={fetchWorkOrderViewList}
                      dataResult={testsArray}
                      setSelectedTestId={setSelectedTestId}
                      checkedArr={checkedArr}
                      setCheckedArr={setCheckedArr}
                      checkedIsmicroArr={checkedIsmicroArr}
                      setCheckedIsmicroArr={setCheckedIsmicroArr}
                      postArr={postArr}
                      setPostArr={setPostArr}
                      fetchAllTest={fetchAllTest}
                      actionsRequired={true}
                      renderActions={RenderActions2}
                      rowBackgroundColor={rowBackgroundColor2}
                      highlightRow={false}
                      tableClass={"h-96"}
                      style={true}
                      page={nonAuthorizedPage}
                      setPage={setNonAuthorizedPage}
                      rowsPerPage={testsArray?.length}
                      count={testsArray?.length}
                      newDataResult={newTestArray}
                      removeHeaders={[
                        "Order No",
                        "IsPathologyAuthority",
                        "Doctor Name",
                        "Gender",
                        "Age",
                        "IsApproved",
                        "IsDelivered",
                        "IsCompleted",
                        "IsPrinted",
                        "IsOrderGenerated",
                        "Urgent",
                        "IsCancelled",
                        "Count",
                        "Opd_Ipd_External_Id",
                        "Opd_Ipd_External",
                        "PatientId",
                        "ProvisionalDiagnosis",
                        "cheifComplaints",
                        "Test Code",
                        "site",
                        "Test Cost",
                        "Quantity",
                        "Remark",
                        "OutSourceSampleReceivedBy",
                        "IsSampleCollected",
                        "First Level",
                        "Second Level",
                        "Third Level",
                        "HasThreeLevels",
                        "isPackageService",
                        "IsPackage",
                        "SampleCollectionNO",
                        "ExtAgencyId",
                        "ChargeId",
                        "IsOutSourced",
                        "isconsent",
                        "IsHistopathTest",
                        "ReportId",
                        "OpdIpd",
                        "IsMicro",
                        "IsProcedureEnd",
                        "IsProcedureStart",
                        "FirstLevel",
                        "SecondLevel",
                        "ThirdLevel",
                        "ClinicalNote",
                        "PackageTestId",
                        "OrderDtlsId",
                        "HasWordPrecessor",
                        "HasTemplate",
                        "SampleNo",
                        "Id",
                        "Description",
                        "PackageTestName",
                        "IsCultureSenTest",
                        "TechnitionName",
                        "OpdIpd",
                        "OrderId",
                        "Status",
                        "checked",
                        "HistoryOfPresentIllness",
                        "IsPathologyReAuthorised",
                        "IsRetest",
                      ]}
                    />
                  ) : (
                    <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded my-2">
                      No Records Found...
                    </div>
                  )}
                </>
              ) : (
                <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded">
                  <LoadingSpinner />
                </div>
              )}
            </>
          )}

          <div className="flex space-x-2">
            <TextField
              name="provisionalRemark"
              aria-label="minimum height"
              multiline
              maxRows={4}
              minRows={2}
              fullWidth
              placeholder="Provisional Diagnosis / Remark"
              {...register("provisionalRemark")}
            />
            <TextField
              name="chiefComplaints"
              aria-label="minimum height"
              multiline
              maxRows={4}
              minRows={2}
              fullWidth
              placeholder="Chief Complaints"
              {...register("chiefComplaints")}
            />
            <>
              {selectedTestId?.IsMicro === true && checkedArr.length > 0 ? (
                <div className="flex space-x-2">
                  {selectedTestId?.IsProcedureStart === false &&
                  selectedTestId?.IsProcedureEnd === false ? (
                    <div>
                      <CommonButton
                        type="button"
                        label="Start Procedure"
                        className="bg-customGreen text-white"
                        onClick={(e) => {
                          setProcedureConfirmation(true);
                          setForStatus(e.target.innerHTML);
                        }}
                      />
                    </div>
                  ) : (selectedTestId?.IsProcedureStart === true &&
                      selectedTestId?.IsProcedureEnd === false) ||
                    (forStatus === "Start Procedure" &&
                      confirmText === "Yes") ? (
                    <div>
                      <CommonButton
                        type="button"
                        label="End Procedure"
                        className="bg-customRed text-white"
                        onClick={(e) => {
                          setProcedureConfirmation(true);
                          setForStatus(e.target.innerHTML);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </>
            {userActions.map((action, i) => (
              <>
                {action.isAction === false && action.action === "Create" ? (
                  <div>
                    <CommonButton
                      type="button"
                      label="OK"
                      className="bg-customGreen text-white"
                      onClick={() => {
                        if (
                          ((selectedTestId &&
                            selectedTestId !== null &&
                            selectedTestId?.IsCultureSenTest === 1 &&
                            selectedTestId?.IsProcedureStart === true) ||
                            selectedTestId?.IsCultureSenTest === 0) &&
                          checkedArr?.length > 0
                        ) {
                          handleOpen();
                          fetchPatientInfo(selectedTestId);
                        } else if (
                          selectedTestId &&
                          selectedTestId !== null &&
                          selectedTestId?.IsCultureSenTest === 1 &&
                          selectedTestId?.IsProcedureStart === false
                        ) {
                          warningAlert("Please start procedure...!");
                        } else if (selectedTestId === null) {
                          warningAlert("Please select test...!");
                        }
                      }}
                    />
                  </div>
                ) : null}
              </>
            ))}
            <div>
              <CommonButton
                type="button"
                label="Generate Report"
                className="bg-customGreen text-white"
                onClick={() => {
                  if (checkedArr?.length > 0) {
                    setOpenGenerateReport(true);
                  } else {
                    warningAlert("Please select test...!");
                  }
                }}
                disabled={
                  authorizationArr.some(
                    (val) => val.IsPathologyAuthority === false
                  ) || checkedArr?.length === 0
                }
              />
            </div>
          </div>
        </div>
        <ReportDetailsModal
          openReportDetailsModal={openReportDetailsModal}
          setOpenReportDetailsModal={setOpenReportDetailsModal}
          close={handleClose}
          selectedTestId={selectedTestId}
          data={data}
          patientInfo={patientInfo}
          setPatientInfo={setPatientInfo}
          openBackdrop={openBackdrop}
          setOpenBackdrop={setOpenBackdrop}
          getUnit={getUnit}
          checkedArr={checkedArr}
          setCheckedArr={setCheckedArr}
          checkedIsmicroArr={checkedIsmicroArr}
          postArr={postArr}
          fetchAllTest={fetchAllTest}
          fetchWorkOrderViewList={fetchWorkOrderViewList}
          fetchFirstWorkOrderViewList={fetchFirstWorkOrderViewList}
          userActions={userActions}
          menuId={location?.state?.menuId}
          privilege={privilege}
          setPrivilege={setPrivilege}
          orderIdToPost={orderIdToPost}
          authorizationArr={authorizationArr}
          setIsWhatsApp={setIsWhatsApp}
          isView={isView}
          setIsView={setIsView}
          // getPathologyPrint={getPathologyPrint}
          getNonAuthorized={getNonAuthorized}
          setSelectedTestId={setSelectedTestId}
          handleOpenPrintTestModal={handleOpenPrintTestModal}
          setLIMSType={setLIMSType}
          viewLIMSHistory={viewLIMSHistory}
          setOpenLIMSHistoryModal={setOpenLIMSHistoryModal}
          selectedRow={selectedRow}
          setPathHistoryData={setPathHistoryData}
          setTestsArray={setTestsArray}
          testsArray={testsArray}
        />
        <PathologyQueueModal
          openQueueModal={openQueueModal}
          setOpenQueueModal={setOpenQueueModal}
        />
        <div
          onClick={(e) => {
            setConfirmText(e.target.innerText);
          }}
        >
          <ConfirmationModal
            confirmationOpen={procedureConfirmation}
            confirmationHandleClose={() => {
              setProcedureConfirmation(false);
            }}
            confirmationSubmitFunc={forProcedure}
            confirmationLabel="Confirmation"
            confirmationMsg={
              forStatus === "Start Procedure"
                ? "Are you sure want to start the procedure ?"
                : "Are you sure want to end the procedure ?"
            }
            confirmationButtonMsg="Yes"
          />
        </div>
      </div>
      <ConfirmationModal
        confirmationOpen={cancelOrderConfirmation}
        confirmationHandleClose={() => {
          setCancelOrderConfirmation(false);
        }}
        confirmationSubmitFunc={forCancelOrder}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure to cancel selected work order ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={cancelTestConfirmation}
        confirmationHandleClose={() => {
          setCancelTestConfirmation(false);
        }}
        confirmationSubmitFunc={forCancelTest}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure to cancel selected test ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={openGenerateReport}
        confirmationHandleClose={() => {
          setOpenGenerateReport(false);
        }}
        confirmationSubmitFunc={generatePathReport}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure to generate selected report ?"
        confirmationButtonMsg="Yes"
      />
      {openOrderPrintModal ? (
        <CommonPrintModal
          open={openOrderPrintModal}
          setOpen={setOpenOrderPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleCloseOrderPrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
      <ConfirmationModal
        confirmationOpen={messageConfirmation}
        confirmationHandleClose={() => {
          setMessageConfirmation(false);
        }}
        confirmationSubmitFunc={sendReportToOnline}
        confirmationLabel="Confirmation"
        confirmationMsg={`Are you sure want to send report on ${
          isWhatsApp ? "whatsapp" : "email"
        } ?`}
        confirmationButtonMsg="Yes"
      />
      <CommonBackDrop openBackdrop={pdfOpenBackdrop} />
      {/*
      {openPrintModal && isWhatsApp === false && renderPrint()}
      {openPrintModal && printResult && isWhatsApp === true && (
        <div className="mt-96">
          <div id="pathologyPrint">
            <PathologyPrint
              // renderImage={renderImage}
              // printImage={printImage}
              // dataResult={printDetails}
              // selectedRow={selectedRow2}
              setOpenBackdrop={setOpenBackdrop}
              // isPrint={isPrint}
              // setIsPrint={setIsPrint}
              images={images[selectedRowIndex]}
              selectedRowIndex={selectedRowIndex}
              setSelectedRowIndex={setSelectedRowIndex}
              isWhatsApp={isWhatsApp}
              printPatientInfo={printPatientInfo}
              printResult={printResult}
              setPrintResult={setPrintResult}
              templateImages={templateImages}
              reportImages={reportImages}
              cultureTestImages={cultureTestImages}
              allTest={allTest}
            />
          </div>
        </div>
      )}
    */}
      {openPrintModal ? (
        <Modal
          open={openPrintModal}
          onClose={() => handleClosePrintModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              marginX: "auto",
              position: "absolute",
              top: "1%",
              left: "1%",
              backgroundColor: "white",
              width: "97%",
              height: "93%",
            }}
          >
            <div className=" bg-white font-bold flex justify-between px-4">
              <p className="text-lg">Report Details</p>
              <Button
                onClick={() => setOpenPrintModal(false)}
                sx={{ color: "black", fontWeight: "bold" }}
              >
                Close
              </Button>
            </div>

            {reportPdf !== "" ? (
              <embed
                src={reportPdf}
                frameBorder="0"
                width="100%"
                height="100%"
              />
            ) : (
              <div>
                <p>Report is Not Available</p>
              </div>
            )}
          </Box>
        </Modal>
      ) : null}
      <LIMSHistoryModal
        openLIMSHistoryModal={openLIMSHistoryModal}
        setOpenLIMSHistoryModal={setOpenLIMSHistoryModal}
        pathHistoryData={pathHistoryData}
        setPathHistoryData={setPathHistoryData}
        patientInfo={selectedRow}
        LIMSType={LIMSType}
      />

      <PatientTestTATModal
        openPatientTATModal={openPatientTATModal}
        setOpenPatientTATModal={setOpenPatientTATModal}
      />
      <ConfirmationModal
        confirmationOpen={openSampleAction}
        confirmationHandleClose={() => {
          setOpenSampleAction(false);
        }}
        confirmationSubmitFunc={
          sampleProcedure === "Recollect"
            ? recollectSampleTest
            : sampleProcedure === "Cancel"
            ? cancelSampleTest
            : null
        }
        confirmationLabel="Confirmation"
        confirmationMsg={
          sampleProcedure === "Recollect"
            ? "Are you sure want to recollect this sample ?"
            : sampleProcedure === "Cancel"
            ? "Are you sure want to cancel this sample ?"
            : ""
        }
        confirmationButtonMsg="Confirm"
      />
      <Modal
        open={openRetestModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #848211",
            borderRadius: "0.5rem",
            boxShadow: 24,
            px: 4,
            pb: 4,
          }}
          className="w-[50%]"
        >
          <p className="mt-6 tracking-wide font-medium  text-lg ">
            {"Retest Reason"}
          </p>
          <div className=" justify-center align-middle space-y-2">
            <InputArea
              control={control}
              name="retestReason"
              // label="Remark"
              placeholder="Retest Reason"
              minRows={2}
              maxRows={2}
              size="md"
            />
            <div className="mt-3 flex  justify-center space-x-4">
              <CommonButton
                type="button"
                label="Cancel"
                onClick={() => {
                  setOpenRetestModal(false);
                  setValue("retestReason", "");
                }}
                className="border border-customRed text-customRed"
              />
              <CommonButton
                type="button"
                label="Proceed"
                onClick={() => {
                  if (getRetestReason !== "") {
                    submitRetestReason();
                  } else {
                    warningAlert("Retest Reason mandatory...!");
                  }
                }}
                className="bg-customGreen text-white"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default WorkOrderView;

import { Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchIssuedItemsList,
  fetchOTDetailsSurgeryList,
  fetchPatientInformationForOtDtls,
  saveOtIssuedItems,
} from "../../../services/otDetailsServices/OtDetailsServices";
import IssuedItemsModal from "./IssuedItemsModal";
import PendingImplantModal from "./PendingImplantModal";

const issue_return = [
  {
    id: 1,
    label: "Update Issued Items",
    value: "Update Issued Items",
  },
  {
    id: 0,
    label: "Return Issued Items",
    value: "Return Issued Items",
  },
];
const OtDetailsIssuedItems = () => {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let location = useLocation();
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  //
  const [patientInfo, setPatientInfo] = useState(null);
  const [issuedItemsList, setIssuedItemsList] = useState([]);
  const [deletedIssuedItems, setDeletedIssuedItems] = useState([]);
  const [errorToTblInput, setErrorToTblInput] = useState(false);
  //
  const [otSurgeries, setOtSurgeries] = useState([]);
  const [selectedSurgery, setSelectedSurgery] = useState();

  //
  const [openIssuedItemsModal, setOpenIssuedItemsModal] = React.useState(false);
  const handleOpenIssuedItemsModal = () => setOpenIssuedItemsModal(true);
  const handleCloseIssuedItemsModal = () => setOpenIssuedItemsModal(false);
  //
  const [openPendingImplantModal, setOpenPendingImplantModal] =
    React.useState(false);
  const handleOpenPendingImplantModal = () => setOpenPendingImplantModal(true);
  const handleClosePendingImplantModal = () =>
    setOpenPendingImplantModal(false);

  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  //
  const { control, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      totalAmt: 0,
      totalItems: 0,
      totalQty: 0,
      //
      isIssueItem: "1",
    },
  });

  //
  const selected_issue_return = watch("isIssueItem");
  const InputTotalAmout = watch("totalAmt");

  //
  useEffect(() => {
    fetchOTDetailsSurgeryList(location?.state?.row?.OTHeaderID).then(
      (response) => {
        setOtSurgeries(
          response.data.result.length > 1
            ? [{ id: 0, "Surgery Name": "All" }, ...response.data.result]
            : response.data.result
        );

        setSelectedSurgery(
          response.data.result.length > 1
            ? { id: 0, "Surgery Name": "All" }
            : response.data.result[0]
        );
      }
    );
  }, []);

  //

  useEffect(() => {
    if (location && location.state !== null) {
      getPatientInfo(location.state.row.PatientId, location.state.row.opdIpd);
    }
  }, []);

  useEffect(() => {
    if (location && location.state !== null && selectedSurgery) {
      getIssuedItemsList(
        location.state.row.OTHeaderID,
        selectedSurgery?.SurgeryId || 0
      );
    }
  }, [selectedSurgery, selected_issue_return]);

  const getIssuedItemsList = (OTHeaderID, SurgeryId) => {
    fetchIssuedItemsList(OTHeaderID, SurgeryId)
      .then((response) => response.data)
      .then((res) => {
        if (Number(selected_issue_return) > 0) {
          let updatedresult = res.result.map((item) => ({
            ...item,
            Amount: (
              (item["Issued Qty"] - item.TotalReturnQty) *
              item.MRP
            ).toFixed(2),
          }));

          setIssuedItemsList(updatedresult);
        } else {
          let totalIssuedQty = 0;
          let totalAmount = 0;

          res.result.forEach((item) => {
            totalIssuedQty +=
              item["Issued Qty"] === null
                ? 0
                : Number(item["Issued Qty"] - item.TotalReturnQty);

            totalAmount +=
              (item["Issued Qty"] - item.TotalReturnQty) * item.MRP;
          });

          setValue("totalAmt", totalAmount.toFixed(2));
          setValue("totalQty", totalIssuedQty);

          setIssuedItemsList(
            res.result.map((item) => ({ ...item, Amount: 0 }))
          );
        }
      });
  };

  function getPatientInfo(patientId, OpdIPd) {
    //call the below function to get the patient information by id.
    fetchPatientInformationForOtDtls(patientId, OpdIPd)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfo(res.result);
      });
  }

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, [location]);

  const onUpdateSubmit = () => {
    let wrongIssuedQty = issuedItemsList.some((item) => item.error);

    !errorToTblInput && !wrongIssuedQty
      ? setOpenConfirmationModal(true)
      : warningAlert("Please Enter Valid Quantity...");
  };

  ///save updated issued items
  const handleFormUpdateSubmit = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    let postIssuedItems = [...deletedIssuedItems, ...issuedItemsList];

    let postArrFinale = [];
    if (postIssuedItems.length > 0) {
      postArrFinale = otSurgeries
        .filter((item) => item["Surgery Name"] !== "All")
        .map((item) => ({
          companyId: patientInfo.CompanyId,
          opdIpdExternalId:
            patientInfo.OpdIpd === 1
              ? patientInfo.admissionId
              : patientInfo.visitId,
          opdIpdExternal: patientInfo.OpdIpd,
          otHeaderId: location.state.row.OTHeaderID,
          surgeryId: item.SurgeryId,
          storeId: token.storeId,
          tariffId: patientInfo?.tariffId,
          otDetailsIssuedItemsDtoList: postIssuedItems
            .filter((filterObj) => filterObj.SurgeryId === item.SurgeryId)
            .map((obj) => ({
              amount: obj.Amount,
              batchCode: obj["Batch Code"],
              chargeId: 0,
              concession: 0,
              concessionPercentage: 0,
              consumed: obj.isConsumed,
              conversion: obj.Conversion,
              discount: 0,
              discountPercentage: 0,
              expiryDate: "2023-07-27T12:52:52.890Z",
              isConsignment: obj.IsConsignment,
              isPackaged: obj.isPackaged,
              issueQty: Number(obj["Issued Qty"]),

              returnQty: Number(obj["Return Qty"]),
              isIssue: selected_issue_return === "1" ? true : false,

              itemId: obj.ItemId,
              mrp: obj.MRP,
              otHeaderId: location.state.row.OTHeaderID,
              otMaterialDetailsId: obj.OTMaterialDetailId,
              otherTaxAmount: 0,
              purchaseRate: obj.PurchaseRate,
              qtyAvlForReturn: obj.Quantity,
              sellingPrice: 0,
              staffFree: 0,
              staffFreePercentage: 0,
              taxAmount: 0,
              totalAmount: 0,
              totalForVAT: 0,
              unitLandedRate: obj.PurchaseRate,
              vatAmount: 0,
              vatInclusive: obj.VATInClusive,
              vatPercentage: obj.VATPercentage,
              //new added by g1
              // opdIpdExternal: patientInfo.opdIpdExternalId,
              opdIpdExternalId:
                patientInfo.OpdIpd === 1
                  ? patientInfo.admissionId
                  : patientInfo.visitId,
              opdIpdExternal: patientInfo.OpdIpd,

              companyId: patientInfo.CompanyId,
              addedBy: token.userId,

              //new keys
              surgeryId: obj.surgeryId,
              storeId: token.storeId,
              tariffId: patientInfo?.tariffId,
            })),
        }));
    }

    saveOtIssuedItems(location?.state?.menuId, privilege, postArrFinale)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          successAlert(res.message);
          // methods.reset();
          setOpenBackdrop(false);
          getIssuedItemsList(location?.state?.row?.OTHeaderID, 0);

          setSelectedSurgery((prev) =>
            otSurgeries.length > 1 ? { id: 0, "Surgery Name": "All" } : prev
          );
        }
      })
      .catch((res) => {
        errorAlert(res?.message || res?.response?.data?.message);
        setOpenBackdrop(false);
      });
  };

  ////////table related

  const renderInput = (row, index, header) => {
    const handleTableQuantityChange = (index, newValue) => {
      const updatedList = [...issuedItemsList];
      updatedList[index]["Issued Qty"] = Number(newValue);
      updatedList[index].Amount = Number(
        newValue * updatedList[index].MRP
      ).toFixed(2);
      updatedList[index]["error"] =
        newValue > row.AvailableIssueQty ? true : false;

      setIssuedItemsList(updatedList);
    };

    const handleReturnQuantityChange = (index, newValue) => {
      const updatedList = [...issuedItemsList];

      let error =
        Number(newValue) > updatedList[index]["Available Qty For Return"];

      updatedList[index]["Return Qty"] = Number(newValue);
      updatedList[index].Amount = Number(
        newValue * updatedList[index].MRP
      ).toFixed(2);
      updatedList[index]["errorReturnQty"] = error;
      setErrorToTblInput(error);

      setIssuedItemsList(updatedList);
    };

    return (
      <>
        {header === "Issued Qty" ? (
          selected_issue_return !== "0" ? (
            <span>
              <input
                className={
                  row.error !== true
                    ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                    : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
                }
                onChange={(e) =>
                  handleTableQuantityChange(index, e.target.value)
                }
                value={row["Issued Qty"]}
                type="number"
                // min="0"
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) => {
                  if (
                    e?.key === "-" ||
                    e?.key === "+" ||
                    e?.code === "ArrowUp" ||
                    e?.code === "ArrowDown" ||
                    e?.code === "NumpadDecimal"
                  ) {
                    e.preventDefault();
                  }
                }}
                name={`Quantity${[index]}`}
              />
            </span>
          ) : (
            row["Issued Qty"]
          )
        ) : (
          ""
        )}
        {header === "Return Qty" ? (
          selected_issue_return !== "1" ? (
            <span>
              <input
                className={
                  row.errorReturnQty !== true
                    ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                    : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
                }
                onChange={(e) =>
                  handleReturnQuantityChange(index, e.target.value)
                }
                value={row["Return Qty"]}
                type="number"
                min="0"
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) => {
                  if (
                    e?.key === "-" ||
                    e?.key === "+" ||
                    e?.code === "ArrowUp" ||
                    e?.code === "ArrowDown" ||
                    e?.code === "NumpadDecimal"
                  ) {
                    e.preventDefault();
                  }
                }}
                name={`Quantity${[index]}`}
              />
            </span>
          ) : (
            row["Return Qty"]
          )
        ) : (
          ""
        )}
      </>
    );
  };

  useEffect(() => {
    setIssuedItemsList((prevList) => {
      const updatedList = prevList.map((item) => ({
        ...item,
        Amount: item["Issued Qty"] * item.MRP,
      }));
      return updatedList;
    });
  }, []);

  // showing totals

  useEffect(() => {
    let totalIssuedQty = 0;
    let totalAmount = 0;
    if (Number(selected_issue_return) > 0) {
      const totalNumberOfItems = issuedItemsList.length;

      // Calculate totals
      issuedItemsList.forEach((item) => {
        totalIssuedQty +=
          item["Issued Qty"] === null ? 0 : Number(item["Issued Qty"]);
        totalAmount += Number(item.Amount);
      });

      setValue("totalAmt", totalAmount.toFixed(2));
      setValue("totalItems", totalNumberOfItems);
      setValue("totalQty", totalIssuedQty);
    } else {
      let arr = [];

      issuedItemsList.forEach((item) => {
        if (!!item["Return Qty"] && Number(item["Return Qty"]) > 0) {
          arr.push(item);
        }
        totalIssuedQty +=
          item["Return Qty"] === null ? 0 : Number(item["Return Qty"]);
        totalAmount += Number(item.Amount);
      });
      const totalNumberOfItems = arr.length;
      setValue("totalReturnAmt", totalAmount.toFixed(2));
      setValue("netTotalAmt", (InputTotalAmout - totalAmount).toFixed(2));
      setValue("totalItems", totalNumberOfItems);
      setValue("totalQty", totalIssuedQty);
    }
  }, [issuedItemsList, selected_issue_return]);

  return (
    <>
      <div className="mt-12">
        <div className="text-center text-black font-bold text-xl ">
          OT Issued Items
        </div>
        {/* patient Details */}
        <div className="mb-3">
          <fieldset
            className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
          >
            <div className=" grid  grid-cols-4 2xl:grid-cols-4  ">
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.patientName}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">MR. No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.mrNo}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">IPD/OPD No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && (patientInfo.ipdNo || patientInfo.OPDNO)}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Issue No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {/*patientInfo && patientInfo.mrNo*/}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Gender</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.gender}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.ageYear}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Doctor</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.doctorName}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Bed No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.BedNo}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Bed Category</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.BedCategory}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full lg:col-span-2 md:col-span-3 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Ward</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.Ward}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 w-full lg:col-span-2 md:col-span-3 xl:col-span-1 font-semibold text-sm py-1">
                <span className=" font-semibold w-28">Admission Date</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="text-gray-700 font-normal">
                    {patientInfo && patientInfo.admissionDate}
                  </span>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div
          className="grid grid-cols-12 gap-3 items-center pl-2"
          onClick={() =>
            getIssuedItemsList(
              location &&
                location.state !== null &&
                location.state.row.OTHeaderID,
              selectedSurgery?.SurgeryId || 0
            )
          }
        >
          <div className="col-span-4">
            <RadioField
              name="isIssueItem"
              control={control}
              dataArray={issue_return}
            />
          </div>
          <div className="col-span-8 flex gap-3 justify-end">
            {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Create" && (
                  <>
                    <CommonButton
                      type="button"
                      label="Add New"
                      className="bg-customGreen text-white"
                      disabled={patientInfo !== null ? false : true}
                      onClick={() => {
                        handleOpenIssuedItemsModal();
                        setPrivilege(obj.action);
                      }}
                    />
                  </>
                )}
                {!obj.isAction && obj.action === "Create" && (
                  <>
                    <CommonButton
                      type="button"
                      label="Pending Implants"
                      className="bg-customBlue text-white"
                      disabled={patientInfo !== null ? false : true}
                      onClick={() => {
                        handleOpenPendingImplantModal();
                        setPrivilege(obj.action);
                      }}
                    />
                  </>
                )}
              </>
            ))}
          </div>
        </div>

        <hr className="border my-2 divide-x-8 bg-slate-300 border-slate-300" />

        <div className="flex gap-2 flex-wrap">
          {otSurgeries &&
            otSurgeries.map((data) => {
              return (
                <Chip
                  label={data["Surgery Name"]}
                  onClick={() => setSelectedSurgery(data)}
                  variant={
                    selectedSurgery &&
                    selectedSurgery["Surgery Name"] === data["Surgery Name"]
                      ? "filled"
                      : "outlined"
                  }
                  color={"primary"}
                />
              );
            })}
        </div>

        <div className="my-2">
          {issuedItemsList.length > 0 ? (
            <CommonDynamicTableNew
              dataResult={issuedItemsList}
              highlightRow={false}
              renderInput={renderInput}
              editableColumns={["Issued Qty", "Return Qty"]}
              tableClass="h-96 2xl:h-[520px]"
              removeHeaders={[
                "MRP",
                "OTMaterialDetailId",
                "Other Tax Amount",
                "PurchaseRate",
                "QtyAvlForReturn",
                "SellingPrice",
                "StaffFree",
                "StaffFreePercentage",
                "SurgeryId",
                "Tax Amount",
                "Total Net Amount",
                "TotalAmount",
                "TotalForVAT",
                "UnitLandedRate",
                "VAT Amount",
                "VATInclusive",
                "VATPercentage",
                "serviceChargeAmount",
                "Conversion",
                "Discount",
                "DiscountPercentage",
                "IsConsignment",
                "IsPackaged",
                "ChargeId",
                "Concession",
                "ConcessionPercentage",
                "patchExpirDate",
                "ExpiryDate",
                "Expiry Date",
                "ItemId",
              ]}
            />
          ) : (
            <div className="flex justify-center mt-5">
              No Issued Items Found!
            </div>
          )}
        </div>
        {issuedItemsList.length > 0 && (
          <div className="flex justify-end gap-3 my-2">
            <div>
              <InputField
                name="totalItems"
                variant="outlined"
                label="Total Items"
                control={control}
                inputProps={{ maxLength: 100 }}
                disabled={true}
              />
            </div>
            <div>
              <InputField
                name="totalQty"
                variant="outlined"
                label="Total Quantity"
                control={control}
                inputProps={{ maxLength: 100 }}
                disabled={true}
              />
            </div>
            <div>
              <InputField
                name="totalAmt"
                variant="outlined"
                label="Total Amount"
                control={control}
                inputProps={{ maxLength: 100 }}
                disabled={true}
              />
            </div>
            {Number(selected_issue_return) === 0 && (
              <>
                <div>
                  <InputField
                    name="netTotalAmt"
                    variant="outlined"
                    label="Net Total Amount"
                    control={control}
                    inputProps={{ maxLength: 100 }}
                    disabled={true}
                  />
                </div>
                <div>
                  <InputField
                    name="totalReturnAmt"
                    variant="outlined"
                    label="Total Return Amount"
                    control={control}
                    inputProps={{ maxLength: 100 }}
                    disabled={true}
                  />
                </div>
              </>
            )}

            {/* {userActions.map((obj) => (
              <>
                {!obj.isAction && obj.action === "Edit" && (
                  <>
                    <CommonButton
                      type="button"
                      label="Update"
                      className="bg-customGreen text-white"
                      disabled={patientInfo !== null ? false : true}
                      onClick={() => {
                        // handleFormSubmit(obj.action);
                        onUpdateSubmit();
                        setPrivilege(obj.action);
                      }}
                    />
                  </>
                )}
              </>
            ))} */}
          </div>
        )}
      </div>

      {/*View Patient Info*/}
      {openIssuedItemsModal ? (
        <IssuedItemsModal
          open={openIssuedItemsModal}
          setOpen={setOpenIssuedItemsModal}
          handleOpen={handleOpenIssuedItemsModal}
          handleClose={handleCloseIssuedItemsModal}
          patientInfo={patientInfo}
          privilege={privilege}
          menuId={location?.state?.menuId}
          getIssuedItemsList={getIssuedItemsList}
        />
      ) : null}

      {/*Pending Implant Modal*/}
      {openPendingImplantModal ? (
        <PendingImplantModal
          open={openPendingImplantModal}
          setOpen={setOpenPendingImplantModal}
          handleOpen={handleOpenPendingImplantModal}
          handleClose={handleClosePendingImplantModal}
          patientInfo={patientInfo}
          privilege={privilege}
          menuId={location?.state?.menuId}
          selectedRow={location?.state?.row}
          otSurgeries={otSurgeries}
        />
      ) : null}

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleFormUpdateSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg={"Are You Sure ?"}
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default OtDetailsIssuedItems;

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useForm } from "react-hook-form";
import { ForceCloseIcon } from "../../../assets/CommonAssets";

// import { getInvestigations } from "./Services";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import { errorAlert } from "../../../common/components/Toasts/CustomToasts";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import { getInvestigations } from "./Services";


const ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90%",
  width: "40%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  borderRadius: "10px",
  overflowY: "visible",
  p: 1,
};

export default function InvestigationModal(props) {
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const [invs, setInvs] = React.useState([]);
  const {
    open,
    close,
    investigationList,
    setInvestigationList,
    selectedInvestigations,
    setSelectedInvestigations,
    resetInv,
    departmentId,
  } = props;

  const defaultValues = {
    searchInvestigation: "",
  };

  const { setValue, watch, control } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const handleGetServices = (e) => {
    getInvestigations(e, 2)
      .then((response) => response.data)
      .then((res) => {
        setInvestigationOptions(res?.result);
      })
      .catch((er) => er);
  };

  let serviceWatch = watch("searchInvestigation");
  const [investigationOption,setInvestigationOptions]=React.useState([])

  const handleAddService = () => {
    let filtered = selectedInvestigations.filter(
      (inv) => inv.label === serviceWatch?.label
    );

    
    if (filtered.length > 0) {
      errorAlert("Investigation Already Exist");
    } else {
      if (serviceWatch?.id > 0) {
        let investigationObject = {
          // id: serviceWatch?.id,
          testId: serviceWatch?.id,
          label: serviceWatch?.label,
          value: serviceWatch?.value,
          isChecked: true,
          isUrgent: false,
          Quantity: 1,
          serviceId: serviceWatch?.serviceId,
          serviceName: serviceWatch?.serviceName,
          testType: serviceWatch?.testType,
          testTypeId: serviceWatch?.testTypeId,
          isNew: false,
          deleteFlag: false,
        };
        let dataArray = [...invs];
        dataArray.push(investigationObject);
        setInvs(dataArray);
        setValue("searchInvestigation", null);
      } else {
        // errorAlert("Investigation already exists .");
        setValue("searchInvestigation", null);
      }
    }
  };

  React.useEffect(() => {
    if (serviceWatch !== null && serviceWatch?.label !== "") {
      handleAddService();
    }
  }, [serviceWatch]);

  const handleDeleteInv = (inv) => {
    let dataArray = [...invs];
    let filtered = dataArray.filter((item) => item !== inv);
    setInvs(filtered);
  };

  const combinedArray = [...invs, ...selectedInvestigations];
  const uniqueArray = Array.from(
    new Map(combinedArray.map((item) => [item.label, item])).values()
  );

  uniqueArray.sort((a, b) => {
    if (a.value === null) return 1;
    if (b.value === null) return -1;
    return a.value - b.value;
  });

  const handleAddInvs = () => {
    let filtered = selectedInvestigations.filter(
      (inv) => inv.label === serviceWatch?.label
    );
    let filteredinvs = invs.filter((inv) => inv.label === serviceWatch?.label);

    if (filtered.length > 0 || filteredinvs.length > 0) {
      errorAlert("Investigation Already Exist!");
    } else {
      setSelectedInvestigations(uniqueArray);
      close();
      setInvs([]);
      setInvestigationList([]);
    }
  };

  React.useEffect(() => {
    
    setInvs(selectedInvestigations);
  }, [selectedInvestigations]);
  
  console.log(invs, "selectedInvestigations");
  return (
    <div>
      <div
        onKeyDown={(e) => {
          // if (e.key === "Enter") {
          //   handleAddService();
          // }
        }}
      >
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            <div className="flex justify-between">
              <div>
                <div className="flex justify-center font-bold text-xl my-2 px-3">
                  Investigations
                </div>
              </div>
              <button
                onClick={() => {
                  close();
                }}
                type="button"
              >
                <ForceCloseIcon />
              </button>
            </div>
            <div className="border-t border-slate-400 p-2">
              <SearchDropdown
                name={"searchInvestigation"}
                placeholder={"Search Services"}
                dataArray={investigationOption}
                control={control}
                searchIcon={true}
                handleInputChange={handleGetServices}
                menuPlacement={"bottom"}
              />
            </div>
            <div className="my-2">
              {invs?.length > 0
                ? invs.map((inv, index) => {
                    setValue(`isUrgent${inv?.value}`, inv?.isUrgent);
                    // if (inv?.isChecked === true) {
                      return (
                        <div className="flex gap-2 items-center border border-gray-200 py">
                          <div>
                            <button
                              className="h-5 "
                              type="button"
                              onClick={() => {
                                handleDeleteInv(inv);
                              }}
                            >
                              <DeleteOutlineIcon
                                fontSize="small"
                                sx={{ color: "red" }}
                              />
                            </button>
                          </div>
                          <div className="w-[70%] flex justify-start px-2 ">
                            {inv?.label}
                          </div>
                          {/* <div className="flex justify-start px-2 ">
                            {inv?.Quantity}
                          </div> */}
                          <fieldset
                            onChange={(e) => {
                              let dataArray = [...invs];
                              dataArray[index].isUrgent = e.target.checked;
                              setInvs(dataArray);
                            }}
                            className=" flex justify-start px-2 "
                          ></fieldset>
                        </div>
                      );
                    // }
                  })
                : ""}
            </div>

            <div className="flex justify-end my-2 px-2">
              <CommonButton
                label={"Add"}
                type={"button"}
                className={"px-3 bg-[#368505] text-white h-9 rounded"}
                onClick={() => {
                  handleAddInvs();
                }}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Checkbox, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import {
  cancelVerifyPurchaseOrder,
  fetchAllPurchaseOrderItemDetailList,
  fetchPrintPOForm,
  searchPONumber,
  searchSupplier,
} from "../../../services/generalStore/purchaseorder/PurchaseOrderServices";
import { VerifyDisabledIcon } from "../verifyGrn/assets/VerifyDisabledIcon";
import { VerifyIcon } from "../verifyGrn/assets/VerifyIcon";
import { EditIcon } from "../../../../assets/CommonAssets";

const Verification = [
  {
    id: "All",
    value: "All",
    label: "All",
  },
  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
  {
    id: "Completed",
    value: "Completed",
    label: "Completed",
  },
];

export default function POListModal(props) {
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    height: "80%",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  const defaultValues = {
    verification: "All",
    ammendmentpo: false,
    cancelledpo: false,
    searchPurchaseOrderNumber: null,
    storeName: "",
    supplierName: null,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const {
    control,
    reset,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  let searchPurchaseOrderNumber = watch("searchPurchaseOrderNumber");
  let supplierName = watch("supplierName");
  let verification = watch("verification");
  let ammendmentpo = watch("ammendmentpo");
  let cancelledpo = watch("cancelledpo");
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");
  let cancelOrVerify;
  if (fromDate) {
    props.setSelectedPOListFromDate(fromDate);
  }
  if (toDate) {
    props.setSelectedPOListToDate(toDate);
  }

  useEffect(() => {
    props.setisCancelled(cancelledpo);

    if (verification === "All") {
      props.setIsOrderPlaced(2);
    } else if (verification === "Completed") {
      props.setIsOrderPlaced(1);
    } else if (verification === "Pending") {
      props.setIsOrderPlaced(0);
    }
  }, [ammendmentpo, cancelledpo, verification]);

  const [poId, setPoId] = React.useState(null);

  const [poItemDataResult, setPOItemDataResult] = React.useState([]);
  const [supplier, setSupplier] = React.useState([]);
  const [poNumber, setPONumber] = React.useState([]);
  const [openPost, setOpenPost] = React.useState(false);
  const [status, setStatus] = useState();

  const [checkBoxIndex, setCheckBoxIndex] = React.useState(-1);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };

  useEffect(() => {
    props.populatePOListTable();
  }, [
    searchPurchaseOrderNumber,
    supplierName,
    props.selectedPOListFromDate,
    props.selectedPOListToDate,
    props.isCancelled,
    props.isOrderPlaced,
    ammendmentpo,
  ]);

  //Searchable Supplier Api
  const handleSupplierChange = (autoSearchSupplier) => {
    if (autoSearchSupplier !== "") {
      searchSupplier(autoSearchSupplier)
        .then((response) => response.data)
        .then((res) => {
          setSupplier(res.result);
        });
    }
  };

  // Search PO Number Api
  const handlePoNumberChange = (searchString) => {
    if (searchString !== "") {
      searchPONumber(searchString)
        .then((response) => response.data)
        .then((res) => {
          setPONumber(res.result);
        });
    }
  };

  React.useEffect(() => {
    setValue("storeName", props.getUserInfo.storeName);
  }, [props.getUserInfo]);

  const handleVerifyCancelPo = () => {
    if (poId !== null) {
      cancelVerifyPurchaseOrder(poId, status, props.menuId, props.privilege)
        .then((response) => response.data)
        .then((res) => {
          if (res.statusCode === 200) {
            successAlert(res.message);
            setPONumber(res.result);
            setOpenPost(false);
            props.populatePOListTable();
            setCheckBoxIndex(-1);
          }
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
        });
    }
  };

  //Single Select
  const handleChange = (e, row, requiredIndex) => {
    let obj = {
      purchaseOrderIp: row.DOId,
      storeId: row.StoreId,
      supplierId: row.SupplierId,
    };

    setCheckBoxIndex(requiredIndex);

    if (e.target.checked === true) {
      fetchAllPurchaseOrderItemDetailList(obj)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          setPOItemDataResult(res.result);
        });
    }
  };

  //prints
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };
  const handleOpenPrintModal = (poId) => {
    fetchPrintPOForm(poId).then((response) => {
      setUrlforPrint(response);
      setOpenPrintModal(true);
    });
  };

  const handleEditPo = (row) => {
    console.log("The po to edit is :", row);
    let obj = {
      purchaseOrderIp: row.DOId,
      storeId: row.StoreId,
      supplierId: row.SupplierId,
    };

    let supplier = {
      id: row?.SupplierId,
      label: row?.Supplier,
      value: row?.SupplierId,
    };
    props.setPoId(row?.DOId)
    props.setEditSupplier(supplier)

    fetchAllPurchaseOrderItemDetailList(obj)
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        if (res?.result?.length > 0) {
          let editData = [];

          for (let item of res?.result) {
            let modified = {
              searchItemCode: {
                id: item?.ItemId,
                label: item?.ItemName,
              },
              quantity: Number(item?.DOQty),
              uom: item?.UOM,
              rate: Number(item?.Rate),
              totalAmount: Number(item?.Amount),
              discPercentage: Number(item?.DiscPer),
              discAmount: Number(item?.DiscAmt),
              gstPercentage: Number(item?.VATPer),
              gstAmount: Number(item?.VATAmt),
              id: item?.DOId,
              netAmount: Number(item?.NetAmount),
              specification: item?.Specification,
              mrp: item?.MRP,
              doQty: Number(item?.DOQty),
              creditPeriod: "",
              expDeliveryDate: "2023-05-05T11:13:27.340Z",
              freeQty: 0,
              indentDetailsId: 0,
              poDetailsId: item?.DODetailId,
              isOrderPlaced: true,
              itemId: item?.ItemId,
              itemManufacturedById: 0,
              markForClosed: false,
              pendQty: 0,
            };
            console.log("eeeeeeeeeeeeeeeeeeeeeeeeee",editData);
            
            editData?.push(modified);
            props.setIsEditPo(true);
            props.setPoData(editData);
            props.handlePOListClose();
          }
        }
        // setPOItemDataResult(res.result);
      });
  };

  //Table
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        <>
          <div className="flex pt-1 justify-center gap-2 cursor-pointer">
            <>
              <Checkbox
                size="small"
                className="w-4 h-4 border border-gray-600 rounded"
                type="checkbox"
                color="primary"
                checked={checkBoxIndex === index ? true : false}
                value={index}
                onChange={(e) => {
                  handleChange(e, row, index);
                }}
                inputProps={{
                  "aria-label": "primary checkbox",
                }}
                name={`indentCheckBox${index}`}
              />
            </>
            {props?.userActions.map((actions, i) => (
              <>
                {actions.isAction === true ? (
                  <>
                    {actions.action === "Approve" ? (
                      <>
                        {row.Verified === false ? (
                          <Tooltip title="Verify PO">
                            <span
                              onClick={() => {
                                props.setPrivilege(actions.action);
                                setOpenPost(true);
                                setStatus(1);
                                setPoId(row.DOId);
                              }}
                            >
                              <VerifyIcon className="pl-3" />
                            </span>
                          </Tooltip>
                        ) : (
                          <VerifyDisabledIcon className="pl-3" />
                        )}
                      </>
                    ) : null}

                    {actions.action === "Cancel" ? (
                      <>
                        {row.IsCancelled === false ? (
                          <Tooltip title="Cancel PO">
                            <span
                              className="text-black"
                              onClick={() => {
                                setPoId(row.DOId);
                                props.setPrivilege(actions.action);

                                setOpenPost(true);
                                setStatus(0);
                              }}
                            >
                              <HighlightOffIcon />
                            </span>
                          </Tooltip>
                        ) : (
                          <span className="text-gray-300">
                            <HighlightOffIcon />
                          </span>
                        )}
                      </>
                    ) : null}

                    {actions.action === "Print" ? (
                      <>
                        <Tooltip title="Print PO">
                          <LocalPrintshopIcon
                            sx={{ color: "#4B5563" }}
                            onClick={() => {
                              handleOpenPrintModal(row.DOId);
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                    {actions.action === "Print" ? (
                      <>
                        <Tooltip title="Edit PO">
                          <CommonButton
                            label={<EditIcon />}
                            type={"button"}
                            onClick={() => {
                              handleEditPo(row);
                            }}
                            className={"h-6 px-[1px]"}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={props.poListModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[80%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.handlePOListClose();
              setPOItemDataResult([]);
              props.setDataResult([]);
              props.setSelectedPOListFromDate(new Date());
              props.setSelectedPOListToDate(new Date());
              setCheckBoxIndex(-1);
            }}
          />
          <div className="row px-4">
            <div className="px-2 ml-2 lg:ml-0 font-bold text-gray-800">
              Purchase Order List
            </div>
            <div className="grid grid-cols-4 pt-4 gap-2">
              <div className="">
                <SearchDropdown
                  control={control}
                  name="searchPurchaseOrderNumber"
                  label="Search PO Number"
                  placeholder="Search PO Number"
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  dataArray={poNumber}
                  handleInputChange={handlePoNumberChange}
                />
              </div>
              <div className="w-full whitespace-nowrap">
                <InputField
                  name="storeName"
                  variant="outlined"
                  label="Store Name"
                  disabled={true}
                  error={errors.storeName}
                  control={control}
                />
              </div>
              <div className="">
                <SearchDropdown
                  control={control}
                  name="supplierName"
                  placeholder="Supplier Name"
                  error={errors.supplierName}
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  dataArray={supplier}
                  isMulti={false}
                  handleInputChange={handleSupplierChange}
                  inputRef={{
                    ...register("supplierName", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          props.setPoListModalSupplierID(e.target.value.id);
                        } else if (e.target.value === null) {
                          props.setPoListModalSupplierID(0);
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div className="flex gap-3">
                <DatePickerFieldNew
                  control={control}
                  name="fromDate"
                  label="From Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("fromDate", newValue);
                  }}
                  disablePast={false}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />

                <DatePickerFieldNew
                  control={control}
                  name="toDate"
                  label="To Date"
                  value={new Date()}
                  onChange={(newValue) => {
                    setValue("toDate", newValue);
                  }}
                  disablePast={false}
                  disableFuture={true}
                  inputFormat="dd-MM-yyyy"
                />
              </div>
            </div>

            <div className="grid grid-cols-4 gap-3 pt-3">
              <div className="flex col-span-3 items-center text-sm space-x-2">
                <div className="flex items-center space-x-2 pt-1 mr-4">
                  <label className="px-1 font-bold whitespace-nowrap ">
                    Status:
                  </label>

                  <RadioField
                    label=""
                    name="verification"
                    control={control}
                    dataArray={Verification}
                  />
                </div>

                <div className="">
                  <CheckBoxField
                    control={control}
                    name="ammendmentpo"
                    label="Amendment PO's"
                  />
                </div>
                <div className="">
                  <CheckBoxField
                    control={control}
                    name="cancelledpo"
                    label="Cancelled PO's"
                  />
                </div>
                <div className="flex gap-3">
                  <CommonButton
                    type="button"
                    className="border border-customRed text-customRed"
                    label="Reset"
                    onClick={() => {
                      reset(defaultValues);
                      props.setSelectedPOListFromDate(new Date());
                      props.setSelectedPOListToDate(new Date());
                      setPOItemDataResult([]);
                      setCheckBoxIndex(-1);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-full">
              {props.dataResult.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={props.dataResult}
                  setDataResult={props.setDataResult}
                  populateTable={props.populatePOListTable}
                  page={props.page}
                  setPage={props.setPage}
                  rowsPerPage={props.rowsPerPage}
                  count={props.count}
                  renderActions={renderActions}
                  highlightRow={true}
                  removeHeaders={[
                    "DOId",
                    "StoreId",
                    "SupplierId",
                    "IsOrderPlaced",
                  ]}
                  tableHeading={"Purchase Order List"}
                />
              ) : (
                <h3 className="flex justify-center mt-12 mb-10 font-bold text-gray-600">
                  No Records Found...
                </h3>
              )}
            </div>

            <div className="w-full">
              {checkBoxIndex !== -1 &&
              poItemDataResult !== undefined &&
              poItemDataResult.length > 0 ? (
                <>
                  <span className="text-sm font font-semibold ">
                    Purchase Order Detail List
                  </span>
                  <CommonDynamicTableNew
                    dataResult={poItemDataResult}
                    tableClass={"rounded h-64"}
                    removeHeaders={[
                      "ItemId",
                      "DOId",
                      "DODetailId",
                      "SupplierId",
                    ]}
                    highlightRow={false}
                  />
                </>
              ) : (
                <h3 className="flex justify-center mt-12 mb-10 font-bold text-gray-600">
                  No Records Found...
                </h3>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={handleVerifyCancelPo}
        confirmationLabel="Confirmation"
        // status === 0 = "cancel" status === 1 "Verify"
        confirmationMsg={`Are you sure want to ${
          status === 0 ? "Cancel" : "Verify"
        } this record ?`}
        confirmationButtonMsg="Add"
      />

      {/* Print Modal */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
    </>
  );
}

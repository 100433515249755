import { TextField } from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import InputField from "../../../../common/components/FormFields/InputField";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";

const DeathSummary = (props) => {
  const { deathDate, setDeathDate, deathTime, setDeathTime } = useContext(
    DischargeSummaryContext
  );

  const handleChange = (newValue) => {
    setDeathTime(newValue);
  };

  console.log("deathTime123", deathTime);

  const { control } = useFormContext();
  return (
    <div>
      <div className="">
        <div className="grid grid-cols-3 lg:grid-cols-5 gap-3 pt-3">
          <div className="">Date And Time Of Death -</div>
          <div className=" ">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disableFuture
                label="Date Of Death"
                value={deathDate}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  setDeathDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    className="bg-white"
                    name="dateOfDeath"
                    fullWidth
                    size="small"
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    sx={{
                      svg: { color: "#0B83A5" },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className=" ">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Time"
                value={deathTime}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    className="bg-white"
                    name="timeOfDeath"
                    fullWidth
                    size="small"
                    {...params}
                    // inputProps={{ ...params.inputProps, readOnly: true }}
                    sx={{
                      svg: { color: "#0B83A5" },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3 pt-3">
        {/* Cause Of Death */}
        <div className=" ">
          <InputField
            name="causeOfDeath"
            label="Cause Of Death"
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        {/* Identification Mark */}
        <div className=" ">
          <InputField
            control={control}
            name="identificationMark"
            label="Identification Mark"
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        {/* Dying Declaration If Recorded */}
        <div className=" ">
          <InputField
            name="dyingDeclaration"
            label="Dying Declaration If Recorded"
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        {/* Material is For Any Mediacal */}
        <div className=" ">
          <InputField
            name="materialForMedical"
            label="Material is For Any Medical"
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        {/* Specific Resons For Postmortem*/}
        <div className=" ">
          <InputField
            name="resonsForPostmortem"
            label="Specific Reasons For Postmortem  "
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        {/* Option About Cause Of Death */}
        <div className=" ">
          <InputField
            name="optionAboutCauseOfDeath"
            label="Option About Cause Of Death"
            control={control}
            disabled={false}
            inputProps={{ maxLength: 50 }}
          />
        </div>
      </div>
    </div>
  );
};

export default DeathSummary;

import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CreateableSelect from "../../../../common/components/FormFields/CreateableSelect";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import { fetchDischargeAdvice } from "../../../services/dischargeSummary/DischargeSummaryServices";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";
import { warningAlert } from "../../../../common/components/Toasts/CustomToasts";

const Schema = yup.object().shape({
  advice: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().notRequired(),
      label: yup.string().notRequired(),
    })
    .required("Required"),
});

const SpecificAdvices = () => {
  const { specificAdvices, setSpecificAdvices } = useContext(
    DischargeSummaryContext
  );
  const [adviceList, setAdviceList] = useState([]);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(Schema),
    defaultValues: { advice: "" },
  });

  const {
    control,
    formState: { errors },
    getValues,
  } = methods;

  useEffect(() => {
    DrugAdvices();
  }, []);

  const DrugAdvices = () => {
    fetchDischargeAdvice()
      .then((response) => response.data)
      .then((res) => {
        setAdviceList(res.result);
      });
  };

  const onSubmitAdvices = () => {
    let data = getValues("advice");
    console.log("advice", data, specificAdvices);

    const selectedAdvice = data;

    if (
      selectedAdvice.__isNew__ ||
      (selectedAdvice &&
        !specificAdvices.some((item) => item?.id === selectedAdvice?.id))
    ) {
      setSpecificAdvices((prev) => [...prev, selectedAdvice]);
      methods.reset();
    } else {
      warningAlert("Advice Already Added!");
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setSpecificAdvices((chips) =>
      chips.filter((chip) => chip.label !== chipToDelete.label)
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="h-[276px]" onKeyDown={handleKeyDown}>
      <div className="flex gap-3">
        <div className="w-9/12">
          <CreateableSelect
            control={control}
            error={errors.advice}
            name="advice"
            placeholder="Advice"
            dataArray={adviceList}
            isSearchable={false}
          />
        </div>
        <div className="flex justify-end">
          <CommonButton
            label="Add"
            className="bg-customGreen text-white"
            onClick={onSubmitAdvices}
          />
        </div>
      </div>

      <div className="flex whitespace-nowrap gap-2 flex-wrap max-h-56 mt-3 overflow-y-auto">
        {specificAdvices.map((data, index) => (
          <div key={data.id} className="flex items-center gap-2 m-1">
            <div className="flex w-full items-center px-3 py-1 text-sm font-medium text-white bg-gray-400 rounded-xl whitespace-pre-wrap break-words">
              <input
                autoCorrect="off"
                autoComplete="off"
                spellCheck="off"
                style={{
                  outline: "none",
                }}
                defaultValue={data.AdviceDesc || data.value || data.label}
                className="bg-gray-400 w-[100%]"
                onChange={(e) => {
                  let dataArray = [...specificAdvices];
                  dataArray[index].label = e.target.value;
                  dataArray[index].value = e.target.value;

                  setSpecificAdvices(dataArray);
                }}
              />
              <button
                onClick={handleDelete(data)}
                className="ml-2 text-white bg-gray-600 rounded-full w-4 h-4 flex items-center justify-center"
                aria-label="Delete"
              >
                &times;
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecificAdvices;

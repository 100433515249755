import { SignalCellularNullSharp } from "@mui/icons-material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { fetchDoctor } from "../../../../commonServices/miscellaneousServices/MiscServices";
import {
  fetchDeathSummary,
  fetchDischargeSummaryDetails,
  fetchDischargeSummarySearchDropDown,
  fetchDishargePatientInfo,
  fetchPrintDischargeSummary,
  fetchPrintDischargeSummaryHistorySheet,
  fetchPrintEmbryoTransferCase,
  fetchPrintGyneacCase,
  fetchPrintMtpCaseSheet,
  fetchPrintNeonatal,
  fetchPrintObstetricCaseSheet,
  fetchPrintOvumPICKUP,
  fetchPrintPediatricSummary,
  postDischargeSummaryData,
} from "../../../services/dischargeSummary/DischargeSummaryServices";
import DischargeSummaryTabs from "./DischargeSummaryTabs";

////////

import { Divider, IconButton, Tooltip } from "@mui/material";
import { format, isValid } from "date-fns";
import { createContext } from "react";
import { EditOnIcon, PrintOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import MLCModalEdit from "../../../../common/components/mlcDetails/MLCModalEdit";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import { restrictSamePatientToMultiUserActivity } from "../../../../login/services/LoginServices.js";
import TentativeDischargeModal from "./TentativeDischargeModal";

export const CustomPrintButton = ({
  type,
  onClick,
  label,
  className,
  // disabled,
}) => {
  return (
    <button
      className={`flex items-center rounded border border-black hover:bg-[#b5bbd8] px-1 py-0 text-black h-[31px] my-1 ${className} `}
      onClick={onClick}
      type={type ? type : "button"}
    >
      <h1 className="flex items-center space-x-1 py-0 ">
        <span className="text-customGray">
          <PrintOnIcon />
        </span>
        <span>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            color={"#39314F"}
            style={{
              height: 16,
            }}
          />
        </span>

        <label className="text-center text-zinc-800 text-xs font-medium  capitalize leading-9 tracking-wide">
          {label}
        </label>
      </h1>
    </button>
  );
};

export const DischargeSummaryContext = createContext("");

/////////////

//for neonatal
const checklistArr = [
  {
    id: 1,
    label: "OAE",
    done: false,
    notDone: false,
  },
  {
    id: 2,
    label: "Vaccination",
    done: false,
    notDone: false,
  },
  {
    id: 3,
    label: "New Born Screening",
    done: false,
    notDone: false,
  },
  {
    id: 4,
    label: "TCB",
    done: false,
    notDone: false,
  },
  {
    id: 5,
    label: "Four Limb Saturation",
    done: false,
    notDone: false,
  },
  {
    id: 6,
    label: "Four Limb BP",
    done: false,
    notDone: false,
  },
  {
    id: 7,
    label: "Red Reflex",
    done: false,
    notDone: false,
  },
];

const DischargeSummaryTabWise = (props) => {
  let location = useLocation();
  let token = JSON.parse(localStorage.getItem("userInfo"));

  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState(null);

  //
  const [tabValue, setTabValue] = useState(0);
  // states
  const [printType, setPrintType] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [options, setOptions] = useState([]);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  const [admissionId, setAdmissionId] = useState(null);
  const [patientId, setPatientId] = useState(null);
  // const [selectedPatient, setSelectedPatient] = useState(null);
  // const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [dischargeSummaryId, setDischargeSummaryId] = useState(0);
  //
  const [mewsScore, setMewsScore] = useState();
  const [mewsScoreVitals, setMewsScoreVitals] = useState();
  //specific advices
  const [specificAdvices, setSpecificAdvices] = useState([]);

  //
  const [allMedicationData, setAllMedicationData] = useState([]);
  const [lastUpdatedVtalDate, setLastUpdatedVtalDate] = useState();
  const [deletedMedicationData, setDeletedMedicationData] = useState([]);

  //
  const [followupDate, setFollowupDate] = useState(new Date());

  //menstrul hstry dates
  const [lmpDate, setlmpDate] = useState(null);
  const [eddDate, seteddDate] = useState(null);
  const [pogAccordingDate, setPogAccordingDate] = useState(null);
  const [accordingUsgDate, setAccordingUsgDate] = useState(null);

  //
  const [deathDate, setDeathDate] = useState(new Date());
  const [deathTime, setDeathTime] = useState(new Date());

  //
  const [doctor, setDoctor] = useState();
  const [doctorSearchString, setDoctorSearchString] = useState(null);

  //
  const [patchData, setPatchData] = useState(null);
  //
  const [retrivedDrugData, setRetrivedDrugData] = React.useState([]);

  //finalize
  const [isFinalize, setIsFinalize] = useState();
  //
  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  //AllowMultiPatientAccess Modal
  const [
    openAllowMultiPatientAccessModal,
    setOpenAllowMultiPatientAccessModal,
  ] = useState(false);
  const [multiUserMsg, setMultiUserMsg] = useState(null);

  //Tentative Discharge Modal
  const [openTentativeDischargeModal, setOpenTentativeDischargeModal] =
    useState(false);

  //states to transfer data from onsubmit to final submit
  const [stateDeathSummaryDto, setStateDeathSummaryDto] = useState();
  const [stateFamilyHistoryDto, setStateFamilyHistoryDto] = useState();
  const [stateGeneralExaminationDto, setStateGeneralExaminationDto] =
    useState();
  const [stateGynecologyDetailsDto, setStateGynecologyDetailsDto] = useState();
  const [stateHistorySheetDtoList, setStateHistorySheetDtoList] = useState();
  const [stateOtherHistoryDto, setStateOtherHistoryDto] = useState();
  const [statePaediatricDetailsDto, setStatePaediatricDetailsDto] = useState();
  const [statePatientDetailsDto, setStatePatientDetailsDto] = useState();
  const [stateNeonatalDto, setStateNeonatalDto] = useState(null);
  const [mainVitals, setMainVitals] = useState("");
  //
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  //
  //mlc modal
  const [openMlcModal, setOpenMlcModal] = useState(false);
  const handleOpenMlcModal = () => setOpenMlcModal(true);
  const handleCloseMlcModal = () => setOpenMlcModal(false);
  //
  const [neonatalChecklist, setNeonatalChecklist] = useState(
    structuredClone([...checklistArr])
  );

  const [treatmentInfo, setTreatmentInfo] = useState({
    check: false,
    value: "",
  });
  /////////

  const resetDefaultValues = {
    tentativeDischarge: "",
    isDeathSummary: "",
    //Old vitals data
    inputTempOld: "",
    inputPulserateOld: "",
    inputBPOld: "",
    inputSpO2Old: "",
    inputWeightOld: "",
    inputHeightOld: "",
    inputRespirationOld: "",
    inputMEWSScoreOld: "",
    inputPainScoreOld: "",
    inputHCOld: "",
    inputMalnutritionOld: "",
    //Patient Details
    conditionAtDischarge: "",
    courseInHospital: "",
    diagnosisICD: "",
    investigationsPathology: "",
    investigations: 0,

    //HistorySheet
    chiefComplaint: "",
    historyOfPresentIllness: "",
    surgicalHistory: "",
    treatmentHistory: "",
    familyHistory: "",
    dm: "",
    dmDtls: "",
    ihd: "",
    ihdDtls: "",
    asthama: "",
    asthamaDtls: "",
    stroke: "",
    strokeDtls: "",
    htn: "",
    htnDtls: "",
    copd: "",
    copdDtls: "",
    tuberculosis: "",
    tuberculosisDtls: "",
    other: "", //cancel
    otherDtls: "",

    // GeneralExamination
    temp: "",
    pulse: "",
    bp: "",
    spo2: "",
    weight: "",
    height: "",
    breast: "",
    respiration: "",
    mewsScore: "",
    pewsScore: "",
    painScore: "",
    hc: "",
    malnutrition: "",
    cyanosis: "",
    PeripheralPulses: "",
    jvp: "",
    pallor: "",
    oedema: "",
    clubbing: "",
    lymphadenopathy: "",
    lcterus: "",

    //systematic Examination
    crt: "",
    localExamination: "",
    cvs: "",
    rs: "",
    perAbdomen: "",
    cns: "",
    // Family History
    fHtn: "",
    fDm: "",
    fTuberculosis: "",
    fIhd: "",
    fAsthama: "",
    fStroke: "",
    fOtherDiseases: "",
    //other History
    bowelBladder: "",
    bloodTransfusionHistory: "",
    allergy: "",
    personalHistory: "",
    //Operative Notes
    operativeNotes: "",
    RetriveMedication: "", /// table data
    //Medication On Discharge
    medicationOnDischarge: "", /// table data
    //Medication Advices
    MedicationAdvices: "",
    FollowUpInstructions: "",
    //Specific Advices
    SpecificAdvices: "", //arrey - multiple values
    //On Discharge Instruction
    diet: "",
    physiotherapyInstructions: "",
    investigationsBeforeInstructions: "",
    reportToHospital: "",
    //Gynecology History
    obstHistory: "",
    historyOfIllness: "",
    noOfDeliveries: "",
    routeofDeliveries: "",
    lastChildBirth: "",
    placeOfDeliveries: "",
    sexOfBody: "",
    // Menstrual History
    lmpC: "",
    lmp: null,
    eddC: "",
    eddDate: null,
    pogC: "",
    pog: null,
    usgC: "",
    usg: null,
    cycles: "",
    g: "",
    p: "",
    l: "",
    a: "",
    d: "",
    marriedSince: "",
    mnstlHistInput: "",
    //Gynecology Details
    onExamination: "",
    labourNotes: "",
    tubalLigation: "",
    perSpeculam: "",
    perVaginalExam: "",
    investigation: "",

    //General Details
    adviceForMlc: "0",
    ifYes: "",
    documentFormalities: "",
    painAbdomen: "",
    since: "",
    vaginalBleeding: "",
    historyOfLeaking: "",
    perVaginal: "",

    //Pediatric Details
    // neonatal: false,
    birthHistoryPediatric: "",
    immunisationHistory: "",
    dietaryHistory: "",
    developmentHistory: "",
    socialHistory: "",

    // Urgent Care Instruction
    urgentCareInstruction: "",

    //Follow Up Date And Doctor
    followUpDate: new Date(),
    followUpWithDoctor: "",

    //Death Summary
    dateOfDeath: new Date(),
    timeOfDeath: new Date(),
    causeOfDeath: "",
    identificationMark: "", //remain
    dyingDeclaration: "",
    materialForMedical: "", //remain
    resonsForPostmortem: "",
    optionAboutCauseOfDeath: "",

    //Doctor
    doctor: "",

    // Neonatal Modal in Pediatric Details
    timeofbirth: null,
    // birthHistoryNeonatal: "",//remove
    isbreastfeeding: false,
    age: "",
    bloodGroup: "",
    gravityparity: "",
    gastationalIllness: "",
    FatherBloodGroup: "",
    obstraticIllness: "",
    MedicalIllness: "",
    At1Min: "",
    At5Min: "",
    resuscitationDetails: "",
    birthDaweightonDischargeteTime: "",
    lengthOnAdmission: "",
    weightOnAdmission: "",
    weightOnDischarge: "",
    lengthOnDischarge: "",
    headCircumferenceAdmission: "",
    HeadCircumferenceOnDischarge: "",
    rightUpperLimb: "",
    rightLowerLimb: "",
    leftUpperLimb: "",
    leftLowerLimb: "",
    treatmentDesc: "",
  };

  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      //
      bpVitals: "",
      tempVitals: "",
      pulseVitals: "",
      spo2Vitals: "",
      weightVitals: "",
      heightVitals: "",
      respirationVitals: "",
      hcVitals: "",
      painScoreVitals: "",
      malnutritionVitals: "",
      mewsScoreVitals: "",
      // dischrgeSummarySearch: "",
      isCurrentyAdmitted: true,
      //
      tentativeDischarge: "",
      isDeathSummary: "",
      //Old vitals data
      inputTempOld: "",
      inputPulserateOld: "",
      inputBPOld: "",
      inputSpO2Old: "",
      inputWeightOld: "",
      inputHeightOld: "",
      inputRespirationOld: "",
      inputMEWSScoreOld: "",
      inputPainScoreOld: "",
      inputHCOld: "",
      inputMalnutritionOld: "",
      //Patient Details
      conditionAtDischarge: "",
      courseInHospital: "",
      diagnosisICD: "",
      investigationsPathology: "",
      investigations: 0,
      //HistorySheet
      chiefComplaint: "",
      historyOfPresentIllness: "",
      surgicalHistory: "",
      treatmentHistory: "",
      familyHistory: "",
      dm: "",
      dmDtls: "",
      ihd: "",
      ihdDtls: "",
      asthama: "",
      asthamaDtls: "",
      stroke: "",
      strokeDtls: "",
      htn: "",
      htnDtls: "",
      copd: "",
      copdDtls: "",
      tuberculosis: "",
      tuberculosisDtls: "",
      other: "", //cancel
      otherDtls: "",

      // GeneralExamination
      temp: "",
      pulse: "",
      bp: "",
      spo2: "",
      weight: "",
      height: "",
      breast: "",
      respiration: "",
      mewsScore: "",
      mewsRadio: "0",
      painScore: "",
      hc: "",
      malnutrition: "",
      cyanosis: "",
      PeripheralPulses: "",
      jvp: "",
      pallor: "",
      oedema: "",
      clubbing: "",
      lymphadenopathy: "",
      lcterus: "",

      //systematic Examination
      crt: "",
      localExamination: "",
      cvs: "",
      rs: "",
      perAbdomen: "",
      cns: "",
      // Family History
      fHtn: "",
      fDm: "",
      fTuberculosis: "",
      fIhd: "",
      fAsthama: "",
      fStroke: "",
      fOtherDiseases: "",
      //other History
      bowelBladder: "",
      bloodTransfusionHistory: "",
      allergy: "",
      personalHistory: "",
      //Operative Notes
      operativeNotes: "",
      RetriveMedication: "", /// table data
      //Medication On Discharge
      medicationOnDischarge: "", /// table data
      //Medication Advices
      MedicationAdvices: "",
      FollowUpInstructions: "",
      //Specific Advices
      SpecificAdvices: "", //arrey - multiple values
      //On Discharge Instruction
      diet: "",
      physiotherapyInstructions: "",
      investigationsBeforeInstructions: "",
      reportToHospital: "",
      //Gynecology History
      obstHistory: "",
      historyOfIllness: "",
      noOfDeliveries: "",
      routeofDeliveries: "",
      lastChildBirth: "",
      placeOfDeliveries: "",
      sexOfBody: "",
      // Menstrual History
      lmpC: "",
      lmp: null,
      eddC: "",
      eddDate: null,
      pogC: "",
      pog: null,
      usgC: "",
      usg: null,
      cycles: "",
      g: "",
      p: "",
      l: "",
      a: "",
      d: "",
      marriedSince: "",
      mnstlHistInput: "",
      //Gynecology Details
      onExamination: "",
      labourNotes: "",
      tubalLigation: "",
      perSpeculam: "",
      perVaginalExam: "",
      investigation: "",

      //General Details
      adviceForMlc: "no",
      ifYes: "",
      documentFormalities: "",
      painAbdomen: "",
      since: "",
      vaginalBleeding: "",
      historyOfLeaking: "",
      perVaginal: "",

      //Pediatric Details
      // neonatal: false,
      birthHistoryPediatric: "",
      immunisationHistory: "",
      dietaryHistory: "",
      developmentHistory: "",
      socialHistory: "",

      // Urgent Care Instruction
      urgentCareInstruction: "",

      //Follow Up Date And Doctor
      followUpDate: new Date(),
      followUpTime: new Date(),
      followUpWithDoctor: "",

      //Death Summary
      dateOfDeath: new Date(),
      timeOfDeath: new Date(),
      causeOfDeath: "",
      identificationMark: "", //remain
      dyingDeclaration: "",
      materialForMedical: "", //remain
      resonsForPostmortem: "",
      optionAboutCauseOfDeath: "",

      //Doctor
      doctor: 0,

      // Neonatal Modal in Pediatric Details
      timeofbirth: null,
      // birthHistoryNeonatal: "",//remove
      isbreastfeeding: false,
      age: "",
      bloodGroup: "",
      gravityparity: "",
      gastationalIllness: "",
      FatherBloodGroup: "",
      obstraticIllness: "",
      MedicalIllness: "",
      At1Min: "",
      At5Min: "",
      resuscitationDetails: "",
      birthDaweightonDischargeteTime: "",
      lengthOnAdmission: "",
      weightOnAdmission: "",
      weightOnDischarge: "",
      lengthOnDischarge: "",
      headCircumferenceAdmission: "",
      HeadCircumferenceOnDischarge: "",
      rightUpperLimb: "",
      rightLowerLimb: "",
      leftUpperLimb: "",
      leftLowerLimb: "",
      treatmentDesc: "",
    },
  });

  const {
    formState: { errors },
    control,
    getValues,
    watch,
    register,
    setValue,
  } = methods;

  let deathSummary = watch("isDeathSummary");
  let DM = watch("dm");
  let ihd = watch("ihd");
  let asthama = watch("asthama");
  let stroke = watch("stroke");
  let htn = watch("htn");
  let tuberculosis = watch("tuberculosis");
  let copd = watch("copd");
  let cyanosis = watch("cyanosis");
  let peripheralPulses = watch("PeripheralPulses");
  let jvp = watch("jvp");
  let pallor = watch("pallor");
  let oedema = watch("oedema");
  let clubbiing = watch("clubbiing");
  let lymphadenopathy = watch("lymphadenopathy");
  let lcterus = watch("lcterus");
  let fHtn = watch("fHtn");
  let fDm = watch("fDm");
  let fTuberculosis = watch("fTuberculosis");
  let fIhd = watch("fIhd");
  let fAsthama = watch("fAsthama");
  let fStroke = watch("fStroke");
  let adviceForMlc = watch("adviceForMlc");
  let Search = watch("dischrgeSummarySearch");
  let reportToHospital_val = watch("reportToHospital");

  const deathSummaryDto = {
    causesOfDeath: null,
    dateOfDeath: null,
    doctor: 0,
    dyingDeclaration: null,
    identificationMark: null,
    materialMedical: null,
    opinionAboutCauseOfDeath: null,
    postmortem: null,
    timeOfDeath: null,
  };
  const familyHistoryDto = {
    fasthma: null,
    fdm: null,
    fhtn: null,
    fihd: null,
    fotherDiseases: null,
    fstroke: null,
    ftuberculosis: null,
  };
  const generalExaminationDto = {
    bp: null,
    breast: null,
    clubbing: null,
    cyanosis: null,
    hc: null,
    height: null,
    jvp: null,
    lcterus: null,
    lymphadenopathy: null,
    malNutrition: null,
    mewsScore: null,
    pewsScore: null,
    oedema: null,
    painScore: null,
    pallor: null,
    peripheralPulses: null,
    pulse: null,
    respiration: null,
    spo2: null,
    temp: null,
    weight: null,
  };
  const gynecologyDetailsDto = {
    a: null,
    adviceForMlc: null,
    cycles: null,
    d: null,
    documentFormalities: null,
    edd: null,
    g: null,
    historyOfIllness: null,
    historyOfLeaking: null,
    ifYes: null,
    investigation: null,
    l: null,
    labourNotes: null,
    lastChildBirth: null,
    lmp: null,
    marriedSince: null,
    mnstlHistInput: null,
    noOfDeliveries: null,
    obstHistory: null,
    onExamination: null,
    p: null,
    painAbdomen: null,
    perSpeculum: null,
    perVaginal: null,
    perVaginalExam: null,
    placeOfDeliveries: null,
    pog: null,
    routeOfDeliveries: null,
    sexOfBaby: null,
    since: null,
    tubalLigation: null,
    usg: null,
    vaginalBleeding: null,
  };
  const historySheetDtoList = {
    asthma: null,
    asthmaDtls: null,
    chiefComplaint: null,
    copd: null,
    copdDtls: null,
    dm: null,
    dmDtls: null,
    familyHistory: null,
    historyOfPresentIllness: null,
    htn: null,
    htnDtls: null,
    ihd: SignalCellularNullSharp,
    ihdDtls: null,
    other: null,
    stroke: null,
    strokeDtls: null,
    surgicalHistory: null,
    treatmentHistory: null,
    tuberCulosis: null,
    tuberDtls: null,
  };

  const otherHistoryDto = {
    advices: null,
    allergies: null,
    bloodTransfusionHistory: null,
    bowelBladder: null,
    dietInstruction: null,
    followUpInstruction: null,
    invToBeDoneBeforeFollowUp: null,
    medicationOnDischarge: null,
    operativeNotes: null,
    personalHistory: null,
    physioInstructions: null,
    retrieveMedication: null,
    specificAdvices: null,
    specificAdvicesList: null,
  };
  const paediatricDetailsDto = {
    // neonatal: null,
    birthHistory: null,
    developmentHistory: null,
    dietaryHistory: null,
    followUpDate: null,
    followUpWithDoctor: null,
    immunisationHistory: null,
    socialHistory: null,
  };
  const patientDetailsDto = {
    cns: null,
    crt: null,
    cvs: null,
    diagnosis: null,
    localExamination: null,
    perAbdomen: null,
    rs: null,
    conditionAtDischarge: null,
    courseInHospital: null,
    investigationPathology: null,
  };

  const finalObjectNeonatal = {
    age: null,
    at1Min: null,
    at5Min: null,
    birthHistory: null,
    bloodGroup: null,
    fatherBloodGroup: null,
    gestationalIllness: null,
    gravityParity: null,
    headCircumOnAdm: null,
    headCircumOnDischarge: null,
    isBreastFeeding: false,
    leftLowerLimb: null,
    leftUpperLimb: null,
    lengthOnAdm: null,
    lengthOnDischarge: null,
    medicalIllness: null,
    resus: null,
    rightLowerLimb: null,
    rightUpperLimb: null,
    timeOfBirth: new Date(),
    weightOnAdm: null,
    weightOnDischarge: null,
  };

  const onSubmit = (data, clickedPrivilege) => {
    console.log("datadata321", data);

    let vitals = [
      data?.bpVitals ? `bp-${data.bpVitals}` : "",
      data?.tempVitals ? `temp-${data.tempVitals}` : "",
      data?.pulseVitals ? `pulse-${data.pulseVitals}` : "",
      data?.spo2Vitals ? `spo2-${data.spo2Vitals}` : "",
      data?.weightVitals ? `weight-${data.weightVitals}` : "",
      data?.heightVitals ? `height-${data.heightVitals}` : "",
      data?.respirationVitals ? `respiration-${data.respirationVitals}` : "",
      data?.hcVitals ? `hc-${data.hcVitals}` : "",
      data?.painScoreVitals ? `painScore-${data.painScoreVitals}` : "",
      data?.malnutritionVitals ? `malnutrition-${data.malnutritionVitals}` : "",
      data?.mewsScoreVitals ? `mewsScore-${data.mewsScoreVitals}` : "",
      data?.mewsRadioVitals ? `AVPU-${data.mewsRadioVitals}` : "",
    ]
      .filter(Boolean)
      .join("\n");

    // deathSummaryDto
    deathSummaryDto.causesOfDeath = data.causeOfDeath;
    deathSummaryDto.dateOfDeath = deathSummary
      ? //  patientData.IsDeath
        deathDate
      : "1900-01-01T00:00:00.000Z";

    deathSummaryDto.timeOfDeath = deathSummary
      ? // patientData.IsDeath
        format(new Date(deathTime), "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      : "1900-01-01T00:00:00.000Z";

    data.doctor !== 0 && data.doctor !== undefined
      ? (deathSummaryDto.doctor = Number(data.doctor.id))
      : (deathSummaryDto.doctor = Number(0));

    deathSummaryDto.dyingDeclaration = data.dyingDeclaration;
    deathSummaryDto.identificationMark = data.identificationMark;
    deathSummaryDto.opinionAboutCauseOfDeath = data.optionAboutCauseOfDeath;
    deathSummaryDto.postmortem = data.resonsForPostmortem;
    deathSummaryDto.materialMedical = data.materialForMedical;
    // familyHistoryDto
    familyHistoryDto.fasthma = Number(fAsthama ? 1 : 0);
    familyHistoryDto.fdm = Number(fDm ? 1 : 0);
    familyHistoryDto.fhtn = Number(fHtn ? 1 : 0);
    familyHistoryDto.fihd = Number(fIhd ? 1 : 0);
    familyHistoryDto.fotherDiseases = data.fOtherDiseases;
    familyHistoryDto.fstroke = Number(fStroke ? 1 : 0);
    familyHistoryDto.ftuberculosis = Number(fTuberculosis ? 1 : 0);
    // generalExaminationDto
    generalExaminationDto.bp = data.bp;
    generalExaminationDto.breast = Number(data.breast ? 1 : 0);
    generalExaminationDto.clubbing = Number(clubbiing ? 1 : 0);
    generalExaminationDto.cyanosis = Number(cyanosis ? 1 : 0);
    generalExaminationDto.hc = data.hc;
    generalExaminationDto.height = Number(data.height);
    generalExaminationDto.jvp = Number(jvp ? 1 : 0);
    generalExaminationDto.lcterus = Number(lcterus ? 1 : 0);
    generalExaminationDto.lymphadenopathy = Number(lymphadenopathy ? 1 : 0);
    generalExaminationDto.malNutrition = data.malnutrition;
    generalExaminationDto.mewsScore = data.mewsScore;
    generalExaminationDto.pewsScore = data?.pewsScore;
    generalExaminationDto.oedema = Number(oedema ? 1 : 0);
    generalExaminationDto.painScore = data.painScore;
    generalExaminationDto.pallor = Number(pallor ? 1 : 0);
    generalExaminationDto.peripheralPulses = Number(peripheralPulses ? 1 : 0);
    generalExaminationDto.pulse = data.pulse;
    generalExaminationDto.respiration = data.respiration;
    generalExaminationDto.spo2 = data.spo2;
    generalExaminationDto.temp = data.temp;
    generalExaminationDto.weight = Number(data.weight);
    // gynecologyDetailsDto
    gynecologyDetailsDto.a = data.a;
    gynecologyDetailsDto.adviceForMlc = Number(adviceForMlc ? 1 : 0);
    gynecologyDetailsDto.cycles = data.cycles;
    gynecologyDetailsDto.d = data.d;
    gynecologyDetailsDto.documentFormalities = data.documentFormalities;
    gynecologyDetailsDto.edd = data.eddDate || "1900-01-01T00:00:00.000Z"; //eddDate;
    gynecologyDetailsDto.g = data.g;
    gynecologyDetailsDto.historyOfIllness = data.historyOfIllness;
    gynecologyDetailsDto.historyOfLeaking = data.historyOfLeaking;
    gynecologyDetailsDto.ifYes = data.ifYes;
    gynecologyDetailsDto.investigation = data.investigation;
    gynecologyDetailsDto.l = data.l;
    gynecologyDetailsDto.labourNotes = data.labourNotes;
    gynecologyDetailsDto.lastChildBirth = data.lastChildBirth;
    gynecologyDetailsDto.lmp = data.lmp || "1900-01-01T00:00:00.000Z"; //lmpDate;
    gynecologyDetailsDto.marriedSince = data.marriedSince;
    gynecologyDetailsDto.mnstlHistInput = data.mnstlHistInput;
    gynecologyDetailsDto.noOfDeliveries = data.noOfDeliveries;
    gynecologyDetailsDto.obstHistory = data.obstHistory;
    gynecologyDetailsDto.onExamination = data.onExamination;
    gynecologyDetailsDto.p = data.p;
    gynecologyDetailsDto.painAbdomen = data.painAbdomen;
    gynecologyDetailsDto.perSpeculum = data.perSpeculam;
    gynecologyDetailsDto.perVaginal = data.perVaginal;
    gynecologyDetailsDto.perVaginalExam = data.perVaginalExam;
    gynecologyDetailsDto.placeOfDeliveries = data.placeOfDeliveries;
    gynecologyDetailsDto.pog = data.pog || "1900-01-01T00:00:00.000Z"; //pogAccordingDate;
    gynecologyDetailsDto.routeOfDeliveries = data.routeofDeliveries;

    data.sexOfBody !== null
      ? (gynecologyDetailsDto.sexOfBaby = Number(data?.sexOfBody?.id))
      : (gynecologyDetailsDto.sexOfBaby = Number(0));

    gynecologyDetailsDto.since = data.since;
    gynecologyDetailsDto.tubalLigation = data.tubalLigation;
    gynecologyDetailsDto.usg = data.usg || "1900-01-01T00:00:00.000Z"; //accordingUsgDate;
    gynecologyDetailsDto.onExamination = data.onExamination;
    gynecologyDetailsDto.vaginalBleeding = data.vaginalBleeding;
    // historySheetDtoList
    historySheetDtoList.asthma = asthama;
    historySheetDtoList.asthmaDtls = data.asthamaDtls;
    historySheetDtoList.chiefComplaint = data.chiefComplaint;
    historySheetDtoList.copd = copd ? 1 : 0;
    historySheetDtoList.copdDtls = data.copdDtls;
    historySheetDtoList.dm = DM ? 1 : 0;
    historySheetDtoList.dmDtls = data.dmDtls;
    historySheetDtoList.familyHistory = data.familyHistory;
    historySheetDtoList.historyOfPresentIllness = data.historyOfPresentIllness;
    historySheetDtoList.htn = htn ? 1 : 0;
    historySheetDtoList.htnDtls = data.htnDtls;
    historySheetDtoList.ihd = ihd ? 1 : 0;
    historySheetDtoList.ihdDtls = data.ihdDtls;
    historySheetDtoList.other = data.otherDtls;
    historySheetDtoList.stroke = stroke ? 1 : 0;
    historySheetDtoList.strokeDtls = data.strokeDtls;
    historySheetDtoList.surgicalHistory = data.surgicalHistory;
    historySheetDtoList.treatmentHistory = data.treatmentHistory;
    historySheetDtoList.tuberCulosis = tuberculosis;
    historySheetDtoList.tuberDtls = data.tuberculosisDtls;
    // otherHistoryDto
    otherHistoryDto.advices = data.MedicationAdvices;
    otherHistoryDto.allergies = data.allergy;
    otherHistoryDto.bloodTransfusionHistory = data.bloodTransfusionHistory;
    otherHistoryDto.bowelBladder = data.bowelBladder;
    otherHistoryDto.dietInstruction = data.diet;
    otherHistoryDto.followUpInstruction = data.FollowUpInstructions;
    otherHistoryDto.invToBeDoneBeforeFollowUp =
      data.investigationsBeforeInstructions;
    // otherHistoryDto.medicationOnDischarge = data; //tble data
    otherHistoryDto.operativeNotes = data.operativeNotes;
    otherHistoryDto.personalHistory = data.personalHistory;
    otherHistoryDto.physioInstructions = data.physiotherapyInstructions;
    // otherHistoryDto.retrieveMedication = data;//tble data

    var refarr = [];
    for (let i = 0; i < specificAdvices.length; i++) {
      let a = specificAdvices[i].value;
      refarr.push(a);
      let arrToString = refarr?.toString(); //conversion arrey to string
      otherHistoryDto.specificAdvices = arrToString;
    }

    // otherHistoryDto.specificAdvicesList =
    //   specificAdvices?.length > 0
    //     ? specificAdvices?.map((item) => item?.id)
    //     : [];

    // changes by Rishi -> List of id's has been replaced with List of Label's .
    otherHistoryDto.specificAdvicesList =
      specificAdvices?.length > 0
        ? specificAdvices?.map((item) => item?.label)
        : [];

    // paediatricDetailsDto
    // paediatricDetailsDto.neonatal = Neonatal;
    paediatricDetailsDto.birthHistory = data.birthHistoryPediatric;
    paediatricDetailsDto.developmentHistory = data.developmentHistory;
    paediatricDetailsDto.dietaryHistory = data.dietaryHistory;
    ///
    let followTimeValid = isValid(
      !!data.followUpTime ? data.followUpTime : new Date()
    );

    let timeFollow = followTimeValid
      ? format(!!data.followUpTime ? data.followUpTime : new Date(), "HH:mm:ss")
      : data.followUpTime?.split("T")[1].split(".")[0];

    let dateFollow = format(new Date(data?.followUpDate), "yyyy-MM-dd");
    let DtTime = `${dateFollow}T${timeFollow}.000Z`;
    ///
    paediatricDetailsDto.followUpDate = DtTime;
    paediatricDetailsDto.followUpWithDoctor = data.followUpWithDoctor;
    paediatricDetailsDto.immunisationHistory = data.immunisationHistory;
    paediatricDetailsDto.socialHistory = data.socialHistory;
    // patientDetailsDto
    patientDetailsDto.cns = data.cns;
    patientDetailsDto.crt = data.crt;
    patientDetailsDto.cvs = data.cvs;
    patientDetailsDto.diagnosis = data.diagnosisICD;
    patientDetailsDto.localExamination = data.localExamination;
    patientDetailsDto.perAbdomen = data.perAbdomen;
    patientDetailsDto.rs = data.rs;
    patientDetailsDto.conditionAtDischarge = data.conditionAtDischarge;
    patientDetailsDto.courseInHospital = data.courseInHospital;
    patientDetailsDto.investigationPathology = data.investigationsPathology;

    ///

    finalObjectNeonatal.age = data.age;
    finalObjectNeonatal.at1Min = data.At1Min;
    finalObjectNeonatal.at5Min = data.At5Min;
    finalObjectNeonatal.bloodGroup = data.bloodGroup;
    finalObjectNeonatal.gravityParity = data.gravityparity;
    finalObjectNeonatal.fatherBloodGroup = data.FatherBloodGroup;
    finalObjectNeonatal.gestationalIllness = data.gastationalIllness;
    finalObjectNeonatal.headCircumOnAdm = data.headCircumferenceAdmission;
    finalObjectNeonatal.headCircumOnDischarge =
      data.HeadCircumferenceOnDischarge;
    finalObjectNeonatal.isBreastFeeding = data.isbreastfeeding;
    finalObjectNeonatal.leftLowerLimb = data.leftLowerLimb;
    finalObjectNeonatal.leftUpperLimb = data.leftUpperLimb;
    finalObjectNeonatal.lengthOnAdm = data.lengthOnAdmission;
    finalObjectNeonatal.lengthOnDischarge = data.lengthOnDischarge;
    finalObjectNeonatal.medicalIllness = data.MedicalIllness;
    finalObjectNeonatal.obsIllness = data.obstraticIllness;
    finalObjectNeonatal.resus = data.resuscitationDetails;
    finalObjectNeonatal.rightLowerLimb = data.rightLowerLimb;
    finalObjectNeonatal.rightUpperLimb = data.rightUpperLimb;

    let DateIsValid = isValid(data.timeofbirth);

    finalObjectNeonatal.timeOfBirth = DateIsValid
      ? format(data?.timeofbirth, "yyyy-MM-dd'T'HH:mm:ss.sss'Z'")
      : data?.timeofbirth?.concat("Z"); //add time state

    // finalObjectNeonatal.timeOfBirth = format(
    //   data.timeofbirth,
    //   "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
    // ); ///need to change

    finalObjectNeonatal.weightOnAdm = data.weightOnAdmission;
    finalObjectNeonatal.weightOnDischarge = data.weightOnDischarge;

    const propertyMap = {
      1: "oae",
      2: "vaccination",
      3: "newBornScreening",
      4: "tcb",
      5: "fourUmbSaturation",
      6: "fourUmbBp",
      7: "rsdReflex",
    };

    neonatalChecklist.forEach((item) => {
      const property = propertyMap[item.id];
      if (property !== undefined) {
        finalObjectNeonatal[property] = item.notDone
          ? false
          : item.done || null;
      }
    });
    finalObjectNeonatal.id = patchData?.neonatalId || null;
    ///
    console.log("The neoNatal info filled is :", finalObjectNeonatal);

    setMainVitals(vitals);
    setStateDeathSummaryDto(deathSummaryDto);
    setStateFamilyHistoryDto(familyHistoryDto);
    setStateGeneralExaminationDto(generalExaminationDto);
    setStateGynecologyDetailsDto(gynecologyDetailsDto);
    setStateHistorySheetDtoList(historySheetDtoList);
    setStateOtherHistoryDto(otherHistoryDto);
    setStatePaediatricDetailsDto(paediatricDetailsDto);
    setStatePatientDetailsDto(patientDetailsDto);
    //
    setStateNeonatalDto(finalObjectNeonatal);

    ///
    const hasValue = () => {
      return Object.values(finalObjectNeonatal).some(
        (value) => value !== null && value !== undefined
      );
    };

    // working neonatal

    if (patientData === null || !patientData) {
      warningAlert("Kindly select a patient!");
    } else if (clickedPrivilege === "Finalise") {
      if (patientData?.MedicoLegalCase && !patientData?.mlcFromDischargeDone) {
        warningAlert("Kindly fill discharge MLC details.");
      } else if (data?.diagnosisICD === "") {
        warningAlert("Kindly fill Diagnosis/ICD!");
      } else if (data?.reportToHospital === "") {
        warningAlert(
          "Kindly fill 'Report to Hospital' in follow-up instructions!"
        );
        // } else if (patientData?.isNeonatalCase && !hasValue()) {
      } else if (
        patientData?.isNeonatalCase &&
        (!Number(finalObjectNeonatal?.weightOnAdm) > 0 ||
          !Number(finalObjectNeonatal?.lengthOnAdm) > 0 ||
          finalObjectNeonatal?.newBornScreening === null)
      ) {
        warningAlert("Kindly fill Neonatal Information!");
      } else {
        setOpenConfirmationModal(true);
      }
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const finalObject = {
    admissionId: 0,
    dischargeId: 0,
    dischargeSummaryId: 0,
    addedBy: 0,
    deathSummaryDto: {},
    familyHistoryDto: {},
    generalExaminationDto: {},
    gynecologyDetailsDto: {},
    historySheetDtoList: {},
    medicationOnDischargeDto: [], //arrey of bjects of medication on discharge
    neonatalDto: {}, //assign neonata modal data
    otherHistoryDto: {},
    paediatricDetailsDto: {},
    patientDetailsDto: {},
  };

  console.log(
    "props.menuId || location?.state?.menuId",
    props.menuId,
    location?.state?.menuId
  );

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    let userId = token.userId;

    finalObject.menuId = !!props.menuId
      ? props.menuId
      : location?.state?.menuId;
    finalObject.privilege = privilege;

    finalObject.admissionId = patientData.AdmissionId;

    // finalObject.dischargeId = patientData.DischargeId;

    patientData.DischargeId !== null
      ? (finalObject.dischargeId = patientData.DischargeId)
      : (finalObject.dischargeId = 0);

    finalObject.dischargeSummaryId = dischargeSummaryId;
    finalObject.isDeath = deathSummary;

    finalObject.addedBy = userId;
    finalObject.deathSummaryDto = stateDeathSummaryDto;
    finalObject.familyHistoryDto = stateFamilyHistoryDto;
    finalObject.generalExaminationDto = stateGeneralExaminationDto;
    finalObject.gynecologyDetailsDto = stateGynecologyDetailsDto;
    finalObject.historySheetDtoList = stateHistorySheetDtoList;
    finalObject.reportToHospital = reportToHospital_val || "";

    //drugname

    let prescribedMedications = [
      ...allMedicationData,
      ...deletedMedicationData,
    ];

    finalObject.medicationOnDischargeDto =
      prescribedMedications !== null
        ? prescribedMedications?.map((item) => ({
            dose: item.dose,
            frequency: item.frequency,
            instructions: item.instructions,
            itemName: item.itemName || item.drugname,
            medicationId: item.medicationId || 0,
            route: item.route,
            isDelete: "isDelete" in item ? item.isDelete : false,
          }))
        : [];
    ///
    //finalObject.medicationOnDischargeDto = medicationData;

    if (stateNeonatalDto !== null) {
      finalObject.neonatalDto = stateNeonatalDto;
    }

    // finalObject.neonatalDto = stateNeonatalDto;
    finalObject.otherHistoryDto = stateOtherHistoryDto;
    finalObject.paediatricDetailsDto = statePaediatricDetailsDto;
    finalObject.patientDetailsDto = statePatientDetailsDto;

    finalObject.finalize =
      privilege === "Create" || privilege === "UnFinalise" ? false : true;

    // isFinalize !== false
    //   ? (finalObject.finalize = true)
    //   : (finalObject.finalize = false);

    // privilege === "UnFinalise" && (finalObject.finalize = false);
    finalObject.vitals = mainVitals || "";

    finalObject.treatment =
      treatmentInfo?.check === true
        ? treatmentInfo?.value
        : retrivedDrugData
            ?.map((item) =>
              Object.entries(item)
                ?.filter(([key, value]) => key !== "isChecked")
                ?.map(([key, value]) => `${key}: ${value}`)
                ?.join(", ")
            )
            ?.join("\n") || "";

    // finalObject.finalize = isFinalize;

    setOpenBackdrop(true);
    console.log("finalObject123", finalObject);
    postDischargeSummaryData(finalObject)
      .then((response) => response.data)
      .then((res) => {
        // setValue("dischrgeSummarySearch",null)
        if (res.statusCode === 302) {
          warningAlert(res.message);
        } else if (res.statusCode === 200) {
          //
          patientData &&
            patientData?.DischargeSummaryId !== null &&
            privilege !== "UnFinalise" &&
            (!finalObject?.patientDetailsDto.diagnosis
              ? warningAlert("Please fill diagnosis for this patient.")
              : handleOpenPrintModal("dischargeSummary"));

          checkSimilarTransaction(false, patientData);
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset(resetDefaultValues);
          setAdmissionId(null);
          // setPatientId(null);
          setAllMedicationData([]);
          setDeletedMedicationData([]);
          setSpecificAdvices([]);
          setTreatmentInfo({ check: false, value: "" });
          // methods.resetField("dischrgeSummarySearch");
          // methods.setValue("dischrgeSummarySearch", null); //clear patient search
          setTabValue(0);

          setPatchData(null);
          //
          setPatientData(null);
          getDischargeSummaryDetails();
          getPatientInfo();
          setNeonatalChecklist([...checklistArr]);
        }
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    getPatientInfo();
  }, [patientId]);

  useEffect(() => {
    getDoctor();
  }, [doctorSearchString]);

  useEffect(() => {
    getDischargeSummaryDetails();
  }, [admissionId, patientData]);

  useEffect(() => {
    if (location.state !== null) {
      setPatientId(props?.admissionId || location?.state?.params?.AdmissionId);
      // setSelectedPatientId(location.state.PatientId);
      setIsDisableSearch(location.state.DisableSearch);
      setAdmissionId(props?.admissionId || location.state.AdmissionId);
    }
  }, []);

  //doctor Name
  const getDoctor = () => {
    if (doctorSearchString !== null && doctorSearchString !== "") {
      fetchDoctor(doctorSearchString)
        .then((response) => response.data)
        .then((res) => {
          setDoctor(res.result);
        });
    }
  };

  //handleChange
  const handleChange = (searchString) => {
    let isCurrentAdm = getValues("isCurrentyAdmitted");
    if (searchString !== undefined && searchString !== "") {
      fetchDischargeSummarySearchDropDown(searchString, Number(isCurrentAdm))
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    }
  };

  //Patient Info patientId
  const getPatientInfo = () => {
    if (patientId !== null && patientId !== undefined) {
      fetchDishargePatientInfo(patientId)
        .then((response) => response.data)
        .then((res) => {
          setPatientData(res.result);
          setAdmissionId(res.result.AdmissionId);

          checkSimilarTransaction(true, res.result);

          setOpenMlcModal(
            res.result?.MedicoLegalCase && !res.result?.mlcFromDischargeDone
              ? true
              : false
          );

          res.result?.MedicoLegalCase &&
            !res.result?.mlcFromDischargeDone &&
            warningAlert("Kindly fill discharge MLC details.");

          setValue(
            "isDeathSummary",
            !!patchData && patchData.isDeath === true
              ? patchData.isDeath
              : res?.result?.IsDeath
          );

          setValue(
            "tentativeDischarge",
            res?.result?.TentativeDischargeDate !== null ? true : false
          );

          setValue("doctor", {
            id: res.result.doctorId,
            label: res.result.doctor,
            value: res.result.doctor,
          });

          ///
          // setValue("conditionAtDischarge", res.result.patientName, {
          //   shouldValidate: true,
          // });
        });
    }
  };

  //Patient All Details to patch
  const getDischargeSummaryDetails = () => {
    // if (admissionId === null) {
    //   // reset();
    // } else
    patientData?.DischargeSummaryId !== null &&
      patientData?.DischargeSummaryId !== undefined &&
      patientData?.DischargeSummaryId &&
      fetchDischargeSummaryDetails(patientData?.DischargeSummaryId)
        .then((response) => response.data)
        .then((res) => {
          console.log("resOfResult123", res.result);
          const [year, month, day, hour, minute, second] =
            res.result.daethoftime.split(/[-T:.Z]/).map(Number);

          const date = new Date(
            Date.UTC(year, month - 1, day, hour, minute, second)
          );
          setLastUpdatedVtalDate(res.result.LastUpdatedDate);
          setPatchData(res.result);
          // setlmpDate(res.result.LMP);
          //seteddDate(res.result.EDD);
          //setPogAccordingDate(res.result.POG);
          //setAccordingUsgDate(res.result.USG);
          setDeathDate(res.result.deathofdate);
          setDeathTime(
            `${date.toDateString()} ${hour.toString().padStart(2, "0")}:${minute
              .toString()
              .padStart(2, "0")}:${second
              .toString()
              .padStart(2, "0")} GMT+0521 (India Standard Time)`
          );
          setFollowupDate(res.result.FollowupdDate);
          setDischargeSummaryId(res.result.DischargeSummaryId);

          let reArrangeDishargeMedication = !!res.result.medicineDischarge
            ? res?.result?.medicineDischarge?.map(
                ({
                  medicationId,
                  itemName,
                  dose,
                  route,
                  frequency,
                  instructions,
                  ...items
                }) => ({
                  itemName,
                  dose,
                  route,
                  frequency,
                  instructions,
                  medicationId,
                  isDelete: false,
                  ...items,
                })
              )
            : [];

          setAllMedicationData(reArrangeDishargeMedication || []);
          //
          let string = !!res?.result?.Treatment ? res?.result?.Treatment : "";

          if (string !== "" && string?.includes(":")) {
            const arrayByString = string.split("\n")?.map((objectStr) => {
              const pairs = objectStr.split(", ");
              const obj = {};
              pairs.forEach((pair) => {
                const [key, value] = pair.split(": ");
                obj[key] = value;
              });
              return obj;
            });

            setRetrivedDrugData(arrayByString || []);
          } else {
            setTreatmentInfo((treatmentInfo) => ({
              ...treatmentInfo,
              check: true,
              value: string,
            }));
          }

          //specific advices populate
          //let str = res.result?.SpecificAdvice;
          setSpecificAdvices(
            JSON.parse(res?.result?.SpecificAdvicesList) || []
          );

          let vitalsMain = res?.result?.Vitals?.split("\n");

          let vitalsObject = {};

          vitalsMain?.forEach((vital) => {
            let [key, value] = vital.split("-");
            vitalsObject[key] = value;
          });

          let DateIsValidPatch = isValid(res.result.timeofbirth);

          let PatchBirthTime = res.result.timeofbirth || res.result.timeOfBirth;

          ////

          const propertyMap = {
            1: res.result.oae,
            2: res.result.vaccination,
            3: res.result.new_born_screening,
            4: res.result.tcb,
            5: res.result.four_umb_saturation,
            6: res.result.four_umb_bp,
            7: res.result.rsd_reflex,
          };

          const updatedChecklist = neonatalChecklist?.map((item) => {
            const value = propertyMap?.[item.id];
            if (value !== null) {
              return {
                ...item,
                done: value === true ? true : item.done,
                notDone: value === false ? true : item.notDone,
              };
            }
            return item;
          });

          // Update state with the new checklist
          setNeonatalChecklist(updatedChecklist);

          /////

          methods?.reset(
            {
              timeofbirth: PatchBirthTime?.replace("Z", ""),
              // birthHistoryNeonatal: "",//remove
              isbreastfeeding:
                res.result.isbreastfeeding || res.result.isBreastFeeding,
              age: res.result.age,
              bloodGroup: res.result.bloodgroup || res.result.bloodGroup,
              gravityparity:
                res.result.gravityparity || res.result.gravityParity,
              gastationalIllness: res.result.gestationalIllness,
              FatherBloodGroup:
                res.result.fatherbloodgroup || res.result.fatherBloodGroup,
              obstraticIllness:
                res.result.obsIllness || res.result.obstraticIllness,
              MedicalIllness:
                res.result.medicalIllness || res.result.MedicalIllness,
              At1Min: res.result.apgr1 || res.result.at1Min,
              At5Min: res.result.apgr5 || res.result.at5Min,
              resuscitationDetails: res.result.resus || res.result.resus,
              weightOnAdmission:
                res.result.weightonadm || res.result.weightOnAdm,
              // birthDaweightonDischargeteTime: res.result.gravityparity,
              lengthOnAdmission:
                res.result.lenthonadm || res.result.lengthOnAdm,
              weightOnDischarge:
                res.result.weightondischarge || res.result.weightOnDischarge,
              lengthOnDischarge:
                res.result.lenthondischarge || res.result.lengthOnDischarge,
              headCircumferenceAdmission:
                res.result.headcircumonadm || res.result.headCircumOnAdm,
              HeadCircumferenceOnDischarge:
                res.result.headcircumondischarge ||
                res.result.headCircumOnDischarge,
              rightUpperLimb: res.result.rulimb || res.result.rightUpperLimb,
              rightLowerLimb: res.result.rllimb || res.result.rightLowerLimb,
              leftUpperLimb: res.result.lulimb || res.result.leftUpperLimb,
              leftLowerLimb: res.result.lllimb || res.result.leftLowerLimb,

              //

              bpVitals: vitalsObject.bp || "",
              tempVitals: vitalsObject.temp || "",
              pulseVitals: vitalsObject.pulse || "",
              spo2Vitals: vitalsObject.spo2 || "",
              weightVitals: vitalsObject.weight || "",
              heightVitals: vitalsObject.height || "",
              respirationVitals: vitalsObject.respiration || "",
              hcVitals: vitalsObject.hc || "",
              painScoreVitals: vitalsObject.painScore || "",
              malnutritionVitals: vitalsObject.malnutrition || "",
              mewsScoreVitals: vitalsObject.mewsScore || "",
              mewsRadioVitals: vitalsObject.AVPU || "",
              // //

              // tentativeDischarge: "",
              isDeathSummary:
                patientData.IsDeath === true
                  ? patientData.IsDeath
                  : res.result?.isDeath,
              // deathSummary: res.result.deathofdate ? true : false,
              //Old vitals data
              inputTempOld: res.result.Temp,
              inputPulserateOld: res.result.Pulse,
              inputBPOld: res.result.BP,
              inputSpO2Old: res.result.SPO2,
              inputWeightOld: res.result.Weight,
              inputHeightOld: res.result.Height,
              inputRespirationOld: res.result.RespRate,
              inputMEWSScoreOld: res.result.MewsScore,
              inputPainScoreOld: res.result.PainScore,
              inputHCOld: res.result.hc,
              inputMalnutritionOld: res.result.gradeofmal,
              inputPEWSOld: res.result.PEWScore,
              //Patient Details
              conditionAtDischarge: res.result.ConditionAtDischarge,
              courseInHospital: res.result.CourseInHospital,
              diagnosisICD: res.result.Diagnosis,
              investigationsPathology: res.result.InvestigationPathology,
              //HistorySheet
              chiefComplaint: res.result.ChiefComplaint,
              historyOfPresentIllness: res.result.AssociatedComplaint,
              surgicalHistory: res.result.SurgicalHistory,
              treatmentHistory: res.result.TreatmentHistory,
              familyHistory: res.result.FamilyHistory,
              dm: res.result.DM,
              dmDtls: res.result.dm_dtls,
              ihd: res.result.IHD,
              ihdDtls: res.result.ihd_dtls,
              asthama: res.result.Asthma,
              asthamaDtls: res.result.astima_dtls,
              stroke: res.result.PStroke,
              strokeDtls: res.result.stroke_Dtls,
              htn: res.result.HT,
              htnDtls: res.result.htn_dtls,
              copd: res.result.COPD,
              copdDtls: res.result.copd_dtls,
              tuberculosis: res.result.Tuberculosis,
              tuberculosisDtls: res.result.tuber_dtls,
              //other:  res.result.ConditionAtDischarge, //cancel
              otherDtls: res.result.Other,

              // GeneralExamination
              temp: res.result.Temp,
              pulse: res.result.Pulse,
              bp: res.result.BP,
              spo2: res.result.SPO2,
              weight: res.result.Weight,
              height: res.result.Height,
              // breast:  res.result.ConditionAtDischarge,
              respiration: res.result.RespRate,
              mewsScore: res.result.MewsScore,
              pewsScore: res.result.PEWScore,
              painScore: res.result.PainScore,
              hc: res.result.hc,
              malnutrition: res.result.gradeofmal,
              cyanosis: res.result.Cyanosis,
              PeripheralPulses: res.result.PeripheralPulses,
              jvp: res.result.JVP,
              pallor: res.result.Pallor,
              oedema: res.result.Oedema,
              clubbiing: res.result.Clubbing,
              lymphadenopathy: res.result.Lymphadenopathy,
              lcterus: res.result.Lcterus,

              //systematic Examination
              crt: res.result.CRT,
              localExamination: res.result.LocalExamination,
              cvs: res.result.CVS,
              rs: res.result.RS,
              perAbdomen: res.result.PerAbdomen,
              cns: res.result.CNS,
              // Family History
              fHtn: res.result.FHTN,
              fDm: res.result.FDM,
              fTuberculosis: res.result.FTuberculosis,
              fIhd: res.result.FIHD,
              fAsthama: res.result.FAsthma,
              fStroke: res.result.FStroke,
              fOtherDiseases: res.result.FOtherDiseases,
              //other History
              bowelBladder: res.result.Bowel_Bladder,
              bloodTransfusionHistory: res.result.BloodTransfusionHistory,
              allergy: res.result.Allergies,
              personalHistory: res.result.PersonalHistory,
              //Operative Notes
              operativeNotes: res.result.OperativeNotes,
              // RetriveMedication:  res.result.ConditionAtDischarge, /// table data
              //Medication On Discharge
              // medicationOnDischarge:  res.result.ConditionAtDischarge, /// table data
              //Medication Advices
              MedicationAdvices: res.result.AdviceAtDischarge,
              FollowUpInstructions: res.result.FollowupInstructions,
              //Specific Advices
              // SpecificAdvices:  res.result.ConditionAtDischarge, //arrey - multiple values
              //On Discharge Instruction
              diet: res.result.DietInstruction,
              physiotherapyInstructions: res.result.PhysioInstructions,
              investigationsBeforeInstructions:
                res.result.InvToBeDoneBeforeFollowUp,
              //Gynecology History
              obstHistory: res.result.OBSTHistory,
              historyOfIllness: res.result.PresentIllness,
              noOfDeliveries: res.result.NoOfDeliveries,
              routeofDeliveries: res.result.RouteOfDelivery,
              lastChildBirth: res.result.LastChildBirth,
              placeOfDeliveries: res.result.PlaceOfDelivery,
              sexOfBody: {
                id: res.result.sexOfBaby,
                label: res.result.babyGender,
                value: res.result.babyGender,
              },
              // Menstrual History
              // lmpC:  res.result.ConditionAtDischarge,

              lmp:
                res.result.LMP !== "1900-01-01T12:00:00.000Z"
                  ? res.result.LMP
                  : null,
              // eddC:  res.result.ConditionAtDischarge,
              eddDate:
                res.result.EDD !== "1900-01-01T12:00:00.000Z"
                  ? res.result.EDD
                  : null,
              // pogC:  res.result.ConditionAtDischarge,
              pog:
                res.result.POG !== "1900-01-01T12:00:00.000Z"
                  ? res.result.POG
                  : null,
              // usgC:  res.result.ConditionAtDischarge,
              usg:
                res.result.USG !== "1900-01-01T12:00:00.000Z"
                  ? res.result.USG
                  : null,
              cycles: res.result.Cycles,
              g: res.result.G,
              p: res.result.P,
              l: res.result.L,
              a: res.result.A,
              d: res.result.D,
              marriedSince: res.result.MarriedLife,
              mnstlHistInput: res.result.mnstlHistory,
              //Gynecology Details
              onExamination: res.result.OnExamination,
              labourNotes: res.result.LabourNotes,
              tubalLigation: res.result.TubalLigation,
              perSpeculam: res.result.PerSpeculum,
              perVaginalExam: res.result.PerVaginalExam,
              investigation: res.result.GynecInvestigation,

              //General Details
              adviceForMlc: res.result.AdviceForMLC,
              ifYes: res.result.MLCAdvice,
              documentFormalities: res.result.DocumentFormalities,
              painAbdomen: res.result.PainInAbdomen,
              since: res.result.PainSince,
              vaginalBleeding: res.result.VaginalBleeding,
              historyOfLeaking: res.result.Leaking,
              perVaginal: res.result.PerVaginal,

              //Pediatric Details
              birthHistoryPediatric: res.result.BirthHistory,
              immunisationHistory: res.result.ImmunisationHistory,
              dietaryHistory: res.result.DietaryHistory,
              developmentHistory: res.result.DevelopmentHistory,
              socialHistory: res.result.SocialHistory,

              // Urgent Care Instruction
              // urgentCareInstruction:  res.result.ConditionAtDischarge,

              //Follow Up Date And Doctor
              followUpDate: res.result.FollowupdDate,
              followUpTime: res?.result?.FollowupdDate?.substring(
                0,
                res.result.FollowupdDate.length - 1
              ),
              followUpWithDoctor: res.result.FollowUpWithDoctor,
              reportToHospital: res.result.ReportToHospital,

              //Death Summary
              // dateOfDeath: res.result.deathofdate,
              // timeOfDeath: res.result.daethoftime,
              causeOfDeath: res.result.CousesofDeath,
              identificationMark: res.result.identificationMark, //remain
              dyingDeclaration: res.result.DyingDeclaration,
              materialForMedical: res.result.MaterialMedical, //remain
              resonsForPostmortem: res.result.Postmortem,
              optionAboutCauseOfDeath: res.result.opinionCousesofDeath,

              //Doctor
              doctor: {
                id: res.result.DoctorId,
                label: res.result.DoctorName,
                value: res.result.DoctorName,
              },
            },

            {
              keepErrors: true,
              keepDirty: true,
            }
          );
        });
  };

  //////print

  console.log("privilege", privilege);

  const handleOpenPrintModal = (type) => {
    // print Discharge Summary...if pt is death then open death summary print

    let isBilling = token.userRoles.some(
      (item) => item?.toLowerCase() === "ipd billing"
    );

    let AllowPrint = isBilling && patientData?.companyId > 1;
    console.log("AllowPrint123", AllowPrint);

    setPrintType(type);

    console.log("patientData123", patientData, patchData);

    ///if role is billing and company patient then show final print else check allow...
    // to go true and finalise true then only final print else provisional print

    type === "dischargeSummary" && !patientData.IsDeath
      ? patientData?.DischargeSummaryId &&
        fetchPrintDischargeSummary(
          patientData?.DischargeSummaryId,
          AllowPrint === false
            ? patientData?.allowgotoslip === true &&
              // ||
              // patientData?.allowgotoslip !== null
              // || patientData?.companyId > 1
              ((!!patchData && patchData.Finalize === 1) ||
                privilege === "Finalise")
              ? false
              : true
            : false
        ).then((response) => {
          if (response) {
            setUrlforPrint(response);
            setOpenPrintModal(true);
          }
        })
      : type === "deathSummary" ||
        (type === "dischargeSummary" && patientData.IsDeath)
      ? fetchDeathSummary(patientData?.DischargeSummaryId).then((response) => {
          if (response) {
            setUrlforPrint(response);
            setOpenPrintModal(true);
          }
        })
      : fetchDeathSummary().then((response) => {
          if (response) {
            setUrlforPrint(response);
            setOpenPrintModal(true);
          }
        });

    // print History Sheet
    type === "historySheet" &&
      fetchPrintDischargeSummaryHistorySheet(
        patientData?.DischargeSummaryId
      ).then((response) => {
        if (response) {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });

    // print death Summary
    // type === "deathSummary" &&
    //   fetchDeathSummary(patientData?.DischargeSummaryId).then((response) => {
    //     if (response) {
    //       setUrlforPrint(response);
    //       setOpenPrintModal(true);
    //     }
    //   });

    // print neonatal
    type === "neonatal" &&
      fetchPrintNeonatal(patientData?.DischargeSummaryId).then((response) => {
        if (response) {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });

    // print pediatric Summary
    type === "pediatricSummary" &&
      fetchPrintPediatricSummary(patientData?.DischargeSummaryId).then(
        (response) => {
          setOpenPrintModal(true);
          setUrlforPrint(response);
        }
      );

    // print embryo Transfer Case
    type === "embryoTransferCase" &&
      fetchPrintEmbryoTransferCase(patientData?.DischargeSummaryId).then(
        (response) => {
          setOpenPrintModal(true);
          setUrlforPrint(response);
        }
      );

    // print OvumPICKUP
    type === "ovumPICKUP" &&
      fetchPrintOvumPICKUP(patientData?.DischargeSummaryId).then((response) => {
        if (response) {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });

    // print mtp Case Sheet
    type === "mtpCaseSheet" &&
      fetchPrintMtpCaseSheet(patientData?.DischargeSummaryId).then(
        (response) => {
          setOpenPrintModal(true);
          setUrlforPrint(response);
        }
      );

    // print Gyneac Case
    type === "gyneacCase" &&
      fetchPrintGyneacCase(patientData?.DischargeSummaryId).then((response) => {
        if (response) {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });

    // print Obstetric Case Sheet
    type === "obstetricCaseSheet" &&
      fetchPrintObstetricCaseSheet(patientData?.DischargeSummaryId).then(
        (response) => {
          setOpenPrintModal(true);
          setUrlforPrint(response);
        }
      );
  };

  ///get User Action
  useEffect(() => {
    if (!!props.menuId || location?.state?.menuId) {
      fetchUserActionsByMenuId(
        !!props?.menuId ? props?.menuId : location?.state?.menuId
      )
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
    }
  }, [props?.menuId]);

  // *********************** similar patient for multiuser ***************************

  const checkSimilarTransaction = (isInsert, patientDtls, refreshObj) => {
    let obj = {
      id: {
        documentId: 0,
        functionalityId: !!props.menuId
          ? props.menuId
          : location?.state?.menuId,
        opdIpd: 1,
        opdIpdId: (!!patientDtls && patientDtls.AdmissionId) || 0,
        patientId: (!!patientDtls && patientDtls.PatientId) || 0,
        userId: token?.userId,
      },
      isInsert: isInsert,
    };

    restrictSamePatientToMultiUserActivity(refreshObj || obj)
      .then((response) => response.data)
      .then((res) => {})

      .catch((res) => {
        if (res.response.data.statusCode === 400) {
          setOpenAllowMultiPatientAccessModal(true);
          setMultiUserMsg(res.response.data.message);
        }

        // res.response.data.statusCode === 400 && resetData();
        // warningAlert(res.response.data.message);
      });
  };

  // ***********************^^^^^ similar User ^^^^^*************************** //

  const resetData = () => {
    setIsDisableSearch(false);
    methods.reset(resetDefaultValues);
    setAdmissionId(null);
    setPatientId(null);
    setPatientData(null);
    setAllMedicationData([]);
    setDeletedMedicationData([]);
    setSpecificAdvices([]);
    // setSelectedPatient(null);
    // methods.resetField("dischrgeSummarySearch");
    methods.setValue("dischrgeSummarySearch", null); //clear patient search
    setPatchData(null);
    setTabValue(0);
  };

  //
  console.log("deathSummaryDto123", deathSummaryDto);

  return (
    <div className="mt-12">
      {/* //heading// */}
      <div className="text-center text-black font-bold text-xl ">
        Discharge Summary
      </div>
      {/* search */}
      <div className="grid py-2 grid-cols-3 gap-3 items-center">
        <div className="col-span-2 lg:col-span-1">
          <SearchDropdown
            control={control}
            placeholder="Search By Patient Name / MR.No./Mob No."
            dataArray={options}
            name="dischrgeSummarySearch"
            searchIcon={true}
            isClearable={true}
            handleInputChange={handleChange}
            isDisabled={
              props.isFromClinicalCarechart
                ? props.isFromClinicalCarechart
                : isDisableSearch
            }
            inputRef={{
              ...register("dischrgeSummarySearch", {
                onChange: (e) => {
                  if (e.target.value !== null) {
                    setPatientId(e.target.value.id);
                  } else {
                    checkSimilarTransaction(false, patientData);
                    resetData();

                    // setPatientId(null);
                    // setSpecificAdvices([]);
                    // methods.reset(resetDefaultValues);
                    // setAllMedicationData([]);
                    // setDeletedMedicationData([]);
                    // setAdmissionId(null);
                    // setTabValue(0);
                    // setPatientData("");
                    // setPatchData(null);
                  }
                },
              }),
            }}
          />
        </div>
        <div>
          <CheckBoxField
            control={control}
            name="isCurrentyAdmitted"
            label="Current Admitted"
          />
        </div>

        <div className="flex justify-end md:col-span-3 lg:col-span-1">
          {userActions?.map((obj) => (
            <>
              {!obj.isAction &&
                obj.action === "Print" &&
                patientData &&
                patientData?.DischargeSummaryId !== null && (
                  // ((patientData?.companyId === 0 &&
                  //   patientData?.allowgotoslip !== null) ||
                  //   (patientData?.companyId > 0 &&
                  //     patientData?.IsBillGenerated === true)) &&
                  <>
                    <Tooltip
                      title={
                        !!patientData?.allowgotoslip ||
                        patientData?.companyId > 1 ||
                        (!!patchData && patchData.Finalize === 1)
                          ? "Print Discharge Summary"
                          : "Preview Discharge Summary"
                      }
                    >
                      <IconButton
                        onClick={() => {
                          if (!patchData?.Diagnosis) {
                            warningAlert(
                              "Please fill diagnosis for this patient."
                            );
                          } else {
                            handleOpenPrintModal("dischargeSummary");
                          }
                        }}
                      >
                        <LocalPrintshopIcon sx={{ color: "#4B5563" }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
            </>
          ))}
        </div>
      </div>
      {/* patientInfo */}
      <fieldset
        className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
      >
        <div className="py-2 grid  grid-cols-4 2xl:grid-cols-4 gap-2 p-3 ">
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Patient Name</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.patientName}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">MR. No.</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.mrNo}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Admission No.</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.AdmNo}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Gender</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.gender}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Age</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.AgeYear}
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Doctor</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.doctor}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Admitted For</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.AdmittedFor}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Bed</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.BedNo}
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Ward</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.Ward}
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">ADM Date/Time</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.admissionDate}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Discharge Date/Time</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.dischargeDate}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Bill Status</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {patientData && patientData.BillStatus}
              </span>
            </div>
          </div>
        </div>
      </fieldset>

      {!!patientData && (
        <>
          <div className="grid lg:grid-cols-9 md:grid-cols-3   items-center">
            <div
              className="md:col-span-1 lg:col-span-2 "
              onClick={(e) => {
                // patientData &&
                //   patientData.TentativeDischargeDate === null &&
                e.target.checked && setOpenTentativeDischargeModal(true);
              }}
            >
              <CheckBoxField
                control={control}
                name="tentativeDischarge"
                label="Tentative Discharge"
              />
            </div>
            {patientData && patientData.TentativeDischargeDate && (
              <div className="md:col-span-2 lg:col-span-3">
                <div className="flex items-center font-semibold text-sm">
                  <span className="">Tentative Discharge Date : &nbsp;</span>
                  <span className="text-gray-700 font-normal">
                    {patientData && patientData.TentativeDischargeDate}
                  </span>
                </div>
              </div>
            )}
            <div
              className="lg:col-span-1 md:col-span-1"
              onClick={(e) => {
                if (!e.target.checked && tabValue === 7) {
                  setTabValue(0);

                  setValue("dateOfDeath", new Date());
                  setValue("timeOfDeath", new Date());
                  setValue("causeOfDeath", "");
                  setValue("identificationMark", "");
                  setValue("dyingDeclaration", "");
                  setValue("materialForMedical", "");
                  setValue("resonsForPostmortem", "");
                  setValue("optionAboutCauseOfDeath", "");
                }
              }}
            >
              <CheckBoxField
                control={control}
                name="isDeathSummary"
                label="Death Summary"
                // disabled={true}
              />
            </div>

            {userActions?.map((obj) => (
              <>
                {!obj.isAction &&
                  obj.action === "MLCEdit" &&
                  patientData &&
                  patientData?.MedicoLegalCase && (
                    <div className="flex justify-end">
                      <button className="flex border border-customGray bg-gray-100 rounded items-center px-1 hover:bg-indigo-100">
                        <EditOnIcon
                          title={"Edit Discharge Mlc"}
                          onClick={() => {
                            setOpenMlcModal(true);
                            setPrivilege(obj.action);
                          }}
                        />
                      </button>
                    </div>
                  )}
              </>
            ))}

            <div
              className={
                patientData.TentativeDischargeDate
                  ? "lg:col-span-9 md:col-span-2 flex justify-end"
                  : "lg:col-span-6 md:col-span-2 flex justify-end"
              }
            >
              {/* // Print History Sheet */}

              {userActions?.map((obj) => (
                <>
                  {!obj.isAction &&
                    obj.action === "Print" &&
                    patientData &&
                    patientData?.DischargeSummaryId !== null && (
                      // ((patientData?.companyId === 0 &&
                      //   patientData?.allowgotoslip !== null) ||
                      //   (patientData?.companyId > 0 &&
                      //     patientData?.IsBillGenerated === true)) &&
                      <>
                        {/* History Sheet */}
                        {tabValue === 1 && (
                          // &&
                          //!deathSummary
                          //  !patientData.IsDeath
                          <>
                            <CustomPrintButton
                              label="History Sheet"
                              className="bg-pink-200"
                              onClick={() =>
                                handleOpenPrintModal("historySheet")
                              }
                            />
                          </>
                        )}

                        {/* Ganacology section*/}
                        {tabValue === 4 && !deathSummary && (
                          // !patientData.IsDeath
                          <div className="flex flex-wrap gap-3">
                            <CustomPrintButton
                              label="Embryo Transfer"
                              className="bg-amber-100"
                              onClick={() =>
                                handleOpenPrintModal("embryoTransferCase")
                              }
                            />

                            <CustomPrintButton
                              label="Ovum PICKUP"
                              className="bg-pink-200"
                              onClick={() => handleOpenPrintModal("ovumPICKUP")}
                            />

                            <CustomPrintButton
                              label="Mtp Case"
                              className="bg-green-100"
                              onClick={() =>
                                handleOpenPrintModal("mtpCaseSheet")
                              }
                            />

                            <CustomPrintButton
                              label="Gyneac Case"
                              className="bg-violet-100"
                              onClick={() => handleOpenPrintModal("gyneacCase")}
                            />

                            <CustomPrintButton
                              label="Obstetric"
                              className="bg-sky-200"
                              onClick={() =>
                                handleOpenPrintModal("obstetricCaseSheet")
                              }
                            />
                          </div>
                        )}

                        {/* pediatric */}
                        {tabValue === 5 && !deathSummary && (
                          // !patientData.IsDeath
                          <div className="flex gap-3">
                            <CustomPrintButton
                              label="Pediatric"
                              className="bg-amber-100"
                              onClick={() =>
                                handleOpenPrintModal("pediatricSummary")
                              }
                            />
                          </div>
                        )}
                        {/* neonatal */}
                        {tabValue === 6 && !deathSummary && (
                          <div className="flex gap-3">
                            <CustomPrintButton
                              label="Neonatal"
                              className="bg-green-200"
                              onClick={() => handleOpenPrintModal("neonatal")}
                            />
                          </div>
                        )}

                        {/* deathSummary */}
                        {tabValue === 7 && (
                          //  && patientData.IsDeath
                          <>
                            <CustomPrintButton
                              label="Death Summary"
                              className="bg-[#FFD4E3]"
                              onClick={() =>
                                handleOpenPrintModal("deathSummary")
                              }
                            />
                          </>
                        )}
                      </>
                    )}
                </>
              ))}
            </div>
          </div>

          <FormProvider {...methods}>
            <form>
              <div className="grid grid-cols-12">
                <div
                  className="col-span-12"
                  onClick={() => {
                    !!patchData &&
                      patchData.Finalize === 1 &&
                      warningAlert(
                        "Discharge summary is finalized for this patient..."
                      );
                  }}
                >
                  <DischargeSummaryContext.Provider
                    value={{
                      //
                      tabValue,
                      setTabValue,
                      //
                      patientData,
                      patchData,
                      //
                      setMewsScore,
                      mewsScore,
                      mewsScoreVitals,
                      setMewsScoreVitals,
                      //
                      lastUpdatedVtalDate,
                      setLastUpdatedVtalDate,
                      //

                      deletedMedicationData,
                      setDeletedMedicationData,
                      allMedicationData,
                      setAllMedicationData,
                      //
                      specificAdvices,
                      setSpecificAdvices,
                      //
                      followupDate,
                      setFollowupDate,
                      //
                      lmpDate,
                      setlmpDate,
                      eddDate,
                      seteddDate,
                      pogAccordingDate,
                      setPogAccordingDate,
                      accordingUsgDate,
                      setAccordingUsgDate,
                      //
                      deathDate,
                      setDeathDate,
                      deathTime,
                      setDeathTime,
                      //
                      admissionId,
                      deathSummary,
                      //
                      setStateNeonatalDto,
                      stateNeonatalDto,
                      //
                      retrivedDrugData,
                      setRetrivedDrugData,
                      //
                      neonatalChecklist,
                      setNeonatalChecklist,
                    }}
                  >
                    <DischargeSummaryTabs
                      treatmentInfo={treatmentInfo}
                      setTreatmentInfo={setTreatmentInfo}
                    />
                  </DischargeSummaryContext.Provider>
                  <div className="grid lg:grid-cols-8 gap-3 pt-6 ">
                    <div className="grid grid-cols-5 lg:col-span-3 ">
                      <div className="text-black text-sm font-semibold pt-2 col-span-1  ">
                        Doctor Name -
                      </div>
                      <div className="col-span-2 ">
                        <DropdownField
                          control={control}
                          //error={errors.doctor}
                          name="doctor"
                          label="Doctor "
                          dataArray={doctor}
                          onInputChange={(e) => {
                            setDoctorSearchString(e);
                          }}
                          isSearchable={true}
                          isClearable={true}
                          searchIcon={true}
                          placeholder="Doctor"
                          isDisabled={true}
                        />
                      </div>
                    </div>

                    <div className="flex gap-3 justify-end lg:col-span-5 ">
                      {userActions?.map((obj) => (
                        <>
                          {!obj.isAction &&
                            obj.action === "Finalise" &&
                            !!patchData &&
                            patchData.Finalize === 0 && (
                              <CommonButton
                                className="bg-customBlue text-white"
                                label="Finalize"
                                onClick={() => {
                                  methods.handleSubmit(onSubmit)(obj.action);
                                  setPrivilege(obj.action);
                                }}
                              />
                            )}

                          {!obj.isAction &&
                            obj.action === "UnFinalise" &&
                            !!patchData &&
                            patchData.Finalize === 1 && (
                              <CommonButton
                                className="bg-customBlue text-white"
                                label="Un-Finalize"
                                onClick={() => {
                                  methods.handleSubmit(onSubmit)(obj.action);
                                  setPrivilege(obj.action);
                                }}
                              />
                            )}

                          {!obj.isAction && obj.action === "Create" && (
                            <>
                              <div>
                                <CommonButton
                                  label="Reset"
                                  className="border border-customRed text-customRed"
                                  onClick={() => {
                                    checkSimilarTransaction(false, patientData);
                                    resetData();
                                  }}
                                />
                              </div>

                              {(patchData === null ||
                                (!!patchData && patchData.Finalize) === 0) && (
                                <div>
                                  <CommonButton
                                    label="Save"
                                    onClick={() => {
                                      methods.handleSubmit(onSubmit)(
                                        obj.action
                                      );
                                      setPrivilege(obj.action);
                                    }}
                                    className="bg-customGreen text-white"
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </div>

                    {/* 
                    <div className="flex gap-3 justify-end lg:col-span-5 ">
                      {userActions.map((obj) => (
                        <>
                          {!obj.isAction && obj.action === "Finalise" ? (
                            <>
                              {patchData !== null ? (
                                patchData.Finalize === 0 ? (
                                  <div
                                    onClick={() => {
                                      setPrivilege(obj.action);
                                      // if (!patientData?.mlcFromDischargeDone) {
                                      //   warningAlert(
                                      //     "Kindly fill discharge MLC details."
                                      //   );
                                      // }
                                    }}
                                  >
                                    <CommonButton
                                      label="Finalize"
                                      // disabled={props?.IsBillGenerated===false}
                                      onClick={() => {
                                        if (diagnosisICD_val === "") {
                                          warningAlert(
                                            "Please fill Diagnosis/ICD !"
                                          );
                                        } else if (
                                          reportToHospital_val === ""
                                        ) {
                                          warningAlert(
                                            "Please fill 'Report to Hospital' in follow-up instructions !"
                                          );
                                        } else if (
                                          patientData?.MedicoLegalCase
                                        ) {
                                          if (
                                            patientData?.mlcFromDischargeDone
                                          ) {
                                            methods.handleSubmit(onSubmit)();
                                          } else {
                                            warningAlert(
                                              "Kindly fill discharge MLC details."
                                            );
                                          }
                                        } else {
                                          methods.handleSubmit(onSubmit)();
                                        }
                                      }}
                                      className="bg-customBlue text-white"
                                    />
                                  </div>
                                ) : null
                              ) : patchData === null ? (
                                <div
                                  onClick={() => {
                                    setPrivilege(obj.action);
                                    // if (!patientData?.mlcFromDischargeDone) {
                                    //   warningAlert(
                                    //     "Kindly fill discharge MLC details."
                                    //   );
                                    // }
                                  }}
                                >
                                  <CommonButton
                                    label="Finalize"
                                    // disabled={props?.IsBillGenerated===false}
                                    onClick={() => {
                                      if (diagnosisICD_val === "") {
                                        warningAlert(
                                          "Please fill Diagnosis/ICD !"
                                        );
                                      } else if (reportToHospital_val === "") {
                                        warningAlert(
                                          "Please fill 'Report to Hospital' in follow-up instructions !"
                                        );
                                      } else if (patientData?.MedicoLegalCase) {
                                        if (patientData?.mlcFromDischargeDone) {
                                          methods.handleSubmit(onSubmit)();
                                        } else {
                                          warningAlert(
                                            "Kindly fill discharge MLC details."
                                          );
                                        }
                                      } else {
                                        methods.handleSubmit(onSubmit)();
                                      }
                                    }}
                                    className="bg-customBlue text-white"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ) : null}

                          {!obj.isAction &&
                            obj.action === "UnFinalise" &&
                            patchData !== null &&
                            patchData.Finalize === 1 && (
                              <div
                                onClick={() => {
                                  getDischargeSummaryDetails();
                                  setPrivilege(obj.action);
                                }}
                              >
                                <CommonButton
                                  label="Un-Finalize"
                                  onClick={methods.handleSubmit(onSubmit)}
                                  className="bg-customBlue text-white"
                                />
                              </div>
                            )}

                          {!obj.isAction && obj.action === "Create" ? (
                            <>
                              <div>
                                <CommonButton
                                  label="Reset"
                                  className="border border-customRed text-customRed"
                                  onClick={() => {
                                    checkSimilarTransaction(false, patientData);
                                    resetData();

                                    // setIsDisableSearch(false);
                                    // methods.reset(resetDefaultValues);
                                    // setAdmissionId(null);
                                    // setPatientId(null);
                                    // setPatientData("");
                                    // setAllMedicationData([]);
                                    // deletedMedicationData([]);
                                    // setSpecificAdvices([]);
                                    // // setSelectedPatient(null);
                                    // // methods.resetField("dischrgeSummarySearch");
                                    // methods.setValue(
                                    //   "dischrgeSummarySearch",
                                    //   null
                                    // ); //clear patient search

                                    // setTabValue(0);
                                  }}
                                />
                              </div>

                              {patchData !== null ? (
                                patchData.Finalize === 0 ? (
                                  <div
                                    onClick={() => {
                                      setIsFinalize(false);
                                      setPrivilege(obj.action);
                                    }}
                                  >
                                    <CommonButton
                                      // type="submit"
                                      label="Save"
                                      onClick={methods.handleSubmit(onSubmit)}
                                      className="bg-customGreen text-white"
                                      // disabled={props?.IsBillGenerated===false}
                                    />
                                  </div>
                                ) : null
                              ) : patchData === null ? (
                                <div
                                  onClick={() => {
                                    setIsFinalize(false);
                                    setPrivilege(obj.action);
                                  }}
                                >
                                  <CommonButton
                                    //type="submit"
                                    label="Save"
                                    onClick={methods.handleSubmit(onSubmit)}
                                    className="bg-customGreen text-white"
                                    // disabled={props?.IsBillGenerated===false}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ) : null}
                        </>
                      ))}
                    </div>*/}
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </>
      )}
      {/* Discharge Summary Print Modal */}
      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
          preview={
            printType !== "historySheet"
              ? (!!patientData?.allowgotoslip || patientData?.companyId > 1) &&
                !!patchData &&
                patchData.Finalize === 1
                ? false
                : true
              : false
          }
        />
      ) : null}

      {openAllowMultiPatientAccessModal && (
        <ConfirmationModal
          confirmationOpen={openAllowMultiPatientAccessModal}
          confirmationHandleClose={() => {
            setOpenAllowMultiPatientAccessModal(false);
            resetData();
          }}
          confirmationSubmitFunc={() => {
            setOpenAllowMultiPatientAccessModal(false);
            successAlert("Allowed to access patient.");
          }}
          confirmationLabel="Confirmation "
          confirmationMsg={
            <div className="grid gap-3">
              <div> {multiUserMsg}</div>
              <div> Do you want to continue?</div>
            </div>
          }
          confirmationButtonMsg="Proceed"
        />
      )}

      {/* Tentative Discharge Modal */}

      {openTentativeDischargeModal ? (
        <TentativeDischargeModal
          open={openTentativeDischargeModal}
          setOpen={setOpenTentativeDischargeModal}
          handleClose={() => {
            setOpenTentativeDischargeModal(false);
          }}
          admissionId={admissionId}
          getPatientInfo={getPatientInfo}
          patientId={patientId}
          tentativediscDate={
            patientData && patientData.TentativeDischargeDate !== null
              ? patientData.TentativeDischargeDate
              : new Date()
          }
        />
      ) : null}
      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
          setIsFinalize("");
        }}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />

      {/* mlc Modal */}
      {openMlcModal && patientData && patientData !== null ? (
        <MLCModalEdit
          open={openMlcModal}
          setOpen={setOpenMlcModal}
          handleOpen={handleOpenMlcModal}
          handleClose={handleCloseMlcModal}
          patientInfo={true}
          MLCNo={null}
          isFromForm={false} //nneed to save so false
          admissionId={admissionId !== null ? Number(admissionId) : Number(0)}
          emergencyId={patientData?.EmergencyId} //patientId is Actualy EmergencyId
          opdIpd={1}
          ipdEmergency={admissionId !== 0 ? 1 : 2}
          //
          patientId={patientData?.PatientId}
          isEmergency={false}
          //
          isFromDischargeSummary={true}
          privilege={privilege || "MLCEdit"} //it is to hide inputs and show only info and police info
          menuId={!!props.menuId ? props.menuId : location?.state?.menuId}
          getPatientInfo={getPatientInfo}
        />
      ) : null}
    </div>
  );
};

export default DischargeSummaryTabWise;

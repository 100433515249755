import * as React from "react";

import { Box, Grid, Modal, TextField } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import DropdownField from "../../../../common/components/FormFields/DropdownField";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import InputField from "../../../../common/components/FormFields/InputField";

import CancelButton from "../../../../common/components/Buttons/CancelButton";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SaveButton } from "../../../../common/components/Buttons/CommonButtons";

import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
//imports from react hook form
import { Controller, useFieldArray, useForm } from "react-hook-form";

import {
  getAdvancedDropdown,
  getBankList,
  getPaymentMode,
} from "../../../../billing/services/BillingServices";
//style for model
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "1px solid gray",

  boxShadow: 20,
  p: 2,
};
export default function BillInformationModal(props) {
  

  let paymentSchema = {
    paymentCart: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup
            .number()
            .typeError("Amount Required")
            .min(1, "Min value 1.")
            .required("Amount Required"),

          paymentMode: yup
            .object()
            .nullable()
            .shape({
              value: yup.string().required("Required"),
              label: yup.string().required("Required"),
            })
            .required("Required"),

          // referenceNumber: yup.string().when("paymentMode", (paymentMode) => {
          //   if (paymentMode !== null) {
          //     if (paymentMode.label !== "CASH") {
          //       return yup
          //         .string()
          //         .required("Required")
          //         .min(4, "Add Min Four Digit")
          //         .max(4, "Add Max Four Digit  ");
          //     }
          //   }
          // }),
          //validation schema for referenceNumber input field
          referenceNumber: yup.string().when("paymentMode", (paymentMode) => {
            

            let paymentModeName;

            if (paymentMode) {
              paymentModeName = paymentMode.label;
            }

            if (
              paymentModeName === "CREDIT CARD" ||
              paymentModeName === "DEBIT CARD"
            ) {
              return yup
                .string()
                .required("Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(4)
                .max(30);
            } else if (paymentModeName === "UPI") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(30);
              // .length(20, "Only 6 Digits allowed");
            } else if (paymentModeName === "CHEQUE") {
              return yup
                .string()
                .required("Reference Number Required")
                .max(30, "Only 6 Digits allowed");
            } else if (paymentModeName === "ECS") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(30);
            } else if (paymentModeName === "Online") {
              return yup
                .string()
                .required("Reference Number Required")
                .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric.")
                .min(1)
                .max(30);
              // .length(20, "Only 6 Digits allowed");
            } else {
              return yup.string().notRequired();
            }
          }),
          bankName: yup
            .object()
            .nullable()
            .typeError("Required")
            .when("paymentMode", (paymentMode) => {
              if (paymentMode !== null) {
                if (
                  paymentMode.label !== "CASH" &&
                  paymentMode.label !== "UPI"
                ) {
                  return yup
                    .object()
                    .nullable()
                    .shape({
                      value: yup.string().required("Select Bank"),
                      label: yup.string().required("Select Bank"),
                    })
                    .required("Select Bank");
                }
              }
            }),
        })
      )
      .min(1, "Please Add amount Details"),
  };
  const paymentAppointValidation = yup.object().shape(paymentSchema).required();
  const paymentSchemaObj = paymentAppointValidation;

  let today = new Date();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(paymentSchemaObj),
    defaultValues: {
      paymentCart: [
        {
          paymentMode: "",
          amount: "",
          referenceNumber: "",
          bankName: "",
          paymentDate: today,
        },
      ],
    },
  });

  //destructuring the methods and giving them the same name , as they have in the useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = methods;

  const {
    fields,
    append: append,
    remove: remove,
  } = useFieldArray({
    control: control,
    name: "paymentCart",
  });

  // state variables
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [paymentInfo, setPaymentInfo] = React.useState([]);
  const [showAddIcon, setShowAddIcon] = React.useState(false);
  const [netPayableAmountVal, setNetPayableAmountVal] = React.useState(0);
  const [netPayErrorMessage, setNetPayErrorMessage] = React.useState("");
  const [customCashError, setCustomCashError] = React.useState(false);
  const [advancedList, setAdvancedList] = React.useState([]);
  const [bankList, setBankList] = React.useState([]);
  const [paymentModeList, setPaymentModeList] = React.useState([]);

  // other variables
  let consumeAdvanceFlag = watch("consumeAdvance");
  let refundVal = props.getValues("refundAmount");

  let totalAmount = 0;
  let netPayErrorMessage1 = "";
  let paymentInfoList = [];

  // useEffects

  const watchPaymentCart = watch("paymentCart");


  const controlledPaymentCart = fields.map((field, index) => {
    return {
      ...field,
      ...watchPaymentCart[index],
    };
  });

  React.useEffect(() => {
    if (controlledPaymentCart.length > 0) {
      let paidAmount = 0;
      for (let paymentObj of controlledPaymentCart) {
        if (paymentObj.amount) {
          paidAmount = parseInt(paidAmount) + parseInt(paymentObj.amount);
        }
      }
      let netPayableAmount = props.getValues("netPayableAmount");
      if (paidAmount > netPayableAmount) {
        
      }
      
    }
  }, [props, controlledPaymentCart]);

  React.useEffect(() => {
    getAdvancedDropdown().then((response) => {
      
      setAdvancedList(response.data.result);
    });

    getBankList().then((response) => {
      
      setBankList(response.data.result);
    });

    getPaymentMode().then((response) => {
      
      setPaymentModeList(response.data.result);
      
      setValue(`paymentCart.0.paymentMode`, response.data.result[1]);
    });

    setValue(`paymentCart.0.amount`, props.refundAmount);
    // setRefundAmount(props.refundAmount);
  }, [refundVal, props.refundAmount, setValue]);

  // functions

  // handle open and close
  const handleOpenConfirmation = () => {
    // if (netPayErrorMessage1 === "") {
      setConfirmationOpen(true);
    // }
  };
  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  //calculate sum of all add button
  function calculateFieldAmountSum() {
    
    let totalAmount = 0;

    //Calculate the sum of all the paid amounts from various payment modes and store it in the variable totalAmount.
    for (let cartObj of watchPaymentCart) {
      
      
      if (watchPaymentCart.length > 1) {
        
        if (cartObj && cartObj.amount) {
          
          totalAmount = Number(totalAmount) + Number(cartObj.amount);
          
        }
      } else if (watchPaymentCart.length === 1 && cartObj.amount) {
        
        totalAmount = Number(cartObj.amount);
        
        
      }
    }

    return totalAmount;
  }

  if (watchPaymentCart[0].amount < props.refundAmount) {
    netPayErrorMessage1 = "Total Amount Must Be Equal To Refund Amount";

    
  } else {
    netPayErrorMessage1 = "";
    
  }

  //update amount change from amount input filed
  function handleAmountChange() {
    //The below if block is to display the "+" icon only at the first field ; when the length of the field is 1.
    if (watchPaymentCart.length === 1) {
      let firstFieldAmount = Number(watchPaymentCart[0].amount);

      if (firstFieldAmount === props.refundAmount) {
        setShowAddIcon(false);
      } else if (firstFieldAmount < props.refundAmount) {
        setShowAddIcon(true);
      }
    }

    let totalSumVal = calculateFieldAmountSum();
    
    //This if block is to detect "Total Amount has exceeded netPayVal".
    if (props.refundAmount !== null) {
      if (totalSumVal > props.refundAmount) {
        setNetPayErrorMessage("Total Amount Exceeded Net Refund Amount");
      } else {
        setNetPayErrorMessage("");
      }
    }
  }

  // cash options allow only once
  function detectDuplicateCashOption() {
    let cashCounterIndicator = 0;
    for (let cartArr of watchPaymentCart) {
      if (cartArr.paymentMode.label === "CASH") {
        cashCounterIndicator = cashCounterIndicator + 1;
      }
      if (cashCounterIndicator > 1) {
        setCustomCashError(true);
      } else {
        setCustomCashError(false);
      }
    }
  }

  //function that is called after clicking the submit button of the payment info modal
  function onSubmitDataHandler(data) {
    

    let paymentCart = data.paymentCart;

    

    for (let formObj of paymentCart) {
      let obj = {
        amount: 0,
        bankId: 0,
        paymentDate: "",
        paymentType: {
          id: 0,
          label: "",
          value: "",
        },
        referenceNumber: "",
      };

      obj.amount = formObj.amount;
      obj.paymentType = formObj.paymentMode;

      if (formObj.hasOwnProperty("referenceNumber")) {
        obj.referenceNumber = formObj.referenceNumber;
      }

      if (formObj.hasOwnProperty("bankName") && formObj["bankName"] !== null) {
        obj.bankId = formObj.bankName.id;
      }

      if (formObj.hasOwnProperty("paymentDate")) {
        obj.paymentDate = formObj.paymentDate;
      }

      paymentInfoList.push(obj);
      setPaymentInfo(paymentInfoList);
      if (netPayErrorMessage === "") {
        handleOpenConfirmation();
      }
    }

    // props.submitPaymentInfoModal(paymentInfoList);
  }
  function saveData(paymentInfo) {
    
    props.setOpenBackdrop(true);
    props.submitPaymentInfoModal(paymentInfo);
    handleCloseConfirmation();
  }

  function appendPaymentField(fieldIndex) {
    let requiredObj = watchPaymentCart[fieldIndex];

    

    //if the payment mode is "CASH"

    let totalAmountVal = calculateFieldAmountSum();
    
 

    //check CASH Payment mode
    if (requiredObj.paymentMode && requiredObj.paymentMode.label === "CASH") {
      if (requiredObj.amount || requiredObj.amount === 0) {
        if (totalAmountVal < props.refundAmount) {
          

          let nextFieldAmountVal = props.refundAmount - totalAmountVal;
          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        } else {
          trigger();
        }
      }
    }

    //payment mode is UPI
    if (requiredObj.paymentMode && requiredObj.paymentMode.label === "UPI") {
      if (
        (requiredObj.amount || requiredObj.amount === 0) &&
        requiredObj.referenceNumber
      ) {
        let totalAmountVal = calculateFieldAmountSum();

        if (totalAmountVal < props.refundAmount && customCashError === false) {
          let nextFieldAmountVal = props.refundAmount - totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        }
      } else {
        trigger();
      }
    }

    //if the payment mode is one of "CHEQUE" , "CREDIT CARD" , "DD" , "DEBIT CARD"
    if (
      requiredObj.paymentMode &&
      (requiredObj.paymentMode.label === "CHEQUE" ||
        requiredObj.paymentMode.label === "CREDIT CARD" ||
        requiredObj.paymentMode.label === "DD" ||
        requiredObj.paymentMode.label === "DEBIT CARD")
    ) {
      if (
        (requiredObj.amount || requiredObj.amount === 0) &&
        requiredObj.referenceNumber &&
        requiredObj.bankName &&
        requiredObj.bankName.label !== " "
      ) {
        let totalAmountVal = calculateFieldAmountSum();

        if (totalAmountVal < props.refundAmount && customCashError === false) {
          let nextFieldAmountVal = props.refundAmount - totalAmountVal;

          append({
            paymentMode: null,
            amount: nextFieldAmountVal,
            referenceNumber: "",
            bankName: null,
            paymentDate: today,
          });
        }
      } else {
        trigger();
      }
    }

    //if the paymentMode is not selected -- that is when it is undefined ; then at that time also trigger the validation.
    if (!requiredObj.paymentMode) {
      
      trigger();
    }

    //if the paymentMode is selected as blank ; then at that time also trigger the validation.
    if (requiredObj.paymentMode.label === " ") {
      
      trigger();
    }
  }

  function removePaymentFieldRow(fieldIndex) {
    let requiredObj = watchPaymentCart[fieldIndex];
    

    let totalSumVal = 0;
    for (let index = 0; index < watchPaymentCart.length; index++) {
      if (watchPaymentCart.length > 1) {
        if (watchPaymentCart[index] && watchPaymentCart[index].amount) {
          if (index !== fieldIndex) {
            totalSumVal =
              Number(totalSumVal) + Number(watchPaymentCart[index].amount);
          }
        }
      } else if (
        watchPaymentCart.length === 1 &&
        watchPaymentCart[index].amount
      ) {
        totalSumVal = Number(watchPaymentCart[index].amount);
      }
    }
    remove(fieldIndex);
  }

  return (
    <div className=" bg-white px-6">
      <Modal
        open={props.open}
        onClose={() => {
          // props.handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div className="grid grid-cols-1 md:grid-cols-1  w-full">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClose();
                setValue(`paymentCart.0.amount`, 0);
                paymentInfoList = [];
                props.refundAmount(0);
                setNetPayErrorMessage("");
                setPaymentInfo([]);
                setPaymentModeList([]);
                setBankList([]);
              }}
              style={{
                paddingLeft: 10,
              }}
            />
          </div>
          <div className="row">
            <fieldset className="border border-gray-300 text-left   lg:mx-auto lg:px-4 md:ml-0 md:mr-0  rounded   lg:m-2 ">
              <legend className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                Bill Information
              </legend>
              <form
                onSubmit={handleSubmit(onSubmitDataHandler)}
                className="grid grid-cols-1 md:grid-cols-1  gap-2"
              >
                <div className=" bg-white px-4 ">
                  <>
                    {/* Paitent Inf0 */}
                    <fieldset className="mx-auto border w-full rounded my-2 bg-gray-100 px-4">
                      <legend className=" rounded-xl">
                        <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                          Patient Information
                        </p>
                      </legend>
                      <>
                        <div className="mb-2 grid  lg:grid lg:grid-cols-[35%_20%_20%_25%]  2xl:grid-cols-[35%_20%_20%_25%] gap-2 p-2 ">
                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                            <span className="w-24 font-semibold">
                              Patient Name
                            </span>
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {props.searchResult &&
                                props.searchResult.patientName}
                            </span>
                          </div>
                          <div className="flex items-enter gap-2 w-full font-semibold text-sm py-1">
                            <span className="w-24 lg:w-20 xl:w-14 font-semibold">
                              MR No.
                            </span>
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {props.searchResult && props.searchResult.mrNo}
                            </span>
                          </div>

                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1">
                            <span className="w-24 xl:w-8 font-semibold">
                              Age
                            </span>
                            <span>:</span>
                            <span className="text-gray-700 font-normal">
                              {props.searchResult &&
                                props.searchResult.ageYear}
                            </span>
                          </div>

                          <div className="flex items-center gap-2 w-full font-semibold text-sm py-1 whitespace-nowrap">
                            <span className=" w-32  ">Net Payable Amount</span>
                            <div className="flex space-x-2 items-center">
                              <span>:</span>
                              <span className="text-gray-700 font-normal">
                              ₹{props.refundAmount}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    </fieldset>

                    {/* Payment Form */}
                    <div className="w-full">
                      <fieldset className="mx-auto border w-full rounded my-3">
                        <legend className="ml-6 my-0 rounded-xl">
                          <p className="md:mx-2 md:px-2 lg:px-2 font-bold text-gray-700">
                            Payment Details
                          </p>
                        </legend>

                        <div className="mx-auto px-6 py-2">
                          {fields.map((field, index) => {
                            let dropdownObj = watch(
                              `paymentCart.[${index}].paymentMode`
                            );

                            

                            return (
                              <Grid container spacing={2} key={field.id}>
                                <>
                                  <Grid
                                    item
                                    lg={2.5}
                                    md={6}
                                    sm={6}
                                    sx={{ marginY: "0.5rem" }}
                                  >
                                    <DropdownField
                                      control={control}
                                      name={`paymentCart.[${index}].paymentMode`}
                                      error={
                                        watchPaymentCart[index].paymentMode
                                          ?.label === "CASH" &&
                                        customCashError === true
                                          ? {
                                              message:
                                                "Cash Allowed only once.",
                                            }
                                          : errors.paymentCart?.[index]
                                              ?.paymentMode
                                      }
                                      label="Payment Type"
                                      dataArray={paymentModeList}
                                      isSearchable={false}
                                      placeholder="Payment Type *"
                                      inputRef={{
                                        ...register(
                                          `paymentCart.[${index}].paymentMode`,
                                          {
                                            onChange: (e) => {
                                              detectDuplicateCashOption();
                                            },
                                          }
                                        ),
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    lg={2}
                                    md={6}
                                    sm={6}
                                    sx={{ marginY: "0.5rem" }}
                                  >
                                    <InputField
                                      name={`paymentCart.[${index}].amount`}
                                      error={
                                        errors.paymentCart?.[index]?.amount
                                      }
                                      variant="outlined"
                                      label="Amount"
                                      control={control}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      inputRef={{
                                        ...register(
                                          `paymentCart.[${index}].amount`,
                                          {
                                            onChange: (e) => {
                                              handleAmountChange();
                                            },
                                          }
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </>

                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "DD" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "ONLINE" ||
                                  dropdownObj.label === "CHEQUE" ||
                                  dropdownObj.label === "UPI") ? (
                                  <>
                                    <Grid
                                      item
                                      lg={2}
                                      md={6}
                                      sm={6}
                                      sx={{ marginY: "0.5rem" }}
                                    >
                                      <InputField
                                        name={`paymentCart.[${index}].referenceNumber`}
                                        variant="outlined"
                                        label="Reference Number"
                                        control={control}
                                        error={
                                          errors.paymentCart?.[index]
                                            ?.referenceNumber
                                        }
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "DD" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "UPI" ||
                                  dropdownObj.label === "ONLINE" ||
                                  dropdownObj.label === "CHEQUE") ? (
                                  <>
                                    <Grid
                                      item
                                      lg={2.5}
                                      md={6}
                                      sm={6}
                                      sx={{ marginY: "0.5rem" }}
                                    >
                                      <DropdownField
                                        control={control}
                                        name={`paymentCart.[${index}].bankName`}
                                        label="Bank Name"
                                        dataArray={bankList}
                                        isSearchable={true}
                                        placeholder="Bank Name *"
                                        searchIcon={true}
                                        error={
                                          errors.paymentCart?.[index]?.bankName
                                        }
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                {/* Date Picker Component */}
                                {dropdownObj &&
                                (dropdownObj.label === "DEBIT CARD" ||
                                  dropdownObj.label === "CREDIT CARD" ||
                                  dropdownObj.label === "DD" ||
                                  dropdownObj.label === "ONLINE" ||
                                  dropdownObj.label === "ECS" ||
                                  dropdownObj.label === "CHEQUE") ? (
                                  <>
                                    <Grid
                                      lg={2}
                                      sm={6}
                                      sx={{
                                        marginY: "1.6rem",
                                        marginLeft: 2,
                                      }}
                                    >
                                      <Controller
                                        control={control}
                                        defaultValue={new Date()}
                                        rules={{
                                          required: true,
                                        }}
                                        render={({ field }) => (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DatePicker
                                              // open={openDate}
                                              // onOpen={() => setOpenDate(true)}
                                              // onClose={() => setOpenDate(false)}
                                              inputProps={{
                                                readOnly: true,
                                              }}
                                              // disablePast
                                              // readOnly={true}

                                              renderInput={(props) => (
                                                <TextField
                                                  {...props}
                                                  type="date"
                                                  variant="outlined"
                                                  label="Registration Date"
                                                  name="paymentDate"
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  fullWidth
                                                  size="small"
                                                  sx={{
                                                    svg: {
                                                      color: "#1e3a8a",
                                                      height: 22,
                                                      width: "100%",
                                                      marginRight: "16px",
                                                    },
                                                    backgroundColor: "white",
                                                    overflow: "visible",

                                                    "& .MuiOutlinedInput-root":
                                                      {
                                                        "& .MuiOutlinedInput-input":
                                                          {
                                                            // border: 0,
                                                            fontSize: 14,
                                                            height: "18px",
                                                            width: "100%",

                                                            borderColor: "  ",
                                                            overflow: "hidden",
                                                          },
                                                        "& .MuiFormLabel-root":
                                                          {
                                                            fontSize: "14px",
                                                          },
                                                      },
                                                  }}
                                                />
                                              )}
                                              inputFormat="dd/MM/yyyy"
                                              disablePast
                                              {...field}
                                              onAccept={(e) => {
                                                // getNewRegDate(e, index);
                                              }}
                                              // error={
                                              //   errors.paymentDetails?.[index]
                                              //     ?.paymentDate
                                              // }
                                              // helperText={errors.paymentDate?.message}
                                            />
                                          </LocalizationProvider>
                                        )}
                                        name={`paymentCart.[${index}].paymentDate`}
                                      />
                                    </Grid>
                                  </>
                                ) : null}

                                <div className="flex items-center">
                                  {showAddIcon === true && index === 0 ? (
                                    <AddOutlinedIcon
                                      className="mt-2 mx-1  rounded-full border-2 border-cyan-600"
                                      onClick={() => {
                                        if (netPayErrorMessage === "") {
                                          appendPaymentField(index);
                                        }
                                      }}
                                    />
                                  ) : null}

                                  {index > 0 && fields.length > 1 ? (
                                    <>
                                      <RemoveOutlinedIcon
                                        className="mt-2 mx-1 rounded-full border-2 border-red-600"
                                        onClick={() => {
                                          if (fields.length > 1) {
                                            removePaymentFieldRow(index);
                                          }
                                        }}
                                      />
                                      <AddOutlinedIcon
                                        className="mt-2 mx-1  rounded-full border-2 border-cyan-600"
                                        onClick={() => {
                                          if (netPayErrorMessage === "") {
                                            appendPaymentField(index);
                                          }
                                        }}
                                      />
                                    </>
                                  ) : null}
                                </div>
                                {/* </Grid> */}
                              </Grid>
                            );
                          })}
                          <span className=" text-red-500">
                            {netPayErrorMessage}
                          </span>
                        </div>
                      </fieldset>

                      <Grid
                        item
                        lg={1.5}
                        md={1.5}
                        className="flex justify-end gap-2 py-2"
                      >
                        <CancelButton
                          onClick={() => {
                            props.handleClose();
                          }}
                        />
                        <SaveButton
                          // let consumeAmountFlag=
                          // let consumeAmountVal=
                          onClick={() => {
                            //sum of all the amounts in the field
                            let totalOfFieldAmounts = calculateFieldAmountSum();

                            if (
                              netPayErrorMessage === "" &&
                              totalOfFieldAmounts === netPayableAmountVal
                            ) {
                              //open the confirmation modal
                              setConfirmationOpen(true);
                            }
                          }}
                        />
                      </Grid>
                    </div>
                  </>
                </div>
              </form>
            </fieldset>
            <CommonBackDrop openBackdrop={props.openBackdrop} />
          </div>
        </Box>
      </Modal>
      {confirmationOpen ? (
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          confirmationHandleClose={handleCloseConfirmation}
          confirmationSubmitFunc={() => {
            saveData(paymentInfo);
          }}
          confirmationLabel="Confirmation "
          confirmationMsg="Save Payment Details?"
          confirmationButtonMsg="Save Payment"
        />
      ) : null}
    </div>
  );
}

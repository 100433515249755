import apiClient from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const fetchCategoryForRadiology = () => {
  return apiClient.get(`/radiology/selectCategory`, {
    headers: authHeader(),
  });
};

export const fetchUnitOptions = () => {
  return apiClient.get(`/misc/unit`, {
    headers: authHeader(),
  });
};

export const getWorkOrderViewListing = (postObj) => {
  return apiClient.post(`/radiology/listOfOrders`, postObj, {
    headers: authHeader(),
  });
};

export const getListOfTests = (listOfTestsObj) => {
  return apiClient.post(`/radiology/listOfTests`, listOfTestsObj, {
    headers: authHeader(),
  });
};

export const getPatientInfo = (orderDtlsId, opdIpd) => {
  return apiClient.get(`/radiology/patientList/${orderDtlsId}/${opdIpd}`, {
    headers: authHeader(),
  });
};

export const getRadiologist = () => {
  return apiClient.get(`/radiology/radiologist`, {
    headers: authHeader(),
  });
};

export const getAbnormalList = () => {
  return apiClient.get(`/radiology/abnormal`, {
    headers: authHeader(),
  });
};

export const getGenderInfo = () => {
  return apiClient.get(`/misc/gender`, {
    headers: authHeader(),
  });
};

export const getReferenceDoctor = (searchString) => {
  return apiClient.get(`/misc/referenceDoctor/${searchString}`, {
    headers: authHeader(),
  });
};

export const getFilm = () => {
  return apiClient.get(`/radiology/film`, {
    headers: authHeader(),
  });
};

export const updateNonPathOrder = async (saveFinalObj) => {
  return apiClient.post(`/radiology/updateNonPathOrder`, saveFinalObj, {
    headers: authHeader(),
  });
};

export const updateProcedureStatus = (orderDtlsId, status) => {
  return apiClient.get(
    `/radiology/updateProcedureStatus/${orderDtlsId}/${status}`,
    {
      headers: authHeader(),
    }
  );
};

export const getTemplates = (orderDtlsId) => {
  return apiClient.get(`/radiology/templates/${orderDtlsId}`, {
    headers: authHeader(),
  });
};

export const getReportDetails = (orderDtlsId) => {
  return apiClient.get(`/radiology/reportsDetails/${orderDtlsId}`, {
    headers: authHeader(),
  });
};

export const getCabinPatientDtlList = (cabinName) => {
  return apiClient.get(`/radiology/cabinPatientDtlList/${cabinName}`, {
    headers: authHeader(),
  });
};

export const getCategoryPatientDtlList = (categoryId) => {
  return apiClient.get(`/radiology/categoryPatientDtlList/${categoryId}`, {
    headers: authHeader(),
  });
};

export const selectCabin = (unitId) => {
  return apiClient.get(`/radiology/selectCabin/${unitId}`, {
    headers: authHeader(),
  });
};

export const selectCategory = () => {
  return apiClient.get(`/radiology/selectCategory`, {
    headers: authHeader(),
  });
};

export const cancelTest = (orderDtlsId, menuId, privilege) => {
  return apiClient.get(
    `/radiology/cancelTest/${orderDtlsId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const cancelOrder = (orderId, menuId, privilege) => {
  return apiClient.get(
    `/radiology/cancelOrder/${orderId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const radiologyTestPrint = (orderDetailsId) => {
  return apiClient.get(
    `/reports/lapReports/radioLogy?orderDetailsId=${orderDetailsId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

export const fetchRadiologyPatientOptions = (searchObj) => {
  return apiClient.post(`/radiology/listOfOrdersSearch`, searchObj, {
    headers: authHeader(),
  });
};

export const generateRadiologyReport = (orderDtlsIds) => {
  return apiClient.get(`/radiology/generateRadiologyReport/${orderDtlsIds}`, {
    headers: authHeader(),
  });
};

export const sendReportOnWhatsapp = (postObj) => {
  return apiClient.post(`/radiology/sendReportOnWhatsapp`, postObj, {
    headers: authHeader(),
  });
};

export const sendReportOnEmail = (postObj) => {
  return apiClient.post(`/radiology/sendReportOnEmail`, postObj, {
    headers: authHeader(),
  });
};

export const radiologyWorkOrderPrint = (orderDetailsId) => {
  return apiClient.get(
    `/reports/lapReports/radioLogy?orderDetailsId=${orderDetailsId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getANCSchedulerData = (postObj) => {
  return apiClient.post(`/radiology/getAncScheduler`, postObj, {
    headers: authHeader(),
  });
};

export const saveANCSchedulerData = (postObj) => {
  return apiClient.post(`/radiology/savePatientAncScheduler`, postObj, {
    headers: authHeader(),
  });
};

export const getPatientAncScheduler = (patientId, lmpDate) => {
  let searchObject = {
    patientId: patientId,
    lmpDate: lmpDate,
  };
  return apiClient.post(`/radiology/getPatientAncScheduler`, searchObject, {
    headers: authHeader(),
  });
};

export const getServicesSearch = (searchString) => {
  return apiClient.get(`/services/getServicesSearch/${searchString}`, {
    headers: authHeader(),
  });
};

export const getImage = (filePath, type) => {
  console.log("imageType123", type);
  return apiClient.get(
    `/radiology/getDocumentByPath/${filePath[filePath?.length - 6]}/${
      filePath[filePath?.length - 5]
    }/${filePath[filePath?.length - 4]}/${filePath[filePath?.length - 3]}/${
      filePath[filePath?.length - 2]
    }/${filePath[filePath?.length - 1]}`,
    {
      headers: authHeader(),
      responseType: type === "pdf" ? "blob" : "arraybuffer",
    }
  );
};

export const getIpdFileCheckListPrint = (admissionId) => {
  return apiClient.get(`/reports/getIpdFileCheckListPrint/${admissionId}`, {
    headers: authHeader(),
  });
};

export const getTestReportDetails = (postObj) => {
  return apiClient.post(`/radiology/getTestReportDetails`, postObj, {
    headers: authHeader(),
  });
};

export const clinicalCareRadioWorkOrderTests = (listOfTestsObj) => {
  return apiClient.post(`/radiology/getListOfTestForClinical`, listOfTestsObj, {
    headers: authHeader(),
  });
};

export const updateTestName = (orderDtlsId, testName) => {
  return apiClient.get(`/radiology/updateTestName/${orderDtlsId}/${testName}`, {
    headers: authHeader(),
  });
};

import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useForm } from "react-hook-form";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";

//set descending sort order
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

//set sort desc
const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
export default function MedicinesTable(props) {
  const defaultValues = {
    durationApplyToAll: false,
  };

  const { watch, control, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const {
    dataResult,
    removeHeaders, //send array of headers which need to remove.  NOTE:send at least one header i.e. id
    tableClass, //required css for tableContainer.i.e. height ,etc.
    renderActions, //render Actions @1st column i.e.icons,checkboxes,etc.
    highlightRow, //default row highlighted,if not want to highlight set as false.
    customRowBgColor, //usefull when required another bg color of selected row than default.
    rowBackgroundColor, //use this to show conditional row bg color .
    handleSelectedRow, //get row onclick use this fn..
    editableColumns, //array of headers to make column editable
    renderInput, //actual content to render i.e. input,dropdown,checkbox,icon,etc
    SelectCheckbox, //
  } = props;

  const [rowIndex, setRowIndex] = React.useState();

  //state varibale for the table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  //by default asc order
  const handleSortRequest = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const removeHeader = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(dataResult[0]);

  const headers = removeHeaders
    ? removeHeader(allHeaders, removeHeaders && removeHeaders)
    : allHeaders;

  const filtered = dataResult.filter(
    (item) => item?.Medicine !== "" && item?.isDeleted === false
  );

  console.log(filtered,"filteredfiltered121");
  

  return (
    <>
      {/* <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", border: "" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#ebebeb",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#7393b3",
                  borderRadius: 4,
                },
              }}
              //   className="rounded lg:h-52 md:h-72"
              className={tableClass}
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",
                  paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    {renderActions && <TableCell></TableCell>}
                    {headers.map((header, index) => (
                      <TableCell
                        sortDirection={orderBy === header ? order : false}
                        className="whitespace-nowrap"
                      >
                        <TableSortLabel
                          active={false}
                          direction={orderBy === header ? order : "asc"}
                          onClick={createSortHandler(header)}
                          key={index}
                        >
                          <span className="text-gray-600 font-bold">
                            {header}
                          </span>
                          {orderBy === header ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableSort(dataResult, getComparator(order, orderBy)).map(
                    (row, index) => {
                      if (
                        index === 0 ||
                        (index !== 0 && dataResult[index - 1].Medicine !== "")
                      ) {
                        return (
                          <TableRow
                            key={index}
                            hover={false}
                            style={{
                              backgroundColor:
                                (highlightRow === undefined ||
                                  highlightRow === true) &&
                                rowIndex === index
                                  ? customRowBgColor || "#FFC44B"
                                  : rowBackgroundColor
                                  ? rowBackgroundColor(row, index)
                                  : "",
                            }}
                            onClick={() => {
                              setRowIndex(index);
                              {
                                handleSelectedRow &&
                                  handleSelectedRow(row, index);
                              }
                            }}
                          >
                            {renderActions && (
                              <TableCell sx={{
                                width:"20px"
                              }}>{renderActions(row, index)}</TableCell>
                            )}
                            {headers &&
                              headers.map((header, i) => (
                                <TableCell
                                  className="whitespace-nowrap"
                                  key={i}
                                  sx={{
                                    width:i===0?"20%":i===1?"20%":""
                                  }}
                                  padding={
                                    SelectCheckbox === true ? "checkbox" : ""
                                  }
                                  
                                >
                                  {editableColumns &&
                                  editableColumns.includes(header)
                                    ? renderInput(row, index, header)
                                    : row[header] === true
                                    ? "Yes"
                                    : row[header] === false
                                    ? "No"
                                    : row[header]}
                                </TableCell>
                              ))}
                          </TableRow>
                        );
                      }
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div> */}
      <div
        className={`w-[100%] h-[270px] overflow-x-auto 2xl:overflow-x-visible ${
          filtered?.length > 7 ? `overflow-y-scroll` : `overflow-visible`
        }`}
      >
        <table className="w-[100%] ">
          <thead
           style={{
            position: "sticky",
            top: 0,
            zIndex: 10,
            backgroundColor: "#F1F1F1",
          }}
          >
            <tr
              style={{
                backgroundColor: "#F1F1F1",
              }}
            >
              {renderActions && <td></td>}
              {/* index === 0
                      ? `w-[30%]`
                      : index === 1
                      ? `w-[30%]`
                      : index === 2
                      ? `w-[15%]`
                      : index === 3
                      ? `w-[10%]`
                      : index === 4
                      ? ``
                      : "" */}

              <td className={`whitespace-nowrap w-[15%]`}>
                <span className="text-gray-600 font-bold p-[6px] ">
                  Medicine
                </span>
              </td>
              <td className={`whitespace-nowrap w-[8%]`}>
                <span className="text-gray-600 font-bold p-[6px]">Dosage</span>
              </td>
              <td className={`whitespace-nowrap w-[10%]`}>
                <span className="text-gray-600 font-bold p-[6px]">
                  Frequency
                </span>
              </td>
              <td className={`whitespace-nowrap w-[12%]`}>
                <span className="text-gray-600 font-bold p-[6px]">Route</span>
              </td>
              <td className={`whitespace-nowrap w-[8%] `}>
                <span className="text-gray-600 font-bold w-[6%] p-[6px] flex gap-2 items-center">
                  Duration
                  {/* <fieldset
                    onChange={(e) => {
                      props.setApplyToAllMedicines(e.target.checked);
                      props.handleAllDurations(e.target.checked);
                    }}
                  >
                    <CheckBoxField
                      control={control}
                      name="durationApplyToAll"
                      label={"All"}
                    />
                  </fieldset> */}
                </span>
              </td>
              <td className={`whitespace-nowrap w-[5%]`}>
                <span className="text-gray-600 font-bold  p-[6px]">Qty</span>
              </td>
              <td className={`whitespace-nowrap w-[15%]`}>
                <span className="text-gray-600 font-bold  p-[6px]">
                  Instructions
                </span>
              </td>
              <td className={`whitespace-nowrap w-[15%]`}>
                <span className="text-gray-600 font-bold p-[6px]">Generic</span>
              </td>
              <td className={`whitespace-nowrap w-[15%]`}>
                <span className="text-gray-600 font-bold p-[6px]">Form</span>
              </td>
            </tr>
          </thead>
          <tbody>
            {tableSort(dataResult, getComparator(order, orderBy)).map(
              (row, index) => {
                if (
                  (index === 0 && dataResult?.[index].isDeleted === false) ||
                  (index !== 0 &&
                    dataResult[index - 1]?.Medicine.Medicine !== "" &&
                    dataResult[index]?.isDeleted === false)
                ) {
                  console.log(
                    "dataResultdataResultdataResult",
                    dataResult,
                    dataResult[index]
                  );

                  return (
                    <tr
                      key={index}
                      hover={false}
                      style={{
                        backgroundColor:
                          (highlightRow === undefined ||
                            highlightRow === true) &&
                          rowIndex === index
                            ? customRowBgColor || "#FFC44B"
                            : rowBackgroundColor
                            ? rowBackgroundColor(row, index)
                            : "",
                      }}
                      onClick={() => {
                        setRowIndex(index);
                        {
                          handleSelectedRow && handleSelectedRow(row, index);
                        }
                      }}
                    >
                      {renderActions && (
                        <td
                        // sx={{
                        //   width: "20px",
                        // }}
                        >
                          {renderActions(row, index)}
                        </td>
                      )}
                      {headers &&
                        headers.map((header, i) => (
                          <td
                            className={`whitespace-nowrap ${
                              i === 4 ? `ml-5` : ``
                            }`}
                            key={i}
                            sx={{
                              width: i === 0 ? "20%" : i === 1 ? "20%" : "",
                            }}
                            padding={SelectCheckbox === true ? "checkbox" : ""}
                          >
                            {editableColumns && editableColumns.includes(header)
                              ? renderInput(row, index, header)
                              : row[header] === true
                              ? "Yes"
                              : row[header] === false
                              ? "No"
                              : row[header]}
                          </td>
                        ))}
                    </tr>
                  );
                }
              }
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}



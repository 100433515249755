import apiClient from "../../../../http-common";
import authHeader from "../../../../authentication/authservices/auth-header";

export const getWorkOrderViewListing = (postObj) => {
  return apiClient.post(`/orderOrderView/patientList`, postObj, {
    headers: authHeader(),
  });
};

export const fetchCategoryOptions = () => {
  return apiClient.get(`/pathSampleCollection/sampleCategory`, {
    headers: authHeader(),
  });
};

export const fetchUnitOptions = () => {
  return apiClient.get(`/misc/unit`, {
    headers: authHeader(),
  });
};

export const fetchPatientOptions = (searchObj) => {
  return apiClient.post(`/orderOrderView/patientAutoComplete`, searchObj, {
    headers: authHeader(),
  });
};

export const fetchWorkOrderTests = (obj) => {
  return apiClient.post(`/orderOrderView/getPatientTests`, obj, {
    headers: authHeader(),
  });
};

export const fetchTests = (obj) => {
  return apiClient.post(
    `/pathSampleCollection/sampleCollectionPatientTest`,
    obj,
    {
      headers: authHeader(),
    }
  );
};

export const fethcPathReportDtlsTable = (orderDtlsId) => {
  return apiClient.get(`/orderOrderView/pathReportDtlsTable/${orderDtlsId}`, {
    headers: authHeader(),
  });
};

export const getPathologistList = () => {
  return apiClient.get(`/orderOrderView/pathologistList`, {
    headers: authHeader(),
  });
};

export const fetchDoctorList = (searchString) => {
  return apiClient.get(`/misc/doctor/${searchString}`, {
    headers: authHeader(),
  });
};

export const getPatientInfo = (orderDtlsId, opdIpd) => {
  return apiClient.get(`/orderOrderView/patientInfo/${orderDtlsId}/${opdIpd}`, {
    headers: authHeader(),
  });
};

export const fetchOrganismList = () => {
  return apiClient.get(`/orderOrderView/organismList`, {
    headers: authHeader(),
  });
};

export const fetchAntibioticsList = (organismId) => {
  return apiClient.get(`/orderOrderView/antibioticList/${organismId}`, {
    headers: authHeader(),
  });
};

export const savePathWorkOrderView = async (
  saveFinalObj,
  menuId,
  privilege
) => {
  return apiClient.post(
    `/orderOrderView/savePathOrderView/${menuId}/${privilege}`,
    saveFinalObj,
    {
      headers: authHeader(),
    }
  );
};

export const getHelpValues = (parameterId) => {
  return apiClient.get(`/orderOrderView/helpValues/${parameterId}`, {
    headers: authHeader(),
  });
};

export const cancelTest = (orderDtlsId, menuId, privilege) => {
  return apiClient.get(
    `/orderOrderView/cancelTest/${orderDtlsId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const cancelWorkOrder = (orderId, menuId, privilege) => {
  return apiClient.get(
    `/orderOrderView/cancelWorkOrder/${orderId}/${menuId}/${privilege}`,
    {
      headers: authHeader(),
    }
  );
};

export const getServiceDetailsList = (serviceId, page, size) => {
  return apiClient.get(
    `/orderOrderView/serviceDetailsList/${serviceId}/${page}/${size}`,
    {
      headers: authHeader(),
    }
  );
};

export const getServiceNameList = (searchString) => {
  return apiClient.get(`/orderOrderView/serviceNameList/${searchString}`, {
    headers: authHeader(),
  });
};

export const startEndProcedure = (status, orderDtlsId) => {
  return apiClient.get(
    `/orderOrderView/startEndProcedure/${status}/${orderDtlsId}`,
    {
      headers: authHeader(),
    }
  );
};

export const updateSiteAndInvestigation = (instruction, orderDtlsId) => {
  return apiClient.get(
    `/orderOrderView/updateSiteAndInvestigation/${instruction}/${orderDtlsId}`,
    {
      headers: authHeader(),
    }
  );
};

export const counterBookPrint = (orderId) => {
  return apiClient.get(`/reports/lapReports/counterBook/${orderId}`, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const labReportsCommonPrint = (printArr) => {
  return apiClient.post(`/reports/lapReports/labReportsCommon`, printArr, {
    responseType: "blob",
    headers: authHeader(),
  });
};

export const getTestTemplates = (orderDtlsId) => {
  return apiClient.get(`/orderOrderView/getTestTemplates/${orderDtlsId}`, {
    headers: authHeader(),
  });
};

export const getMachineParameterValues = (sampleNo, orderDtlsIds) => {
  return apiClient.get(
    `/orderOrderView/getMachineParameterValues/${sampleNo}/${orderDtlsIds}`,
    {
      headers: authHeader(),
    }
  );
};

export const getPatientTests = async (listObj) => {
  return await apiClient.post(
    `/pathSampleCollection/sampleCollectionPatientTest`,
    listObj,
    {
      headers: authHeader(),
    }
  );
};

export const generatePathologyReport = (orderDtlsIds) => {
  return apiClient.get(
    `/orderOrderView/generatePathologyReport/${orderDtlsIds}`,
    {
      headers: authHeader(),
    }
  );
};

export const sendReportOnWhatsapp = (postObj) => {
  return apiClient.post(`/orderOrderView/pathReportSendOnWhatsapp`, postObj, {
    headers: authHeader(),
  });
};

export const sendReportOnEmail = (postObj) => {
  return apiClient.post(`/orderOrderView/pathReportSendOnEmail`, postObj, {
    headers: authHeader(),
  });
};

export const getTestParameterReportDetails = (postObj) => {
  return apiClient.post(
    `/orderOrderView/getTestParameterReportDetails`,
    postObj,
    {
      headers: authHeader(),
    }
  );
};

export const clinicalCarePathWorkOrderTests = (obj) => {
  return apiClient.post(`/orderOrderView/getPatientTestsForClinical`, obj, {
    headers: authHeader(),
  });
};

export const cancelSampleCollectionTest = (orderDtlsId, privilege, menuId) => {
  return apiClient.get(
    `/pathSampleCollection/cancelSampleCollection/${orderDtlsId}/${privilege}/${menuId}`,
    {
      headers: authHeader(),
    }
  );
};

export const recollectSampleCollectionTest = (
  orderDtlsId,
  privilege,
  menuId
) => {
  return apiClient.get(
    `/pathSampleCollection/recollectSample/${orderDtlsId}/${privilege}/${menuId}`,
    {
      headers: authHeader(),
    }
  );
};

export const saveRetestReason = (obj) => {
  return apiClient.post(`/orderOrderView/retest`, obj, {
    headers: authHeader(),
  });
};




export const getPatientTestTATList = async (obj) => {
  return await apiClient.post(`/orderOrderView/getPatientTestTAT`, obj, {
    headers: authHeader(),
  });
};

export const getPathTests = async (searchString) => {
  return await apiClient.get(`/orderOrderView/getPathTests/${searchString}`, {
    headers: authHeader(),
  })
}

export const getPatientTestTATListSearch = async (obj) => {
  return await apiClient.post(`/orderOrderView/getPatientTestTATListSearch`, obj, {
    headers: authHeader(),
  })
}

export const getCategoryData = async () =>{
  return await apiClient.get('/pathSampleCollection/sampleCategory', {
    headers: authHeader(),
  })
}

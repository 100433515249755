import Box from "@mui/material/Box";
import React, { useEffect } from "react";
// import CancelPresentationIconButton from "../../../Common Components/Buttons/CancelPresentationIconButton";
import { Modal, TextField, Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import ReportDetailTabs from "./ReportDetailTabs";
// import DropdownField from "../../../Common Components/FormFields/DropdownField";
// import SaveButton from "../../../Common Components/Buttons/SaveButton";
import { useState } from "react";
import Refresh from "../../../../../src/assets/icons/Refresh.png";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  getMachineParameterValues,
  getTestParameterReportDetails,
  savePathWorkOrderView,
} from "../../Services/Pathalogy/WorkOrderViewServices";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrintOnIcon } from "../../../../assets/icons/CustomIcons";
import { DetailedReportIcon } from "../../../../assets/CommonAssets";
import InputArea from "../../../../common/components/FormFields/InputArea";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  width: "95%",
  // height: "98%",
  minHeight: "50%",
  maxHeight: "98%",
  p: 2,
  overflowY: "scroll",
};

const normalTemplateButton = [
  {
    id: "Normal",
    label: "Normal",
    value: "Normal",
  },
  {
    id: "Template",
    label: "Template",
    value: "Template",
  },
];

function ReportDetailsModal(props) {
  // const [requiredTableArr, setRequiredTableArr] = useState([]);
  // const [pathologistList, setPathologistList] = useState();
  // const [doctor, setDoctor] = useState();
  // const [doctorSearchString, setDoctorSearchString] = useState();
  const [finalData, setFinalData] = useState();
  const [openPost, setOpenPost] = useState(false);
  const [postArray, setPostArray] = useState([]);
  const [drugSensitivityArr, setDrugSensitivityArr] = useState([]);
  const [requiredArr, setRequiredArr] = useState([]);
  const [finalArr, setFinalArr] = useState([]);
  const [getOrganismId, setGetOrganismId] = useState();
  const [checkIndex, setCheckIndex] = useState();
  const [content1, setContent1] = useState("");
  const [templateDetails, setTemplateDetails] = useState([]);
  const [values, setValues] = useState(0);
  const [machineParameterValues, setMachineParameterValues] = useState([]);
  const [inputBorder, setInputBorder] = useState(null);
  const [antibioticsData, setAntibioticsData] = useState([]);

  const {
    checkedArr,
    setCheckedArr,
    postArr,
    checkedIsmicroArr,
    fetchAllTest,
    fetchWorkOrderViewList,
    fetchFirstWorkOrderViewList,
    userActions,
    privilege,
    setPrivilege,
    authorizationArr,
    setIsWhatsApp,
    // getPathologyPrint,
    setOpenBackdrop,
    openBackdrop,
    openReportDetailsModal,
    setOpenReportDetailsModal,
    isView,
    setIsView,
    getNonAuthorized,
    patientInfo,
    setPatientInfo,
    setSelectedTestId,
    handleOpenPrintTestModal,
    setLIMSType,
    // viewLIMSHistory,
    setOpenLIMSHistoryModal,
    selectedRow,
    setPathHistoryData,
    setTestsArray,
    testsArray,
  } = props;

  const handleClosePost = () => {
    setOpenPost(false);
  };

  console.log("privilege123", privilege);
  console.log("props.finalArr321", finalArr);
  console.log("antibioticsData321", antibioticsData);

  let getOrderDtlsId = props.selectedTestId && props.selectedTestId.OrderDtlsId;
  let getOrderId = props.selectedTestId && props.selectedTestId.OrderId;
  let getReportId = props.selectedTestId && props.selectedTestId.ReportId;
  let getHasTempalate =
    props.selectedTestId && props.selectedTestId.HasTemplate;
  let getIsCultureSenTest =
    props.selectedTestId && props.selectedTestId.IsCultureSenTest;
  const defaultValues = {
    pathologist1: null,
    pathologist2: null,
    // pathologist3: null,
    referredDoc: null,
    suggestionNote: "",
    footNote: "",
    interpretation: "",
    history: "",
    // remark: "",
    sampleNo: "",
    normalTemplate: "Normal",
  };

  console.log("props.selectedTestId123", props.selectedTestId);

  const schema = yup.object().shape({
    remark: yup.object().when(() => {
      if (authorizationArr?.some((val) => val.IsPathologyAuthority === true)) {
        return yup.string().required();
      }
    }),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    register,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  let getSampleNo = Number(watch("sampleNo"));
  let getNormalTemplate = watch("normalTemplate");

  const savePathOrderView = () => {
    setOpenBackdrop(true);
    savePathWorkOrderView(finalData, props.menuId, privilege)
      .then((response) => response.data)
      .then((res) => {
        console.log("resres123", res);
        if (res.statusCode === 200) {
          successAlert(res.message);
          // handleClosePost();
          // setOpenReportDetailsModal(false);
          fetchAllTest();
          // setTemplateDetails([]);
          // setMachineParameterValues([]);
          // setCheckedArr([]);
          // setFinalArr([]);
          // reset(defaultValues);
          if (getNonAuthorized === true) {
            // fetchWorkOrderViewList();
            fetchFirstWorkOrderViewList();
          }
          setOpenBackdrop(false);
        } else {
          errorAlert(res.message);
          setOpenBackdrop(false);
        }
      })
      .catch((error) => {
        console.log("errors123", error);
        errorAlert(error?.response?.data?.message || error?.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
    // setIsWhatsApp(true);
    setOpenPost(false);
  };
  const onSubmitData = (data) => {
    // let create = userActions?.some(
    //   (item) => item.isAction === false && item.action === "Create"
    // );

    // let auth = userActions?.some(
    //   (item) => item.isAction === false && item.action === "Authorise"
    // );

    // let allow;

    // if (
    //   create &&
    //   !auth &&
    //   authorizationArr?.some((val) => val.IsPathologyAuthority === true)
    // ) {
    //   allow = false;
    // } else {
    //   allow = true;
    // }

    // if (allow) {
    let postFinalArr = [];
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));
    let getFootnote = getValues("footNote");
    let getInterPretation = getValues("interpretation");
    let getTestnote = getValues("suggestionNote");
    let getPathologist1 = getValues("pathologist1");
    let getPathologist2 = getValues("pathologist2");
    let getReferredDoc = getValues("referredDoc");
    let orderId = props.orderIdToPost;

    console.log("postArray123", postArray);
    let remarkArr = [];
    for (let a = 0; a < authorizationArr?.length; a++) {
      if (authorizationArr[a].IsPathologyAuthority === true) {
        remarkArr.push(authorizationArr[a]);
      }
    }
    let requireArr = [];
    if (getHasTempalate === 0 && getIsCultureSenTest === 0) {
      for (let i = 0; i < postArray.length; i++) {
        // let postFinalObj = {};
        let orderDtlsId = postArray[i].orderDtlsId;
        let reportId = postArray[i].reportId;
        let parameterArr = postArray[i].pathParametersDtoList;
        for (let obj of parameterArr) {
          let requiredObj = {};
          //requiredObj.orderDtlsId = postArray[i].orderDtlsId;
          requiredObj.firstLevelValue = obj.firstLevelValue
            ? obj.firstLevelValue.toString()
            : "";
          requiredObj.retestResult = obj.retestResult
            ? obj.retestResult.toString()
            : "";
          requiredObj.normalRange = obj.normalRange;
          requiredObj.reportDetailId = obj.reportDetailId;
          requiredObj.secondLevelValue = obj.firstLevelValue
            ? obj.firstLevelValue
            : obj.retestResult
            ? obj.retestResult
            : "";
          requiredObj.parameterId = obj.parameterId;
          requiredObj.unit = props.getUnit.label;
          requireArr.push(requiredObj);
        }
        let postObj = {
          addedBy: getUserId.userId,
          footNote: getFootnote,
          interpretation: getInterPretation,
          hasTemplate: getHasTempalate,
          isCultureSenTest: getIsCultureSenTest,
          orderDtlsId: orderDtlsId,
          orderId: orderId,
          pathReportDetailsDtoList: requireArr,
          reportId: reportId,
          testNote: getTestnote,
          opdIpd: selectedRow?.["Opd/Ipd"] === "IPD" ? 1 : 0,
          opdIpdId: selectedRow?.Opd_Ipd_External_Id,
          isAuthorised: privilege === "Create" ? false : true,
          authorizedRemark: remarkArr
            .map((val) => (val?.OrderDtlsId === orderDtlsId ? data.remark : ""))
            .toString(),
          isReauthoriseTest: privilege === "Reauthorise" ? true : false,
        };
        postFinalArr.push(postObj);
        console.log("postFinalArr123", postFinalArr);
        setFinalData(postFinalArr);
        setOpenPost(true);
      }
    } else if (getHasTempalate === 0 && getIsCultureSenTest === 1) {
      var newArray = [];
      finalArr.forEach((item) => {
        var newItem = {
          organismId: item.organismId,
          pathTestSensitivityNormalDetailsDto: [],
          reportId: item.reportId,
          pathTestSensitivityNormalId: item.pathTestSensitivityNormalId,
        };
        finalArr.forEach((innerItem) => {
          if (innerItem.organismId === item.organismId) {
            newItem.pathTestSensitivityNormalDetailsDto =
              newItem.pathTestSensitivityNormalDetailsDto.concat(
                innerItem.pathTestSensitivityNormalDetailsDto
              );
          }
        });
        newArray.push(newItem);
      });
      const arr = newArray;
      const ids = newArray.map((o) => o.organismId);
      const filteredArr = arr.filter(
        ({ organismId }, index) => !ids.includes(organismId, index + 1)
      );
      var tempArray = [];
      filteredArr.map((item) => {
        let innerArray = item.pathTestSensitivityNormalDetailsDto;

        const innerArr = innerArray;
        const innerIds = innerArray.map((o) => o.antibioticId);
        const innerFiltered = innerArr.filter(
          ({ antibioticId }, index) =>
            !innerIds.includes(antibioticId, index + 1)
        );
        console.log("innerFiltered", innerFiltered);

        let requiredObj = {};
        requiredObj.organismId = item.organismId;
        requiredObj.pathTestSensitivityNormalDetailsDto = innerFiltered;
        requiredObj.reportId = item.reportId;
        requiredObj.pathTestSensitivityNormalId =
          item.pathTestSensitivityNormalId;

        tempArray.push(requiredObj);
        console.log("tempArray321", tempArray);
      });
      if (getNormalTemplate === "Normal") {
        for (let i = 0; i < postArray.length; i++) {
          // let postFinalObj = {};
          let orderDtlsId = postArray[i].orderDtlsId;
          let parameterArr = postArray[i].pathParametersDtoList;
          for (let obj of parameterArr) {
            let requiredObj = {};
            requiredObj.firstLevelValue = obj.firstLevelValue
              ? obj.firstLevelValue.toString()
              : "";
            requiredObj.retestResult = obj.retestResult
              ? obj.retestResult.toString()
              : "";
            requiredObj.normalRange = obj.normalRange;
            requiredObj.reportDetailId = obj.reportDetailId;
            requiredObj.secondLevelValue = obj.firstLevelValue
              ? obj.firstLevelValue
              : obj.retestResult
              ? obj.retestResult
              : "";
            requiredObj.parameterId = obj.parameterId;
            requiredObj.unit = props.getUnit.label;
            requireArr.push(requiredObj);
          }
          let postObj = {
            addedBy: getUserId.userId,
            firstLevelTemplateDesc: "",
            footNote: getFootnote,
            interpretation: getInterPretation,
            hasTemplate: getHasTempalate,
            isCultureSenTest: getIsCultureSenTest,
            isNormal: true,
            isTemplate: false,
            orderDtlsId: orderDtlsId,
            orderId: getOrderId,
            isAuthorised: privilege === "Create" ? false : true,
            pathReportDetailsDtoList: requireArr,
            pathTestSensitivityDto: {
              firstLevelTemplate: "",
              secondLevelTemplate: "",
              pathTestSensitivityNormalDto: tempArray,
            },
            reportId: getReportId,
            testNote: getTestnote,
            opdIpd: selectedRow?.["Opd/Ipd"] === "IPD" ? 1 : 0,
            opdIpdId: selectedRow?.Opd_Ipd_External_Id,
            authorizedRemark: remarkArr
              .map((val) =>
                val?.OrderDtlsId === orderDtlsId ? data.remark : ""
              )
              .toString(),
            isReauthoriseTest: privilege === "Reauthorise" ? true : false,
          };
          postFinalArr.push(postObj);
          console.log("postFinalArr123", postFinalArr);

          setFinalData(postFinalArr);
          setOpenPost(true);
        }
      } else if (getNormalTemplate === "Template") {
        let postObj = {
          addedBy: getUserId.userId,
          firstLevelTemplateDesc:
            content1 !== "<p><br></p>" && content1 !== ""
              ? content1
                  ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                  .replaceAll("\n", "")
              : "",
          footNote: getFootnote,
          interpretation: getInterPretation,
          hasTemplate: getHasTempalate,
          isNormal: false,
          isTemplate: true,
          isCultureSenTest: getIsCultureSenTest,
          orderDtlsId: getOrderDtlsId,
          orderId: getOrderId,
          isAuthorised: privilege === "Create" ? false : true,
          pathReportDetailsDtoList: requireArr,
          pathTestSensitivityDto: {
            firstLevelTemplate:
              content1 !== "<p><br></p>" && content1 !== ""
                ? content1
                    ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                    .replaceAll("\n", "")
                : "",
            secondLevelTemplate:
              content1 !== "<p><br></p>" && content1 !== ""
                ? content1
                    ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                    .replaceAll("\n", "")
                : "",
            pathTestSensitivityNormalDto: [],
          },
          reportId: getReportId,
          testNote: getTestnote,
          authorizedRemark: remarkArr
            .map((val) =>
              val?.OrderDtlsId === getOrderDtlsId ? data.remark : ""
            )
            .toString(),
          opdIpd: selectedRow?.["Opd/Ipd"] === "IPD" ? 1 : 0,
          opdIpdId: selectedRow?.Opd_Ipd_External_Id,
          isReauthoriseTest: privilege === "Reauthorise" ? true : false,
        };
        postFinalArr.push(postObj);
        console.log("postFinalArr123", postFinalArr);
        if (postObj?.firstLevelTemplateDesc !== "") {
          setFinalData(postFinalArr);
          setOpenPost(true);
        } else {
          warningAlert("Fill Template Data...!");
        }
      }
    } else if (getHasTempalate === 1 && getIsCultureSenTest === 0) {
      let postObj = {
        firstLevelTemplateDesc:
          content1 !== "<p><br></p>" && content1 !== ""
            ? content1
                ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                .replaceAll("\n", "")
            : "",
        footNote: getFootnote,
        interpretation: getInterPretation,
        testNote: getTestnote,
        orderDtlsId: getOrderDtlsId,
        hasTemplate: getHasTempalate,
        isCultureSenTest: getIsCultureSenTest,
        orderId: getOrderId,
        isAuthorised: privilege === "Create" ? false : true,
        reportId: getReportId,
        authorizedRemark: remarkArr
          .map((val) =>
            val?.OrderDtlsId === getOrderDtlsId ? data.remark : ""
          )
          .toString(),
        opdIpd: selectedRow?.["Opd/Ipd"] === "IPD" ? 1 : 0,
        opdIpdId: selectedRow?.Opd_Ipd_External_Id,
        isReauthoriseTest: privilege === "Reauthorise" ? true : false,
      };
      postFinalArr.push(postObj);

      setFinalData(postFinalArr);
      setOpenPost(true);
    } else if (getHasTempalate === 1 && getIsCultureSenTest === 1) {
      let postObj = {
        addedBy: getUserId.userId,
        pathTestSensitivityDto: {
          firstLevelTemplate:
            content1 !== "<p><br></p>" && content1 !== ""
              ? content1
                  ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                  .replaceAll("\n", "")
              : "",
          secondLevelTemplate:
            content1 !== "<p><br></p>" && content1 !== ""
              ? content1
                  ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
                  .replaceAll("\n", "")
              : "",
        },
        footNote: getFootnote,
        interpretation: getInterPretation,
        testNote: getTestnote,
        orderDtlsId: getOrderDtlsId,
        hasTemplate: getHasTempalate,
        isCultureSenTest: getIsCultureSenTest,
        orderId: getOrderId,
        isAuthorised: privilege === "Create" ? false : true,
        reportId: getReportId,
        authorizedRemark: remarkArr
          .map((val) =>
            val?.OrderDtlsId === getOrderDtlsId ? data.remark : ""
          )
          .toString(),
        opdIpd: selectedRow?.["Opd/Ipd"] === "IPD" ? 1 : 0,
        opdIpdId: selectedRow?.Opd_Ipd_External_Id,
        isReauthoriseTest: privilege === "Reauthorise" ? true : false,
      };
      postFinalArr.push(postObj);
      setFinalData(postFinalArr);
      setOpenPost(true);
    }
    // } else {
    //   warningAlert("Report Is Already Authorised.");
    // }
  };

  useEffect(() => {
    setValue(
      "sampleNo",
      patientInfo?.SampleNo || props.selectedTestId?.SampleNo
    );
    setValues(0);
  }, [getOrderDtlsId, patientInfo, getNormalTemplate]);

  useEffect(() => {
    for (let i = 0; i < templateDetails.length; i++) {
      setValue("suggestionNote", templateDetails[i]?.testNote);
      setValue("footNote", templateDetails[i]?.footNote);
      setValue("interpretation", templateDetails[i]?.interpretation);
      if (
        templateDetails[0]?.templateDescription !== null &&
        templateDetails[0]?.templateDescription !== ""
      ) {
        setContent1(
          templateDetails[i]?.templateDescription
            ?.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
            .replaceAll("\n", "")
        );
        setValue("normalTemplate", "Template");
      } else if (templateDetails[0]?.templateDescription === null) {
        setValue("normalTemplate", "Normal");
      }
    }
  }, [templateDetails]);

  console.log("templateDetails321", templateDetails);

  function syncParameterValues() {
    if (getSampleNo > Number(0)) {
      getMachineParameterValues(getSampleNo, checkedArr)
        .then((response) => response.data)
        .then((res) => {
          // if (res.statusCode === 200) {
          setAntibioticsData([]);
          setMachineParameterValues(res.result);
          // }
        })
        .catch((error) => {
          warningAlert(error?.response?.data?.message || error?.message);
        });
      setInputBorder("border-2 border-black rounded px-1 w-32");
    } else if (getSampleNo < Number(1)) {
      setInputBorder("border-2 border-red-600 rounded px-1 w-32");
    }
  }

  function viewLIMSHistory() {
    setOpenBackdrop(true);
    getTestParameterReportDetails({
      opdIpdExternal: selectedRow?.["Opd/Ipd"] === "IPD" ? 1 : 0,
      opdIpdExternalId: selectedRow?.Opd_Ipd_External_Id,
      patientId: selectedRow?.PatientId,
    }).then((response) => {
      setOpenBackdrop(false);
      const reportPastHistory = response.data.result.filter((val) =>
        authorizationArr.some((obj) => Number(val?.testId) === obj?.PathTestId)
      );

      console.log("reportPastHistory123", reportPastHistory);
      setPathHistoryData(reportPastHistory);
    });
  }

  useEffect(() => {
    if (authorizationArr) {
      setValue("history", authorizationArr[0]?.ColorTubeSampleCollectionRemark);
    }
  }, [authorizationArr]);

  console.log("checkedArrcheckedArr123", checkedArr);
  console.log("patientInfo123456", patientInfo);

  return (
    <>
      <Modal
        open={openReportDetailsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              setOpenReportDetailsModal(false);
              // setRequiredTableArr([]);
              reset(defaultValues);
              setTemplateDetails([]);
              setMachineParameterValues([]);
              setAntibioticsData([]);
              setValue("sampleNo", "");
              setInputBorder(null);
              setIsView(false);
              setSelectedTestId(null);
              setPathHistoryData([]);
              let tempTestArray = [...testsArray];
              tempTestArray.forEach((val) => (val.checked = false));
              setTestsArray(tempTestArray);
              setContent1("");
              // setPatientInfo(null);
            }}
          />
          <form onSubmit={handleSubmit(onSubmitData)}>
            <fieldset className="space-y-1" disabled={isView === true}>
              <div className="text-xl font-semibold">
                <label>Report Details</label>
              </div>
              <div className="gap-2 w-full space-y-1">
                <div className="text-sm grid lg:grid-cols-2 xl:flex xl:justify-between bg-[#F1F1F1] border border-gray-400 rounded px-2 py-1">
                  <div className="flex">
                    <span className="font-bold w-48 xl:w-24">
                      Patient Name&nbsp;
                    </span>
                    <span>
                      :{" "}
                      {patientInfo?.PatientName ||
                        patientInfo?.["Patient Name"]}
                    </span>
                  </div>
                  {patientInfo?.IpdNo ? (
                    <div className="flex items-center">
                      <span className="whitespace-nowrap font-bold w-48 lg:w-28 xl:w-12">
                        IPD No&nbsp;
                      </span>
                      <span>: {patientInfo?.IpdNo}</span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <span className="whitespace-nowrap font-bold w-48 lg:w-28 xl:w-12">
                        OPD No&nbsp;
                      </span>
                      <span>: {patientInfo?.OPDNO}</span>
                    </div>
                  )}
                  <div className="flex items-center">
                    <span className="font-bold w-48 xl:w-12">MR No&nbsp;</span>
                    <span>: {patientInfo?.MRNo}</span>
                  </div>
                  <fieldset
                    className="flex items-center"
                    disabled={isView === true}
                  >
                    <span className="font-bold w-48 lg:w-28 xl:w-20">
                      Sample No&nbsp;
                    </span>
                    <span className="flex gap-2 items-center">
                      :
                      <input
                        // className={`border-2 rounded px-1 w-32`}
                        className={
                          inputBorder
                            ? inputBorder
                            : `border-2 rounded px-1 w-32`
                        }
                        type="text"
                        name="sampleNo"
                        {...register("sampleNo")}
                      />
                      <button disabled={isView === true} type="button">
                        <img
                          type="button"
                          src={Refresh}
                          className="w-6 h-6 cursor-pointer"
                          onClick={() => {
                            syncParameterValues();
                          }}
                        />
                      </button>
                    </span>
                  </fieldset>
                  <div className="flex items-center">
                    <span className="whitespace-nowrap font-bold w-40">
                      Sample Collection Time&nbsp;
                    </span>
                    <span className="whitespace-nowrap">
                      : {patientInfo && patientInfo.SampleCollectionTime}
                    </span>
                  </div>
                </div>
                <div className="w-full gap-2 grid grid-cols-2 xl:flex"></div>
              </div>
              <div className="flex w-full justify-between">
                <fieldset
                  className="ml-5"
                  // disabled={
                  //   // props.selectedTestId?.FirstLevel ||
                  //   // props.selectedTestId?.SecondLevel ||
                  //   // props.selectedTestId?.ThirdLevel
                  //   props.selectedTestId?.IsPathologyAuthority === true
                  // }
                >
                  {getHasTempalate === 0 && getIsCultureSenTest === 1 ? (
                    <RadioField
                      control={control}
                      name="normalTemplate"
                      dataArray={normalTemplateButton}
                    />
                  ) : (
                    ""
                  )}
                </fieldset>
                <div className="flex justify-end gap-2 items-center">
                  <Tooltip title="Reports History">
                    <button
                      type="button"
                      onClick={() => {
                        if (selectedRow !== null) {
                          setLIMSType("Pathology");
                          viewLIMSHistory();
                          setOpenLIMSHistoryModal(true);
                        } else {
                          warningAlert("Select Order...!");
                        }
                      }}
                    >
                      <DetailedReportIcon />
                    </button>
                  </Tooltip>
                  {checkedArr?.length === 1 && (
                    <Tooltip title="Print">
                      <button
                        type="button"
                        onClick={() => {
                          handleOpenPrintTestModal(props.selectedTestId);
                        }}
                      >
                        <PrintOnIcon />
                      </button>
                    </Tooltip>
                  )}
                  <div className="flex gap-2 items-center border-[1px] border-black rounded px-2 -py-1">
                    <label className="font-semibold text-sm">Formula</label>
                    <div className="h-3 w-3 bg-red-400"></div>
                  </div>
                  <div className="flex gap-2 items-center border-[1px] border-black rounded px-2 -py-1">
                    <label className="font-semibold text-sm">Help-Values</label>
                    <div className="h-3 w-3 bg-green-400"></div>
                  </div>
                  <div className="flex gap-2 items-center border-[1px] border-black rounded px-2 -py-1">
                    <label className="font-semibold text-sm">
                      Normal Range
                    </label>
                    <div className="h-3 w-3 bg-blue-400"></div>
                  </div>
                </div>
              </div>
              <div className="rounded border border-gray-400 my-1 w-full">
                <ReportDetailTabs
                  selectedTestId={props.selectedTestId}
                  setPostArray={setPostArray}
                  // setRequiredTableArr={setRequiredTableArr}
                  // requiredTableArr={requiredTableArr}
                  requiredArr={requiredArr}
                  setFinalArr={setFinalArr}
                  finalArr={finalArr}
                  setRequiredArr={setRequiredArr}
                  drugSensitivityArr={drugSensitivityArr}
                  setDrugSensitivityArr={setDrugSensitivityArr}
                  setGetOrganismId={setGetOrganismId}
                  getOrganismId={getOrganismId}
                  setCheckIndex={setCheckIndex}
                  checkIndex={checkIndex}
                  getNormalTemplate={getNormalTemplate}
                  getUnit={props.getUnit}
                  checkedArr={checkedArr}
                  setCheckedArr={setCheckedArr}
                  checkedIsmicroArr={checkedIsmicroArr}
                  content1={content1}
                  setContent1={setContent1}
                  setTemplateDetails={setTemplateDetails}
                  values={values}
                  setValues={setValues}
                  machineParameterValues={machineParameterValues}
                  antibioticsData={antibioticsData}
                  setAntibioticsData={setAntibioticsData}
                  isView={isView}
                  authorizationArr={authorizationArr}
                />
              </div>
              <div className="grid grid-cols-3 gap-3 py-2">
                <div>
                  <InputArea
                    control={control}
                    name="suggestionNote"
                    label="Suggestion Note"
                    maxRows={5}
                    minRows={2}
                    size="md"
                  />
                </div>
                <div>
                  <InputArea
                    control={control}
                    name="footNote"
                    label="Foot Note"
                    maxRows={5}
                    minRows={2}
                    size="md"
                  />
                </div>
                <div>
                  <InputArea
                    control={control}
                    name="interpretation"
                    label="InterPretation"
                    maxRows={5}
                    minRows={2}
                    size="md"
                  />
                </div>
                <fieldset disabled>
                  <InputArea
                    control={control}
                    name="history"
                    label="History"
                    maxRows={5}
                    minRows={2}
                    size="md"
                  />
                </fieldset>
                <div className="flex space-x-2 justify-between col-span-2">
                  {authorizationArr?.some(
                    (val) => val.IsPathologyAuthority === true
                  ) ? (
                    <div className="w-full">
                      <InputArea
                        control={control}
                        name="remark"
                        label="Remark"
                        maxRows={5}
                        minRows={2}
                        size="md"
                        error={errors.remark}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {isView === false && (
                    <div className="flex justify-end space-x-2">
                      <div>
                        <CommonButton
                          type="button"
                          label="Close"
                          className="border border-customRed text-customRed"
                          onClick={() => {
                            setOpenReportDetailsModal(false);
                            // setRequiredTableArr([]);
                            reset(defaultValues);
                            // setPatientInfo(null);
                            setSelectedTestId(null);
                            setContent1("");
                          }}
                        />
                      </div>
                      {userActions?.map((action, i) => (
                        <>
                          {authorizationArr?.every(
                            (val) =>
                              val.IsPathologyAuthority === true &&
                              val?.IsPathologyReAuthorised === false
                          ) ? (
                            <>
                              {action.isAction === false &&
                                action.action === "Reauthorise" && (
                                  <CommonButton
                                    type="submit"
                                    label="Reauthorization"
                                    className="border border-customOrange text-customOrange"
                                    onClick={() => {
                                      setPrivilege(action.action);
                                      // setIsWhatsApp(true);
                                    }}
                                    // disabled={
                                    //   (props.selectedTestId?.Description ===
                                    //     "MICROBIOLOGY" &&
                                    //     props.selectedTestId
                                    //       ?.IsProcedureStart === true &&
                                    //     props.selectedTestId?.IsProcedureEnd ===
                                    //       false) ||
                                    //   (props.selectedTestId?.Description !==
                                    //     "MICROBIOLOGY" &&
                                    //     authorizationArr?.some(
                                    //       (val) =>
                                    //         val.IsPathologyAuthority === true
                                    //     ))
                                    // }
                                  />
                                )}
                            </>
                          ) : (
                            <>
                              {action.isAction === false &&
                                action.action === "Authorise" && (
                                  <CommonButton
                                    type="submit"
                                    label="Authorization"
                                    className="border border-customOrange text-customOrange"
                                    onClick={() => {
                                      setPrivilege(action.action);
                                      setIsWhatsApp(true);
                                    }}
                                    // disabled={
                                    //   (props.selectedTestId?.Description ===
                                    //     "MICROBIOLOGY" &&
                                    //     props.selectedTestId
                                    //       ?.IsProcedureStart === true &&
                                    //     props.selectedTestId?.IsProcedureEnd ===
                                    //       false) ||
                                    //   (props.selectedTestId?.Description !==
                                    //     "MICROBIOLOGY" &&
                                    //     authorizationArr?.some(
                                    //       (val) =>
                                    //         val.IsPathologyAuthority === true
                                    //     ))
                                    // }
                                  />
                                )}
                            </>
                          )}
                        </>
                      ))}
                      <div>
                        {userActions?.map((action, i) => (
                          <>
                            {action.isAction === false &&
                            action.action === "Create" ? (
                              <CommonButton
                                type="submit"
                                label="Save"
                                className="bg-customGreen text-white"
                                disabled={openBackdrop === true}
                                onClick={() => {
                                  setPrivilege(action.action);
                                }}
                              />
                            ) : null}
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </fieldset>
          </form>
        </Box>
      </Modal>
      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={savePathOrderView}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this Record ?"
        confirmationButtonMsg="Add"
      />
    </>
  );
}

export default ReportDetailsModal;

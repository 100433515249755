import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import { ModalStyleFeedback } from "../../../common/components/ModalStyle";
import InputField from "../../../common/components/FormFields/InputField";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { fetchApplicableStores } from "../../../commonServices/miscellaneousServices/MiscServices";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import {
  fetchAlternateDrugNameTable,
  fetchDrugNameTable,
  getItemsForDrugIndent,
  postPatientIndent,
} from "../../../inventory/services/generalStore/PatientIndentServices/PatientIndentServices";

import CommonButton from "../../../common/components/Buttons/CommonButton";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import RadioField from "../../../common/components/FormFields/RadioField";
import InputEnter from "../../../common/components/FormFields/InputEnter";
import CommonDivider from "../../../common/components/divider/CommonDivider";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DrugTable from "../../../inventory/components/common/storeIndent/DrugTable";
import AlternateDrugTable from "../../../inventory/components/common/storeIndent/AlternateDrugTable";
import { DeleteIcon } from "../../../assets/CommonAssets";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

const IsUrgentRadioArr = [
  {
    id: "Yes",
    value: "Yes",
    label: "Yes",
  },
  {
    id: "No",
    value: "No",
    label: "No",
  },
];

function CreateNewIndentDrugAdmChartModal({
  data,
  privilege,
  handleClose,
  open,
  patientDetails,
  menuId,
  setOpenLoader,
}) {
  const schema = yup.object().shape({
    indentToStore: yup
      .object()
      .required("Required")
      .nullable()
      .when("indentTo", (indentTo) => {
        if (indentTo === "Store") {
          return yup.object().nullable().required("Required").shape({
            value: yup.string(),
            label: yup.string(),
          });
        } else if (indentTo === "Unit") {
          return yup.object().nullable().notRequired().shape({
            value: yup.string(),
            label: yup.string(),
          });
        }
      }),
  });
  const defaultValues = {
    indentToStore: false,
    isUrgentRadio: "No",
    quantity: "",
    searchdrugName: "",
  };
  const {
    control,
    register,
    handleSubmit,
    reset,
    trigger,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let toStoreValue = watch("indentToStore");
  let searchdrugName = watch("searchdrugName");

  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const removeFocusSearch = useRef(null);
  let quantityValue = watch("quantity");
  //
  const [toStoreOptions, setToStoreOptions] = useState([]);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [drugSearchString, setDrugSearchString] = useState("");
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [searchDrugTableData, setSearchDrugTableData] = useState([]);
  const [altDrugTableData, setAltDrugTableData] = useState([]);
  const [selectedAltDrugRowID, setSelectedAltDrugRowID] = useState(null);
  const [lastIndex, setLastIndex] = useState(null);
  const [selectedDrugRowID, setSelectedDrugRowID] = useState(null);
  const [selectedDrugRowData, setSelectedDrugRowData] = useState(null);
  const [selectedDrugData, setSelectedDrugData] = useState([]);
  const [selectedGenericId, setSelectedGenericId] = useState(null);
  const [selectedItemDtlsID, setSelectedItemDtlsID] = useState(null);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [finalDataObj, setFinalObjData] = useState(null);
  const [openPostConfirmationModal, setOpenConfirmationModal] = useState(false);

  const onEnterFocusNext = (e) => {
    if (e && e.target) {
      const name = e.target.name;
      const [fieldName, fieldIndex] = name.split("-");
      if (parseInt(fieldIndex, 10) < 6) {
        const nextSibling = document.querySelector(
          `input[name=input-${parseInt(fieldIndex, 10) + 1}]`
        );
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  const removeFocusInputSearch = () => {
    if (removeFocusSearch.current) {
      removeFocusSearch.current.blur();
    }
  };

  const mergeArray = (arr) => {
    const map = new Map();
    arr.forEach((val) => {
      const existing = map.get(val.Id);
      if (existing) {
        existing["Indent Qty"] += val["Indent Qty"];
      } else {
        map.set(val.Id, { ...val });
      }
    });
    return Array.from(map.values());
  };

  const addToItemDetailsTbl = () => {
    if (quantityValue !== "") {
      selectedDrug["Quantity"] = parseInt(quantityValue);
      selectedDrug.Amount = quantityValue * selectedDrug.MRP;
      selectedDrug["searchMedicine"] = "searchMedicine";
      let arr = [...filteredDataList];
      const existingItem = arr.find((item) => item.Id === selectedDrug.Id);

      if (existingItem) {
        warningAlert("Drug already exists!");
        inputRefOne.current.focus();
        setValue("searchdrugName", "");
        setValue("quantity", "");
        setSelectedDrug(null);
        setDrugSearchString("");
        return;
      }

      const keys = Object.keys(selectedDrug);
      const reorderedDrug = {};
      if (selectedDrug?.["Item Name"] || selectedDrug?.Drug) {
        reorderedDrug["Item Name"] =
          selectedDrug["Item Name"] || selectedDrug.Drug;
      }

      const rowQuantityValue = selectedDrug.Quantity;
      const keysWithoutQuantity = keys.filter(
        (key) => key !== "Quantity" && key !== "Item Name"
      );
      keysWithoutQuantity.slice(0, 4).forEach((key) => {
        reorderedDrug[key] = selectedDrug[key];
      });

      reorderedDrug.Quantity = rowQuantityValue;
      keysWithoutQuantity.slice(4).forEach((key) => {
        reorderedDrug[key] = selectedDrug[key];
      });

      arr.push(reorderedDrug);
      setFilteredDataList(mergeArray(arr));
      setValue("searchdrugName", "");
      setValue("quantity", "");
      setSelectedDrug(null);
    }

    const e = { target: { name: "searchdrugName" } };
    onEnterFocusNext(e);
  };

  const onSubmitDataHandler = (data) => {
    const TblData = filteredDataList.map((item) => ({
      isClosed: false,
      itemId: item.Id,
      indentDetailsId: item.indentDetailsId || 0,
      pendIssueQty: item["Quantity"],
      pendQty: item["Quantity"],
      rate: item.MRP,
      reqdQty: item["Quantity"],
      totalCost: Number(item.MRP) * Number(item["Quantity"]),
    }));

    let filteredData = filteredDataList.filter((list) => list.Quantity === "");

    if (filteredData?.length > 0) {
      errorAlert("Please Enter Quantity");
    } else {
      const finalObj = {
        menuId: menuId,
        privilege,
        addedBy: token.userId,
        admissionId: patientDetails.AdmissionId,
        drId: 0,
        indentId: 0,
        isUrgent: data.isUrgentRadio === "No" ? false : true,
        cashCounterId: token.cashCounterId,
        quantity: getValues("totalQuantity"),
        remark: "",
        totalValue: 0,
        totalVatAmount: 0,
        updatedBy: token.userId,
        fromStoreId: token.storeId,
        toStoreId: data.indentToStore?.id || 0,
        totalAmountPayable: getValues("netPayableAmount"),
        patientIndentDtlsDto: TblData,
      };
      setFinalObjData(finalObj);
      setOpenConfirmationModal(true);
    }
  };

  function saveIndent() {
    setOpenLoader(true);
    postPatientIndent(finalDataObj)
      .then((response) => {
        if (response.data.statusCode === 302) {
          warningAlert(response.data.message);
        } else if (response.data.statusCode === 200) {
          successAlert(response.data.message);
          reset(defaultValues);
          setOpenLoader(false);
          setFilteredDataList([]);
          setOpenConfirmationModal(false);
          handleClose();
        }
      })
      .catch((error) => {
        errorAlert(error.message);
        setOpenLoader(false);
      });
  }

  const getAlternateDrugNameTable = () => {
    if (selectedDrugRowData !== null && selectedDrugRowData !== undefined) {
      let altDrugObj = {
        deptStore: 1,
        deptStoreId: token.storeId,
        genericId: selectedDrugRowData.ItemGenericId,
        isConsignment: selectedDrugRowData.IsConsignment ? 1 : 0,
        itemCode: "%",
        itemName: "%",
      };
      fetchAlternateDrugNameTable(altDrugObj)
        .then((response) => response.data)
        .then((res) => {
          setAltDrugTableData(res.result);
        });
    }
  };

  function deleteIndent() {
    if (deleteIndex !== null) {
      let tempArr = [...filteredDataList];
      tempArr.splice(deleteIndex, 1);
      setFilteredDataList(tempArr);
      setOpenDeleteConfirmationModal(false)
    }
  }

  console.log("filterdData",filteredDataList);
  

  function renderAction(row, index, header) {
    return (
      <>
        <button
          type="button"
          onClick={() => {
            setOpenDeleteConfirmationModal(true);
            setDeleteIndex(index);
          }}
        >
          <DeleteIcon />
        </button>
      </>
    );
  }

  function renderInput(row, index, header) {
    return (
      <>
        <div>
          <input
            type="number"
            name={`quantity${index}`}
            {...register(`quantity${index}`)}
            className={
              row.Quantity !== ""
                ? `border rounded w-20 pl-2 border-customBlue`
                : " border rounded w-20 pl-2 border-customRed"
            }
            value={row.Quantity || ""}
            onChange={(e) => {
              const newQuantity = e.target.value;
              const updatedDataList = filteredDataList.map((item, i) => {
                if (i === index) {
                  return {
                    ...item,
                    Quantity: Number(newQuantity),
                  };
                }
                return item;
              });
              setFilteredDataList(updatedDataList);
            }}
          />
        </div>
      </>
    );
  }

  useEffect(() => {
    getAlternateDrugNameTable();
    if (selectedDrug !== null && patientDetails !== null) {
      setValue(
        "searchdrugName",
        selectedDrug && selectedDrug["Item Name"]
          ? selectedDrug["Item Name"]
          : selectedDrug.Drug
      );
      inputRefTwo.current.focus();
    }
  }, [selectedDrug, selectedDrugRowData]);
  
  useEffect(() => {
    const drugListObj = {
      deptStore:1,
      deptStoreId: token.storeId,
      isConsignment: 0,
      itemCode: "%",
      itemName: "%",
      searchString: drugSearchString,
      deptStore1: toStoreValue?.id,
      itemClass: 0,
      storePatient: 0,
    };
    if (drugSearchString !== "") {
      fetchDrugNameTable(drugListObj)
      .then((response) => response.data)
      .then((res) => {
          setSearchDrugTableData(res.result);
        });
    }
  }, [drugSearchString, toStoreValue]);

  useEffect(() => {
    setValue("fromStore", token.storeName);
    fetchApplicableStores(token.storeId)
      .then((response) => response.data)
      .then((res) => {
        setToStoreOptions(res.result);
      });
    }, [token.storeId, token.storeName]);
    
    useEffect(() => {
      const { result } = data || {};
      if (!result?.length) return;
      
      const filteredData = result.filter((list) => !list.IsStopDrugAdmin);
      const tempArr = filteredData.map((obj) => obj.ItemId);
      
      if (tempArr.length && toStoreValue) {
      console.log("token123",token);
      getItemsForDrugIndent({ itemIds: tempArr, toStoreId: toStoreValue.id })
        .then((response) => {
          const tempArr = response.data.result.map((item) => {
            const { Quantity = "", ...rest } = item;

            return {
              ...rest,
              Quantity,
            };
          });
          const reorderedArr = tempArr.map((item) => {
            return {
              Id: item.Id,
              Code: item.Code,
              "Item Name": item["Item Name"],
              "Balance Qty": item["Balance Qty"],
              Quantity: item.Quantity,
              ...item,
            };
          });
          let filteredNewData = filteredDataList.filter(
            (list) => list.searchMedicine === "searchMedicine"
          );
          setFilteredDataList([...reorderedArr, ...filteredNewData]);
        })
        .catch((error) => {
          console.error("Error fetching drug indent items:", error.message);
        });
    }
  }, [data, toStoreValue]);

  useEffect(() => {
    if (searchdrugName === "") {
      setSearchDrugTableData([]);
      setAltDrugTableData([]);
    }
  }, [searchdrugName]);

  console.log("drugadmchartdata", filteredDataList);
  return (
    <div className="w-full grid justify-center items-center rounded lg:px-0">
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={ModalStyleFeedback}
          className="h-[80%] w-[80%] 2xl:w-[70%] 2xl:h-[70%] p-4 "
        >
          <div className="flex justify-between items-center">
            <h1 className="text-lg 2xl:text-xl font-semibold whitespace-nowrap">
              Create Indent
            </h1>
            <CancelPresentationIconButton
              onClick={() => {
                handleClose();
                reset(defaultValues);
              }}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmitDataHandler)}>
            <div className="flex space-x-2 items-center pt-2">
              <div className="w-3/12">
                <InputField
                  control={control}
                  name="fromStore"
                  disabled={token.storeId ? true : false}
                  label="Indent From Store"
                />
              </div>
              <div className="w-4/12">
                <DropdownField
                  control={control}
                  name="indentToStore"
                  error={errors.indentToStore}
                  placeholder="To Store"
                  isSearchable={true}
                  isClearable={true}
                  dataArray={toStoreOptions}
                  inputRef={{
                    ...register(`indentToStore`, {
                      onChange: (e) => {
                        if (e.target.value === null) {
                          setValue("indentToStore", null);
                        }
                      },
                    }),
                  }}
                />
              </div>
              <div className="col-span-2 flex md:justify-start gap-2 items-center text-sm whitespace-nowrap">
                <p className="text-gray-700 font-semibold">
                  Is Urgent &nbsp; : &nbsp;
                </p>
                <div className="mt-1">
                  <RadioField
                    label=""
                    name="isUrgentRadio"
                    control={control}
                    dataArray={IsUrgentRadioArr}
                  />
                </div>
              </div>
            </div>
            <CommonDivider />
            <div className="flex space-x-3 items-center py-1 w-full">
              <fieldset
                className="w-3/12"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setValue("searchdrugName", "");
                    setDrugSearchString("");
                    setSelectedDrug(null);
                  } else {
                    setDrugSearchString(e.target.value);
                  }
                }}
              >
                <InputEnter
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      onEnterFocusNext();
                    }
                  }}
                  control={control}
                  name="searchdrugName"
                  label="Search by Item "
                  placeholder="Search by Item"
                  disabled={toStoreValue === null ? true : false}
                  {...register("searchdrugName")}
                  inputRef={inputRefOne}
                />
              </fieldset>
              <div className="lg:w-24">
                <Controller
                  control={control}
                  name="quantity"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          addToItemDetailsTbl();
                        }
                        if (e.key === "-" || e.key === "+") {
                          e.target.blur();
                        }
                      }}
                      inputRef={inputRefTwo}
                      size="small"
                      type="number"
                      InputLabelProps={{
                        style: { fontSize: "14px" },
                      }}
                      InputProps={{
                        style: {
                          fontSize: "14px",
                          height: "36.3px",
                          background: "white",
                        },
                      }}
                      name="quantity"
                      variant="outlined"
                      label="Qty"
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setValue("quantity", newValue);
                        if (newValue === "0") {
                          setValue("quantity", "1");
                        }
                      }}
                      disabled={toStoreValue === null ? true : false}
                    />
                  )}
                />
              </div>

              <div>
                <CommonButton
                  type="button"
                  label="Add"
                  className="border border-customBlue text-customBlue"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      addToItemDetailsTbl();
                    }
                  }}
                  onClick={addToItemDetailsTbl}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 items-center">
              <div>
                {searchDrugTableData?.length > 0 && searchdrugName !== "" ? (
                  <>
                    <h4 className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                      Drug Name
                    </h4>
                    <DrugTable
                      data={searchDrugTableData}
                      selectedItemDtlsID={selectedItemDtlsID}
                      setSelectedDrug={setSelectedDrug}
                      setSelectedItemDtlsID={setSelectedItemDtlsID}
                      setDrugTableData={setSearchDrugTableData}
                      setAltDrugTableData={setAltDrugTableData}
                      selectedGenericId={selectedGenericId}
                      setSelectedGenericId={setSelectedGenericId}
                      setSelectedDrugRowData={setSelectedDrugRowData}
                      setSelectedDrugData={setSelectedDrugData}
                      selectedDrugData={selectedDrugData}
                      selectedDrugRowID={selectedDrugRowID}
                      setSelectedDrugRowID={setSelectedDrugRowID}
                      lastIndex={lastIndex}
                      setLastIndex={setLastIndex}
                      setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                      selectedAltDrugRowID={selectedAltDrugRowID}
                      setDrugSearchString={setDrugSearchString}
                      removeFocusInputSearch={removeFocusInputSearch}
                    />
                  </>
                ) : null}
              </div>
              {altDrugTableData.length > 0 && searchdrugName === "" ? (
                <div>
                  <h4 className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                    Alternate Drug Name
                  </h4>

                  <div>
                    <AlternateDrugTable
                      data={altDrugTableData}
                      setSelectedDrug={setSelectedDrug}
                      selectedItemDtlsID={selectedItemDtlsID}
                      setSelectedItemDtlsID={setSelectedItemDtlsID}
                      setAltDrugTableData={setAltDrugTableData}
                      setDrugTableData={setSearchDrugTableData}
                      setSelectedDrugData={setSelectedDrugData}
                      selectedDrugData={selectedDrugData}
                      setSelectedDrugRowData={setSelectedDrugRowData}
                      selectedAltDrugRowID={selectedAltDrugRowID}
                      setSelectedAltDrugRowID={setSelectedAltDrugRowID}
                      setSelectedDrugRowID={setSelectedDrugRowID}
                      selectedDrugRowID={selectedDrugRowID}
                      setDrugSearchString={setDrugSearchString}
                      lastIndex={lastIndex}
                      removeFocusInputSearch={removeFocusInputSearch}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {filteredDataList?.length > 0 ? (
              <CommonDynamicTableNew
                dataResult={filteredDataList}
                renderActions={renderAction}
                removeHeaders={[
                  "Id",
                  "BatchesRequired",
                  "ItemGenericId",
                  "VATInClusive",
                  "Consignment",
                ]}
                tableClass={" h-[400px] 2xl:h-[500px] "}
                renderInput={renderInput}
                editableColumns={"Quantity"}
              />
            ) : (
              <p className="text-center py-28 2xl:py-56 text-gray-600">
                No Records Added...
              </p>
            )}

            <div className="flex justify-end space-x-4 pt-1">
              <CommonButton
                type="button"
                label="Reset"
                className="border border-customRed text-customRed"
                onClick={() => {
                  reset(defaultValues);
                  setFilteredDataList([]);
                }}
              />
              <CommonButton
                type="submit"
                label="Save"
                className="bg-customGreen text-white"
              />
            </div>
          </form>
        </Box>
      </Modal>
      <ConfirmationModal
        confirmationOpen={openDeleteConfirmationModal}
        confirmationHandleClose={() => setOpenDeleteConfirmationModal(false)}
        confirmationSubmitFunc={() => deleteIndent()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure Want Delete Indent ?"
        confirmationButtonMsg="Confirm"
      />
      <ConfirmationModal
        confirmationOpen={openPostConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => saveIndent()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure Want Add This Record ?"
        confirmationButtonMsg="Confirm"
      />
    </div>
  );
}
export default React.memo(CreateNewIndentDrugAdmChartModal);

